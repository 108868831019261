import React, { useState, useRef } from 'react';
import './responsive.css';

import zoomIcon from "../../../Assets/icons/pan_zoom.png"
import { PropertImageBaseUrl } from '../../../Utils/urls';
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
import ImageModal from './ImageModal';


export default function MobileImageSlider({ AllPropertyImages }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);
  const imageContainerRef = useRef(null);
const [showbigimage,setShowBigimage]=useState(false)


 

  const sampleimages = AllPropertyImages.map(image => {
    const { Key, bucket } = image;
    return `${PropertImageBaseUrl}/${Key}`;
  });


  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    if (!touchPosition) {
      return;
    }

    const touchDown = touchPosition;
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      goToNextSlide();
    }

    if (diff < -5) {
      goToPrevSlide();
    }

    setTouchPosition(null);
  };

  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === sampleimages.length - 1 ? 0 : prevIndex + 1));
  };

  const goToPrevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? sampleimages.length - 1 : prevIndex - 1));
  };

  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };


  const handletoggleShowBigImage=()=>{
        setShowBigimage(!showbigimage)
  }
  const handleHideBigImage=()=>{
    setShowBigimage(false)
  }
  return (
    <div className="carousel-mobile font-nunito">
      <div className="carousel-container-mobile ">

      <div className='w-full z-10 flex justify-between  items-center absolute py-2 px-3'>
       
       <Link to="/">
       <MdArrowBack />
       </Link>
   

        <img src={zoomIcon} alt="zoom" className='cursor-pointer' onClick={handletoggleShowBigImage} />
      </div>


        <div
          className="image-container-mobile"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          ref={imageContainerRef}
        >
          <img src={sampleimages[currentImageIndex]} alt={`Slide ${currentImageIndex}`} className="carousel-img w-full" />
        </div>

        <div className="carousel-dots-mobile">
          {sampleimages.map((image, index) => (
            <div
              key={index}
              className={`carousel-dot-mobile ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
      </div>


      



{
  showbigimage && (
    <ImageModal closeModal={handleHideBigImage}>

<img src={sampleimages[currentImageIndex]} alt="" className='w-full' />
    </ImageModal>
  )
}


 




    </div>
  );
}
