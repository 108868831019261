import React, { useState } from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { ApiBaseUrl, PropertImageBaseUrl } from '../../Utils/urls';


const ImageCarousel = ({propDetails }) => {

const {property_info}=propDetails
const floorPlans=property_info?.floor_plan

// const imageKeys = floorPlans?.map(item => item.floor_image.Key) ?? [];
const imageKeys = floorPlans?.map(item => `${PropertImageBaseUrl}/${item.floor_image.Key.trim()}`) || [];
const descriptions = floorPlans?.map(item => item.description) ?? [];


  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === imageKeys?.length - 1 ? 0 : prevIndex + 1));
  };

  const goToPrevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? imageKeys?.length - 1 : prevIndex - 1));
  };

  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };


  return (
    <div className="carousel font-nunito py-2">

      <div className='top-text text-center'>
        <p className='text-sm font-semibold text-black'>
        {floorPlans?.length > 0 && floorPlans[currentImageIndex]?.title}
        </p>
         <p className='text-xs font-medium'>{floorPlans?.length > 0 && floorPlans[currentImageIndex]?.value}  Sq Ft</p> 
        </div>

    <div className="carousel-container">
      <button className="carousel-control prev text-black" onClick={goToPrevSlide}>
      
        <MdKeyboardArrowLeft className='text-base sm:text-2xl text-gray' />
      </button>

      <div className="image-container">
        <img src={imageKeys[currentImageIndex]} alt={`Slide ${currentImageIndex}`} className='fplan-carousel-img w-full'/>
        <p className="text-overlay  font-nunito text-xs sm:text-sm"> {descriptions[currentImageIndex]}
</p>
      </div>
      <button className="carousel-control next text-black" onClick={goToNextSlide}>
         <MdKeyboardArrowRight className='text-base sm:text-2xl text-gray' />
      </button>
      
    </div>
    <div className="carousel-dots">
      {imageKeys && imageKeys.map((image, index) => (
        <div
          key={index}
          className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
          onClick={() => goToSlide(index)}
        ></div>
      ))}
    </div>

    <div className='bottom-text text-center hidden'>
        <p className='text-sm font-semibold text-blue'>
           {floorPlans?.length > 0 && floorPlans[currentImageIndex]?.title}</p>
        <p className='text-sm font-bold text-blue'>{floorPlans?.length > 0 && floorPlans[currentImageIndex]?.value} Sq Ft</p>
        </div>
  </div>
  );
};

export default ImageCarousel;








