import React from 'react'

export default function DeleteIcon() {
  return (
    <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M81.8071 55.4622C87.3852 58.9206 90.8436 65.1522 90.8436 71.7025C90.8436 78.7947 86.7796 85.4725 80.4683 88.7238C77.8227 90.0784 74.8105 90.8132 71.7664 90.8132C69.3918 90.8132 67.0489 90.3669 64.8655 89.5366C63.2558 88.9309 61.7418 88.1181 60.3711 87.0997C55.0639 83.1791 52.1155 76.6591 52.6574 70.0769C53.2152 63.5919 57.1518 57.6934 62.9689 54.6988C65.6305 53.3282 68.6443 52.6094 71.7027 52.6094C72.3243 52.6094 72.9618 52.6428 73.5674 52.7209C76.5158 52.9759 79.3368 53.9163 81.8071 55.4622ZM80.7393 64.945C81.3608 64.3234 81.3608 63.3194 80.7393 62.6978C80.1161 62.0763 79.0977 62.0763 78.4761 62.6978L71.7186 69.4553L64.9611 62.6978C64.3396 62.0763 63.3212 62.0763 62.698 62.6978C62.0764 63.321 62.0764 64.325 62.698 64.945L69.4714 71.7184L62.698 78.4759C62.0764 79.0975 62.0764 80.1016 62.698 80.7231C63.0168 81.0403 63.4152 81.1853 63.8296 81.1853C64.2439 81.1853 64.6424 81.0403 64.9611 80.7231L71.7186 73.9656L78.4761 80.7231C78.7949 81.0403 79.1933 81.1853 79.6077 81.1853C80.0221 81.1853 80.4205 81.0403 80.7393 80.7231C81.3608 80.1 81.3608 79.0959 80.7393 78.4759L73.9818 71.7184L80.7393 64.945Z" fill="#FC83F8"/>
<path d="M66.141 29.4681C66.141 34.4231 64.1329 39.0625 60.4672 42.5369C59.7341 43.2365 58.9373 43.8756 58.1085 44.4318C54.9848 46.5675 51.2713 47.7628 47.5419 47.7628C44.721 47.7628 41.9957 47.0934 39.4616 45.7881C38.7444 45.4216 38.0751 45.0072 37.4216 44.5753C37.4057 44.5434 37.4057 44.5434 37.3898 44.5434C32.7679 41.3081 29.7557 35.9372 29.5007 30.2953C29.2138 23.6828 32.5129 17.4018 38.1229 13.9131C41.0076 12.1122 44.3544 11.1719 47.8288 11.1719C51.0163 11.1719 54.1241 11.9847 56.8494 13.5163C62.571 16.7819 66.141 22.8859 66.141 29.4681Z" fill="#FC83F8"/>
<path d="M59.2716 47.4785C61.9634 48.0682 64.625 48.881 67.1925 49.8851C65.2003 50.2835 63.2878 50.9529 61.5028 51.8613C54.7294 55.3676 50.1234 62.2366 49.4859 69.8069C48.8325 77.4888 52.275 85.091 58.4763 89.6651C58.7138 89.8404 58.9544 90.0173 59.1919 90.1751C44.0672 91.9282 28.6556 90.1416 14.2975 84.9316C12.4153 84.2463 11.1562 82.4454 11.1562 80.4373V69.3448C11.1562 63.3363 14.1684 57.8235 19.2366 54.5707C24.4322 51.2398 30.1059 48.8651 36.1303 47.5263C36.7359 47.9248 37.3575 48.2897 38.0109 48.6244C41.0072 50.172 44.2106 50.9513 47.5416 50.9513C51.6534 50.9529 55.7653 49.7098 59.2716 47.4785Z" fill="#FC83F8"/>
</svg>

  )
}
