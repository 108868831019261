import React, { useEffect, useRef, useState } from 'react'
import NomineeContainer from './NomineeContainer'
import CustomeNomineeInput from './CustomeNomineeInput'
import "./nominee-style.css"
import NomineeModal from './NomineeModal';
import { OtpTab } from '../Signup/OtpTab';
import { changeDateFormat } from '../../Utils/DateFormat';
import { ApiBaseUrl } from '../../Utils/urls';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';
import { Link, useNavigate } from 'react-router-dom';
import { AddNomineeFunc, NomineeVerifyFunc, ResendAddNomineeFunc, fetchAllNominee } from '../../Api/NomineeApi';
import { IoMdArrowBack } from 'react-icons/io';
import BankLoader from "../../Components/BankDetails/BankLoader"
import loader from "../../Assets/icons/Loader.gif"

export default function NomineeDetailsForm() {
  const [percentage, setPercentage] = useState(1);
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [isVerifying,setIsVerying]=useState(false)
  const sliderRef = useRef(null);
  const valueRef = useRef(null);
  const [nomineeDetails,setNomineeDetails]=useState({percentage_of_investment:1})
  const [isMobileTyping,setMobileTyping]=useState(false) //to check  user has started typing mobile no or not
  const [isAadharTyping,setAadharTyping]=useState(false) //to check  user has started typing aadhar no or not
  const [istotalInputFilled,settotalInputFilled]=useState(false) //to check all input filled or not to enable add button
  const [isOtpsent,setOtpsent]=useState(false)
  const [isSameAadhaar,setSameAadhar]=useState(false) //same aadhaar error
  const [alertMessage,setAlertMsg]=useState("")
  const [isotpVerifying,setOtpVerifying]=useState(false) //for otp verify button string
  const [isOtpVeried,setOtpVerified]=useState(false)//for successfull otp verification alert
  const [iswrongOtp,setWrongOtp]=useState(false)
  const userId=localStorage.getItem("user_id")
  const [isSendingOtp,setSendingOtp]=useState(false) //loader for sending otp
  const accesstoken=localStorage.getItem("accestoken")
  const userCountryCode=localStorage.getItem("user_country_code")
  const userMobileNo=localStorage.getItem("user_mobile_no")
  const [isLoading,setLoading]=useState(false)
  const [allNominee,setAllNominee]=useState([])



const navigate=useNavigate()
 
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setTimerActive(false)
          
        }
        return prevTimer - 1;
      });
    }, 1000);
  
    
    return () => clearInterval(interval);
  }, [timerActive]);

  useEffect(() => {
    if (otp.length === 4) {
      setAllInputsFilled(true);
    } else {
      setAllInputsFilled(false);
    }
  }, [otp]);


  const openModal = () => {
    setIsModalOpen(true);
    setTimer(60); 
    setTimerActive(true)
  };
  
  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  const handleResend=()=>{
    handleResendOtp()
    setTimer(60); 
    setTimerActive(true)
  }

const handleTypingMobile = () => {
    setMobileTyping(true);
};
const handleTypingAadhar = () => {
  setAadharTyping(true);
};

const handleNomineeInputChange=(e)=>{
  const { name, value } = e.target;
  if (name === 'percentage_of_investment') {
    // If the input is the percentage range input, update the percentage state
    setPercentage(value);
  }
  setNomineeDetails({ ...nomineeDetails, [name]: value });
  
}


useEffect(() => {
  // Check if all required fields are filled
  const isFormFilled =
    nomineeDetails.nominee_name &&
    nomineeDetails.mobile_no &&
    nomineeDetails.gender &&
    nomineeDetails.relation &&
    nomineeDetails.dob &&
    nomineeDetails.aadhar_number &&
    nomineeDetails.percentage_of_investment &&
    nomineeDetails.aadhar_number.length === 12 &&
    nomineeDetails.mobile_no.length === 10
  

  // Update the allInputsFilled state
  settotalInputFilled(isFormFilled);
}, [nomineeDetails]);


//sent otp for adding nominee

const handleAddNominee=(e)=>{
  AddNomineeFunc(e,
    nomineeDetails,
    userId,
    setOtpVerifying,
    accesstoken,
    setOtpsent,
    openModal,
    setSameAadhar,
    setAlertMsg,
    setSendingOtp
  )
}

//resend otp for adding nominee

const handleResendOtp=()=>{
  ResendAddNomineeFunc(
    nomineeDetails,
    userId,
    setOtpVerifying,
    accesstoken,
    setOtpsent,
    openModal,
    setSameAadhar,
    setAlertMsg)
}

const handlenomineeVerify=()=>{
  NomineeVerifyFunc(
    userId,
    otp,
    accesstoken,
    setOtpVerified,
    closeModal,
    navigate,
    setWrongOtp)
}

useEffect(()=>{
  fetchAllNominee(userId,accesstoken,setAllNominee,setLoading,navigate)
},[])

const totalAllocatedNominee = allNominee.reduce((total, current) => {
  
  return total + parseFloat(current.percentage_of_investment);
}, 0);

const remainingAlocationNom=100-totalAllocatedNominee

  return (


    <>

{
  isSendingOtp && (
    <div className='loader-nomineeAbsolute'>
    <img src={loader} alt="Loader"  className='nomineeLoader' />
    </div>
  )
}
{
  isOtpVeried && (
    <div className='loader-nomineeAbsolute'>
    <img src={loader} alt="Loader"  className='nomineeLoader' />
    </div>
  )
}

{
  isOtpsent && (
    <SuccessAlert message="OTP Sent Successfully" />
  )
}

{
  isOtpVeried && (
    <SuccessAlert message="Nominee Added Successfully" />
  )
}
    

{
  iswrongOtp && (
    <ErrorAlert message="Invalid OTP. Please Try Again!" />
  )
}


{
  isSameAadhaar && (
    <ErrorAlert message={alertMessage}/>
  )
}
{
  isModalOpen &&(
<NomineeModal closeModal={closeModal}>
         <div className='flex flex-col gap-7 font-nunito'>
            <p className='text-secondary text-xl font-bold text-center'>OTP Verification</p>
            <p className='text-lg font-normal text-center'>Enter 4 digit OTP sent to registered mobile 
number <span className='text-secondary'>{userCountryCode}{userMobileNo}</span> </p>
           
              <div className='flex flex-col gap-9 '>
                <div className='flex flex-col gap-9 '>
                <OtpTab length={4} maxChar={1} setOtp={setOtp} otp={otp} setAllInputsFilled={setAllInputsFilled}/>
                  <button onClick={handlenomineeVerify} disabled={!allInputsFilled ||isOtpVeried} 
                  className={` text-white verifyBtn-profile ${allInputsFilled ? 'bg-secondary text-white' : 'disable-btn-bg disabled'} `}
                  
                  >
                    {isOtpVeried?"Verifying...":"Verify"}
                  </button>
         
                

                </div>
                 
                 
             
               
            <div>

            {
                timerActive?
                <p className='text-sm text-center'>00:{timer < 10 ? `0${timer}` : timer}</p> 
                  :
                  ""
              }
           {/* <p className='text-sm text-center'>00:60</p>  */}

           {
            !timerActive ?
<div className='flex gap-5  items-center justify-center '>
              <p className='text-xs sm:text-lg font-normal text-gray'>Didn’t receive any code? 
              <span className='text-xs sm:text-sm font-normal text-secondary' onClick={handleResend}>
                &nbsp; RESEND</span> 
                </p>
            </div>
            :
            ""
           }
             
          </div>
        
             
         
         
              </div>


         </div>
          
        </NomineeModal>
  )
}


    <NomineeContainer>
       <div>
      <div className='font-nunito'>

  



 <div className='flex gap-2 items-center px-2.5 pb-4'>
  <Link to='/user/nominee'>
  <IoMdArrowBack />
  </Link>
  <div className='text-center w-full'>
  <p className='text-base sm:font-medium text-center sm:text-left font-bold '>Nominee Details </p>
  </div>

 </div>


        <div className='profileSection  font-nunito'>

<form  onSubmit={handleAddNominee}>
    <div className='flex flex-col gap-2.5'>
        {/* form */}
        <div className='flex flex-col gap-4'>

                <div className='flex flex-col sm:flex-row labeldiv'>

                    <div className='flex w-full 2xl:w-1/2 lg:w-1/2  md:w-full flex-col gap-2.5 px-2 sm:px-10 '>
                            <p className='text-left text-sm text-gray '>Nominee Name <span style={{color:"#c51f1f"}}>*</span></p>
                          <div>
                          <CustomeNomineeInput 
                            name="nominee_name" 
                            value={nomineeDetails.nominee_name} 
                            onChange={handleNomineeInputChange}
                            placeholder="Full Name (As per PAN Card)"
                            maxLength={70}
                             />
                           
                          <p className='text-sm'></p>
                          </div>
                           
                    </div>

                    <div className='flex w-full 2xl:w-1/2 lg:w-1/2  md:w-full flex-col gap-2.5  px-2 sm:px-10'>
                            <p className='text-left text-sm text-gray'>Mobile Number<span style={{color:"#c51f1f"}}>*</span></p>
                           <div >
                           <CustomeNomineeInput 
                            name="mobile_no" 
                            maxLength="10"
                           onChange={(e)=>{
                              handleTypingMobile();
                              handleNomineeInputChange(e)
                          }}
                            numericInput={true}
                             placeholder="Enter mobile number"
                            value={nomineeDetails.mobile_no}  
                            />
      
                            {
  isMobileTyping && nomineeDetails.mobile_no.length !== 10 &&
  <p className='text-sm' style={{color:"#e23838",textAlign:"left"}}>
  Please enter a valid 10-digit number.
  </p>
                            }
                           </div>
                            
                    </div>
                </div>


                <div className='flex flex-col sm:flex-row labeldiv'>

         <div className='flex w-full  flex-col gap-2.5  px-2 sm:px-10 '>
                  <p className='text-left text-sm text-gray '>Gender<span style={{color:"#c51f1f"}}>*</span></p>
              <select 
              name="gender"
              value={nomineeDetails.gender}  
              onChange={handleNomineeInputChange}
            className='border w-full border-gray text-sm text-gray rounded-md  sm:rounded-lg nominee-input'
            >

            <option value="" selected  disabled>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
         </select>

                    </div>

    <div className='flex  w-full flex-col gap-2.5  px-2 sm:px-10'>
        <p className='text-left text-sm text-gray'>Relation<span style={{color:"#c51f1f"}}>*</span></p>
          <select 
             name='relation'
              value={nomineeDetails.relation}  
              onChange={handleNomineeInputChange}
            className='border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg nominee-input'
            >

            <option   selected  disabled>Relation with Nominee</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Sister">Sister</option>
              <option value="Brother">Brother</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Spouse">Spouse</option>
         </select>
                    </div>
                </div>

      <div className='flex  flex-col sm:flex-row labeldiv'>
          <div className='flex sm:w-1/2  flex-col gap-2.5  px-2 sm:px-10 '>
            <p className='text-left text-sm text-gray '>Date of Birth<span style={{color:"#c51f1f"}}>*</span></p>
              <input
              type="date"
              className={`border grow border-gray  text-sm text-gray rounded-md  sm:rounded-lg   profile-input`}
              placeholder=""
               name="dob"
               onChange={handleNomineeInputChange}
               value={nomineeDetails && nomineeDetails.dob? changeDateFormat(nomineeDetails.dob):""} 
               max={new Date().toISOString().split("T")[0]}
             />
            </div>

            <div className='flex sm:w-1/2 flex-col gap-2.5  px-2 sm:px-10'>
             
              <p className='text-left text-sm text-gray'>Aadhar Number <span style={{color:"#c51f1f"}}>*</span></p>
                 <CustomeNomineeInput 
                 name="aadhar_number" 
                  placeholder="1234567890"
                  maxLength="12"
                 numericInput={true}
                  onChange={(e)=>{
                    handleTypingAadhar()
                    handleNomineeInputChange(e)
                  }

                  }
                  value={nomineeDetails.aadhar_number}  
                   />
                   {
                      isAadharTyping && nomineeDetails.aadhar_number.length !== 12 &&
                      <p className='text-sm' style={{color:"#e23838",textAlign:"left"}}>
                      Please enter a valid 12-digit aadhar number.
                      </p>
                      }
              
                
           </div>
     </div>



                  <div className='flex flex-col sm:flex-row  labeldiv'>

                <div className='flex sm:w-1/2 flex-col gap-2.5  px-2 sm:px-10 '>
                  <p className='text-left text-sm text-gray '>What percentage of your investment do you wish to dedicate to this nominee?</p>
             

      {/* show range here */}
      <div className="slider-value" ref={valueRef}>{percentage}%</div>
       <input 
                        type="range"
                        min="1"
                        max="100"
                        name='percentage_of_investment'
                        value={nomineeDetails.percentage_of_investment} 
                        onChange={handleNomineeInputChange}
                        className='nomineeRangeSlider'
                        ref={sliderRef}
                      />

<div className='flex gap-2'>
<span class="material-symbols-outlined allocationIcon">
donut_large
</span>

<p>Remaining allocation: {remainingAlocationNom}%</p>
</div>
                    </div>

                </div>   
        </div>

        <div className='flex  2xl:pl-10 pr-0 sm:pl-10 pt-10'>
            <button 
            type='submit' 
            className={`nomineesibmitRelative   ${istotalInputFilled? 'activenomineeBtn':'disablenomineeBtn'}`}
            disabled={!istotalInputFilled}
            >
             
              Add Nominee
            </button>
        </div>


     </div>
</form>
   
           
          </div>

</div>
    </div>
    </NomineeContainer>

    </>
  )
}
