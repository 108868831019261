import React, { useState,useEffect } from 'react'
import { MdArrowBack } from 'react-icons/md'
import "./purchase-style.css"
import { IoMdArrowDropdown, IoMdInformationCircleOutline } from 'react-icons/io'

import { Link, useLocation,useNavigate } from 'react-router-dom'
import { RiArrowUpSFill } from 'react-icons/ri'
import CryptoJS from 'crypto-js';
import Navbar from '../Navbar/Index'

import { ApiBaseUrl } from '../../Utils/urls'
import loader from "../../Assets/icons/Loader.gif"
import Footer from '../MainFooter/Footer'
import MobileFooter from '../MainFooter/MobileFooter'
import WarningAlert from '../Alert/WarningAlert'
import { formatForUrl } from '../../Utils/formatSinglePropertyUrl'
import { FaRegQuestionCircle } from "react-icons/fa";

import PurchaseModal from './PurchaseModal'
import PayNowOwnLaterPopup from './PayNowOwnLaterPopup'

export default function PurchasePageResale() {

    const [showBreakdown,setShowBreakdown]=useState(false)
    const location = useLocation();
    
   const [grandTotal,setGrandTotal]=useState(0)

    const [isLoggedin, setisLoggedin] = useState(false); 
    const [userId, setUserId] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isePaymentLoading,setpaymentLoading]=useState(false)
    const [gateWayLink,setGateWayLink]=useState("")
    const [showExitTokenLimit,setShowExittokenLimit]=useState(false)
    const [ExitTokenLimitMsg,setExitTokenLimitMsg]=useState("")
    const [paymentRefid,setpaymentRefId]=useState("")
    
    const [showPayLaterPopUp,setShowPayLaterPopup]=useState(false)
    const [showConsentPopUp,setConsentPopup]=useState(false)

const handleShowPaylaterPop=()=>{
  setShowPayLaterPopup(true)
}

const handleClosePaylaterPop=()=>{
  setShowPayLaterPopup(false)
}

const handleShowConsentPop=()=>{
  setConsentPopup(true)
}

const handleCloseConsentPop=()=>{
  setConsentPopup(false)
}
  const navigate=useNavigate()

    const accesstoken=localStorage.getItem("accestoken")
    useEffect(() => {
      // Check if there's a user ID and session LS in localStorage
      const userIdFromLocalStorage = localStorage.getItem('user_id');
      const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
      
      if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
        // If both exist, set isLoggedin to true and store the user ID in the state
        setisLoggedin(true);
        setUserId(userIdFromLocalStorage);
      }
    }, [isLoggedin]); // Run only once when the component mounts
    
    const { 
      tokenCountResaleMob,
      totalPriceResaleMob,
      formattedPriceResaleMob,
      gstFeeResaleMob,
      platformFeeResaleMob,
      totalFeeResaleMob,
      id,
      propertyCity,
      propertyName,
      isSuccessfulPayment,
      lockinPeriod

     } 
        = location.state || {};

  


        const handleCheckboxChange = () => {
            setIsChecked(!isChecked);
          };

    const handleToggleShiwBeakDown=()=>{
        setShowBreakdown(!showBreakdown)
    }

  
      useEffect(()=>{
        setGrandTotal(totalFeeResaleMob)
      },[])


      let queryData={
        formattedPriceResaleMob,
        lockinPeriod,
        gstFeeResaleMob,
        platformFeeResaleMob,
        totalFeeResaleMob,
        grandTotal,
        tokenCountResaleMob,
      
        propertyId:Number(id)
      }



      const jsonQuery=JSON.stringify(queryData);

      const encryptedjsonQuery=CryptoJS.AES.encrypt(jsonQuery, 'PROPFTX_PAYMENT').toString();
      
      
     let currentUrl = window.location.href;

    

     currentUrl=currentUrl.replace("/purchase", "/success")
          // Append the encrypted data to the existing query string
    currentUrl += `?payment=true&data=${encodeURIComponent(encryptedjsonQuery)}`;
    // const test=()=>{
    //   window.location.replace(currentUrl)
    // }
       

      const redirectUrl=currentUrl

   
      let paymentBody={
        user_id: userId, 
        property_id:Number(id), 
        number_of_shares: tokenCountResaleMob, 
        price_per_share: parseFloat(formattedPriceResaleMob), 
        transaction_type: "BUY_PRIMARY_SHARES", 
        isPartCryptoAmount: false,
        isPartCrypto: false,
        redirect_url:redirectUrl
    }

  const handleInitiatePayment=()=>{
        setpaymentLoading(true)
        fetch(`${ApiBaseUrl}/fr-payment/initiate-payment`, 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': accesstoken 
          },
          body: JSON.stringify(paymentBody)
        }
        
        )
          .then(response => {
          
            if (!response.ok) {
              console.log("not ok");
            }
           
            return response.json();
          })
          .then(res => {
          setpaymentLoading(false)
          if(res.success)
          {
 setGateWayLink(res.data.gateway_link)
 setpaymentRefId(res.data.payment_ref_id)
 
           console.log('Response from server:', res);
          //  navigate(res.data.gateway_link)
         
            window.location.href = res.data.gateway_link;
         
        //  window.location.replace(res.data.gateway_link);
          }
          else if(!res.success)
          {
            setShowExittokenLimit(true)
            setExitTokenLimitMsg(res.data)
            setTimeout(() => {
              setShowExittokenLimit(false)
             
            }, 3000);
            
            // console.log(res.data)
          }
         
      
           
          })
          .catch(error => {
           
            console.error('error:', error);
           
          });
      
      }




      useEffect(() => {
        // Scroll to the top 
       
            window.scrollTo(0, 0);
        
      }, [location]);
         
   
//see here

const handleBack=()=>{

  navigate(`/${formatForUrl(propertyCity)}/${formatForUrl(propertyName)}/${"RESALE"}/${id}`)
}
 
    
  return (

    

    <>
{
  showExitTokenLimit  && (
    <WarningAlert message={ExitTokenLimitMsg}/>
  )
}


{
    isePaymentLoading && (
        <div className='loader-bankAbsolute'>
    <img src={loader} alt="Loader"  className='bankLoader' />
    </div>
    )
}


    < div class="container mx-auto pt-20 font-nunito ">
    <Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
        <div className='flex flex-col gap-2.5 px-3 purchase-header'>

            <div className='flex gap-2.5 items-center cursor-pointer'>

           {/*  onClick={handleBack}*/}
                <MdArrowBack style={{fontSize:"24px"}} onClick={handleBack} />
              
                
                
                <p className= 'text-lg font-normal sm:text-2xl sm:font-bold text-left text-blue'>Purchase Confirmation</p>

            </div>

            <hr  className='hrstyle'/>
        </div>

       
       <div className='purchaseSection flex pt-10 justify-between flex-col sm:flex-row'>
            <div className="leftPart">

                    <div className="inerPart flex flex-col gap-2.5 sm:gap-10">

                        <div className='flex flex-row-reverse items-center justify-between sm:flex-col gap-2.5'>
                            <p className='text-sm font-semibold  sm:text-2xl sm:font-bold'>{tokenCountResaleMob && tokenCountResaleMob}</p>
                            <p className='text-sm  font-medium sm:text-base sm:font-normal'>Selected Tokens</p>
                        </div>

                        <div className='flex flex-row-reverse items-center justify-between sm:flex-col gap-2.5'>
                            <p className='text-sm font-semibold  sm:text-2xl sm:font-bold'>₹ {formattedPriceResaleMob  && parseFloat(formattedPriceResaleMob).toFixed(2)}</p>
                            <p className='text-sm  font-medium sm:text-base sm:font-normal'>Token Price </p>
                        </div>


                    </div>

            </div>
            <div className="rightPart flex flex-col gap-4">

                <div className='calculationPart flex flex-col gap-4'>
                    <div className="grandTotal flex flex-col gap-2.5">
                            <div className='w-full items-center flex justify-between'>
                                <p className='text-secondary text-base sm:text-lg font-medium'>Grand Total</p>
                                <p className='text-blue text-sm sm:text-2xl font-bold'>₹ {grandTotal && grandTotal.toLocaleString()}</p>
                            </div>

                            <div className='w-full items-center flex justify-between'>
                                <p className='text-xs'>Total Token Value + Platform Fee + Taxes</p>
                                <div className='flex gap-1.5 text-secondary items-center cursor-pointer text-xs' onClick={handleToggleShiwBeakDown}>
                                    <p >{showBreakdown?"Hide":"Show"} Breakdown</p>
                                   {
                                    showBreakdown?
                        <IoMdArrowDropdown />
                                    :
                                    <RiArrowUpSFill />
                                   }
                                    
                                  
                                </div>
                            </div>

                    </div>

                <div className="feepart flex flex-col gap-4">

                    {showBreakdown && 
                    <>
                     <div className='flex w-full justify-between'>
                     <div className='flex gap-1 items-center'>
                         <p className='text-xs sm:text-sm'>Token Price</p>
                         <div className="tooltip">
                           <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">

                                <p className='text-blue text-xs sm:text-sm'>What is Token Price?</p>
                                <p className='text-gray text-xs sm:text-sm'>
                                      It is the total price of tokens held by the investor.
                                     </p>
                              </span>
                          </div>
                          </div>
                          <p className='text-xs sm:text-sm font-bold text-balance'>₹ {totalPriceResaleMob && parseFloat(totalPriceResaleMob).toFixed(2)}</p>
               </div>

                       

              <div className='flex w-full justify-between'>
                     <div className='flex gap-1 items-center'>
                     <p className='text-xs sm:text-sm'>Platform Handling Fee</p>
                     
                     <div className="tooltip">
                           <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">

                                <p className='text-blue text-xs sm:text-sm'>What is Platform Handling fee?</p>
                                <p className='text-gray text-xs sm:text-sm'>
                                PropFTX charges a platform handling fee of 2.5% on the token price.

                                     </p>
                              </span>
                          </div>

                 </div>
                     
                 <p className='text-xs sm:text-sm font-bold text-balance'>₹ {platformFeeResaleMob && platformFeeResaleMob.toLocaleString()}</p>
                 </div>


          <div className='flex w-full justify-between'>
                 <div className='flex gap-1 items-center'>
                     <p className='text-xs sm:text-sm'>GST on (Platform Handling Fee)</p>
                     

                     <div className="tooltip">
                           <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">

                                <p className='text-blue text-xs sm:text-sm'>What is GST on Platform Handling fee?</p>
                                <p className='text-gray text-xs sm:text-sm'>
                                GST of 18% is charged on platform handling fees


                                     </p>
                              </span>
                          </div>

                 </div>
                     <p className='text-xs sm:text-sm font-bold text-balance'>₹ {gstFeeResaleMob && gstFeeResaleMob.toLocaleString()}</p>
             </div>
             </>  
                    }
                   



{/* total fee */}
                <div className='flex w-full justify-between'>
                   
                        <p className='text-sm sm:text-sm font-bold text-blue'>Total</p>
                        
                        <p className='text-sm sm:text-sm  font-bold text-secondary'>₹ {totalFeeResaleMob && totalFeeResaleMob.toLocaleString()}</p>
                </div>
{/* total fee */}

                    </div>




                    <div className="legalPart w-full flex gap-4">

                        <span class="material-symbols-outlined protect-icon" >
                        verified_user
                        </span>

                        <div className='flex flex-col gap-2 text-left'>
                            <p className='text-xs sm:text-sm font-extrabold text-gray'>Your investment is under legal protection.</p>
                            <p className='text-xs  text-gray'>Rest assured, your investment is fully secured by legal measures. We'll promptly prepare the mutual legal agreement /investment certificate and send 
                                it to your registered email address .</p>
                                <p className='text-xs  text-gray '>
                                  {/* <span className='font-bold'> Note :</span>
                                We use the address provided in the KYC verification documents to generate the digital invoice for the purchase. */}
                                </p>
                        </div>


                    </div>

                </div>
{
  showPayLaterPopUp && (
<PayNowOwnLaterPopup  handleClosePaylaterPop={handleClosePaylaterPop} />
  )
}

{
  showConsentPopUp && (
    <PurchaseModal width={'max-w-4xl'} closeModal={handleCloseConsentPop}>


<div className='overflow-auto' style={{"height":"500px"}}>
    <p className='text-blue font-bold'>Consent to Share KYC Information for Loan Approval</p>
    
    <div className='flex flex-col gap-4 text-left text-gray text-sm '>
    
    <div>
    At PropFTX, we strive to provide seamless services to our users, including facilitating access to loan facilities for purchasing tokens. To expedite the loan approval process, we collaborate with trusted loan providers 
    who may require access to your Know Your Customer (KYC) information. Your consent to share this information
     is crucial for us to proceed with your loan application.
    </div>
    
    
<div>
 <ul>
   <b>Details Shared:</b> 
      <li>Full Name</li>
      <li>Date of Birth</li>
      <li>Residential Address</li>
      <li>Identification Document (e.g., Passport, Driver's License)</li>
      <li>Any additional KYC information deemed necessary for loan approval</li>
 </ul>
</div>
    

<div>
 <ul>
   <b>Purpose:</b> 
   <li>Sharing your KYC information will enable the loan facility vendor to assess your eligibility for the loan quickly and efficiently, thereby expediting the process of purchasing tokens on PropFTX</li>
  </ul>
</div>
    
    

<div>
 <ul>
   <b>Data Protection:</b> 
   <li> We prioritize the security and confidentiality of your personal information. Your data will be transmitted securely and handled with the utmost care to prevent unauthorized access or misuse.
   </li>
  </ul>
</div>
   


<div>
 <ul>
   <b>Withdrawal of Consent:</b> 
   <li> You have the right to withdraw your consent at any time by contacting our customer support team. However, please note that withdrawing 
    consent may affect the processing of 
   </li>
  </ul>
</div>
    
    
 <div className='flex  flex-col gap-4 items-center justify-center md:flex-row' >
  <button className='purchaseBtn bg-secondary text-white'>Yes, I agree</button>
  <button className='purchaseBtn bg-secondary text-white'>Visit Efundzz</button>
  </div>  
    
    
    </div>
    </div>
    
    </PurchaseModal>
  )
}






                <div className='btnPart flex flex-col mx-auto w-full justify-center gap-2.5  '>

                        <div className='flex gap-3 items-center text-left sm:text-center justify-center'>
                                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                             <p className='text-xs text-blue'>I hereby agree to buy token(s) and accept 
                             all the     <a href="https://www.propftx.com/terms-of-use/" target='_blank'>
                              <span className='text-secondary'>Terms of use</span>
                              </a>  of PropFTX 
                              </p>
                        </div>

                        
                        <div className='flex gap-4 items-center w-full justify-center flex-col md:flex-row'>
                        <button onClick={handleInitiatePayment} className={`  payNowBtn ${isChecked  ?'bg-secondary text-white':'bg-lightgray text-black'}`} disabled={!isChecked || isePaymentLoading} 
                        
                        >Pay Now</button>



{/* <div className='flex gap-2 items-center justify-center ownNowSec' >
<button onClick={handleInitiatePayment} className={`ownNowBtn  ${isChecked  ?'bg-secondary text-white':'bg-lightgray text-black'}`} disabled={!isChecked || isePaymentLoading} 
                        
                        >Pay Now</button>

<FaRegQuestionCircle className=' text-white ' />
</div>


<div className='flex gap-2 items-center justify-center ownNowSec' >
<button onClick={handleShowConsentPop} className={`ownNowBtn ${isChecked  ?'bg-secondary text-white':'bg-lightgray text-black'}`} disabled={!isChecked || isePaymentLoading} 
                        
                        >Own Now, Pay Later</button>

<FaRegQuestionCircle className='text-lg text-secondary cursor-pointer' onClick={handleShowPaylaterPop} />
</div> */}

                        </div>
                        
                </div>


            </div>
       </div>
       
        </div>

        <Footer/>
<MobileFooter/>
        </>
  )
}
