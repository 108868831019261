import { signup, signupFailure, signupSuccess } from "../Redux/AuthRedux/authActions";
import { storeCountryCode, storePhoneNumber } from "../Redux/phoneRedux/phoneActions";


export const SignupFunc = (signUpData,dispatch,phoneNumbers,countryCode,setShowOtpAlert,setAllInputsFilled,navigate

) => {
 
    dispatch(signup(signUpData))
    
    .then((res) => {
        
      const { username, session,new_user,success } = res.data; 
    
      dispatch(signupSuccess(username, session,new_user,phoneNumbers,countryCode));
      localStorage.setItem("sessionLS",res.data.session)
      localStorage.setItem("signup_username",res.data.username)

      dispatch(storePhoneNumber(phoneNumbers))
      dispatch(storeCountryCode(countryCode))
     
      setShowOtpAlert(true);
      //  Remove alert after 1 second
      setTimeout(() => {
        setShowOtpAlert(false);
        // navigate to otp route
        navigate("/otp");
      }, 1000);
  
  
  
    })
    .catch((err) => {
      dispatch(signupFailure(err.message));
    });
  };