import React from 'react'
import "./responsive.css"
import { useState } from 'react';
export default function StickyPriceComponent(
    {showStickyPriceSection,
        priceSectionRef,
        handleClosePushSection,
        price,
        handleDecrement,
        handleIncrement,
        tokenCount,
        reachedTokenLimit,
        formattedPrice,
        platformFee,
        gstFee,
        tokenLimit,
        totalFee,
        handleProceed,
        isPropComingSoon
    }) {
      

        const [showTokenPricePopup, setShowTokenPricePopup] = useState(false);
        const handleclosetokenPriceInfoPopup=()=>{
          setShowTokenPricePopup(false)
        }
    
        const handleshowtokenPriceInfoPopup=()=>{
          setShowTokenPricePopup(true)
        }
    
   




  return (
    <div ref={priceSectionRef} class={`sticky-price-section ${showStickyPriceSection ? 'show' : ''}`}>
      
      {/* close-section */}
      <div className=" flex flex-row justify-center" onClick={handleClosePushSection}>
        <div className="h-1  w-60 mb-1 rounded-lg" style={{'backgroundColor':"#D9D9D9"}}>
            </div>

        </div>
        {/* close-section */}

        <div className='flex justify-between items-center font-nunito'>
        <p className='text-secondary text-xl font-bold'>Invest Now</p>
        <div className='flex flex-col'>
            <p className='text-blue text-base font-semibold'>₹ {price && parseFloat(price).toLocaleString()}</p>
            <p className='text-xs text-gray'>Per Token Price</p>
        </div>
    
    </div>

{/*******  increment-decrement-button-section   *******************/}
    <div className="btnSection pt-10" >

    <div className="flex items-center max-w-[15rem]  mx-auto py-2.5">

        
    <button type="button" onClick={handleDecrement} id="decrement-button" className="flex justify-center items-center bg-blue w-[81px] border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 d focus:ring-2 focus:outline-none">
        <svg className="w-3 h-3 text-gray-900 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"/>
        </svg>
    </button>
    <input 
        type="text" 
        id="quantity-input" 
        
        value={tokenCount}
        className="text-blue border-blue border h-11 text-center  text-lg font-semibold focus:ring-blue-500 focus:border-blue-500 block w-[81px] py-2.5" 
        placeholder="placeholder" 
        required 
    />

   


    <button type="button" disabled={reachedTokenLimit} onClick={handleIncrement} id="increment-button" data-input-counter-increment="quantity-input"
 className={`w-[81px] bg-${reachedTokenLimit ? 'lightgray' : 'blue'} border  rounded-e-lg flex justify-center items-center p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none`}>
    <svg className={`w-3 h-3  ${reachedTokenLimit ? 'text-black':'text-white'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
    </svg>
</button>
     </div>
     {reachedTokenLimit && <p className='text-xs' style={{color:"#d82222"}}>You can select maximum {tokenLimit} Tokens</p>}
   
    </div>


    {/********  total-amount ********/}
    <div className='flex items-center max-w-[15rem]  justify-around  mx-auto  font-nunito text-gray'>
        <p>Amount</p>
        <p>₹ {formattedPrice}</p>
    </div>

{/* price-section  */}
<div className="tokenSection flex flex-col gap-2.5 pt-7 ">
<div className="flex justify-between text-sm">
    <p className='font-nunito font-normal text-gray '>Token Price</p>
    <p className='font-nunito font-medium text-blue '>₹ {formattedPrice}</p>
</div>
<div className="flex justify-between text-sm">
    <p className='font-nunito font-normal text-gray'>Platform Handling Fee</p>
    <p className='font-nunito font-medium text-blue'>₹ {platformFee && (Number(platformFee).toFixed(2)).toLocaleString()}</p>
</div>

<div className="flex justify-between">
    <p className='font-nunito font-normal text-gray text-sm' >GST on <span className='text-xs'>(Platform Handling Fee)</span></p>
    <p className='font-nunito font-medium text-blue text-sm'>₹ {gstFee && (Number(gstFee).toFixed(2)).toLocaleString()} </p>
</div>
<div className="flex justify-between">
    <p className='font-nunito font-bold text-lg text-secondary text-sm' >Total</p>
    <p className='font-nunito  font-bold  text-blue'>₹ {Number(totalFee.toFixed(2)).toLocaleString()}  </p>
</div>
</div>


{
        isPropComingSoon==="CREATED"
        ?
        ( <button disabled className={`bg-lightgray text-black w-full py-2.5 px-4 rounded-md `}
        >Coming Soon</button>)
        :


(<button  onClick={handleProceed}  className='w-full bg-secondary text-white py-2.5 px-4 mt-3 rounded-md font-semibold text-base'>
            Proceed
        </button>)
    }

    </div>
  )
}
