import React, { useState } from 'react'
import propImage from '../../../Assets/propImageMob.svg'
import Location from '../../../Assets/bookedPropLocation.svg'
import Vertical from '../../../Assets/verticalLineBooked.svg'
import { RiArrowUpFill } from "react-icons/ri";
import Download from '../../../Assets/downloadBookedProp.svg'
import { IoMdInformationCircleOutline } from "react-icons/io";
import InfoPopup from '../../InfoPopup/InfoPopup'
import './Index.css'

function BookedPropertiesCardMob(props) {
    const [showdownloDInfoPopup, setShowdownloDInfoPopup] = useState(false);

    const handleshowdownloadInfoPopup = () => {
        setShowdownloDInfoPopup(true);
      };
      const handleclosedownloadInfoPopup=()=>{
        setShowdownloDInfoPopup(false);
      }

  return (
    <div>
      <div className=' flex flex-col p-2.5 rounded-lg parentBookedContainer'>
        <div className=' flex flex-row justify-center'><img src={propImage} className='w-full'/></div>
        
        <div className='-mt-8'>
            <div className=' text-start pl-[12px] '>
                <div className='bg-[#78F9FF] h-[22px] w-[76px] text-[12px] font-normal flex rounded-md flex-row justify-center'>
                    <div className='flex flex-col justify-center'>Residential</div>
                </div>
            </div>
            <div className=' mt-6 flex flex-col'>
                <div className=' text-[16px] font-semibold text-start'>{props.name}</div>
                <div className=' text-[16px] font-semibold text-start flex flex-row gap-[14px]'>
                    <div className=''><img src={Location}/></div>
                    <div className=' text-[12px] font-normal text-[#5D5C5C]'>{props.city}, {props.location}</div>
                </div>
            </div>

            <div className='mt-[15px]' style={{color:"#DE28A7",width:"100%"}}><img className='w-full' src={Vertical}/></div>



            <div className='flex flex-row justify-between mt-[15px]'>
                <div className=' flex flex-col'>
                    <div className='  text-[16px] font-semibold'>{props.myShare}</div>
                    <div className=' text-[12px] font-medium text-[#5D5C5C]'>No. of Tokens</div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <div className='  text-[16px] font-semibold'>₹{parseFloat(props.currentPrice).toFixed(0)}</div>
                        <div className='flex flex-col justify-center'><RiArrowUpFill style={{height:"24px",width:"18px",color:"#219653"}}/></div>
                    </div>
                    <div className=' text-[12px] font-medium text-[#5D5C5C]'>Current Value</div>
                </div>
                <div className=' flex flex-col'>
                    <div className='  text-[16px] font-semibold'> {  (((parseFloat(props.myShare)*parseFloat(props.currentPrice) - parseFloat(props.purchasedPrice)) / parseFloat(props.purchasedPrice)) * 100).toFixed(2)  }%</div>
                    <div className=' text-[12px] font-medium text-[#5D5C5C]'>Potential Gain</div>
                </div>
            </div>

            {/* <div className=' flex flex-row mt-5  justify-end  gap-[8px] items-center'>
                <div className=' text-[14px] font-semibold'>Share Certificate</div>
                
       
                <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleshowdownloadInfoPopup} />
                {

showdownloDInfoPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>Download</p>
                                <p className='text-gray text-left text-sm'>Please download the investor agreement from here.
                                 After a successful transaction,
                                 you will receive it via mail as well within 24 hours.
                            </p>
                            </div>
           
                            <button onClick={handleclosedownloadInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}


<div className=' flex flex-col justify-center'><img src={Download} className='h-[15px] w-[15px]'/></div>

   </div> */}





            <div className=' bg-[#D4D7DB] rounded-lg h-[44px] mt-[20px] flex flex-col justify-center'>
                <div className='flex flex-col justify-center text-[16px] font-bold'>Booked</div>
            </div>


        </div>


      </div>
    </div>
  )
}

export default BookedPropertiesCardMob
