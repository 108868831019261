import React from 'react'
import "./responsive-signup.css"



export default function LoginCustomInput({placeholder,value,onChange,maxLength,numericInput}) {
  const handleKeyPress = (e) => {
    if (numericInput) {
      // Allow certain keys even if they're not numbers
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
      const isNumeric = /^\d*$/.test(e.key);

      if (!isNumeric && !allowedKeys.includes(e.key)) {
        e.preventDefault(); // Prevent invalid key presses
      }
    }
  };


  return (
    <input
      type="text"
      className='border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg   login-input'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      style={{width:"100%"}}
      onKeyDown={handleKeyPress}
    />
  )
}
