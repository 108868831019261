import React, { useState,useEffect } from 'react';
import { IoMdSearch } from "react-icons/io";
import { TfiFilter } from "react-icons/tfi";
import './Index.css'; // Import CSS for styling
import { BookedPropertyFiltersProvider, useBookedPropertyFilters } from '../../Utils/bookedPropFilter';
import DashboardFilter from '../DashboardFilter/Index';
import BookedPropertiesCardMob from '../Cards/BookedPropertiesCardMob/Index';
import { BeatLoader } from 'react-spinners'
import { debounce } from 'lodash';
import { ApiBaseUrl } from '../../Utils/urls';

function BookedProperties(props) {
  const [searchInput, setSearchInput] = useState('');
  const [responseData, setResponseData] = useState([]);

  const [isOpenMob, setIsOpenMob] = useState(false);
  const togglePopup = () => {
    props.setshowMobFilter(!props.showMobFilter);
  };

  const [isLoading,setLoading]=useState(false)
  const [isOneSecondOver, setisOneSecondOver] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setisOneSecondOver(true);
    }, 1500);
  
    return () => clearTimeout(timer);
  }, []);
  
  

  const { BookedpropertyFilters, setBookedPropertyFilters } = useBookedPropertyFilters();


  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  const [accessToken, setaccessToken] = useState(null);
  
  const [propertiesData, setPropertiesData] = useState([]);

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
    const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');

    if (userIdFromLocalStorage ) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
      setaccessToken(AccessTokenFromLocalStorage);
    }
  }, [isLoggedin]);

  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate);
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
  sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
  sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
  const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');
  
  currentDate.setHours(currentDate.getHours() + 5); 
  currentDate.setMinutes(currentDate.getMinutes() + 30); 
  const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');
  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedin && userId && accessToken) {
        setLoading(true);
        const url = `${ApiBaseUrl}/fr-user/user-properties`;
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken,
          },
          body: JSON.stringify({
            "user_id": userId,
            "filtersByString": BookedpropertyFilters.filtersByString,
            "purchased_date": BookedpropertyFilters.purchased_date,
            "filtersByPlaces": BookedpropertyFilters.filtersByPlaces,
            "filtersByBuilders": BookedpropertyFilters.filtersByBuilders,
            "purchased_date": {
              "from": fromDateString,
              "to": toDateString
            },
          }),
        };
        
        try {
          const response = await fetch(url, requestOptions);
          if (response.ok) {
            const data = await response.json();
            console.log(data.data)
            setPropertiesData(data.data);
            setResponseData(data.data); 
            setLoading(false);
          } else {
            setLoading(false);
            console.error('Failed to fetch data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [isLoggedin, userId, accessToken, BookedpropertyFilters]);



  const handleInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
  };
  useEffect(() => {
    const debouncedSearch = debounce((input) => {
      const filteredProperties = input.trim() === '' ? responseData : responseData.filter(property =>
        `${property.property_name} ${property.property_city} ${property.current_price} ${property.purchased_price} ${property.number_of_owned_shares} ${property.property_location} ${property.builder_name} ${property.price_per_share}`.toLowerCase().includes(input.toLowerCase())
      );
      setPropertiesData(filteredProperties);
    }, 300);

    if (searchInput !== '') {
      debouncedSearch(searchInput);
    } else {
      setPropertiesData(responseData);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, responseData]);


  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  

  return (
    <div>
      <div className='flex flex-row justify-between  mt-3 '>
        <div className='flex flex-row gap-2 px-1 py-2 searchBar mSearch'>
          <div><IoMdSearch style={{ height: "25px", width: "20px" }} /></div>
          <input
            type="text"
            className='text-[12px] w-full'
            placeholder="Search by Properties, Builder's Name, Location"
            value={searchInput}
            onChange={handleInputChange}
          />
        </div>
       
      {propertiesData.length > 0 && (
        <div className='flex flex-col justify-center mfilterIcon'>
          <div className=' cursor-pointer' onClick={togglePopup}><TfiFilter style={{ height: "35px" }} /></div>
        </div>
      )}
      </div>
      {propertiesData.length === 0 && isOneSecondOver  && 
      
      (
        searchInput === '' ? (
          <div className='noPropContainer'>
            <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property has been booked yet.</p>
          </div>
        ) : (
          <div className='noPropContainer'>
             <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property found.</p>
          </div>
        )
      )
      
      
      }

      { isLoading && !isOneSecondOver ? (
        <div className="alertBar" style={{height:"100vh"}}> <BeatLoader color={'#123abc'} loading={isLoading}  /></div>
      ) : (
      <div className=' mt-[20px] flex flex-row justify-center gap-[20px]'>
<div className='flex flex-col pb-5 justify-center gap-[40px]' style={{width:"95%"}}>
      {Array.isArray(propertiesData) && propertiesData.map(property => (
        <BookedPropertiesCardMob key={property.user_property_id} 
        name={property.property_name} 
        location={property.property_location}
        city={property.property_city} 
        myShare={property.number_of_owned_shares}
        currentPrice={property.current_price}
        purchasedPrice={property.purchased_price}
        />
      ))}
    </div>
    </div>
      )}
      
    </div>
  );
}

export default BookedProperties;
