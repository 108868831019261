import React, { useEffect, useState } from 'react'
import "./signup.css"
import { FcGoogle } from "react-icons/fc";
import FormContainer from './FormContainer';
import LoginCustomInput from './LoginCustomInput';
import "./responsive-signup.css"
import ReactFlagsSelect from "react-flags-select";
import {countries} from "countries-list"

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { storeCountryCode, storePhoneNumber } from '../../Redux/phoneRedux/phoneActions';
import { signup, signupFailure, signupSuccess } from '../../Redux/AuthRedux/authActions';


import SuccessAlert from '../Alert/SuccessAlert';



export default function EditNumber() {


const [selected, setSelected] = useState("IN");
const [showAlert, setShowAlert] = useState(false);

const navigate=useNavigate()
const dispatch = useDispatch()
const isOtpLoading=useSelector((store)=>store.signupReducer.loading)
const isNewUser=useSelector((store)=>store.signupReducer?.new_user)
const username=useSelector((store)=>store.signupReducer?.username)
const phoneNumbers=useSelector((store)=>store.phoneReducer?.phoneNumber)


const customLabels = {};




for (const countryCode in countries) {
  const countryData = countries[countryCode];
  const primary = countryData.name;
  const secondary = '+' + countryData.phone[0]; 
  
  customLabels[countryCode] = { primary, secondary };
}

const isMobileValid = phoneNumbers.length === 10;



const signUpData = {
  phone_number: phoneNumbers,
  country_code: customLabels[selected].secondary,
  user_type: 'USER'
};




const handleSignup = (e) => {
  e.preventDefault();
  dispatch(signup(signUpData))
  
  .then((res) => {
      
    const { username, session,new_user,success } = res.data; 
    
    dispatch(signupSuccess(username, session,new_user,phoneNumbers,customLabels[selected].secondary));

   
    dispatch(storePhoneNumber(phoneNumbers))
    dispatch(storeCountryCode(customLabels[selected].secondary))
   
    setShowAlert(true);
    //  Remove alert after 1 second
    setTimeout(() => {
      setShowAlert(false);
      // navigate to otp route
      navigate("/otp");
    }, 1000);



  })
  .catch((err) => {
    dispatch(signupFailure(err.message));
  });
};



  return (
<>



<FormContainer 
title="Login / Sign Up"
subtitle="We are glad you’re here!"
isfooterExist={true}
issubTitle={true}

>




{showAlert && (
           <SuccessAlert  message="OTP Sent Successfully" />

        )}    

<div>
      <form onSubmit={handleSignup} className='loginform flex flex-col gap-4'>

<div className='flex gap-2.5'>
<ReactFlagsSelect

selectButtonClassName="menu-flags-button"
className="menu-flags"
    selected={selected}
    onSelect={(code) => setSelected(code)}
    searchable
    searchPlaceholder="Search countries"
    showSelectedLabel={false}
    customLabels={customLabels}
    showSecondarySelectedLabel={false}

  />
       
 {/* <LoginCustomInput placeholder="Enter Mobile Number" maxLength={10} onChange={(e)=>setPhoneNo(e.target.value)} /> */}

 <LoginCustomInput 
  placeholder="Enter Mobile Number" 
  maxLength={10} 
  onChange={(e) => dispatch(storePhoneNumber(e.target.value))}
  value={phoneNumbers} // Set the value here
/>


</div>

                <button   disabled={!isMobileValid} type="submit" 
                className={`loginbtn text-base text-black font-semibold ${isMobileValid ? 'bg-secondary text-white' : 'disable-btn-bg disabled'}`}>
                  {isOtpLoading ? 'Sending OTP...' : 'Get OTP'}
                  </button>

            </form>

            <div className='flex flex-col gap-4 loginform pt-4'>

              <div>
              <h2 class="hr-lines text-secondary font-nunito  ortext font-normal"> OR </h2>
              </div>


              <button type='submit' className='flex items-center border border-blue rounded-md sm:rounded-lg google-btn'>
              <FcGoogle style={{fontSize:"24px"}} />
              <p className='grow text-sm font-normal text-blue'>Continue via Google</p>
              </button>
         
          </div>

        <hr className='hrClass' />
          </div>

</FormContainer>
</>
)
}
