import React, { forwardRef } from 'react'
export const OtpInput =forwardRef( ({ maxChar,handleKeyup,ind, },ref) => {
    return  <input
      type="text"
      data-testid="pin-input"
      className='border  border-gray text-base text-center font-medium text-black rounded-xl w-1/4 sm:rounded-lg  otp-input'
      maxLength={maxChar} 
     onKeyUp={(e)=>handleKeyup(e,ind)} 
     ref={ref}
     inputMode="numeric" 
    />
  });