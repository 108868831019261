import { STORE_COUNTRY_CODE, STORE_PHONE_NUMBER } from "../actionTypes";


export const storePhoneNumber = (phoneNumber) => ({
  type: STORE_PHONE_NUMBER,
  payload: phoneNumber
});


export const storeCountryCode = (countryCode) => ({
  type: STORE_COUNTRY_CODE,
  payload: countryCode
});

