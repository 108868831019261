import React from 'react'
import approvalIcon from "../../Assets/icons/Approval.svg"
import { PropertImageBaseUrl } from '../../Utils/urls'

import { MdOutlineFileDownload } from 'react-icons/md'
import { Link } from 'react-router-dom'


export default function PropertyDocs({propDetails}) {

  
  const accessToken=localStorage.getItem("accestoken")


  return (
    <div className='documentSection flex flex-col gap-3 text-left font-nunito py-2 px-1 sm:p-3'>


{
    propDetails && propDetails.map((el,i)=>(
      <div key={i}>
        <div  className='w-full  flex gap-2 items-center border-b border-lightgray pb-3'>
        <div className='w-1/12'>
        <img src={approvalIcon} alt=""  />
        </div>
        
        <div className='w-10/12'>
            <p>{el.title} </p>
            <p className='text-gray' style={{fontSize:"10px"}}>Verified on  {el.created_at} </p>
        </div>
        <div className='w-1/12 cursor-pointer hidden sm:block'>

{
  accessToken?
  <a href={`${PropertImageBaseUrl}/${el.docs[0].Key}`} target='_blank' rel="noreferrer">

<MdOutlineFileDownload style={{color:"#D4D4D4",fontSize:"28px"}} />
  </a>
  :
  <Link to='/login'>
          <MdOutlineFileDownload style={{color:"#D4D4D4",fontSize:"28px"}} />
          </Link>
}


          {/* <Link to={accessToken ? `${PropertImageBaseUrl}/${el.docs[0].Key}` : '/login'}>
          <MdOutlineFileDownload style={{color:"#D4D4D4",fontSize:"28px"}} />
          </Link> */}
      
      

                 
                        
        </div>
        <div className='w-1/12 cursor-pointer block sm:hidden'>
         
       

        {
  accessToken?
  <a href={`${PropertImageBaseUrl}/${el.docs[0].Key}`} target='_blank' rel="noreferrer">

<MdOutlineFileDownload style={{color:"#1C1B1F",fontSize:"14px"}} />
  </a>
  :
  <Link to='/login'>
          <MdOutlineFileDownload style={{color:"#D4D4D4",fontSize:"28px"}} />
          </Link>
}


       
        </div>
       
    </div>  
    </div>
    ))
}
     
    </div>
  )
}
