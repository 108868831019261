import React from 'react'
import faqTabArrowIcon from "../../Assets/icons/faq-tab-arrow.png"

import FAQSingleAccordianTab from './Common/FAQSingleAccordianTab'

export default function FAQComponenet() {
  return (
    <div className='flex flex-col gap-4 font-nunito'>

        <FAQSingleAccordianTab title="What does the investment process for PropFTX look like?" >
        <p>
        The investment process steps are as follows:
      First, you need to create a PropFTX account and ensure that your digital KYC is verified. Have all the necessary documents ready for KYC completion, including your PAN Card, Aadhar/Driving License/Passport/Voter ID. 

          </p>
          <p>      
Then, proceed with the selfie verification for authenticity check.
 Your KYC will be verified within a minute.
 </p>
 <p>
Once the KYC process is complete, you can start the token purchase process. Select the tokens you wish to buy and proceed to checkout. 
</p>
<p>
Please Note: There are additional charges, such as the Platform Handling fee and GST over the handling fee, that will be applicable, and the investor needs to pay these charges based on the investment value.
</p>
<p>
After completing the purchase, you need to add your bank account details. It is necessary to receive the appreciation income and rental income from your investment.
</p>

<p>
Make the token purchase via the payment gateway, which accepts Debit cards, credit cards, UPI, and net banking.
</p>

<p>
After completing the purchase, monitor your dashboard to track your investment. Within 24 hours, an investment certificate will be issued to you via email, which you can also download from your dashboard as well.
</p>
        </FAQSingleAccordianTab>

        <FAQSingleAccordianTab title="what is the legal structure of the investment?" >
        <p>Every property which is listed on PropFTX is owned by a Special Purpose Vehicle (SPV) private limited company and manages the asset. When you purchase a token, you indirectly become the shareholders of the SPV. </p>
     
      <p>When you invest in a fraction of the property, you are basically acquiring a share in the SPV, which you can subsequently sell to other investors through PropFTX. This process is similar to digital share transfer.
All the legal operations are managed internally by PropFTX, ensuring complete compliance with the regulations. </p>
      
      <p>Additionally, PropFTX issues a share or investment certificate to each investor as proof of their investment.</p>
        </FAQSingleAccordianTab>
      
      
        <FAQSingleAccordianTab title="What is potential gain? " >
        <p>
The potential gain represents the profit earned by the investor by owning/holding their tokens on PropFTX's platform. It is the difference between the invested amount inclusive of all the fees from the final selling price. </p>
        </FAQSingleAccordianTab>



        <FAQSingleAccordianTab title="What is the target IRR? " >
        <p>
        The target Internal Rate of Return (IRR) is the anticipated average annual return that you can expect to receive throughout your investment with PropFTX. This calculation is essential in assessing the 
        potential profitability and attractiveness of the investment.
        </p>
        </FAQSingleAccordianTab>


        <FAQSingleAccordianTab title="How do I liquidate the investments? " >
        <p> For liquidation of investments, you would have to wait for 150 days. After 150 days, you can list your tokens on 
          the resale window and initiate the selling procedure.</p>
        </FAQSingleAccordianTab>


        <FAQSingleAccordianTab title="What is the total yield? " >
       <p>The total yield is the income you receive on the investments of your tokens in the form of rentals or capital appreciation. 
</p>
        </FAQSingleAccordianTab>


        <FAQSingleAccordianTab title="Is there any lock-in period? " >
        <p>Yes, there is a lock-in period of 150 days for the investors. Within this period, PropFTX aims to fund the property and bring investors on-board to form SPV. </p>
        </FAQSingleAccordianTab>

        <FAQSingleAccordianTab title="What is the exit strategy for this investment?" >
        <p>You can exit the investment only after the lock-in period of 150 days by listing your tokens on the resale marketplace. </p>
        </FAQSingleAccordianTab>

        <FAQSingleAccordianTab title="How can I track my investment? " >
        <p>You can track your investments by keeping a tab on your dashboard which provides all the information related to tokens purchased. 
</p>
        </FAQSingleAccordianTab>

        <FAQSingleAccordianTab title="How much do I have to pay when I buy a token of the property? " >
        <p>After you buy the token, there is a platform handling fee and GST (goods and service tax), which you are required to pay. </p>
        </FAQSingleAccordianTab>

        <FAQSingleAccordianTab title="What is the pre-sale and public sale? " >
        <p>
Pre-sale: It is an initial phase when the property is under construction and not ready for occupancy. By investing in presale properties, investors can earn long-term capital gain with good returns.
</p>
<p>Public sale: It is the property that is ready to move in and accrues rental income apart from capital appreciation.</p>
        </FAQSingleAccordianTab>
       
        <FAQSingleAccordianTab title="When will I start receiving the rental income from the property?
 " >
        <p>
        You will start receiving the rental income once the tenants occupy the property and begin paying rent. </p>
      </FAQSingleAccordianTab>

       {/* <button className='text-blue mx-auto flex items-center font-medium gap-2.5'>
        <p>View More FAQs</p>
        <img src={faqTabArrowIcon} alt="" />
        
        </button> */}
    </div>
  )
}
