
import axios from 'axios';
import { OTP_VERIFICATION_FAILURE, OTP_VERIFICATION_REQUEST, OTP_VERIFICATION_SUCCESS } from '../actionTypes';
import { ApiBaseUrl } from '../../Utils/urls';

export const otpVerificationRequest = () => ({
  type: OTP_VERIFICATION_REQUEST,
});

export const otpVerificationSuccess = (response) => ({
  type: OTP_VERIFICATION_SUCCESS,
  payload: response,
});

export const otpVerificationFailure = (error) => ({
  type: OTP_VERIFICATION_FAILURE,
  payload: error,
});

export const verifyOtp = (otpData) => (dispatch) => {
  dispatch(otpVerificationRequest());
  // return axios.post(`${ApiBaseUrl}/fr-cognito/verify`,otpData)

  return fetch(`${ApiBaseUrl}/fr-cognito/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(otpData),
  })
    
};
