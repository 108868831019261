import React, { useEffect, useState } from 'react'
import NomineeModal from './NomineeModal'
import { Link, useNavigate } from 'react-router-dom'

import NomineeCard from './NomineeCard'
import deleteImg from "../../Assets/icons/delteimg.png"
import { deleteNomineeFunc, fetchAllNominee } from '../../Api/NomineeApi'
import { BeatLoader } from 'react-spinners'
import DeleteIcon from '../../Assets/icons/DeleteIcon'
import BankLoader from '../BankDetails/BankLoader'
import useGetProfileAndKycDetails from "../UseGetProfileAndKycDetails/UseGetProfileAndKycDetails"

import "./nominee-style.css"
import WarningAlert from '../Alert/WarningAlert'

export default function AddNominee() {
const [showAddModal,setShowAddModal]=useState(false)
const [allNominee,setAllNominee]=useState([])
const userId=localStorage.getItem("user_id")
const accesstoken=localStorage.getItem("accestoken")
const [showDeleteConfirm,setDeleteConfirm]=useState(false)
const [selectedNomineeId, setSelectedNomineeId] = useState(null);
const [showUpdateModal,setShowUpdateModal]=useState(false)
const [selectedNominiUpdateid,setSelectedNominiUpdte]=useState(null)
const [isLoading,setLoading]=useState(false)
const navigate=useNavigate()
const {profileData,profileError,profileloading}=useGetProfileAndKycDetails()
const [showMobileUpdatePopup,setShowMobileUpdatePopup]=useState(false)



const totalAllocatedNominee = allNominee.reduce((total, current) => {
  
  return total + parseFloat(current.percentage_of_investment);
}, 0);

const remainingAlocationNom=100-totalAllocatedNominee



//initial modal
const handleCloseModale=()=>{
    setShowAddModal(false)
}
const handleOpenmodel=()=>{
    setShowAddModal(true)
}

//delete confirm modal
const handleOpenDeleteModal=(nomineeId)=>{
    setSelectedNomineeId(nomineeId)
    setDeleteConfirm(true)
}
const handleCloseDeleteModal=()=>{
    setDeleteConfirm(false)
 }


 //update confirm modal
const handleOpenUpdateModal=(nomineeId)=>{
    setSelectedNominiUpdte(nomineeId)
    setShowUpdateModal(true)
}
const handleCloseUpdateModal=()=>{
    setShowUpdateModal(false)
 
}
const handleOk=()=>{
navigate("/user/nominee/add")
}

const handleDeleteNominee = () => {
    if (selectedNomineeId) {
      
      deleteNomineeFunc(selectedNomineeId,userId,accesstoken,setAllNominee,setLoading)

        // Reset selectedNomineeId after deletion
        setSelectedNomineeId(null);
    }
    // Close the modal
    handleCloseDeleteModal();
}

const handleProceedUpdate=()=>{
//navigate to user/nominee/:id
if (selectedNominiUpdateid) {
    const nomineeId = selectedNominiUpdateid;
    const url = `/user/nominee/${nomineeId}`;
    navigate(url);
}
}


const handleAddNomineeRedirect=()=>{
  if(!profileData.profile?.mobile)
  {
    setShowMobileUpdatePopup(true)
    setTimeout(() => {
      setShowMobileUpdatePopup(false)
      navigate("/user/profile")
    }, 3000);
  }
  else{
    handleOpenmodel()
  }
}

//fetch All Nominees
useEffect(()=>{
    fetchAllNominee(userId,accesstoken,setAllNominee,setLoading,navigate)
},[])


  return (

<>
{
    showAddModal && (
<NomineeModal closeModal={handleCloseModale}>
<div className='flex flex-col gap-7 font-nunito'>
            <p className='text-gray text-xl font-bold text-center'> More About Nominee</p>

           <p className='text-gray text-sm font-normal text-center'>
           Having the nominee's details included can provide safety and security in the event of any unforeseen event. Please follow the outlined steps for updating nominee information; 
           this will help ensure an efficient process, saving your family valuable time and effort.
            This process is essential to keeping your family's financial future safe.

           </p>
            
            <button 
            className='bg-secondary rounded-md text-white nomineeOkbtn'
            onClick={handleOk}
            >
                OK
            </button>

 </div>

</NomineeModal>
    )
}

{/* delete-modal */}
{
    showDeleteConfirm &&(
        <NomineeModal closeModal={handleCloseDeleteModal}>
           <div className='flex flex-col gap-7 font-nunito'>
            <p className='text-black text-xl font-semibold text-center'> Are you sure you want to delete nominee details ?</p>

            <div className=' mx-auto'>
           
             <DeleteIcon className='mx-auto'/>
            </div>
         
            
            <button 
            className='bg-secondary rounded-md text-white nomineeOkbtn'
            onClick={handleDeleteNominee}
            >
               Yes, Delete
            </button>

 </div>
            </NomineeModal>  
    )
}


{
    showUpdateModal &&(
        <NomineeModal closeModal={handleCloseUpdateModal}>
           <div className='flex flex-col gap-7 font-nunito'>
            <p className='text-black text-xl font-semibold text-center'> 
            Are you sure you want to change nominee details ?</p>
<p className='text-xs text-gray text-center'>Make sure you are changing/editing the right information about the nominee</p>
            <div className='w-full'>
             <img src={deleteImg} alt="" className='mx-auto' style={{width:"130px"}} />
            </div>
         
            
            <button 
            className='bg-secondary rounded-md text-white nomineeOkbtn'
            onClick={handleProceedUpdate}
            >
               Yes, Proceed
            </button>

 </div>
            </NomineeModal>  
    )
}

{
  showMobileUpdatePopup && (
    <WarningAlert message={"Please update mobile no."}/>
  )
}


<div>
      <div className='font-nunito'>


      <div className='text-center w-full block sm:hidden  px-1 sm:px-2.5  pb-4'>
  <p className='text-2xl font-medium sm:text-base text-center sm:text-left'>

    
  {
        allNominee && allNominee.length===0?
        "Add Nominee"
        :
        "Nominee Details"
    }
  </p>
  </div>
   


<div className="w-full flex  pb-4 justify-between items-center ">
  


<div className='text-center hidden sm:block   px-1 sm:px-2.5  '>


  <p className='text-2xl sm:text-base font-medium text-center sm:text-left'>
    
    {
        allNominee && allNominee.length===0?
        "Add Nominee"
        :
        "Nominee Details"
    }
    
    </p>
    </div>

    {
        allNominee && allNominee.length>0 && allNominee.length<3 && remainingAlocationNom >0 && (
            <Link to="/user/nominee/add" className='hidden sm:block'>
            <button className='bg-secondary text-white  text-xs font-bold rounded-md py-3 px-4'>
                  Add Nominee 
            </button>

            </Link>
           
        )
    }


</div>

{/* {
    allNominee && allNominee.length===0 &&
    (
       <div className='profileSection  font-nunito'>
      <div className='flex flex-col'>
      
      <div className='flex justify-between'>
                 <p className='text-2xl text-blue font-bold'>Add a nominee for emergency transfer of ownership</p> 
                  <div className='flex gap-4 items-center'>
                    
                  </div>
  </div>

   <div className='flex flex-col gap-4'>
           <div className='flex flex-col gap-2 text-left nomieeInnerDiv'>
              <p className='text-gray text-sm'>The nominee for an emergency transfer of ownership Should be  be a legal heir. They can be your parents, siblings, spouse, children, or any individual. However, no corporate or
               non-individual is identified as a legal heir.</p>
                    
          </div>

       
           </div>

  
           <div className='nomineeContainer'> </div>




<hr className='kychrClass' />

<div className='flex items-center justify-between mt-5'>
<p className='text-base text-secondary underline'>Learn more</p>

<button className='bg-secondary text-white proceedBtn'  onClick={handleOpenmodel} >
    Add Nominee 
    </button>
</div>
           </div>
           
          </div>)
 } */}




{/* {
    allNominee && allNominee.length!==0 &&
          
         ( <div className='profileSection  font-nunito'>
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          
          {
              allNominee && allNominee.map((el)=>(

                <NomineeCard 
                key={el.nominee_id} 
                {...el} 
                
                handleOpenDeleteModal={() => handleOpenDeleteModal(el.nominee_id)}
                handleOpenUpdateModal={()=>handleOpenUpdateModal(el.nominee_id)}
                />

           
              ))
          }
           
          
          
          
          
            
          
          </div>
          </div> 
         )     
} */}




{
  isLoading ? (
    // Show loader while loading
    <BankLoader  /> 
  ) : allNominee && allNominee.length === 0 ? (
    // Show content when not loading and allNominee is empty
    <div className='profileSection  font-nunito'>
      <div className='flex flex-col'>
        <div className='flex justify-between'>
          <p className='text-sm sm:text-2xl text-gray sm:text-blue text-left font-bold pb-2'>Update Nominee Information for Added Security</p> 
          <div className='flex gap-4 items-center'></div>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2 text-left nomieeInnerDiv'>
            <p className='text-gray  text-sm sm:text-sm'>The nominee for an emergency transfer of ownership should be a legal heir. They can be your parents, siblings, spouse, children, or any individual. However, no corporate or non-individual is identified as a legal heir.</p>
          </div>
        </div>
        {/* background-image */}
        <div className='nomineeContainer'></div>
        <hr className='nomchrClass' />
        <div className='flex items-center justify-between mt-5'>
          <p className='text-base text-secondary underline'></p>
          <button className='bg-secondary text-white nomineeAddBtn' onClick={handleAddNomineeRedirect}>Add Nominee</button>
        </div>
      </div>
    </div>
  ) : (
    // Show content when not loading and allNominee is not empty
    <div className='profileSection font-nunito'>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
        {allNominee.map((el) => (
          <NomineeCard 
            key={el.nominee_id} 
            {...el} 
            handleOpenDeleteModal={() => handleOpenDeleteModal(el.nominee_id)}
            handleOpenUpdateModal={() => handleOpenUpdateModal(el.nominee_id)}
          />
        ))}
      </div>

{/* //mobile nominee button */}
      {/* <Link to="/user/nominee/add">
            <button className='bg-secondary text-white w-full mt-auto left-0 text-xs font-bold rounded-md py-3 px-4'>
                  + Add Nominee 
            </button>

            </Link> */}




{/* mobile-btn */}

{
        allNominee && allNominee.length>0 && allNominee.length<3 &&(
        <div className='flex sm:hidden pt-10 w-full'>
        <Link to="/user/nominee/add" className='w-full'>
            <button 
            type='submit' 
             className={`nomineeSubmitBtn bg-secondary text-white`}
            
            >
              Add Nominee
            </button>
            </Link>
        </div>
        )
      }

    </div>
  )
}






</div>
    </div>

    </>
  )
}
