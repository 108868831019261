import React,{useState} from 'react'
import './ConfirmPopup.css'
import ConfirmWeb from '../../../Assets/ConfirmWeb.svg'
import { ApiBaseUrl } from '../../../Utils/urls'


function ConfirmPopup(props) {

    const handleClick =()=>{
      props.setPropertyIdForListingApi(0)
      props.setuserIdForListingApi("")
      props.setaccessTokenForListingApi("")
      props.setnoOfTokensForListingApi(0)
      props.setcurrentPriceForListingApi(0)
      props.setResalePopupOpen(true);
      props.setshowConfirmPopup(false);
    }



   const handleSuccessListing = async () => {
        const url = `${ApiBaseUrl}/fr-resale/add-sales-offer`;
        const body = {
            user_id: props.userIdForListingApi,
            user_property_id: props.PropertyIdForListingApi,
            number_of_tokens_for_resale: props.noOfTokensForListingApi,
            sale_price: props.currentPriceForListingApi
        };
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': props.accessTokenForListingApi
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            });
            const data = await response.json();
            // Handle success listing
            props.setsuccessListing(true);
            props.setapiUpdateFlag(!props.apiUpdateFlag)
            props.setResalePopupOpen(false);
            props.setshowConfirmPopup(false);

            setTimeout(() => {
                props.setsuccessListing(false);
            }, 3000);
        } catch (error) {
            console.error('Error:', error);
        }
    };

// console.log("a",props.PropertyIdForListingApi);
// console.log("b",props.userIdForListingApi);
// console.log("c",props.accessTokenForListingApi);
// console.log("d",props.noOfTokensForListingApi);
// console.log("e",props.currentPriceForListingApi);



  
  return (
    <div className='px-[24px] py-[30px]  flex flex-col justify-between font-nunito'>

    <div className=' flex flex-row justify-center'><img className=' w-[110px] h-[80px]' src={ConfirmWeb} alt="clock_PropFTX"/></div>
    
    <div className=' mt-[33px] font-normal text-[16px]'>
    Please confirm the token listing on the resale marketplace.<br></br> You can cancel the resale at any time.
    </div>


    <div className=' flex flex-row justify-between'>
        <div className='mt-12  h-[47px] px-[65px] py-[12px] rounded-md text-[16px] font-bold text-white bg-[#D328A7] cursor-pointer' onClick={handleSuccessListing}>Yes, Confirm</div>
        <div className='mt-12 border h-[47px] px-[65px] py-[12px] rounded-md text-[16px] font-bold text-[#787878] border-[#787878] cursor-pointer' onClick={handleClick}>Cancel</div>
    </div>
   
    
    </div>
   
  )
}

export default ConfirmPopup
