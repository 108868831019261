// PinTab.js
import React, { useEffect, useRef, useState } from "react";

import { OtpInput } from "./OtpInput";
// import PropTypes from "prop-types";

export const OtpTab = ({ length, maxChar, setOtp ,setAllInputsFilled }) => {
  const [pinLength, setPinLength] = useState(new Array(length).fill(""));
  const inputRef = useRef([]);
  const [isAnyInputEmpty, setIsAnyInputEmpty] = useState(true);

  useEffect(() => {
    // Focus on the first input when component mounts
    if (inputRef.current && inputRef.current.length > 0) {
      inputRef.current[0].focus();
    }
  }, []);


  const handleFocus = (e, index) => {
    if (e.target.value.length === maxChar && index < length - 1) {
      inputRef.current[index + 1].focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.target.value.length === 0 && index > 0) {
      inputRef.current[index - 1].focus();
    }
  };




  // const handleKeyup = (e, index) => {
  //   const updatedPinLength = [...pinLength]; 
  //   updatedPinLength[index] = e.target.value;
  //   setPinLength(updatedPinLength); 

  //   if (e.keyCode === 8) {
  //     handleBackspace(e, index);
  //   } else {
  //     handleFocus(e, index);

  //     if (e.target.value.length === maxChar && index === length - 1) {
  //       const otpValue = updatedPinLength.join(""); 
  //       setOtp(otpValue);
  //     }
  //   }

  //   // Update setAllInputsFilled based on whether any input is empty
  // setAllInputsFilled(!updatedPinLength.includes(""));
  // };





  const handleKeyup = (e, index) => {
    const updatedPinLength = [...pinLength];
    updatedPinLength[index] = e.target.value;
    setPinLength(updatedPinLength);
  
    const otpValue = updatedPinLength.join("");
    setOtp(otpValue); // Update OTP state for each digit entry
  
    if (e.keyCode === 8) {
      handleBackspace(e, index);
    } else {
      handleFocus(e, index);
    }
  
    // Update setAllInputsFilled based on whether any input is empty
    setAllInputsFilled(!updatedPinLength.includes(""));
  };
  


  const handlePaste = (e) => {
    let data = e.clipboardData.getData("Text").split("").filter((el, index) => {
      return index < length;
    });

    data.forEach((el, index) => {
      inputRef.current[index].value = el;

      if (e.target.value.length === maxChar && index < data.length - 1) {
        inputRef.current[index + 1].focus();
      }
    });
  };

  useEffect(() => {
    setIsAnyInputEmpty(pinLength.includes(""));
  }, [pinLength]);

  return (
    <>
    <div className='flex otp-section gap-6 sm:gap-10 justify-between' data-testid="pin-tab" onPaste={handlePaste}>
      {pinLength.map((el, ind) => (
        <OtpInput
          key={ind}
          maxChar={maxChar}
          handleKeyup={handleKeyup}
          ind={ind}
          ref={(e) => {
            inputRef.current[ind] = e;
          }}
        />
      ))}
      
    </div>
    
    </>
  );
};

