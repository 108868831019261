import React, { useEffect, useState } from 'react'
import "./index.css"

import { Link, useNavigate, useParams,useLocation } from 'react-router-dom'
import { ApiBaseUrl, PropertImageBaseUrl } from '../../Utils/urls'
import SharePopup from './SharePopup'

import { MdOutlineLocationOn, MdOutlineShare } from "react-icons/md";

import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BiUpArrowAlt } from 'react-icons/bi'
import { fetchUserDetailes, getUserDetails } from '../../Api/ProfileApi'
import SuccessAlert from '../Alert/SuccessAlert'
import WarningAlert from '../Alert/WarningAlert'

import loader from "../../Assets/icons/Loader.gif"



export default function DetailsComponent() {
const [details,setPropertyDetails]=useState({})

    const {property_info,invoice_info}=details
    const [tokenCount, setTokenCount] = useState(1); 
    const [showSharePopup,setSharePopup]=useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const tokenLimit=details.property_info?.limit_per_share
    const navigate=useNavigate()
    const { id } = useParams();
    const [propertyInfo, setPropertyInfo] = useState(property_info);
    const [count,setCount]=useState(0)
    const [reachedTokenLimit, setReachedTokenLimit] = useState(false);
    const [isLoading,setLoading]=useState(false)
  

    const propertyName=property_info?.property_name
    const propertyCity=property_info?.property_city
    const propertyLocation=property_info?.property_location
 
    const userId=localStorage.getItem("user_id")
    const accestoken=localStorage.getItem("accestoken")

    const [platformFee,setPlatformFee]=useState(1)
    const [gstFee,setGstFee]=useState(1)
    const initialTotalFee=parseFloat(property_info?.price_per_share) + platformFee + gstFee
    const [totalFee,setTotalFee]=useState(initialTotalFee)

    const [isLoggedIn,setLoggedin]=useState(false)
    const [kycStatus,setKycStatus]=useState("")
    const [isUserEmail,setUserEmail]=useState("")

    const [isEmailUpdated,setEmailupdated]=useState(true) //for checking email is verified or not
    const [isPropComingSoon,setPropComingSoon]=useState("")
    const location=useLocation()
    const PerTokenPrice = property_info?.price_per_share ;


    // Function to toggle checkbox status
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

   //console.log(details)


useEffect(()=>{
    if(userId)
        {
            fetchUserDetails()
        }
    
},[])

//ectract the element with property invoice_details
let invoiceDetails=invoice_info?.find(el=>el?.hasOwnProperty("invoice_details"))

//get the array
invoiceDetails=invoiceDetails?.invoice_details

//get the element with platformm handling fee name as an object
let phmArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="Platform Handling fee".toLowerCase())
const phm_percentage=phmArray?.invoice_price


let gstArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="gst".toLowerCase())
const gst_percentage=gstArray?.invoice_price







useEffect(() => {
    if (typeof PerTokenPrice === 'undefined' || typeof phm_percentage === 'undefined' || typeof gst_percentage === 'undefined') {
        // Data is not available yet, return early or set default values
        return;
    }

    // Calculate the total price
    const totalPrice = PerTokenPrice * tokenCount;

    // Calculate the platform fee
    const calculatedPlatformFee = (totalPrice * phm_percentage) / 100;

    // Calculate GST Fee
    const gst_fee = (calculatedPlatformFee * gst_percentage) / 100;

    // Calculate Total Amount
    const total_fee = parseFloat(totalPrice) + parseFloat(calculatedPlatformFee) + parseFloat(gst_fee);

    setPlatformFee(calculatedPlatformFee);
    setGstFee(gst_fee);
    setTotalFee(total_fee);


}, [tokenCount, PerTokenPrice, phm_percentage, gst_percentage]);








    //  increment button
    const handleIncrement = () => {
        if(tokenCount < tokenLimit) {
            setTokenCount(tokenCount + 1);
        } else {
            setReachedTokenLimit(true);
        }
       
        
    };

    //  decrement button
    const handleDecrement = () => {
        if (tokenCount > 1) {
            setTokenCount(tokenCount - 1);
            setReachedTokenLimit(false); // Reset the reachedTokenLimit state
        }
    };

    //toggle show share popup
    const handleToggleSharePopup=()=>{
        setSharePopup(!showSharePopup)
    }

  
    const handleProceed = () => {

if(isLoggedIn && kycStatus==="APPROVED" && isUserEmail){
 // Navigate to the purchase page with state
 navigate('/purchase', {
    state: {
        tokenCount,
        totalPrice,
        formattedPrice,
        lockinPeriod:property_info?.lock_in_period,
        gstFee,
        platformFee,
        totalFee,
         id,
         PerTokenPrice,
       propertyName,
          propertyCity,
         propertyLocation

    }
});
sessionStorage.setItem('isFromPropertyDetails', 'true');

}
else if(!isLoggedIn)
{
   
    navigate('/login', { state: { from: location } })
}
else if(!isUserEmail){

    setEmailupdated(false)
   
    setTimeout(() => {
      setEmailupdated(true); 
      navigate("/user/profile")
    }, 3000);
 
}else if(isLoggedIn && isUserEmail && kycStatus!=="APPROVED"){
    navigate("/user/kyc",{ state: { from: location }})
}

}

       

  
//console.log(isUserEmail,kycStatus,"kyc");


// Calculate the total price
const totalPrice = PerTokenPrice * tokenCount;

// Format the total price with commas
const formattedPrice = totalPrice.toLocaleString();




const user_id=localStorage.getItem("user_id")
let  propertyDetailsURl=""
if(user_id)
{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}&user_id=${user_id}`

}
else{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}`
 
}

const fetchPropertyDetails = async () => {
    setLoading(true);
    try {
     
      const response = await fetch(propertyDetailsURl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
       
      });
      if (!response.ok) {
        throw new Error('Failed to fetch property details');
      }
      setLoading(false)
      const res = await response.json();
      //console.log(data);
     // setLoading(false)
      setPropertyDetails(res.data);
      setPropComingSoon(res.data.property_info.sale_status)
      
    } catch (error) {

        //for handling internet connection error
        // if (error instanceof TypeError) {
        //    console.error('Network error or no internet connection:', error.message);
        //    alert("check your internet connection")
        //   }


      //console.error('Error fetching property details:', error);
      //setLoading(false);
    }
  };

useEffect(() => {


  fetchPropertyDetails();
}, [id]);



const addToWishlist = () => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
        // Make API request to add property to wishlist
        fetch(`${ApiBaseUrl}/fr-user/wishlist-property`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem("accestoken")}`
            },
            body: JSON.stringify({
                "user_id": userId,
                "property_id": id
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if (data.success) {
                
                
               fetchPropertyDetails()
            }

            // Handle success or error message as required
        })
        .catch(error => console.error('Error:', error));
    } else {
        // Redirect to login page if user is not logged in
        navigate('/login');
    }
};

const fetchUserDetails=()=>{

getUserDetails(userId,accestoken,setLoggedin,setKycStatus,setUserEmail,navigate)
   
}


if(isLoading)
{
    return <div className='loaderDiv'>
    <img src={loader} altloaderDiv=""  className='LoaderClass' alt='loader'/>
    </div>
}



  return (
<>
{
    !isEmailUpdated && (
        <WarningAlert message="Please Update Your Email"/>
    )
}
   <div className='relative'>

<div className='absolute presale-badge'>{ property_info?.property_phase}</div>
    <div className='detailsCard'>



{/* Badge-section */}
<div className='badgeSection flex justify-between'>

    <div className='flex justify-between gap-3'>
        <span className="inline-block gray-badges bg-gray-200 text-black text-xs px-2 py-1  rounded-md">New</span>
        <span className="inline-block gray-badges bg-gray-200 text-black text-xs px-2 py-1  rounded-md">{property_info?.property_type}</span>

    </div>

<div className='flex gap-5 items-center'>

   <MdOutlineShare style={{fontSize:'24px',color:"gray",cursor:"pointer"}} onClick={handleToggleSharePopup} />
    {/* <FaRegHeart onClick={addToWishlist} style={{fontSize:'24px',color:"gray"}} /> */}

{
    property_info?.is_wishlisted==0?
    <FaRegHeart onClick={addToWishlist} className='cursor-pointer' style={{fontSize:'24px',color:"gray"}} />
    :
    <FaHeart onClick={addToWishlist} className='cursor-pointer' style={{fontSize:'24px',color:"red"}}/>
    
}




</div>

{showSharePopup && <SharePopup/> }
</div>


<div className="logoSection flex gap-5 py-5">
        <div>
            <img src={`${PropertImageBaseUrl}/${property_info?.builder_logo.Key}`} alt="" className='company-logo' />

        </div>
        <div>
            <p className='text-base font-semibold font-nunito'>{property_info?.property_name}</p>

            <div className='flex items-center gap-1'>
           
                <MdOutlineLocationOn/>
                <p>{property_info?.property_location}, {property_info?.property_city}</p>
            </div>
           
        </div>
    
</div>

<hr className='' style={{backgroundColor:"#D9D9D9",border:0,height:"1px"}} />


<div className="investmentSection flex justify-between rounded-lg my-5 py-2.5 px-4">

<div className='flex flex-col items-center'>
    <p  className='font-semibold font-nunito text-gray  text-lg'>
    
    {details && details.property_info?.sale_type==="Presale"?"Est. Yield":"Yield"}
    </p>

    <div className="flex items-center gap-1">
    <div  className='font-semibold text-blue  text-xl'>
    {property_info?.gross_yield}%</div>
  

    <div className="tooltip">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is {property_info?.sale_type==="Presale"?" Estimated Yield":"Yield"}?</p>
                                <p className='text-gray text-sm'>
                                {property_info?.sale_type==="Presale"?
                                "It represents the percentage of annual rental income expected from the property."
                                :
                                "	It represents the percentage of annual rental income generated from the property."
                                
                                }
                                   
                            </p>
                              </span>
                          </div>
    

    </div>
    
</div>




<div className='flex flex-col items-center'>
    <p  className='font-semibold text-gray font-nunito  text-lg'>
    
    {details && details.property_info?.sale_type==="Presale"?"Target ARR":"Target IRR"}
    </p>

    <div className="flex items-center gap-1">
        <div  className='font-semibold text-blue  text-xl'>
        {property_info?.target_IRR}%
        </div>
                        <div className="tooltip">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Target {property_info?.sale_type==="Presale"?"ARR":"IRR"}?</p>
                                <p className='text-gray text-sm'>

                                {details && details.property_info?.sale_type==="Presale"?
                                "Target ARR refers to desired increase in the value of Investment on an annual basis.":
                                "	It is the return rate investors aim to achieve from an investment, considering its projected cash flows."
                                
                                }
                                

                            </p>
                              </span>
                          </div>
    </div>
    
</div>




<div className='flex flex-col items-center'>
    <p  className='font-semibold text-gray font-nunito text-lg'>
    Potential Gain
          {/* {details && details.property_info?.sale_type==="Presale"?"Potential Gain":" Capital Gain"} */}
    </p>

    <div className="flex items-center gap-1">
   
    <BiUpArrowAlt style={{fontSize:"23px",color: "#2FDF99"}} />
        <div  className='font-semibold text-blue  text-xl'>
           
        {property_info?.capital_gain}%
        </div>
        <div className="tooltip">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is  Potential Gain? 
                                {/* {property_info?.sale_type==="Presale"?"Potential Gain":"Capital Gain"}? */}
                                
                                </p>
                                <p className='text-gray text-sm'>
                                It refers to the estimated gain generated upon sale of the property.
                                {/* {details && details.property_info?.sale_type==="Presale"?
                                "It refers to the estimated gain generated upon sale of the property."
                                :
                                " 	It refers to the estimated profit generated upon sale of the property."
                                } */}

                            </p>
                              </span>
                          </div>
    </div>
    
</div>

</div>



{/* token-section  */}
<div className="tokenSection flex flex-col gap-2.5 ">
<div className="flex justify-between">
    <p className='font-nunito font-normal text-black'>Available Tokens</p>
    <p className='font-nunito font-medium text-blue'>{property_info?.total_shares-(property_info?.sold_shares+Number(property_info?.reserved_tokens))}</p>
</div>
<div className="flex justify-between">
    <p className='font-nunito font-normal text-black' >Total Tokens</p>
    <p className='font-nunito font-medium text-blue'>{property_info?.total_shares}</p>
</div>

<div className="flex justify-between">
    <p className='font-nunito font-normal text-black' >Lock in Period</p>
    <p className='font-nunito font-medium text-blue'>{property_info?.lock_in_period} days</p>
</div>
</div>


{/*******  increment-decrement-button-section   *******************/}
<div className="btnSection">

<div className="flex items-center max-w-[15rem]  mx-auto py-2.5">

      
<button type="button" onClick={handleDecrement} id="decrement-button" className="flex justify-center items-center bg-blue w-[81px] border border-gray-300 rounded-s-lg p-3 h-12 focus:ring-gray-100 d focus:ring-2 focus:outline-none">
    <svg className="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"/>
    </svg>
</button>
<input 
    type="text" 
    id="quantity-input" 
    
    value={tokenCount}
    className="text-blue border-blue border h-12 text-center  text-lg font-semibold focus:ring-blue-500 focus:border-blue-500 block w-[81px] py-2.5" 
    placeholder="placeholder" 
    required 
/>

<button type="button" disabled={reachedTokenLimit} onClick={handleIncrement} id="increment-button" data-input-counter-increment="quantity-input"
 className={`w-[81px] bg-${reachedTokenLimit ? 'lightgray' : 'blue'} border  rounded-e-lg flex justify-center items-center p-3 h-12 focus:ring-gray-100  focus:ring-2 focus:outline-none`}>
    <svg className={`w-3 h-3  ${reachedTokenLimit ? 'text-black':'text-white'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
    </svg>
</button>

    </div>

    {reachedTokenLimit && <p className='text-xs' style={{color:"#d82222"}}>You can select maximum {tokenLimit} Tokens</p>}
</div>


{/********************* tokensold-progressbar-section *************/}


<div className='text-black flex flex-col  mt-2 p-1 gap-1'>
                <div className='flex flex-row justify-center'>
                <div className='tokenSoldProgressbar text-black flex flex-row'>
                    <div className='progressLength'  style={{ width: `${(property_info?.sold_shares / property_info?.total_shares) * 100}%` }}></div>
                    <div className='tokenIcon bg-navyBlue text-white -ml-2 flex flex-col justify-center'>
                        <div className='fontTokenPB font-bold bg-blue rounded-xl'>T</div>
                    </div>
                </div>
                </div>

                <div className='text-black text-sm font-nunito'>
                    {<span className='text-secondary font-extrabold'>{property_info?.sold_shares||0} </span>} 
                 Out Of {<span className='text-secondary font-extrabold'>{property_info?.total_shares} </span>} 
                Tokens Sold</div>
            </div>


{/* price-section */}
<div className="priceSection">
    <div className='flex justify-between  py-2.5'>
            <p className='font-nunito text-black  text-base font-bold'>Token Price</p>
            <p className='font-nunito text-blue  text-lg font-extrabold'>₹ {formattedPrice}</p>
    </div>
    <hr className='' style={{backgroundColor:"#D9D9D9",border:0,height:"1px"}} />

    {/* <div className='flex  gap-2.5 py-2.5'>

        <input type="checkbox"  checked={isChecked} onChange={handleCheckboxChange}/>
        <p className='font-nunito text-black text-xs text-left' >I hereby agree to buy token(s) and accept all the <Link to="/terms-of-use"><span className='text-secondary underline'>Terms of use</span></Link>   of PropFTX</p>
        </div> */}
      
     {
        isPropComingSoon==="CREATED"
        ?
        ( <button disabled className={`bg-lightgray text-black w-full py-2.5 px-4 rounded-md `}
        >Coming Soon</button>)
        :
        (
            <button onClick={handleProceed} className={`bg-secondary text-white w-full py-2.5 px-4 rounded-md `}
            >Proceed</button>
        )

     }   
       



   
       
       
</div>



    </div>
    </div>

    </>
  )
}
