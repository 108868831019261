import React from 'react'
import PurchaseModal from './PurchaseModal'

export default function PayNowOwnLaterPopup({handleClosePaylaterPop}) {
  return (
    <PurchaseModal width={'max-w-4xl'} closeModal={handleClosePaylaterPop}>

<div className='overflow-auto' style={{"height":"500px"}}>
<p className='text-blue font-bold'>Understanding Own Now, Pay Later</p>

<div className='flex flex-col gap-4 text-left text-gray text-sm'>

<div>
Own Now, Pay Later is a convenient financial service offered by PropFTX that allows 
users to apply for a loan to purchase tokens without having to pay upfront. 
This flexible payment option enables users to access the tokens they desire immediately
 and repay the loan amount in installments over time, 
subject to the terms and conditions of the loan provider.
</div>


<div>

  How it Works:
  <ul>
    <li>• Loan Application: Users can apply for a loan directly through PropFTX to fund their token purchase. The application process is simple and straightforward.</li>
  <li>• Token Purchase: Once the loan is approved, users can proceed to purchase tokens on PropFTX without making any upfront payment.</li>
  <li>• Repayment: Users are required to repay the loan amount in Equated Monthly Installments (EMIs) as per the terms and conditions set by the loan provider. The repayment schedule is determined at the time of loan approval.</li>
  </ul>

</div>


<div>
Benefits:
  <ul>
    <li>• Immediate Access: Own Now, Pay Later allows users to acquire tokens instantly without waiting to accumulate funds.</li>
    <li>• Flexibility: Users have the flexibility to repay the loan amount in installments, making it easier to manage their finances.</li>
    <li>• Convenience: By integrating the loan application and token purchase process, PropFTX offers a seamless and convenient experience for user</li>
  
  
  </ul>

</div>



<div>
Terms & Conditions:
  <ul>
  <li>• Loan approval is subject to the discretion of the loan provider and may be based on various factors including creditworthiness and eligibility criteria.</li>
  <li>• Users are responsible for repaying the loan amount along with any applicable interest and fees as per the agreed-upon terms.</li>
  <li>• Failure to repay the loan in a timely manner may result in additional charges and impact the user's credit score.</li>

  
  </ul>

</div>



<div>

Conclusion:

<p>Own Now, Pay Later is an innovative solution that empowers users to access tokens on PropFTX without the need for immediate payment. By offering this flexible payment option, we aim to enhance the user experience and provide greater financial accessibility to our users.For any further inquiries or assistance regarding Own Now, Pay Later, please feel free to contact our customer support team.
</p>

</div>


</div>

</div>



</PurchaseModal>
  )
}
