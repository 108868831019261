import React, { useEffect, useRef, useState } from 'react'
import "./paymentsche-mobile.css"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ClickOutside, DecrementToken, IncrementToken, ProceedToPurchase, calculateTotalPrice } from '../../Utils/tokenCalculation'
import ResaleStickyPriceComponent from './ResponsiveComponents/ResaleStickyPriceComp';
import { getUserDetails } from '../../Api/ProfileApi'
import { getPropertyDetails } from '../../Api/PropertyApi'
import { ApiBaseUrl } from '../../Utils/urls'
import { getPaymentScheduleData } from '../../Utils/paymentScheduleValue'
import { formatDateWithMonthName } from '../../Utils/DateFormat'
export default function PaymentScheduleMobile({propDetails}) {
    // token section
    const accessToken=localStorage.getItem("accestoken")
    const location=useLocation()
const {id}=useParams()
const userId=localStorage.getItem("user_id")
const {property_info,invoice_info}=propDetails
const [tokenCount, setTokenCount] = useState(1);
const [formattedPrice, setFormattedPrice] = useState(0);
const [platformFee,setPlatformFee]=useState(1)
const [gstFee,setGstFee]=useState(1)
const initialTotalFee=parseFloat(property_info?.price_per_share) + platformFee + gstFee
const [totalFee,setTotalFee]=useState(initialTotalFee)
const [reachedTokenLimit, setReachedTokenLimit] = useState(false);
const [isUserEmail,setUserEmail]=useState("")
const [isEmailUpdated,setEmailupdated]=useState(true)
const [isLoggedIn,setLoggedin]=useState(false)
  const [kycStatus,setKycStatus]=useState("")
const [showStickyPriceSection,setshowStickyPriceSection]=useState(false) 
const [isLoading,setLoading]=useState(false)
const [tokenLimitMob, settokenLimitMob] = useState(0);
  const priceSectionRef = useRef(null);
  const navigate=useNavigate()

  let isPropComingSoon=property_info?.sale_status

  const fetchUserDetails=()=>{
    getUserDetails(userId,accessToken,setLoggedin,setKycStatus,setUserEmail,navigate)
}

      
      useEffect(()=>{
        if(userId)
            {
                fetchUserDetails()
            }
      },[])

    



// useEffect(()=>{
//   getPropertyDetails(propertyDetailsURl)
// },[id])

  
//token section 

//ectract the element with property invoice_details
let invoiceDetails=invoice_info?.find(el=>el?.hasOwnProperty("invoice_details"))

//get the array
invoiceDetails=invoiceDetails?.invoice_details

//get the element with platformm handling fee name as an object
let phmArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="Platform Handling fee".toLowerCase())
const phm_percentage=phmArray?.invoice_price


let gstArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="gst".toLowerCase())
const gst_percentage=gstArray?.invoice_price

const price = property_info?.price_per_share ;
const tokenLimit=property_info?.limit_per_share

const handleToggleStickyPriceSection=()=>{
  setshowStickyPriceSection(!showStickyPriceSection)
}

const handleClickOutside = (event) => {
ClickOutside(event,priceSectionRef,setshowStickyPriceSection)
};


const handleClosePushSection=()=>{
setshowStickyPriceSection(false)
}



useEffect(() => {
  // Add event listener when the component mounts
  document.addEventListener('mousedown', handleClickOutside);

  // Remove event listener when the component unmounts
  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

const handleIncrement = () => {
IncrementToken(tokenCount, setTokenCount, tokenLimit, setReachedTokenLimit);
};


const handleDecrement = () => {
DecrementToken(tokenCount, setTokenCount, setReachedTokenLimit);
};

const PerTokenPrice = property_info?.price_per_share ;

useEffect(() => {
const { totalPrice, calculatedPlatformFee, gst_fee, total_fee } = calculateTotalPrice(PerTokenPrice, tokenCount, phm_percentage, gst_percentage);

setFormattedPrice(totalPrice.toLocaleString());
setPlatformFee(calculatedPlatformFee);
setGstFee(gst_fee);
setTotalFee(total_fee);
}, [tokenCount, PerTokenPrice, phm_percentage, gst_percentage]);

// Calculate the total price
const totalPrice = price * tokenCount;

const [buyPriceMob, setbuyPriceMob] = useState(0);

const handleProceed = () => {

ProceedToPurchase(
  isLoggedIn,
  kycStatus,
  isUserEmail,
  navigate,
  tokenCount,
  totalPrice,
  formattedPrice,
  property_info,
  gstFee,
  platformFee,
  totalFee,
  id,
  PerTokenPrice,
  setEmailupdated,
  location
)

}

//payment schedulesection
const paymentData = getPaymentScheduleData(id);

const {
        block,
       unit,
        first_amount,
        second_amount,
        third_amount,
        others_amount,
        total_amount,
      } = paymentData;


      const paymentScheduleNote=propDetails?.property_info?.payment_schedule_note
      const paymentScheduleConditions=propDetails?.property_info?.payment_schedule_conditions
      const paymentsSchedule=propDetails?.payment_schedules
    
      

//to convert number to ordinal string
const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const [propertyCity, setpropertyCity] = useState("")
  const [propertyName, setpropertyName] = useState("")
  useEffect(() => {
    if (property_info !== undefined) {
      setpropertyCity(property_info?.property_city)
      setpropertyName(property_info?.property_name.toLowerCase())
      settokenLimitMob(property_info?.limit_per_share)
    }
  }, [property_info]);
  




  return (


<>

<div class="sticky-button sm:hidden">
        <button  onClick={handleToggleStickyPriceSection} className='w-full bg-secondary text-white py-2.5 px-4 rounded-md font-semibold text-base'>
            Select Token
        </button>
    </div>


    {
    showStickyPriceSection && 
    (
  

      <ResaleStickyPriceComponent
      id={id}
      buyPriceMob={buyPriceMob}
      propertyCity={propertyCity}
      propertyName={propertyName}
      tokenLimitMob={tokenLimitMob}
      handleClosePushSection={handleClosePushSection}
      handleProceed={handleProceed}
      priceSectionRef={priceSectionRef}
      showStickyPriceSection={showStickyPriceSection}
      />
      
    )
}




    <div>

    <div className='tab-shadow bg-white font-nunito flex flex-col gap-4 rounded-lg p-2.5'>
        <p className='text-black text-base font-bold text-left'>
        {propDetails?.property_info?.property_name_only}

<br />
<span className='text-sm'>{propDetails?.property_info?.configuration}, 
BLOCK # {propDetails?.property_info?.block_no},

UNIT #   {propDetails?.property_info?.block_no}</span> 
                
                </p>    
        
   {

    paymentsSchedule && paymentsSchedule.map((el,index)=>(

    <>  
        <div className='flex flex-col gap-2 p-2.5' key={index}>

 <div className='flex'>
                    <div className='pTitle'>
                        SNo.
                    </div>
                    <div className='pDetails'>
                    {el.schedule_order}
                    </div>
            </div>
        
        
            <div className='flex'>
                    <div className='pTitle'>
                    Description
                    </div>
                    <div className='pDetails'>
                   
                    {getOrdinal(el.schedule_order)} Payment Schedule <br />
         <span className=''>
        {el.description}
        
         </span>
                    </div>
            </div>
            
        
        
            <div className='flex'>
                    <div className='pTitle'>
                    Amount (IN ₹)
                    </div>
                    <div className='pDetails'>
                    ₹ {el.amount.toLocaleString()}
                   
                   
                    </div>
            </div>
        
        
            <div className='flex items-center'>
                    <div className='pTitle'>
                    Collection Dates
                    </div>
                    <div className='pDetails'>
                        <div className='flex flex-col'>
                        {
   el.schedule_order!=1?
   (
      formatDateWithMonthName(el.due_date)
   )
   :
   <p>{el.due_date}</p>
}
    
                        </div>
          
                    </div>
            </div>
        
        </div>
        <hr  className='paymetSchHr'/>
        </>      
    ))
   }
   


    </div>


<div className='flex  flex-col gap-2 py-4'>

    <div className='flex'>
    <div className='finalTitleM'>
           <p>Final Unit Token</p>  
              <p>  Price (Approximate)</p> 
        </div>
        <div className='finalPriceM'>
        ₹ {propDetails?.total_unit_price.toLocaleString()}
            </div>
    </div>


    <div className='flex'>
    <div className='noteTitleM text-blue'>
    NOTE:  {paymentScheduleNote && paymentScheduleNote}
        </div>
      
    </div>
       
</div>

{/* general conditions */}
<div className='generalConditionM'>
<p className='text-gray text-xs font-semibold text-left'>Please Note General Conditions :</p>

<div className='conditionsSecM'>
{
      paymentScheduleConditions && paymentScheduleConditions.map((el,ind)=>(
               <p key={ind}>{ind+1}.  {el}</p>
      ))
   }

</div>

</div>

</div>

</>
    
  )
}
