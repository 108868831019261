import React, { useState } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdInformationCircleOutline } from 'react-icons/io';
import InfoPopup from '../InfoPopup/InfoPopup';



function ProfileAccordianTab({ title, children, showInfoIcon = false,tooltiptext,tootltipdetails}) {
  const [isAccordionActive, setAccordionActive] = useState(false);

  const handleToggleAccordion = () => {
    setAccordionActive(!isAccordionActive);
  };

  const [showInfoPopup, setShowInfoPopup] = useState(false);
const handleshowInfoPopup = () => {
  setShowInfoPopup(true);
};

const handlecloseInfoPopup = () => {
  setShowInfoPopup(false);
};

  return (
    <div className={`font-nunito hs-accordion  rounded-2xl `}>
      <button
        className={`font-nunito text-blue hs-accordion-toggle   inline-flex items-center justify-between w-full text-lg text-start text-gray-800 hover:text-gray-500 rounded-lg focus:outline-none
        }`}
        onClick={handleToggleAccordion}
      >
        <div className='flex gap-1.5 items-center'>
        
          {title}
          {showInfoIcon && (
            <div className="tooltip">
                          
            <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                <span className="tooltiptextBottom">

                  <p className='text-blue text-base'>{tooltiptext}</p>
                  <p className='text-gray text-xs'>{tootltipdetails}
              </p>
                </span>
            </div>
          )}



<IoMdInformationCircleOutline className='block sm:hidden' style={{fontSize:"14px",color:" #D328A7"}} onClick={handleshowInfoPopup} />

{

showInfoPopup && (
    <>
     
                    <InfoPopup>
                        <div className='p-2'>
                        <p className='text-blue text-left text-sm mb-1'>AML Declaration</p>
                            <p className='text-gray text-left text-xs'>

                            An anti-money laundering (AML) declaration is required as per the Prevention of Money Laundering Act, 2002 (PMLA).<br/>
                            These regulations have been created to prohibit any criminal activities that generate income through our platform.

                        </p>
                        </div>
       
                        <button onClick={handlecloseInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                            Ok</button>
                        
                        </InfoPopup>
    </>
)
}


        </div>

{
  isAccordionActive ?
  <IoMdArrowDropup />
  :

  <IoMdArrowDropdown />
}

       
      </button>
      <div className={`font-nunito text-sm text-justify text-secondarygray hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${isAccordionActive ? '' : 'h-0'}`}>
        <div className=''>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ProfileAccordianTab;
