import React from 'react'
import loader from "../../Assets/icons/Loader.gif"

export default function SignupLoader() {
  return (
    <div className='loader-div'>
<img src={loader} alt="Loader"  className='signupLoader' />
</div>
  )
}
