import React, { useEffect, useState } from 'react'

import FormContainer from './FormContainer'

import { FaChevronLeft } from "react-icons/fa6";
import { OtpTab } from './OtpTab';
import { useDispatch, useSelector } from 'react-redux';
import { otpVerificationFailure, otpVerificationSuccess, verifyOtp } from '../../Redux/otpVerificationRedux/otpVerificationAction';
import { Link, Navigate, useNavigate, useParams,useLocation } from 'react-router-dom';
import ErrorAlert from '../Alert/ErrorAlert';
import { signup, signupFailure, signupSuccess } from '../../Redux/AuthRedux/authActions';
import { storeCountryCode, storePhoneNumber } from '../../Redux/phoneRedux/phoneActions';
import { SignupFunc } from '../../Utils/SignUp';
import { ApiBaseUrl } from '../../Utils/urls';
import SuccessAlert from '../Alert/SuccessAlert';
import LoginNavbar from '../LoginNavbar/LoginNavbar';
import SignupLoader from './SignupLoader';

export default function EnterOtp() {
  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(true); 

  const [errorAlert, setShowErrorAlert] = useState(false);

  const navigate=useNavigate()
const phoneNumbers=useSelector((store)=>store.phoneReducer?.phoneNumber)
const countryCode=useSelector((store)=>store.phoneReducer?.countryCode)
// const session=useSelector((store)=>store.signupReducer?.session)
const session=localStorage.getItem("sessionLS")
const username=useSelector((store)=>store.signupReducer?.username)
const [allInputsFilled, setAllInputsFilled] = useState(false);
const isVerifying=useSelector((store)=>store.otpVerificationReducer.loading)
const isNewUser=useSelector((store)=>store.signupReducer?.new_user)
const [showOtppAlert, setShowOtpAlert] = useState(false);
const [alertMsg,setalertmsg]=useState("")
const { propertyId } = useParams()

const location = useLocation();
const { state } = location;


const dispatch = useDispatch();



useEffect(() => {
  const interval = setInterval(() => {
    setTimer((prevTimer) => {
      if (prevTimer === 1) {
        clearInterval(interval);
        setTimerActive(false)
        
      }
      return prevTimer - 1;
    });
  }, 1000);

  // Cleanup function to clear interval when component unmounts
  return () => clearInterval(interval);
}, [timerActive]);



useEffect(() => {
  if (otp.length === 4) { // Check for length 
    setAllInputsFilled(true);
  } else {
    setAllInputsFilled(false);
  }
}, [otp]);


const handleVerifyOtp = (e) => {
  e.preventDefault();

 

  dispatch(verifyOtp({
    answer: otp,
    phone_number: localStorage.getItem("signup_phonenumber"),
    country_code: localStorage.getItem("signup_countryCode"), 
    user_type: 'USER',
    session,
    username:localStorage.getItem("signup_username")
  }))
  .then(response => {
    const statusCode = response.status;
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }
     return response.json();
    
   // return response.json().then(res => ({ statusCode, res }));
  })
  .then(( res ) => {
  
 // console.log(res);
 // console.log(statusCode)
    dispatch(otpVerificationSuccess(res));

    if(res.success)
    {
      localStorage.setItem("accestoken",res.AuthenticationResult?.AccessToken)
       localStorage.setItem("user_id",res.UserDetails.user_id)
      localStorage.setItem("user_mobile_no",res.UserDetails.mobile)
      localStorage.setItem("user_name",res.UserDetails.first_name)
      localStorage.setItem("user_email",res.UserDetails?.email)
      localStorage.setItem("user_country_code",res.UserDetails?.country_code)
      localStorage.setItem("isSucceed",res.success)
      // Setting a cookie with a domain scope that covers both subdomain and parent domain
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      const expires = expirationDate.toUTCString();

  document.cookie = `accessToken=${res.AuthenticationResult?.AccessToken}; path=/; domain=.propftx.com; expires=${expires}`;
document.cookie = `userName=${res.UserDetails?.first_name}; path=/; domain=.propftx.com; expires=${expires}`;
// document.cookie = `accessToken=${res.AuthenticationResult?.AccessToken}; path=/;  expires=Fri, 31 Dec 2025 12:00:00 GMT`;
   
    }
    else{
      localStorage.setItem("sessionLS",res.Session)
    }
  
  
   
    if(!isNewUser && res.success )
    {
      const redirectTo = (state && state.from && state.from.pathname) || '/';
      navigate(redirectTo)
     
    }
    else if(!res.success && res.Session)
    {
      setalertmsg("Invalid OTP. Please Try Again!")
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    }
    else if(!res.success && !res.Session){
      setalertmsg("Three Wrong OTP attempts. Please try again later. !")
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    }
    else{
      const redirectTo = (state && state.from && state.from.pathname) || '/';
      navigate('/verify-with-otp',{ state: { from: redirectTo } })

      
    }
   
   
  })
  .catch((err) => {
  
   
   
    dispatch(otpVerificationFailure(err.message));

    //show for 2 second
   
  });
};



// const signUpData = {
//   phone_number: phoneNumbers,
//   country_code: countryCode,
//   user_type: 'USER'
// };

const signUpData = {
  phone_number: localStorage.getItem("signup_phonenumber"),
  country_code: localStorage.getItem("signup_countryCode"),
  user_type: 'USER'
};



  // Handle resend click
  const handleResendClick = () => {
    // Perform OTP resend (signup)
  SignupFunc(
    signUpData,
    dispatch,
    phoneNumbers,
    countryCode,
    setShowOtpAlert,
    setAllInputsFilled,
    navigate,
   
  )


    setTimer(60); 
    setTimerActive(true)
  };

  const [redirectedDueToSubmission, setRedirectedDueToSubmission] = useState(false);

  // useEffect(() => {
   
  //   if (isNewUser && !redirectedDueToSubmission) {
  //     setRedirectedDueToSubmission(true);
  //   }
  //   else{
  //     setRedirectedDueToSubmission(false)
  //   }
  // }, [isNewUser, redirectedDueToSubmission]);

//console.log(isNewUser);


  const handleOtpPageReload = () => {
    if (window.performance && performance.navigation.type === 1 && redirectedDueToSubmission) {
      // Page reloaded, redirect to login
      navigate("/login");
    }
  };


const handleEditRediret=()=>{
 
  navigate('/login',{ state })
}


  //  useEffect(() => {
   
  //   if (!isNewUser) {
  //     navigate('/login');
  //   }
  // }, [isNewUser, navigate]); 



  return (

 <> 


{/* {handleOtpPageReload()} */}


{
  isVerifying &&(
<SignupLoader/>
  )
}
 
 <LoginNavbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
<FormContainer 
title="Enter"
subtitle={<>Enter 4 digit OTP sent to ${phoneNumbers}
<Link  to= "/login" >
  <span className='underline'>Edit</span>
</Link>

</>}
isfooterExist={false}
spantitle="OTP"
issubTitle={false}
isSmallSubTitle={true}
smalltitle={<span className='text-sm sm:text-lg font-normal'>Enter 4 digit OTP sent to <span className='text-secondary'>{phoneNumbers}</span>. <span className='underline cursor-pointer' onClick={handleEditRediret}>Edit</span></span>}
>

  {errorAlert && (<ErrorAlert message={alertMsg} />)}

  {showOtppAlert && (<SuccessAlert message="OTP Sent Successfully"/>)}

<div>
      <form onSubmit={handleVerifyOtp} className='loginform flex flex-col gap-4'>
           

                  <OtpTab length={4} maxChar={1} setOtp={setOtp} otp={otp} setAllInputsFilled={setAllInputsFilled}/>
              
                  
                  <button   disabled={!allInputsFilled ||isVerifying} type="submit" 
                className={`loginbtn text-base text-black font-semibold ${allInputsFilled ? 'bg-secondary text-white' : 'disable-btn-bg disabled'}`}>
                  {isVerifying ? 'Verifying......' : 'Verify'}
                  </button>
              
            </form>


            <div className='flex flex-col gap-4 loginform pt-4 font-nunito'>
           

              {
                timerActive?
                <p className='text-sm'>00:{timer < 10 ? `0${timer}` : timer}</p> 
                  :
                  ""
              }
            
              {!timerActive && ( // Show resend section only when timer is active
            <div className='flex gap-5  items-center justify-center '>
              <p className='text-xs sm:text-lg font-normal text-gray' onClick={handleResendClick}>Didn’t receive any code? <span className='text-xs sm:text-sm font-normal text-secondary'>RESEND</span> </p>
            </div>
          )}
            
        
              
          </div>
          
     <hr className='hrClass' />
          </div>

<Link to="/login">
<div className='flex items-center loginform justify-center text-blue'>
                <FaChevronLeft />
                <p className='text-xs sm:text-lg'>Back to login</p>
          </div>

</Link>
          

</FormContainer>
</>
  )
}
