export function isDate18YearsFromNow(date) {
    // Get current year
    var currentYear = new Date().getFullYear();
    
    // Calculate 18 years ago from the current year
    var eighteenYearsAgo = currentYear - 18;
    
    // Extract year from the provided date
    var dateYear = date.getFullYear();
    
    // Compare if the provided date's year is 18 years ago or earlier
    return dateYear <= eighteenYearsAgo;
}
