import React, { useState,useEffect } from 'react'
import './Index.css'
import Info from '../../Assets/icons/info.svg'
import BarChart from '../Barchart.js/Index';
import { UserData } from '../../Utils/chart';
import { IoMdArrowBack } from "react-icons/io";
import { MdOutlineFilterAlt } from "react-icons/md";
import ProjectionFilter from '../ProjectionFilter/Index'
import { IoIosArrowDown } from "react-icons/io";
export default function ProjectionComp() {
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [propertyPhases, setPropertyPhases] = useState([]);
  const [saleTypes, setSaleTypes] = useState([]);
  const [showMobFilter, setshowMobFilter] = useState(false);
  const [buttonSelected, setbuttonSelected] = useState("Sealable");
  const togglePopup = () => {
    setshowMobFilter(!showMobFilter);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "HSR Layout",
        data: UserData.map((data) => data.PriceHsr),
        backgroundColor: ["blue"],
        borderColor: "blue"
      },
      {
        label: "Delhi", 
        data: UserData.map((data) => data.PriceDel), 
        backgroundColor: ["green"],
        borderColor: "green"
      }
    ]
  });

  const options = {
    scales: {
      x: {
        type: 'category', // Specify the type of scale as 'category'
        scaleLabel: {
          display: true,
          labelString: 'Year' // X-axis label
        }
      },
      y: {
        scaleLabel: {
          display: true,
          labelString: 'Price' // Y-axis label
        }
      }
    }
  };



  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this threshold as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (isMobile) {
    // Render mobile view
    return (
      <>
      <div className=' -mt-6 flex flex-row justify-between marginAnalHelp'>
        <div className='flex flex-col justify-center'><IoMdArrowBack style={{height:"26px",width:"26px"}} /></div>
        <div className='anaMobText'>Analytics</div>
        <div className='flex flex-col justify-center' onClick={togglePopup}><MdOutlineFilterAlt style={{height:"26px",width:"26px"}} /></div>
      </div>
      <div className=' flex flex-col gap-[15px] mt-[30px]'>
        <div className='headAnalytics'>Property Price Comparison Based on Micro Market</div>
        <div className=''><BarChart className="graph" chartData={userData} chartOptions={options}/></div>
      </div>
      <div className=' flex flex-row justify-between mt-[20px]'>
        <div className='h-[44px] flex flex-col justify-center px-[30px] rounded-md buttonFontAna borderSealable'>Sealable Price</div>
        <div className='h-[44px] border flex flex-col justify-center px-[30px] rounded-md bg-[#D328A7] text-white buttonFontAna'>Rental Price</div>
      </div>

      {showMobFilter && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="h-full w-full">
            <ProjectionFilter
            setshowMobFilter={setshowMobFilter}
            showMobFilter={showMobFilter}
            selectedCities={selectedCities}
            setSelectedCities={setSelectedCities}
            selectedBuilders={selectedBuilders}
            setSelectedBuilders={setSelectedBuilders}
            assetType={assetType}
            setAssetType={setAssetType}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            propertyPhases={propertyPhases}
            setPropertyPhases={setPropertyPhases}
            saleTypes={saleTypes}
            setSaleTypes={setSaleTypes}       
             />
          </div>
        </div>
      )}
      
      </>
    );
  }



  return (
    <div>
      <div className=' flex flex-col font-nunito '>
        <div className=' text-start anaText'>Analytics</div>
        <div className=' mt-[30px] flex flex-col parentBorderHelperClass p-[20px]'>
          <div className=' flex flex-row justify-between'>
            <div className=' priceCompText'>Property Price Comparison Based on Micro Market</div>
            <div className=' flex flex-row gap-[30px]'>
              <div className=' p-1'><img className='h-5' src={Info}/></div>
              <div className=' flex flex-row gap-2 '>
                <input
                  type="radio"
                  name="price"
                  id="minPrice"
                  value="Minimum Price"
                  checked={selectedOption === "Minimum Price"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="minPrice" className={`flex flex-col justify-center selectedOption === "Minimum Price" ? "selected" : ""`}>Minimum Price</label>
              </div>
              <div className=' flex flex-row gap-2'>
                <input
                  type="radio"
                  name="price"
                  id="avgPrice"
                  value="Average Price"
                  checked={selectedOption === "Average Price"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="avgPrice" className={`flex flex-col justify-center selectedOption === "Average Price" ? "selected" : ""`}>Average Price</label>
              </div>
              <div className=' flex flex-row gap-2'>
                <input
                  type="radio"
                  name="price"
                  id="maxPrice"
                  value="Maximum Price"
                  checked={selectedOption === "Maximum Price"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="maxPrice" className={`flex flex-col justify-center selectedOption === "Maximum Price" ? "selected" : ""`}>Maximum Price</label>
              </div>
            </div>
          </div>
          <div className=' flex flex-row justify-between mt-[35px]'>
            <div className=' flex flex-col px-[10px] py-[14px] leftBorderAna' style={{width:"18%"}}>
              <div className=' flex flex-row justify-between'>
                <div>Areas</div>
                <div className="rounded-md bg-[#EBEFF6] px-[10px] py-[4px]">Selected</div>
              </div>
              <div className=' flex flex-col'>
                <div className=' flex flex-row  mt-[24px] '>
                <div className=' w-[140px] text-start'>
                  <label>
                    <input type='checkbox'/>
                    <span className='ml-2'>HSR Layout</span>
                  </label>
                </div> 
                  <div className='flex flex-col justify-center'>
                  <div className='w-4 h-4 bg-[#BD27CF] rounded'></div>
                  </div>

                </div>
                <div className=' flex flex-row mt-[15px] '>
                <div className=' w-[140px] text-start'>
                  <label>
                    <input type='checkbox'/>
                    <span className='ml-2'>Indira Nagar</span>
                  </label>
                </div> 
                  <div className='flex flex-col justify-center'>
                  <div className='w-4 h-4 bg-[#35707C] rounded'></div>
                  </div>

                </div>

              </div>
              <div className=' flex flex-col'>
                <div className=' flex flex-row mt-[15px] '>
                <div className=' w-[140px] text-start'>
                  <label>
                    <input type='checkbox'/>
                    <span className='ml-2'>Malleshwaram</span>
                  </label>
                </div> 
                  <div className='flex flex-col justify-center'>
                  <div className='w-4 h-4 bg-[#1C4670] rounded'></div>
                  </div>

                </div>
                <div className=' flex flex-row mt-[15px] '>
                <div className=' w-[140px] text-start'>
                  <label>
                    <input type='checkbox'/>
                    <span className='ml-2'>Richmond Town</span>
                  </label>
                </div> 
                  <div className='flex flex-col justify-center'>
                  <div className='w-4 h-4 bg-[#00BB4A] rounded'></div>
                  </div>

                </div>

              </div>


              <div className=' flex flex-col'>
                <div className=' flex flex-row mt-[15px] '>
                <div className=' w-[140px] text-start'>
                  <label>
                    <input type='checkbox'/>
                    <span className='ml-2'>RMV Extension</span>
                  </label>
                </div> 
                  <div className='flex flex-col justify-center'>
                  <div className='w-4 h-4 bg-[#1C4670] rounded'></div>
                  </div>

                </div>
                <div className=' flex flex-row mt-[15px] '>
                <div className=' w-[140px] text-start'>
                  <label>
                    <input type='checkbox'/>
                    <span className='ml-2'>Richmond Town</span>
                  </label>
                </div> 
                  <div className='flex flex-col justify-center'>
                  <div className='w-4 h-4 bg-[red] rounded'></div>
                  </div>

                </div>

              </div>

              
            </div>
            <div className=' flex flex-col ml-[20px]' style={{width:"82%"}}>
              <div className=' flex flex-row gap-[10px]'>
                <div className='flex flex-row justify-between px-[16px] py-[4px] rounded-md border gap-2' style={{border:"1px solid #CCCCCC"}}>
                  <div className="text-[#686868]">Bengaluru</div>
                  <div className="flex flex-col justify-center"><IoIosArrowDown style={{color:"#CCCCCC"}}/></div>
                </div>
                <div className='flex flex-row justify-between px-[16px] py-[4px] rounded-md border gap-2' style={{border:"1px solid #CCCCCC"}}>
                  <div className="text-[#686868]">1 Quarter</div>
                  <div className="flex flex-col justify-center"><IoIosArrowDown style={{color:"#CCCCCC"}}/></div>
                </div>
                <div className='flex flex-row justify-between px-[16px] py-[4px] rounded-md border gap-2' style={{border:"1px solid #CCCCCC"}}>
                  <div className="text-[#686868]">2023</div>
                  <div className="flex flex-col justify-center"><IoIosArrowDown style={{color:"#CCCCCC"}}/></div>
                </div>
                <div className='flex flex-row justify-between px-[16px] py-[4px] rounded-md border gap-2' style={{border:"1px solid #CCCCCC"}}>
                  <div className="text-[#686868]">Asset Type</div>
                  <div className="flex flex-col justify-center"><IoIosArrowDown style={{color:"#CCCCCC"}}/></div>
                </div>
                <div className='flex flex-row justify-between px-[16px] py-[4px] rounded-md border gap-2' style={{border:"1px solid #CCCCCC"}}>
                  <div className="text-[#686868]">Property Type</div>
                  <div className="flex flex-col justify-center"><IoIosArrowDown style={{color:"#CCCCCC"}}/></div>
                </div>
              </div>
              <div className=' flex flex-col justify-center' style={{height:"100%"}}> <BarChart chartData={userData} chartOptions={options}/></div>
              <div className="flex flex-row justify-center gap-[26px] mt-[20px]">
                <div className={`px-[14px] py-[6px] text-[12px] ${buttonSelected=== "Sealable" ? "sealRentButton" : "unSealRentButton"}    `} onClick={()=>setbuttonSelected("Sealable")}>Sealable Price</div>
                <div className={`px-[14px] py-[6px] text-[12px]  ${buttonSelected=== "Rental" ? "sealRentButton" : "unSealRentButton"}   `} onClick={()=>setbuttonSelected("Rental")}>Rental Price</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
