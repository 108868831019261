// Reducer.js

import { SIGNUP_FAILURE, SIGNUP_REQUEST, SIGNUP_SUCCESS } from "../actionTypes";


const initialState = {
  loading: false,
  username: null,
  session: null,
  error: null,
  new_user:false,
  phone_number:"",
  country_code:""
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        username: action.payload.username,
        session: action.payload.session,
        new_user:action.payload.new_user,
        country_code:action.payload.country_code,
        phone_number:action.payload.phone_number
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default signupReducer;
