import React, { useState } from 'react'
import "./footer-style.css"
import logo  from  "../../Assets/logo/loog_new_light.svg"
import { MdOutlineEmail, MdOutlineMailOutline, MdOutlinePhone } from 'react-icons/md'
import statrupIndia from "../../Assets/Footer/Startup_india.svg"
import stKarnataka from "../../Assets/Footer/startup_karnataka.svg"
import { IoLogoLinkedin, IoLogoWhatsapp } from 'react-icons/io'
import { AiFillInstagram } from 'react-icons/ai'
import { RiTwitterXLine } from 'react-icons/ri'
import googlePlay from "../../Assets/Footer/google_play.svg"
import appleplay from "../../Assets/Footer/apply_play.png"
import ContactMail from '../../Assets/contactMail.svg'
import FooterModal from './FooterModal'
import emailSent2 from '../../Assets/icons/Mail_1.png'
import submitNewsletter from '../../Api/NewsLetterApi'


export default function Footer() {
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg,setSuccessMsg]=useState('')
  const [isLoading,setLoading]=useState(false)
const [isModalOpen,setModalopen]=useState(false)



  const handleNewsletterSubmit = () => {
    submitNewsletter(email, setEmail, setErrorMsg, setSuccessMsg, setModalopen, setLoading);
  };

  const closeContactPopNewLetter = () => {
    setEmail('');
    setErrorMsg('');
    setModalopen(false)
  };

  return (

<>
{
!isLoading && successMsg!="" && isModalOpen &&
(
  <FooterModal closeModal={closeContactPopNewLetter}>
<div class="flex flex-col gap-5 sm:gap-7">
             
             <div className='flex flex-col items-center gap-5 font-nunito'>
                 <img src={emailSent2} alt="" />
                 <p className='text-blue text-lg sm:text-2xl font-bold'>Thanks</p>
                  <p className='  text-xs sm:text-lg text-gray text-center'>{successMsg && successMsg}</p>
             </div>

             <button   
             style={{width:"43%"}} 
             onClick={closeContactPopNewLetter}
             className='bg-secondary mx-auto  property-popupbtn rounded-md text-white text-sm sm:text-lg font-medium '>
            Ok
               
               </button>
          
          
         </div>
  </FooterModal>
)
}


<div className='footerContainer bg-blue'>



    <footer className='mainFooter font-nunito bg-blue flex flex-col  '>


<div className='flex justify-between w-full'>
{/* section-1 */}
<div className='flex flex-col footSec1 gap-3 '>
    <img src={logo} alt=""  className='logoClass'/>
    <p className='text-sm text-left font-medium text-white pt-5'>
    Your gateway to fractional real estate investments 
    where you earn rentals and capital appreciation.

    </p>
    {/* <div className='flex justify-between startLogo'>
        <img src={statrupIndia} alt="" srcset="" className='startupIndia'/>
        <img src={stKarnataka} alt="" srcset=""  className='stKarnatka'/>

    </div> */}





    {/* <p className='text-xs text-white text-left'>© 2024 PropFTX | All rights reserved</p> */}

{/* subscribe-section */}
    {/* <div className="newsletterSubsc">
  <p>Subscribe to the Newsletter</p>
  <div className="subsInputsec">
    <input type="text" 
     className="subscripInput"
     value={email}
      onChange={(e) => setEmail(e.target.value)}
        placeholder="Your Email"
         />
    <button className="subscribeBtn"  onClick={handleNewsletterSubmit}>Subscribe</button>
  </div>
  
  <div className="errorMsgNewsLetter" style={{ display: errorMsg ? 'block' : 'none',color:"#df3333" ,fontSize:"12px",fontWeight:"500"}}>
              {errorMsg}
            </div>
  </div> */}



</div>

{/* section-2 */}
<div className='flex justify-between footSec2'>

      <div className='flex text-sm font-medium flex-col gap-1.5 text-white text-left'>
        <p className='text-secondary font-semibold'>Resources</p>
        {/* <p>Fractional Investment</p> */}
        <a href="https://www.propftx.com/how-it-works/">
        <p>How it Works</p>
        </a>
        <a href="https://www.propftx.com/blog/">
        <p>Blogs & News</p>
        </a>
        <a href="https://www.propftx.com/faq/">
        <p>FAQ’s</p>
        </a>
      </div>


      <div className='flex text-sm font-medium flex-col gap-1.5 text-white text-left'>
        <p className='text-secondary font-semibold'>Company</p>

        <a href="https://www.propftx.com/about-us/">
        <p>About Company</p>
      </a>

      <a href="https://www.propftx.com/leadership/">
        <p>Leadership</p>
      </a>
      </div>


      <div className='flex text-sm font-medium flex-col gap-1.5 text-white text-left'>
        <p className='text-secondary font-semibold'>Legal</p>
 <a href="https://www.propftx.com/terms-of-use/" >
        <p>Terms of Use</p>
</a>
 <a href="https://www.propftx.com/privacy-policy/" >
        <p>Privacy Policy</p>
</a>
 <a href="https://www.propftx.com/cookies/" >
        <p>Cookie Policy</p>
</a>

 <a href="https://www.propftx.com/kyc/" >
       <p>KYC Policy</p>
</a>

 <a href="https://www.propftx.com/aml/" >
       <p>AML Policy</p>
</a>

 <a href="https://www.propftx.com/risk-policy/"  >      
       <p>Risk Disclosure</p>
</a>
<a href="https://www.propftx.com/refund-policy/"  >      
       <p>Refund Policy</p>
</a>

      </div>

</div>




{/* section-3 */}


<div className='footSec3  flex  flex-col gap-3'>
<div className='flex flex-col gap-3 footSubSec text-left '>
          <p className='text-base text-secondary'>Contact us</p>


          <a href="https://www.propftx.com/contact/">
          <p className='text-white md:text-xs xl:text-base '>Contact</p>
          </a>
          <div className='flex justify-between items-center gap-2 widthMailHelper'>

         <img src={ContactMail}/>
          <p className='text-white md:text-xs xl:text-base '>support@propftx.com</p>
          </div>

          <div className='flex gap-1 items-center'>
               <MdOutlinePhone  style={{color:"#78F9FF",fontSize:"20px"}}/>
          <p className='text-white font-semibold md:text-xs xl:text-base'>+91 7619464634</p>
               </div>


<div className='flex gap-2 text-white text-lg'>

<a href="https://www.linkedin.com/company/propftx" target="_blank">
<div className='socialBox'>
  <IoLogoLinkedin  />
  </div>
</a>
 

<a href="https://wa.link/xagc5o" target="_blank">
<div className='socialBox'>
<IoLogoWhatsapp />
</div>
</a>
 
<a href="https://www.instagram.com/propftx/" target="_blank">
<div className='socialBox'>
<AiFillInstagram />
</div>
</a>
<a href="https://twitter.com/propftx"  target="_blank">
<div className='socialBox'>
<RiTwitterXLine />
</div>
</a>


</div>

      </div>
      {/* <div className='playstoreSec flex flex-col justify-between'>
        <p className='text-xl text-white'>Coming Soon</p>
       
        <div className='playstoreimg'>
        <img src={googlePlay} alt="" />
            <img src={appleplay} alt="" />
        </div>
      
        
            
      </div> */}
</div>

   







                </div>


<div className='flex justify-between'>

<div className="newsletterSubsc">
<p className='text-xs text-white text-left'>© 2024 PropFTX | All rights reserved</p>
  <p>Subscribe to the Newsletter</p>
  <div className="subsInputsec">
    <input type="text" 
     className="subscripInput"
     value={email}
      onChange={(e) => setEmail(e.target.value)}
        placeholder="Your Email"
         />
    <button className="subscribeBtn"  onClick={handleNewsletterSubmit}>Subscribe</button>
  </div>
  
  <div className="errorMsgNewsLetter" style={{ display: errorMsg ? 'block' : 'none',color:"#df3333" ,fontSize:"12px",fontWeight:"500"}}>
              {errorMsg}
            </div>
  </div>


  <div className='playstoreSec flex flex-col justify-between'>
        <p className='text-xl text-white'>Coming Soon</p>
       
        <div className='playstoreimg'>
        <img src={googlePlay} alt="" />
            <img src={appleplay} alt="" />
        </div>
      
        
            
      </div>
</div>


<div className=''>
<p className='text-white text-left text-xs italic'>
  <span  className='font-bold  not-italic'>DISCLAIMER: </span>
 Our platform offers fractional property investment opportunities in India, 
but it's crucial to recognize the associated risks. By using our services, you acknowledge potential market fluctuations, illiquidity,
 property management challenges, regulatory and legal uncertainties, currency exchange risks, market volatility, tenant issues, limited diversification, default and interest rate risks, technology and platform vulnerabilities, taxation complexities, and the lack of a guarantee of future performance. You may lose some or all of your investment, and it's essential to seek professional advice and evaluate your risk tolerance and financial situation before investing. This Risk Disclaimer is not exhaustive, and additional risks may apply.

</p>

</div>



    </footer>
    </div>
    </>
  )
}
