import React, { useState } from 'react';
import './ToggleSwitch.css'; 

const ToggleSwitch = ({defaultChecked,onChange,name,isChecked,setIsChecked}) => {

 

  
  const handleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={`toggle-switch ${isChecked ? 'toggle-checked' : 'unchecked'}`}>
      <label className="switch">
        <input type="checkbox" name={name} checked={isChecked} onChange={handleChange} />
        <span className="slider round">
          {isChecked 
          ? <><span className="yesforChecked">Yes</span><span className="noforChecked">No</span></>
           : <><span className="noforNotChecked">No</span><span className="yesforNotChecked">Yes</span></>}
        </span>
      </label>
    </div>
);
};

export default ToggleSwitch;
