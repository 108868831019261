import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import './Index.css'
import { DefaultFilter } from '../../Utils/urls';
import { usePropertyFilters } from '../../Utils/filter';
import { LuLogOut } from "react-icons/lu";
import Dashboard from '../../Assets/Dashboard/dashboard.svg';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CgProfile } from 'react-icons/cg';
import DashboardOff from '../../Assets/Dashboard/DashboardOff.svg'
import MyprofileOff from '../../Assets/Dashboard/myprofileOff.svg'
import MyprofileOn from '../../Assets/Dashboard/myprofileOn.svg'
import Checkon from '../../Assets/Dashboard/checkOn.svg'
import Checkoff from '../../Assets/Dashboard/checkOff.svg'
import OverviewOff from '../../Assets/Dashboard/overViewOff.svg'
import OverviewOn from '../../Assets/Dashboard/overViewOn.svg'
import ProjectionOn from '../../Assets/Dashboard/projectionOn.svg'
import ProjectionOff from '../../Assets/Dashboard/projectionOff.svg'
import TransactionOff from '../../Assets/Dashboard/TransactionOff.svg'
import TransactionOn from '../../Assets/Dashboard/TransactionOn.svg'
import MyManagerOn from '../../Assets/Dashboard/myManagerOn.svg'
import MyManagerOff from '../../Assets/Dashboard/myManagerOff.svg'
import SettingsOff from '../../Assets/Dashboard/settingsOff.svg'
import SettingsOn from '../../Assets/Dashboard/settingsOn.svg'




function DashboardSideNav(props) {
    const navigate = useNavigate();
const handleOptClick = (index) => {
props.setSelectedOpt(index);
};


  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const redirectToURL = (url) => {
    navigate(url);
  };

  const [isLoggedin, setisLoggedin] = useState(false); 
const [userId, setUserId] = useState(null);
useEffect(() => {
  // Check if there's a user ID and session LS in localStorage
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
  
  if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
    // If both exist, set isLoggedin to true and store the user ID in the state
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
  }
}, [isLoggedin]); // Run only once when the component mounts


  const handleLogout = () => {
    // Clear localStorage and reset state
    localStorage.removeItem('user_id');
    localStorage.removeItem('sessionLS');
    setisLoggedin(false);
    setUserId(null);
    navigate('/');
  };
  const [selectedProfile, setselectedProfile] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(''); //my code


const setProfile=()=>{
    setselectedProfile(!selectedProfile);
}




const [profileCurrent, setprofileCurrent] = useState("setup");

//for setting active menu
useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith('/user/profile')) {
        setselectedProfile(true)
      setActiveMenuItem('profile');
     
    } else if (path === '/user/kyc') {
        setselectedProfile(true)
      setActiveMenuItem('kyc');
    } else if (path === '/user/nominee'  || path === '/user/nominee/add' ||path==='/user/nominee/:id'){
        setselectedProfile(true)
      setActiveMenuItem('nominee');
    }
    else if(path === '/user/bank-details' ||path === '/user/bank-details/edit')
    {
        setselectedProfile(true)
      setActiveMenuItem('bank');
    }
    else{
        setselectedProfile(false)
      setActiveMenuItem('');
    }
  }, []);
  




  return (
    <>
    <div className='flex flex-col gap-4 sideBarScroll' style={{height:"83vh"}}>
    <div className='flex flex-col'>

      <div className='flex flex-row justify-center cursor-pointer' onClick={() => redirectToURL('/dashboard')}>
        <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==0? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(0)}>
            {props.selectedOpt===0 &&(
                <>
                <div className='flex flex-col justify-center'>
                <div className='w-[0.5px] leftLineTab h-[29px]'></div>
            </div>
            </>
            )}
            {props.selectedOpt===0 &&(
              <div className='flex flex-col justify-center' >
              <div className='ml-[9px] w-[26px] h-[26px]'><img src={Dashboard}/></div>
          </div>
          )}
          {props.selectedOpt!==0 &&(
            <div className='flex flex-col justify-center ml-1' >
            <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={DashboardOff}/></div>
        </div>
        )}
            <div className='flex flex-col justify-center'>
                <div className='ml-[10px] text-[14px] font-bold' >Dashboard</div>
            </div>
        </div>
      </div>

      <div className='flex flex-row justify-center mt-[12px] cursor-pointer ' onClick={() => {setProfile()}}>
      <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==1? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(1)}>
      {props.selectedOpt===1 &&(
        <>
        <div className='flex flex-col justify-center'>
        <div className='w-[0.5px] leftLineTab h-[29px]'></div>
    </div>
    </>
    )}
    {props.selectedOpt===1 &&(
      <div className='flex flex-col justify-center ml-1' >
      <div className='ml-[9px] w-[26px] h-[26px] flex flex-col justify-center '><img src={MyprofileOn}/></div>
  </div>
  )}
  {props.selectedOpt!==1 &&(
    <div className='flex flex-col justify-center ml-1' >
    <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={MyprofileOff}/></div>
</div>
)}
          <div className='flex flex-col justify-center '>
              <div className='ml-[10px] text-[14px] font-bold' >My Profile</div>
          </div>
          <div className='flex flex-col justify-center pl-6'>
            {
              selectedProfile ?(  <FaChevronUp style={{color:"#838383"}} />)
              :
              (  <FaChevronDown style={{color:"#838383"}} />)
            }
          
            
            </div>
      </div>
    </div>

    { selectedProfile && (
        <div className='profile-submenu'>
          <Link to="/user/profile">
        <div className=' text-start pl-12 mt-[12px]' onClick={()=>setprofileCurrent("setup")}>Profile Setup</div>
        </Link> 
        {activeMenuItem==="profile" &&(
            <div className='h-0.5 w-16 bg-secondary ml-[50px] '></div>
        )}
       
       <Link to="/user/kyc">
       <div className=' text-start   pl-12 mt-1' onClick={()=>setprofileCurrent("kyc")}>KYC</div>
       </Link>
       
    
        {activeMenuItem==="kyc" &&(
            <div className={`h-0.5 w-6 bg-secondary ml-[50px] `}></div>
        )}
       
       <Link to="/user/bank-details">
        <div className=' text-start pl-12 mt-1' onClick={()=>setprofileCurrent("bank")}>Bank Details</div>
        </Link>
        {activeMenuItem==="bank" &&(
            <div className='h-0.5 w-16 bg-secondary ml-[50px] '></div>
        )}
        <Link to="/user/nominee">
        <div className=' text-start pl-12 mt-1' onClick={()=>setprofileCurrent("nominee")}>Nominee</div>
        </Link>
        {activeMenuItem==="nominee" &&(
            <div className='h-0.5 w-10 bg-secondary ml-[50px] '></div>
        )}
       
        </div>
    )}

   
    
       <div className='flex flex-row justify-center  mt-[12px] cursor-pointer' onClick={() => redirectToURL('/user/wishlist')}>
       <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==2? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(2)}>
        {props.selectedOpt===2 &&(
            <>
            <div className='flex flex-col justify-center'>
            <div className='w-[0.5px] leftLineTab h-[29px]'></div>
        </div>
        </>
        )}
        {props.selectedOpt===2 &&(
          <div className='flex flex-col justify-center ml-1' >
          <div className='ml-[9px] w-[26px] h-[26px] flex flex-col justify-center '><img src={Checkon}/></div>
      </div>
      )}
      {props.selectedOpt!==2 &&(
        <div className='flex flex-col justify-center ml-1' >
        <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={Checkoff}/></div>
    </div>
    )}
            <div className='flex flex-col justify-center'>
                <div className='ml-[10px] text-[14px] font-bold' >Wishlist</div>
            </div>
        </div>
      </div>

      {/* <div className='flex flex-row justify-center  mt-[12px] cursor-pointer' onClick={() => redirectToURL('/user/overview')}> */}
       <div className='flex flex-row justify-center  mt-[12px] cursor-pointer'>
       <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==3? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(3)}>
        {props.selectedOpt===3 &&(
            <>
            <div className='flex flex-col justify-center'>
            <div className='w-[0.5px] leftLineTab h-[29px]'></div>
        </div>
        </>
        )}
        {props.selectedOpt===3 &&(
          <div className='flex flex-col justify-center ml-1' >
          <div className='ml-[9px] w-[26px] h-[26px] flex flex-col justify-center '><img src={OverviewOn}/></div>
      </div>
      )}
      {props.selectedOpt!==3 &&(
        <div className='flex flex-col justify-center ml-1' >
        <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={OverviewOff}/></div>
    </div>
    )}
            <div className='flex flex-col justify-center'>
                <div className='ml-[10px] text-[14px] font-bold' >Overview <sup className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</sup> <br/>
              
                </div>
                {/* <p  className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</p> */}
            </div>
        </div>
      </div>
       <div className='flex flex-row justify-center  mt-[12px] cursor-pointer' >
       <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==4? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(4)}>
        {props.selectedOpt===4 &&(
            <>
            <div className='flex flex-col justify-center'>
            <div className='w-[0.5px] leftLineTab h-[29px]'></div>
        </div>
        </>
        )}
        {props.selectedOpt===4 &&(
          <div className='flex flex-col justify-center ml-1' >
          <div className='ml-[9px] w-[26px] h-[26px] flex flex-col justify-center '><img src={ProjectionOn}/></div>
      </div>
      )}
      {props.selectedOpt!==4 &&(
        <div className='flex flex-col justify-center ml-1' >
        <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={ProjectionOff}/></div>
    </div>
    )}
            <div className='flex flex-col justify-center'>
                <div className='ml-[10px] text-[14px] font-bold flex flex-col gap-1 items-center' >
                  <p>Projections<sup className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</sup></p>
                
                
                </div>
                {/* <p  className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</p> */}
                
            </div>
        </div>
      </div>
       <div className='flex flex-row justify-center  mt-[12px] cursor-pointer' onClick={() => redirectToURL('/user/transactions')}>
       <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==5? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(5)}>
        {props.selectedOpt===5 &&(
            <>
            <div className='flex flex-col justify-center'>
            <div className='w-[0.5px] leftLineTab h-[29px]'></div>
        </div>
        </>
        )}
        {props.selectedOpt===5 &&(
          <div className='flex flex-col justify-center ml-1' >
          <div className='ml-[9px] w-[26px] h-[26px] flex flex-col justify-center '><img src={TransactionOn}/></div>
      </div>
      )}
      {props.selectedOpt!==5 &&(
        <div className='flex flex-col justify-center ml-1' >
        <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={TransactionOff}/></div>
    </div>
    )}
            <div className='flex flex-col justify-center'>
                <div className='ml-[10px] text-[14px] font-bold' >Transactions</div>
            </div>
        </div>
      </div>
       <div className='flex flex-row justify-center  mt-[12px] ' >
       <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==6? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(6)}>
        {props.selectedOpt===6 &&(
            <>
            <div className='flex flex-col justify-center'>
            <div className='w-[0.5px] leftLineTab h-[29px]'></div>
        </div>
        </>
        )}
        {props.selectedOpt===6 &&(
          <div className='flex flex-col justify-center ml-1' >
          <div className='ml-[9px] w-[26px] h-[26px] flex flex-col justify-center '><img src={MyManagerOn}/></div>
      </div>
      )}
      {props.selectedOpt!==6 &&(
        <div className='flex flex-col justify-center ml-1' >
        <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={MyManagerOff}/></div>
    </div>
    )}
           <div className='flex flex-col justify-center'>
                <div className='ml-[10px] text-[14px] font-bold' >My Manager <sup className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</sup>
              
                </div>
                {/* <p  className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</p> */}
            </div>
        </div>
      </div>
       <div className='flex flex-row justify-center  mt-[12px] cursor-pointer' >
       <div className={`flex flex-row  h-[46px] w-[220px] rounded-lg ${props.selectedOpt==7? "optionTabs": "optionsTabNotSelected"}`} onClick={()=>props.setSelectedOpt(7)}>
        {props.selectedOpt===7 &&(
            <>
            <div className='flex flex-col justify-center'>
            <div className='w-[0.5px] leftLineTab h-[29px]'></div>
        </div>
        </>
        )}
        {props.selectedOpt===7 &&(
          <div className='flex flex-col justify-center ml-1' >
          <div className='ml-[9px] w-[26px] h-[26px] flex flex-col justify-center '><img src={SettingsOn}/></div>
      </div>
      )}
      {props.selectedOpt!==7 &&(
        <div className='flex flex-col justify-center ml-1' >
        <div className='ml-[9px] w-[22px] h-[26px] flex flex-col justify-center '><img src={SettingsOff}/></div>
    </div>
    )}
            <div className='flex flex-col justify-center'>
                <div className='ml-[10px] text-[14px] font-bold' >Settings <sup className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</sup>
              
                </div>
                {/* <p  className="text-secondary" style={{fontSize:"8px"}}>(Coming Soon)</p> */}
            </div>
        </div>
      </div>

    </div>

    <div className="flex flex-row justify-center cursor-pointer">
        <div className="bg-white flex flex-row justify-center gap-2 w-32 p-2" style={{backgroundColor: "rgba(255, 255, 255, 1)"}}>
                <div className="flex flex-col justify-center"><LuLogOut /></div>
                <div className="" onClick={handleLogout}>Logout</div>
        </div>
    </div>
    </div>

  </>
  );
}



export default DashboardSideNav
