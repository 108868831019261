
import axios from 'axios';
import { UPDATE_PROFILE_FAILURE, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS } from '../actionTypes';
import { ApiBaseUrl } from '../../Utils/urls';



export const updateprofileRequest = () => ({
  type: UPDATE_PROFILE_REQUEST,
});

export const updateprofileSuccess = (response) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: response,
});

export const updateprofileFailure = (error) => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: error,
});

export const updateProfileAction = (profileData,accessToken) => (dispatch) => {
  dispatch(updateprofileRequest());
  return axios.post(`${ApiBaseUrl}/fr-user/initiate-update-email-only`,profileData,
  {
    headers: {
      Authorization: accessToken, 
      'Content-Type': 'application/json', 
    },
  }
  )

 
  
};


