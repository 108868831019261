export   const calculateTotalInvestment = (selectedPeriod,investmentAmt,additional,extraCharge) => {
    if (selectedPeriod <= 4) {
        return Number(investmentAmt);
    } else if (selectedPeriod > 4 && selectedPeriod <= 36) {
        return Number(investmentAmt) + additional;
    } else {
        return Number(investmentAmt) + additional + extraCharge;
    }
};

export const calculateCapitalAppreciation=(
    investmentAmt,
    capitalApprec,
    selectedPeriod,
    additional,
    extraCharge)=>{
    let capitalapp
    if(selectedPeriod<=4)
    {
        capitalapp=(Number(investmentAmt)*(capitalApprec/100))*(selectedPeriod/12)
       
    }
    else if(selectedPeriod>4 && selectedPeriod<=36)
    {
      capitalapp=((Number(investmentAmt)*(capitalApprec/100))*(selectedPeriod/12))+
      ((additional*(capitalApprec/100))*((selectedPeriod-4)/12)) 
    }
    else{
      capitalapp=((Number(investmentAmt)*(capitalApprec/100))*(selectedPeriod/12))+
      ((additional*(capitalApprec/100))*((selectedPeriod-4)/12))+
      ((extraCharge*(capitalApprec/100))*((selectedPeriod-36)/12))
    }
    return capitalapp
  }

  export const calculateRentalIncome = (
    selectedPeriod,
    investmentAmt,
    additional,
    extraCharge,
    rentalYield) => {
    const totalinvestment=calculateTotalInvestment(selectedPeriod,investmentAmt,additional,extraCharge)
    let rentalincome = 0; 
    if (selectedPeriod > 36) {
      rentalincome = (totalinvestment * (rentalYield / 100)) * ((selectedPeriod - 36) / 12);
      
    }
  
    return rentalincome;
  }

  export const calculateNetprofit = (selectedPeriod,investmentAmt,capitalApprec,additional,extraCharge,rentalYield) => {
    const capitalApp=calculateCapitalAppreciation(investmentAmt,capitalApprec,selectedPeriod
      ,additional,extraCharge)
    const rentalIncome = calculateRentalIncome(selectedPeriod,investmentAmt,additional,extraCharge,rentalYield); // Calculate rentalIncome
    
  
    const netProfit = capitalApp + rentalIncome; 
    
    return netProfit; 
  };

  export const calculateROI=(selectedPeriod,investmentAmt,capitalApprec,additional,extraCharge,rentalYield)=>{
    const netProfit=calculateNetprofit(selectedPeriod,investmentAmt,capitalApprec,additional,extraCharge,rentalYield)
    const totalinvest = calculateTotalInvestment(selectedPeriod,investmentAmt,additional,extraCharge)
  return ((netProfit/totalinvest)*100)
  }
  