import { ApiBaseUrl } from "../Utils/urls";



export const getBuyingFilter = async (
    accesstoken,
    initialBookingFilter,
    setBuilderFilter,
    setPlaceFilters,
    
) => {
    fetch(`${ApiBaseUrl}/fr-user/get-user-purchase-transactions`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accesstoken 
        },
        body: JSON.stringify(initialBookingFilter)
      }
      
      )
        .then(response => {
        
          
         
          return response.json();
        })
        .then(data => {
         
         // console.log('Response from server:', data);
          if(data.success)
          {
           
           setBuilderFilter(data.filters.builder_filters)
           setPlaceFilters(data.filters.place_filters)
           
          }
    
          
        })
        .catch(error => {
         
          console.error('error:', error);
         
        });
    
  };


  export const getAllInitialAllProp = async (
    accesstoken,
    initialBookingFilter,
   
    setUsersAllBoughtProp
) => {
    fetch(`${ApiBaseUrl}/fr-user/get-user-purchase-transactions`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accesstoken 
        },
        body: JSON.stringify(initialBookingFilter)
      }
      
      )
        .then(response => {
        
          if (!response.ok) {
            console.log("not ok");
          }
         
          return response.json();
        })
        .then(data => {
         
         // console.log('Response from server:', data);
          if(data.success)
          {
           
         
           setUsersAllBoughtProp(data.data)
          }
    
          
        })
        .catch(error => {
         
          console.error('error:', error);
         
        });
    
  };