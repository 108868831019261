import React,{useState,useEffect} from 'react'
import AvailableSellOfferCard from '../Cards/AvailableSellOfferCard/AvailableSellOfferCard'
import AvailableBuy from './AvailableBuy'
import AvailableSell from './AvailableSell'
function ResalePartA(props) {

  return (
    <div className='font-nunito p-[20px]' >
        <div className='SellBuyResale gap-[16px]'>
            <div className='border rounded-xl border-[#E6E6E6] pl-[20px] pt-[20px] pb-[20px] leftSellBuyResale' >
            <div className='headingBuySellResale text-start'>Available Sell Offers</div>
            <AvailableBuy availableBuyOffers={props.availableBuyOffers}/>
            </div>
            <div className='border rounded-xl border-[#E6E6E6] pl-[20px] pt-[20px] pb-[20px] RightSellBuyResale' >
            <div className='headingBuySellResale text-start'>Available Buy Offers</div>
              <AvailableSell avaialbleSellOffers={props.avaialbleSellOffers}/>
            </div>
        </div> 
      

    </div>
  )
}

export default ResalePartA
