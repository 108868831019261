import React from 'react'
import "./paymentSchedule-style.css"
import { getPaymentScheduleData } from '../../Utils/paymentScheduleValue'
import { formatDateWithMonthName } from '../../Utils/DateFormat'


export default function PaymentSchedule({propDetails}) {


const tokenPrice=propDetails?.property_info.price_per_share
const propertyid=propDetails?.property_info.property_id


const paymentScheduleNote=propDetails?.property_info?.payment_schedule_note
const paymentScheduleConditions=propDetails?.property_info?.payment_schedule_conditions
const paymentsSchedule=propDetails?.payment_schedules


 const paymentData = getPaymentScheduleData(propertyid);
 const {
   block,
  unit,
   first_amount,
   second_amount,
   third_amount,
   others_amount,
   total_amount,
 } = paymentData;

//function to convert number to ordinal string 
 const getOrdinal = (n) => {
   const s = ["th", "st", "nd", "rd"];
   const v = n % 100;
   return n + (s[(v - 20) % 10] || s[v] || s[0]);
 };

  return (
    <div className="flex font-nunito flex-col sm:flex-row gap-3.5 tab-shadow py-5  sm:py-5 sm:px-4 rounded-2xl">
      
      <div className='flex flex-col'>

     
      
       
       <p className='text-lg text-left mb-3'>
        <b>{propDetails?.property_info?.property_name_only}</b>  <br />
        <span className='text-sm'>{propDetails?.property_info?.configuration}&nbsp;, 
        BLOCK # {propDetails?.property_info?.block_no},&nbsp;
        UNIT #   {propDetails?.property_info?.block_no}</span> 
         </p>





<div className='flex flex-col'>

<div className='paymentTableHead'>
                <div>SNo.</div>
                <div className='descCol'>Description</div>
                {/* <div className='qtyCol'>Qty (BHK)</div>
                <div className='unitCol'>Unit (sqf)</div> */}
                <div className='priceCol'>Amount (IN ₹) </div>
                <div className='collectiondateCol'>Collection Dates</div>
            </div>
           
       

<div className='breakDownDiv'>



{/* sec-1 */}


{
   paymentsSchedule && paymentsSchedule.map((el,index)=>(
<>
<div className='breakDownSec pt-2' key={index}>
               
      <div className='slNosec'>{el.schedule_order}.</div>
      <div  className='descSec' >
      <p>{getOrdinal(el.schedule_order)} Payment Schedule</p>
      {el.description}
      </div>
      {/* <div className='qtyCol'>1</div>
      <div className='unitCol'>35%</div> */}

      <div className='priceCol'>₹ {el.amount.toLocaleString()}</div>

      <div className='collectiondateCol'>

      <div className='flex flex-col'>

{
   el.schedule_order!=1?
   (
      formatDateWithMonthName(el.due_date)
   )
   :
   <p>{el.due_date}</p>
}
       
      
      </div>

      </div>

   </div>

<hr className='paymenthrClass' />
</>
      
      
   ))
}


            </div>



{/* final token section*/}
<div  className='finalTokenSection'>

<div className="finalTitle">
Final Unit Token Price (Approximate)
</div>
<div className="finalAmount">
₹ {propDetails?.total_unit_price.toLocaleString()}
</div>

</div>



<div className='paymentNoteSec'>
NOTE: {paymentScheduleNote && paymentScheduleNote}
</div>

<div className='generalCondition'>
<p className='text-gray text-base font-semibold text-left'>Please Note General Conditions :</p>

<div className='conditionsSec'>
   {
      paymentScheduleConditions && paymentScheduleConditions.map((el,ind)=>(
               <p key={ind}>{ind+1}.  {el}</p>
      ))
   }
   
</div>

</div>

</div>

       
            
            
        


           
       

        </div>
        </div>
  )
}
