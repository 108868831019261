import React, { useState, useRef, useEffect } from 'react';
import './login-navbar.css';
import logo from '../../Assets/logo/loog_new_light.svg';
import { IoMdArrowDropdown } from 'react-icons/io';
import Profile from '../../Assets/profile.svg';
import Notification from '../../Assets/notification.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxDashboard } from "react-icons/rx";
import { CgProfile } from "react-icons/cg";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp, MdOutlineChecklist, MdOutlineLogout } from "react-icons/md";
import { LuBarChart3 } from "react-icons/lu";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { FaPowerOff } from "react-icons/fa6";
import FrInvestment from '../../Assets/navbarPopup/frInvestment.svg'
import HowItWorks from '../../Assets/navbarPopup/howitworks.svg'
import Blogs from '../../Assets/navbarPopup/blogs.svg'
import FAQs from '../../Assets/navbarPopup/faqs.svg'
import Video from '../../Assets/navbarPopup/video.svg'


function LoginNavbar(props) {
  const [currentState, setcurrentState] = useState(0);
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [profileOpen, setprofileOpen] = useState(false);
  const navSecondRef = useRef(null);
  const proIconRef = useRef(null);
  const [ismobile, setismobile] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const navigate = useNavigate();
  const [helpOpen, sethelpOpen] = useState(false);
  const [profileOpenMob, setprofileOpenMob] = useState(false)
  const [profileSubMenuOpen, setProfileSubMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(''); //active menu
  const location = useLocation();
const [showLogout,setShowLogout]=useState(false)
 

  useEffect(() => {
    const handleResize = () => {
      setismobile(window.innerWidth < 1050);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
    
    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      props.setisLoggedin(true);
      props.setUserId(userIdFromLocalStorage);
    }
  }, [props.isLoggedin]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (proIconRef.current && !proIconRef.current.contains(event.target)) {
        setprofileOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDropdownHover = () => {
    setcurrentState(3);
    setdropdownOpen(true);
  };

  const handleDropdownLeave = () => {
    setdropdownOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('sessionLS');
    localStorage.removeItem('accestoken');
    localStorage.removeItem('user_name')

    localStorage.removeItem('user_email')
    localStorage.removeItem('user_mobile_no')

    localStorage.removeItem('user_country_code')
 
    props.setisLoggedin(false);
    props.setUserId(null);
    navigate('/');
  };

  const handleRedirectToDashboard = () => {
    navigate('/dashboard');
  };

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };
  const toggleHelp =()=>{
    sethelpOpen(!helpOpen);
  }
  const toggleMob =()=>{
    setprofileOpenMob(!profileOpenMob);
  }

//for setting active menu
  useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith('/user/profile')) {
      setActiveMenuItem('profile');
    } else if (path === '/user/kyc') {
      setActiveMenuItem('kyc');
    } else if (path === '/user/nominee'){
      setActiveMenuItem('nominee');
    }
    else if(path === '/user/bank-details')
    {
      setActiveMenuItem('bank-details');
    }
    else{
      setActiveMenuItem('');
    }
  }, []);
  

  useEffect(() => {
    const path = location.pathname;
    if (path === '/login' || path === '/otp') {
      setShowLogout(false);
    } else {
        setShowLogout(true);
    }
  }, [location.pathname]);

const [webHelp, setwebHelp] = useState(false);

  return (

    <div className='navContainer'>
    <div className='navParent flex flex-row justify-between px-[24px] py-[19px] h-[60px]'>


 
    
    <Link to="/">
    <div className='text-white navLogo'>
        <img src={logo} className=' w-[135px]' alt='logo' />
      </div>
    </Link>
     

     
      {!ismobile && (
        <div className='text-white navLogin flex flex-row gap-5 -mt-1.5'>
          <div className='flex flex-col justify-center bg-[#78F9FF] text-[14px] font-normal rounded-md text-black px-[40px] py-[6px] cursor-pointer' style={{display:"none"}}>Club Access</div>
          {showLogout  && !ismobile && (
            <div className='border border-white flex flex-col justify-center text-[14px] font-normal rounded-md px-[40px] py-[6px] w-40 cursor-pointer' onClick={handleLogout}>Logout</div>
          )}
          {/* {props.isLoggedin && (
            <div className='flex flex-col'>
              <div className='flex flex-row justify-center rounded-md gap-12 py-[6px] w-40'>
                <div className='flex flex-col justify-center'>
                  <img src={Notification} alt='Notification' />
                </div>
                <div ref={proIconRef} className='-mt-1 h-[30px] w-[30px] p-1.5 bg-[#D4D4D4] rounded-2xl flex flex-col justify-center proIcon cursor-pointer' onClick={() => setprofileOpen(!profileOpen)}>
                  <img src={Profile} alt='Profile' />
                </div>
              </div>
              {profileOpen && (
                <div className=' bg-[#091526] rounded-lg mt-1 -ml-12 mr-12 flex flex-col pl-4 gap-4 pb-2 cursor-pointer'>
                  <div className='text-start' onClick={handleRedirectToDashboard}>Dashboard</div>
                  <div className='text-start' onClick={handleLogout}>Logout</div>
                </div>
              )}
            </div>
          )} */}
        </div>
      )}

      {ismobile  && showLogout && (
        <div className='text-white navLogin flex flex-row gap-5 -mt-1.5 font-nunito'>
          <div className=' hamburger cursor-pointer flex flex-col justify-center' onClick={handleLogout}>
          
            <MdOutlineLogout style={{height:"25px",width:"25px"}} />
            
            </div>
        </div>
      )}

      {ismobile && (
        <div className={`absolute bg-[#091526] text-white top-0 right-0 w-[200px] sideNav cursor-pointer ${sideNavOpen ? 'open' : 'closed'}`}>
          {/* SideNav content */}
          <div className='py-4 px-2 bg-[#091526]'>
            <div className='text-lg font-bold flex flex-row justify-end pr-6 -mt-1 -mr-2' onClick={toggleSideNav}><IoClose style={{height:"35px",width:"35px"}} /></div>
            
              <div className=' mt-4 flex flex-col gap-[16px]'>
                <div className=' text-start ml-4 text-[16px] font-semibold optActiveColorMob cursor-pointer'>About Us</div>
                <div className=' text-start ml-4 text-[16px] font-semibold optActiveColorMob cursor-pointer'>Properties</div>

                <div className='flex flex-col ' onClick={toggleHelp} >
                <div className='flex flex-row justify-between ml-4 mr-[23.5px] optActiveColorMob cursor-pointer'>
                  <div className=' text-[16px] font-semibold ' >Help Guide</div>
                  <div className='flex flex-col justify-center '><IoMdArrowDropdown /></div>
                </div>
                {helpOpen &&(
                  <>
                  <div className=' text-start ml-4 mt-2 text-[16px] font-semibold optActiveColorMob cursor-pointer'>Option 1</div>
                  <div className=' text-start ml-4 mt-2 text-[16px] font-semibold optActiveColorMob cursor-pointer'>Option 2</div>
                  </>
                )}
                </div>
              
                {showLogout && ismobile && (
                  <div className='border border-white flex flex-col justify-center text-[14px] font-normal rounded-md px-[40px] py-[6px] w-40 cursor-pointer Mob ml-4' onClick={handleLogout}>Logout</div>
                )}
                
                {props.isLoggedin && (
                  <div className='flex flex-col justify-between ' style={{height:"70vh"}}>
                    <div className=''>
                    {/* <div className='flex flex-row border border-white justify-between rounded-md gap-12 py-[6px] w-40 ml-2 pr-2 pl-2 cursor-pointer' >
                     <div className='flex flex-row justify-between gap-8'>
                        <div className='flex flex-col justify-center'>
                          <img src={Notification} alt='Notification' />
                        </div>
                        <div ref={proIconRef} className=' h-[25px] w-[25px] p-1.5 bg-[#D4D4D4] rounded-2xl flex flex-col justify-center proIcon cursor-pointer' onClick={toggleMob}>
                          <img src={Profile} alt='Profile' />
                        </div>
                      </div>
                      <div className='flex flex-col justify-center optActiveColorMob cursor-pointer' onClick={toggleMob}><IoMdArrowDropdown /></div>

                    </div> */}

                    {profileOpenMob && (
                      <div className=' cursor-pointer '>
                      <div className='flex flex-row ml-7 gap-5 mt-[28px] optActiveColorMob cursor-pointer' onClick={()=>{navigate("/dashboard")}}>
                      <div className={`flex flex-col justify-center `}><RxDashboard /></div>
                      <div className={` text-[16px] font-semibold  `}>Dashboard</div>
                    </div>

                    <div className='flex flex-row ml-7 items-center gap-5 mt-[10px] optActiveColorMob cursor-pointer' onClick={() => setProfileSubMenuOpen(!profileSubMenuOpen)} >
                    <div className={`flex flex-col justify-center  `}><CgProfile /></div>
                    <div className={` text-[16px] font-semibold  `}>My Profile</div>
                    {profileSubMenuOpen ? <MdOutlineArrowDropDown /> : <MdOutlineArrowDropUp />}
                  </div>
                  

{profileSubMenuOpen && (
    <div className='flex flex-col  gap-3 py-2 pl-7 ml-7'>

      <Link to="/user/profile">
      <p  className={`text-xs text-left ${
      activeMenuItem === 'profile' ? 'text-secondary' : 'text-white'
    }`}
      onClick={() => setActiveMenuItem('profile')}
      >Profile Setup</p>

      </Link>

        
      <Link to="/user/kyc">
      <p className={`text-xs text-left ${
      activeMenuItem === 'kyc' ? 'text-secondary' : 'text-white'
    }`}
      onClick={() => setActiveMenuItem('kyc')} >KYC</p>

      </Link>
      <Link to="/user/bank-details">
      <p className={`text-xs text-left ${
      activeMenuItem === 'bank-details' ? 'text-secondary' : 'text-white'
    }`}
      onClick={() => setActiveMenuItem('bank-details')}>Bank Details</p>

      </Link>
      <Link to="/user/nominee">
      <p className={`text-xs text-left ${
      activeMenuItem === 'nominee' ? 'text-secondary' : 'text-white'
    }`}
      onClick={() => setActiveMenuItem('nominee')}>Nominee </p>

      </Link>
      
    </div>
)}

                  <div className='flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer' onClick={()=>{navigate("/user/wishlist")}}>
                  <div className={`flex flex-col justify-center `}><MdOutlineChecklist/></div>
                  <div className={` text-[16px] font-semibold `}>Wishlist</div>
                </div>

                <div className='flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer ' onClick={()=>{navigate("/user/overview")}}>
                <div className={`flex flex-col justify-center  `}><TbBuildingSkyscraper /></div>
                <div className={` text-[16px] font-semibold `}>Overview</div>
              </div>

              <div className='flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer' onClick={()=>{navigate("/user/analytics")}}>
              <div className={`flex flex-col justify-center  `}><LuBarChart3 /></div>
              <div className={` text-[16px] font-semibold `}>Analytics</div>
            </div>    


            <div className='flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer' onClick={()=>{navigate("/user/transactions")}}>
            <div className={`flex flex-col justify-center  `}><HiOutlineCurrencyRupee /></div>
            <div className={` text-[16px] font-semibold `}>Transactions</div>
          </div>  

          <div className='flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer' onClick={()=>{navigate("/user/mymanager")}}>
          <div className={`flex flex-col justify-center `}><MdOutlinePeopleAlt /></div>
          <div className={` text-[16px] font-semibold `}>My Manager</div>
        </div>  
                    

    
                      </div>
                    )}

                      
                      
            





                  </div>

                  <div className='flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer'>
                  <div className={`flex flex-col justify-center `}><FaPowerOff /></div>
                  <div className={` text-[16px] font-semibold `} onClick={handleLogout}>Logout</div>
                  </div> 
                  </div>

                )}
                

              </div>



          </div>
        </div>
      )}
    </div>
    </div>
  );
}

export default LoginNavbar;
