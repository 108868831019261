import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import "./bank-style.css"

const BankModal = ({ children, closeModal }) => {
  return (




<div className="fixed inset-0 flex items-center px-3 bg-modalbg justify-center z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
<div className="relative w-auto my-6 mx-auto max-w-md">
  <div className="modal-close-btn absolute top-0 right-0 mt-2 mr-2 cursor-pointer" onClick={closeModal}>
    <IoCloseOutline style={{color:"#808080", fontSize:"24px"}} />
  </div>
  <div className="relative p-4 transform overflow-hidden rounded-lg bg-white shadow-xl">
    {children}
  </div>
</div>
</div>


  );
};

export default BankModal;
