import React, { useEffect, useState } from 'react'

import FormContainer from './FormContainer'
import LoginCustomInput from './LoginCustomInput'
import ReactFlagsSelect from 'react-flags-select'
import { countries } from 'countries-list';
import LoginNavbar from '../LoginNavbar/LoginNavbar';
import { ApiBaseUrl } from '../../Utils/urls';
import SignupLoader from './SignupLoader';
import ErrorAlert from '../Alert/ErrorAlert';
import SuccessAlert from '../Alert/SuccessAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import { InitiateMobileUpdate } from '../../Api/MobileNoUpdate';

export default function VerifyMobile() {
  const [typingStarted, setTypingStarted] = useState(false);
  const [selected, setSelected] = useState("IN");
  const[phoneNo,setPhoneNo]=useState("")
  const UserId=localStorage.getItem('user_id')
  const accesstoken= localStorage.getItem('accestoken');
const [isLoading,setLoading]=useState(false)
const [alertmsg,setAlertmsg]=useState("")
const [isError,setError]=useState(false)
const [isOtpSent,setOtpSent]=useState(false)
const navigate=useNavigate()

  const customLabels = {};
const location=useLocation()

 const { state } = location;


 console.log(state)
  const handleSkip=()=>{
    let redirectPath
  
    
    if(state){
      redirectPath=state.from?.pathname
    }
    else if(!state){
      redirectPath= "/"
    }
     navigate(redirectPath)
    
  }

 console.log(state)
  for (const countryCode in countries) {
    const countryData = countries[countryCode];
    const primary = countryData.name;
    const secondary = '+' + countryData.phone[0]; 
    
    customLabels[countryCode] = { primary, secondary };
  }

  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
 
  const [accessToken, setaccessToken] = useState("")

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
    const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
      setaccessToken(AccessTokenFromLocalStorage);
    }
  }, [isLoggedin]); 

  const isMobileValid = phoneNo.length === 10;

  const handleTyping = () => {
    setTypingStarted(true);
};



const mobileUpdateData = {
  phone_number:phoneNo,
  country_code:customLabels[selected].secondary,
  user_id: UserId
};


const handleInitiateMobileUpdate=(e)=>{
InitiateMobileUpdate(e,
  setLoading,
  accesstoken,
  mobileUpdateData,
  setAlertmsg,
  setOtpSent,
  navigate,
  phoneNo,
  setError,
  customLabels,
  selected,
  state
)

}







  return (

    <>
{
  isLoading && (
    <SignupLoader/>
  )
}
{
  isError && (
    <ErrorAlert message={alertmsg}/>
  )
}

{
  isOtpSent && (
    <SuccessAlert message="OTP Sent Successfully"/>
  )
}


 <LoginNavbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
    <FormContainer 

isEmailTitle={true}
emailTitle="Assist us by verifying your number, please."
isfooterExist={false}
issubTitle={false}

>

<div>
<form onSubmit={handleInitiateMobileUpdate}  className='loginform flex flex-col gap-4'>


<div>
  
<div className='flex gap-2.5'>

<ReactFlagsSelect

selectButtonClassName="menu-flags-button"
className="menu-flags"
selected={selected}
onSelect={(code) => setSelected(code)}
searchable
searchPlaceholder="Search countries"
showSelectedLabel={false}
customLabels={customLabels}
showSecondarySelectedLabel={false}

/>
   
<LoginCustomInput  
placeholder="Enter Mobile Number"
maxLength={10} 
 onChange={(e)=>{
    handleTyping();
    setPhoneNo(e.target.value);
}}
numericInput={true}
/>

</div>
{typingStarted && phoneNo.length !== 10 && (
    <p className='text-sm' style={{color:"#e23838",marginTop:"5px"}}>
        Please enter a valid 10-digit number.
    </p>
)}
     

  
  
</div>

            <button disabled={!isMobileValid}   type="submit" 
            className={`loginbtn text-base text-black font-semibold ${isMobileValid ? 'bg-secondary text-white' : 'disable-btn-bg disabled'}`}>
              Get OTP
              </button>

        </form>

            <div className='flex flex-col gap-4 loginform pt-4'>

              
            <button onClick={handleSkip}  className='skipbtn text-base text-blue font-normal'>Skip for Now</button>

              
          </div>

        <hr className='hrClass' />
          </div>

</FormContainer>


</>
  )
}
