import React,{useState,useEffect} from 'react'
import './Index.css'
import Navbar from '../Navbar/Index'
import DashboardList from '../../Assets/Dashboard/dashboard_top.png'
//import DashboardList from '../../Assets/Dashboard/dashboardList.svg'
import lock from '../../Assets/Dashboard/LockMobDash.svg'
import Dashboard from '../Dashboard/Index'
import Dashboard1 from '../../Assets/Dashboard/dashboard1.svg'
import propertyHolding from "../../Assets/Dashboard/Properties_Holding.png"
import Dashboard2 from '../../Assets/Dashboard/dashboard2.svg'
import Dashboard3 from '../../Assets/Dashboard/dashboard3.svg'
import Dashboard4 from '../../Assets/Dashboard/dashboard4.svg'
import Dashboard5 from '../../Assets/Dashboard/dashboard5.svg'
import Dashboard6 from '../../Assets/Dashboard/dashboard6.svg'
import Dashboard7 from '../../Assets/Dashboard/dashboard7.svg'
import Info from '../../Assets/Dashboard/info.svg'
import { IoMdInformationCircleOutline, IoMdSearch } from "react-icons/io";
import { TfiFilter } from "react-icons/tfi";
import Noproperty from '../../Assets/Dashboard/noproperty.svg'
import { ApiBaseUrl } from '../../Utils/urls'
import { BeatLoader } from 'react-spinners'
import BookedPropertiesMob from '../BookedPropertiesMob/Index'
import AvailablePropertiesMob from '../AvailablePropMob'
import ResaleMobFilter from '../ResaleFilters/ResaleMobFilter'
import SoldoutMobileFilter from '../SoldoutFilters/SoldoutFilterMob'
import DashboardMobileFilter from '../DashboardMobileFilter/Index'
import AvailableDashboardMobileFilter from '../AvailableDashboardMobFilter'
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Navigate, useNavigate } from 'react-router-dom'
import InfoPopup from '../InfoPopup/InfoPopup'
import WarningAlert from '../Alert/WarningAlert';
import ResalePropMob from '../ResaleDashboard/ResaleProbMob/ResalePropMob'
import SoldoutPropMob from '../SoldoutDashboard/SoldoutProbMob/SoldoutPropMob'

function DashboardMobile() {
    const [currentMode, setcurrentMode] = useState("Dashboard");
    const [activeTab, setActiveTab] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoggedin, setisLoggedin] = useState(false);
    const [menuSelected, setmenuSelected] = useState(0);
    const [isLoading,setLoading]=useState(false)
    const [isLoadingSecond,setLoadingSecond]=useState(false)


    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedBuilders, setSelectedBuilders] = useState([]);
    const [assetType, setAssetType] = useState([]);
    const [priceRange, setPriceRange] = useState([]);
    const [propertyPhases, setPropertyPhases] = useState([]);
    const [saleTypes, setSaleTypes] = useState([]);
const navigate=useNavigate();

     
const [userId, setUserId] = useState(null);
const [accessToken, setaccessToken] = useState(null)

const userID=localStorage.getItem('user_id');
const AccesToken=localStorage.getItem('accestoken');

const [showTotalInvPopup, setshowTotalInvPopup] = useState(false);
const [showPropHoldPopup, setshowPropHoldPopup] = useState(false);
const [showCurrValPopup, setshowCurrValPopup] = useState(false);
const [showTotalReturnPopup, setshowTotalReturnPopup] = useState(false);
const [showAvgCapPopup, setshowAvgCapPopup] = useState(false);
const [showAvgIRRPopup, setshowAvgIRRPopup] = useState(false);
const [showAvgRentalPopup, setshowAvgRentalPopup] = useState(false);
const userName = localStorage.getItem('user_name');
const handleShowTotalInvPopup = () => {
  setshowTotalInvPopup(true);
};
const handlecloseTotalInvPopup = () => {
  setshowTotalInvPopup(false);
};

const handleshowPropHolding = () => {
  setshowPropHoldPopup(true);
};
const handleclosePropHold = () => {
  setshowPropHoldPopup(false);
};

const handleshowCurrValPop = () => {
  setshowCurrValPopup(true);
};
const handlecloseCurrValPop = () => {
  setshowCurrValPopup(false);
};

const handleShowTotalReturnPopup = () => {
  setshowTotalReturnPopup(true);
};
const handlecloseTotalReturnPopup = () => {
  setshowTotalReturnPopup(false);
};

const handleShowAvgCapPopup = () => {
  setshowAvgCapPopup(true);
};
const handlecloseAvgCapPopup = () => {
  setshowAvgCapPopup(false);
};

const handleShowAvgIRRPopup = () => {
  setshowAvgIRRPopup(true);
};
const handlecloseAvgIRRPopup = () => {
  setshowAvgIRRPopup(false);
};

const handleShowAvgRentalPopup = () => {
  setshowAvgRentalPopup(true);
};
const handlecloseAvgRentalPopup = () => {
  setshowAvgRentalPopup(false);
};


useEffect(() => {
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
  const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
  if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
    setaccessToken(AccessTokenFromLocalStorage);
  }
}, [isLoggedin]); 

const [investmentDetails, setInvestmentDetails] = useState(null);
useEffect(() => {
  setLoading(true);


  const fetchData = async () => {
    try {
     
    // Ensure both userId and accessToken are available
        const response = await fetch(`${ApiBaseUrl}/fr-user/get-investment-details?user_id=${userID}`, {
          headers: {
            Authorization: AccesToken, 
            'Content-Type': 'application/json', 
          },
        });
        // if (!response.ok) {
        //   throw new Error('Failed to fetch investment details');
        // }
        const data = await response.json();
        setInvestmentDetails(data);
        setLoading(false);
      
    } catch (error) {
    
      console.error('Error fetching investment details:', error);
    }
  };

  if (userID && AccesToken){
    fetchData();
  }

 
}, []);





const [isEmailVerified, setisEmailVerified] = useState(false);
const [isKYCVerified, setisKYCVerified] = useState(false);
const [showEmailUpdateAlert,setShowEmailUpdateAlert]=useState(false)
const [showMobileUpdateAlert,setShowMobileUpdateAlert]=useState(false)
const [isMobileVerified,setisMobileVerified]=useState(false)


const handleVerify=()=>{
  if(!isEmailVerified)
  {
    setShowEmailUpdateAlert(true)
    setTimeout(() => {
      setShowEmailUpdateAlert(false);
      navigate("/user/profile")
    }, 2000);
   
  }
  else if(!isMobileVerified)
    {
      setShowMobileUpdateAlert(true)
      setTimeout(() => {
        setShowMobileUpdateAlert(false);
        navigate("/user/profile")
      }, 2000);
     
    }
  else if (isEmailVerified && isMobileVerified)
  {
    navigate("/user/kyc")
  }
}
// console.log(isEmailVerified)


useEffect(() => {
  const fetchData = async () => {
  
    try {
      setLoadingSecond(true);
      if (userID && AccesToken) {
        
        const response = await fetch(`${ApiBaseUrl}/fr-user/fetch-profile-and-kyc?user_id=${userId}`, {
          headers: {
            Authorization: AccesToken, 
            'Content-Type': 'application/json', 
          },
        });
    
        const data = await response.json();

        if (data.data.profile.email === null || data.data.profile.email === undefined) {
          setisEmailVerified(false);
          console.log(1)
        } else {
          setisEmailVerified(true);
          console.log(2)
        }
       
        if (data.data.profile?.mobile === null || data.data.profile?.mobile === undefined) {
          setisMobileVerified(false);
        } else {
          setisMobileVerified(true);
        }
        if (data.data.kyc.kyc_status === "REQUESTED" || data.data.kyc.kyc_status === "APPROVED") {
          setisKYCVerified(true);
        }        
        else{
          setisKYCVerified(false);
        }
        setLoadingSecond(false);

      }
    } catch (error) {
      setLoadingSecond(false);
      console.error('Error fetching profile and KYC details:', error);
    }
  };

  fetchData();
}, [userId, accessToken]);





const handleInputChange = (event) => {
  setSearchQuery(event.target.value);
};

const [showMobFilter, setshowMobFilter] = useState(false)
const [showAvailableMobFilter, setshowAvailableMobFilter] = useState(false)
const [showResaleFilter, setshowResaleFilter] = useState(false)
const [showSoldoutFilter, setshowSoldoutFilter] = useState(false);
const controllFilter =()=>{
    setshowMobFilter(!showMobFilter);
}



  return (
    <>
{
  showEmailUpdateAlert && (
    <WarningAlert message="Please Update Your Email" />
  )
}


{
  showMobileUpdateAlert && (
    <WarningAlert message="Please Update Your Mobile no" />
  )
}
    <div className='font-nunito' style={{paddingLeft:"3%",paddingRight:"3%",marginTop:"70px"}}>
    
    <div className=''><Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/></div>
{/*First col start */}
{!isEmailVerified && !isLoadingSecond &&(
  <div className='bg-white  rightPaneCollection text-black flex flex-col pl-2 pr-2 -mt-1' style={{width:"100%"}}>
  
  <div className='flex flex-row justify-center gap-2 h-6 verifyEmailBg cursor-pointer' style={{borderRadius:"40px"}}>
      
      <div onClick={()=>navigate("/user/profile")} className='text-[12px] font-semibold flex flex-row justify-center'>
       <div className='flex flex-col'>
        <div className='flex flex-row justify-center gap-2'>
        <IoIosInformationCircleOutline style={{height:"20px",color:"rgba(239, 176, 8, 1)"}} />
        <p style={{color:"rgba(239, 176, 8, 1)"}}>Verify your Email first &nbsp;</p>
          </div>

      
    <p> – time to kick start your investment journey !</p>
        </div>
     
    </div>
  </div>

</div>

)}




  
{isLoading ? (
  <div className="alertBar" style={{ height: "100vh" }}>
    <BeatLoader color={'#123abc'} loading={isLoading} />
  </div>
) : null}

{isKYCVerified && isLoadingSecond &&(
  <div className='flex flex-row justify-start px-4 rounded-lg bg-white font-nunito '>
  <div className='flex flex-col justify-between pb-1 gap-1'>
      <div className='text-black text-start text-[20px] font-bold'>Hi,{userName==="null"?"User":userName}</div>
      <div className='text-black text-start text-[14px] font-normal'>Here is your list of invested properties</div>
  </div>
</div>

)}

{/* !isKYCVerified && !isLoadingSecond && */}

{!isKYCVerified  && !isLoadingSecond && (
  <div className='border w-full flex flex-row justify-center rounded-lg bg-black font-nunito'>
  <div className='flex flex-col justify-center'>
      <div className='border  '><img src={DashboardList} className='dashlist-imgMobile'/></div>
  </div>   
  <div className='border border-black flex flex-col justify-between py-4'>
      <div className='border border-black text-white text-start text-[16px] font-semibold'>Hi, {userName==="null"?"User":userName}</div>
      <div className='border border-black text-white text-start text-[10px] font-normal'>Please complete your KYC to start<br></br> investing. You could be the next biggest<br></br> investor.</div>
      <div  onClick={handleVerify} className='flex flex-row justify-center mobileDashBg w-40 px-2 py-2 gap-2 rounded-lg mt-1'>
          <div className=' text-white text-[14px] font-semibold cursor pointer'>Verify Yourself</div>
          <div className='flex flex-col justify-center'><img src={lock} style={{color:"white"}}/></div>
      </div>
  </div>
</div>

)}

{/*First col end */}
{/*Second col start */}
        <div className=' flex flex-row justify-between font-nunito rounded-lg bg-secondary px-0.5 py-0.5 gap-2 mt-[15px] h-[42px]'>
            <div className={`py-1 px-3 rounded-lg text-[14px] font-normal toggleDash text-white flex flex-col justify-center cursor-pointer ${currentMode==="Dashboard"? "activeOptionDash":""}`} onClick={()=>setcurrentMode("Dashboard")}>Dashboard</div>    
            <div className={`py-1 px-3 toggleDash text-[14px] font-normal rounded-lg text-white flex flex-col justify-center cursor-pointer ${currentMode==="Myportfolio"? "activeOptionDash":""}`} onClick={()=>setcurrentMode("Myportfolio")}>My Portfolio</div>     
        </div>
{/*Second col end */}
{/*Third col start */}


{isLoading ? (
    <div className="alertBar" style={{ height: "100vh" }}>
      <BeatLoader color={'#123abc'} loading={isLoading} />
    </div>
  ) : (
currentMode==="Dashboard" &&(

<div className='flex flex-col'>

<div className=' topLeftMob dashShadowMob flex flex-row justify-between mt-[26px] h-[76px]' style={{borderRadius:"10px"}}>
<div className='flex flex-row gap-4 px-4' style={{width:"80%"}}>

    <div className=' flex flex-col justify-center'><img className='iconDashMob' src={propertyHolding}/></div>
    <div className=' flex flex-col justify-center'>
        <div className='text-[#565656] font-normal mt-1' style={{width:"100%"}}>Properties Holding </div>
        <div className=' text-start text-[16px] font-bold'>{investmentDetails && investmentDetails.data.property_holding}</div>
    </div>
</div>
<div className='px-4'>
    <div className='flex flex-row mr-[10px]'>
       
    <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7",marginTop:"10px"}} onClick={handleshowPropHolding} />

{

    showPropHoldPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Property Holding?</p>
                                <p className='text-gray text-left text-sm'>Property holdings are the total number of properties held by an investor.
                            </p>
                            </div>
           
                            <button onClick={handleclosePropHold} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}

    </div>
</div>
</div>



    <div className='flex flex-row  gap-3'>
        <div className='flex flex-row' style={{width:"50%"}}>
                <div className=' topLeftMob dashShadowMob flex flex-row justify-between mt-[26px] h-[76px]' style={{borderRadius:"10px"}}>
                <div className='flex flex-row gap-2 pl-2' style={{width:"90%"}}>
                
                    <div className=' flex flex-col justify-center'><img className='iconDashMobDown' src={Dashboard2}/></div>
                    <div className=' flex flex-col justify-center' style={{width:"100%"}}>
                        <div className='flex flex-row justify-between'>
                            <div className=' text-start text-[16px] font-bold'>₹ {investmentDetails && investmentDetails.data.total_investment}</div>
                            <div className='flex flex-row '>
                                 
    <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleShowTotalInvPopup} />

{

    showTotalInvPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Total Investment?</p>
                                <p className='text-gray text-left text-sm'>
                                Total investment refers to the combined funds across all investments.
                            </p>
                            </div>
           
                            <button onClick={handlecloseTotalInvPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}
                        </div>
                        </div>
                        <div className=' mt-1 text-start text-[#565656] font-normal text-[12px]' style={{width:"100%"}}>Total Investment</div>
                    </div>
                </div>
                </div>
        </div>

        <div className='flex flex-row' style={{width:"50%"}}>
        <div className=' topLeftMob dashShadowMob flex flex-row justify-between mt-[26px] h-[76px] ' style={{borderRadius:"10px"}}>
        <div className='flex flex-row gap-2 pl-2' style={{width:"90%"}}>
        
            <div className=' flex flex-col justify-center'><img className='iconDashMobDown' src={Dashboard3}/></div>
            <div className=' flex flex-col justify-center' style={{width:"100%"}}>
                <div className='flex flex-row justify-between'>
                    <div className=' text-start text-[16px] font-bold'>₹ {investmentDetails && investmentDetails.data.current_value}</div>
                    <div className='flex flex-row '>
   <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleshowCurrValPop} />

{
 showCurrValPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Current Value?</p>
                                <p className='text-gray text-left text-sm'>
                                The current value refers to the present market worth of all
                                 investments i.e ( Investment + Appreciation )
                            </p>
                            </div>
           
                            <button onClick={handlecloseCurrValPop} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            </InfoPopup>
        </>
    )
}
                </div>
                </div>
                <div className=' mt-1 text-start text-[12px] text-[#565656] font-normal' style={{width:"100%"}}>Current Value</div>
            </div>
        </div>
        </div>
        </div>
    </div>



    <div className='flex flex-row  gap-3'>
        <div className='flex flex-row' style={{width:"50%"}}>
                <div className=' topLeftMob dashShadowMob flex flex-row justify-between mt-[26px] h-[76px]' style={{borderRadius:"10px"}}>
                <div className='flex flex-row gap-2 pl-2' style={{width:"90%"}}>
                
                    <div className=' flex flex-col justify-center'><img className='iconDashMobDown' src={Dashboard4}/></div>
                    <div className=' flex flex-col justify-center' style={{width:"100%"}}>
                        <div className='flex flex-row justify-between'>
                            <div className=' text-start text-[16px] font-bold'>₹ {investmentDetails && investmentDetails.data.total_returns}</div>
                            <div className='flex flex-row '>

                              <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleShowTotalReturnPopup} />

{
 showTotalReturnPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Total Returns?</p>
                                <p className='text-gray text-left text-sm'>
                                It represents the actual return over the total investment.
                            </p>
                            </div>
           
                            <button onClick={handlecloseTotalReturnPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            </InfoPopup>
        </>
    )
}
                        </div>
                        </div>
                        <div className=' mt-1 text-start text-[12px] text-[#565656] font-normal' style={{width:"100%"}}>Total Returns</div>
                    </div>
                </div>
                </div>
        </div>

        <div className='flex flex-row' style={{width:"50%"}}>
        <div className=' topLeftMob dashShadowMob flex flex-row justify-between mt-[26px] h-[76px] ' style={{borderRadius:"10px"}}>
        <div className='flex flex-row gap-2 pl-2' style={{width:"90%"}}>
        
            <div className=' flex flex-col justify-center'><img className='iconDashMobDown' src={Dashboard5}/></div>
            <div className=' flex flex-col justify-center' style={{width:"100%"}}>
                <div className='flex flex-row justify-between'>
                    <div className=' text-start text-[16px] font-bold'>{investmentDetails && investmentDetails.data.average_capital_gain}%</div>
                    <div className='flex flex-row '>
                    <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleShowAvgCapPopup} />

{
 showAvgCapPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Average Property Gain?</p>
                                <p className='text-gray text-left text-sm'>
                                Average property gain is the typical increase in property value or profit over a given period.
                            </p>
                            </div>
           
                            <button onClick={handlecloseAvgCapPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            </InfoPopup>
        </>
    )
}

                </div>
                </div>
                <div className=' mt-1 text-start text-[12px] text-[#565656] font-normal' style={{width:"100%"}}>Average Potential Gain</div>
            </div>
        </div>
        </div>
        </div>
    </div>


<div className='flex flex-row  gap-3'>
        <div className='flex flex-row' style={{width:"50%"}}>
                <div className=' topLeftMob dashShadowMob flex flex-row justify-between mt-[26px] h-[76px]' style={{borderRadius:"10px"}}>
                <div className='flex flex-row gap-2 pl-2' style={{width:"90%"}}>
                
                    <div className=' flex flex-col justify-center'><img className='iconDashMobDown' src={Dashboard6}/></div>
                    <div className=' flex flex-col justify-center' style={{width:"100%"}}>
                        <div className='flex flex-row justify-between'>
                            <div className=' text-start text-[16px] font-bold'>{investmentDetails && investmentDetails.data.average_IRR}%</div>
                            <div className='flex flex-row '>
                            <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleShowAvgIRRPopup} />    
                          
                            {
 showAvgIRRPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Average IRR?</p>
                                <p className='text-gray text-left text-sm'>
                                Average IRR is the average annual return of all investments.

                            </p>
                            </div>
           
                            <button onClick={handlecloseAvgIRRPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            </InfoPopup>
        </>
    )
}
                        </div>
                        </div>
                        <div className=' mt-1 text-start text-[12px] text-[#565656] font-normal' style={{width:"100%"}}>Average IRR</div>
                    </div>
                </div>
                </div>
        </div>

        <div className='flex flex-row' style={{width:"50%"}}>
        <div className=' topLeftMob dashShadowMob flex flex-row justify-between mt-[26px] h-[76px] ' style={{borderRadius:"10px"}}>
        <div className='flex flex-row gap-2 pl-2' style={{width:"90%"}}>
        
            <div className=' flex flex-col justify-center'><img className='iconDashMobDown' src={Dashboard7}/></div>
            <div className=' flex flex-col justify-center' style={{width:"100%"}}>
                <div className='flex flex-row justify-between'>
                    <div className=' text-start text-[16px] font-bold'>{investmentDetails && investmentDetails.data.average_yield}%</div>
                    <div className='flex flex-row '>
                    <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleShowAvgRentalPopup} />    
                          
                          {
showAvgRentalPopup && (
      <>
       
                      <InfoPopup>
                          <div className='p-2'>
                          <p className='text-blue text-left text-sm'>What is Average Yield?</p>
                              <p className='text-gray text-left text-sm'>
                              The typical percentage return on investment over a given period, averaged across all investments.

                          </p>
                          </div>
         
                          <button onClick={handlecloseAvgRentalPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                              Ok</button>
                          </InfoPopup>
      </>
  )
}
                </div>
                </div>
                <div className=' mt-1 text-start text-[12px] text-[#565656] font-normal' style={{width:"100%"}}>Average Yield</div>
            </div>
        </div>
        </div>
        </div>
    </div>

</div>
  )



  )}

{currentMode==="Myportfolio" &&(

    <div className='mt-[24px]'>

    <div className="flex  overflow-auto gap-2 ">
      <button
        className={`borderDash flex flex-col items-center text-white text-xs font-nunito h-[35px] rounded-lg  px-4 py-2 text-nunito ${activeTab === 1 ? 'bg-black text-white font-semibold rounded-lg' : 'text-grey'}`}
        onClick={() => setActiveTab(1)}
      >
      
        <p className={`whitespace-nowrap ${activeTab === 1 ?'text-white font-bold':'text-gray font-semibold'}`}>Booked Properties</p>
        
      </button>



      <button
      className={`borderDash flex flex-col items-center text-white text-xs font-nunito h-[35px] rounded-lg  px-4 py-2 text-nunito ${activeTab === 2 ? 'bg-black text-white font-semibold rounded-lg' : 'text-grey'}`}
      onClick={() => setActiveTab(2)}
    >
    
      <p className={`whitespace-nowrap ${activeTab === 2 ?'text-white font-bold':'text-gray font-semibold'}`}>Available Properties</p>
      
    </button>

    <button
    className={`borderDash flex flex-col items-center text-white text-xs font-nunito h-[35px] rounded-lg  px-4 py-2 text-nunito ${activeTab === 3 ? 'bg-black text-white font-semibold rounded-lg' : 'text-grey'}`}
    onClick={() => setActiveTab(3)}
  >
  
    <p className={`whitespace-nowrap ${activeTab === 3 ?'text-white font-bold':'text-gray font-semibold'}`}>Resale Properties</p>
    
  </button>

    
  <button
  className={`borderDash flex flex-col items-center text-white text-xs font-nunito h-[35px] rounded-lg  px-4 py-2 text-nunito ${activeTab === 4 ? 'bg-black text-white font-semibold rounded-lg' : 'text-grey'}`}
  onClick={() => setActiveTab(4)}
>

  <p className={`whitespace-nowrap ${activeTab === 4 ?'text-white font-bold':'text-gray font-semibold'}`}>Sold out Properties</p>
  
</button>
    

</div>
</div>
    )}
{/*Third col end */}

<div className='flex flex-row justify-center enquiryParent mt-2'>
         <hr style={{ color: 'rgba(217, 217, 217, 1)', width: '100%' }}></hr>
</div>


{/*Fourth col start */}

{ currentMode==="Myportfolio" && activeTab === 1 &&(
   <>
   <BookedPropertiesMob showMobFilter={showMobFilter} setshowMobFilter={setshowMobFilter}/>
   </>

)}


{currentMode==="Myportfolio" && activeTab === 2 &&  ( 
  <>
 <AvailablePropertiesMob showAvailableMobFilter={showAvailableMobFilter} setshowAvailableMobFilter={setshowAvailableMobFilter}/>
  </>
        )}

 {currentMode==="Myportfolio" && activeTab === 3 &&   (
 <>
 <ResalePropMob showResaleFilter={showResaleFilter} setshowResaleFilter={setshowResaleFilter} />
 </>
        
        )}

   {currentMode==="Myportfolio" && activeTab === 4 &&  (
    
 <>
 <SoldoutPropMob showSoldoutFilter={showSoldoutFilter} setshowSoldoutFilter={setshowSoldoutFilter} />
 </>
        )}



{/*Fifth col start */}
{showMobFilter && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div className="h-full w-full">
      <DashboardMobileFilter
      setshowMobFilter={setshowMobFilter}
      selectedCities={selectedCities}
      setSelectedCities={setSelectedCities}
      selectedBuilders={selectedBuilders}
      setSelectedBuilders={setSelectedBuilders}
      assetType={assetType}
      setAssetType={setAssetType}
      priceRange={priceRange}
      setPriceRange={setPriceRange}
      propertyPhases={propertyPhases}
      setPropertyPhases={setPropertyPhases}
      saleTypes={saleTypes}
      setSaleTypes={setSaleTypes}       
       />
    </div>
  </div>
)}

{showAvailableMobFilter && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div className="h-full w-full">
      <AvailableDashboardMobileFilter
      setshowAvailableMobFilter={setshowAvailableMobFilter}
      selectedCities={selectedCities}
      setSelectedCities={setSelectedCities}
      selectedBuilders={selectedBuilders}
      setSelectedBuilders={setSelectedBuilders}
      assetType={assetType}
      setAssetType={setAssetType}
      priceRange={priceRange}
      setPriceRange={setPriceRange}
      propertyPhases={propertyPhases}
      setPropertyPhases={setPropertyPhases}
      saleTypes={saleTypes}
      setSaleTypes={setSaleTypes}       
       />
    </div>
  </div>
)}

{showResaleFilter && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div className="h-full w-full">
      <ResaleMobFilter
      setshowResaleFilter={setshowResaleFilter}
      selectedCities={selectedCities}
      setSelectedCities={setSelectedCities}
      selectedBuilders={selectedBuilders}
      setSelectedBuilders={setSelectedBuilders}
      assetType={assetType}
      setAssetType={setAssetType}
      priceRange={priceRange}
      setPriceRange={setPriceRange}
      propertyPhases={propertyPhases}
      setPropertyPhases={setPropertyPhases}
      saleTypes={saleTypes}
      setSaleTypes={setSaleTypes}       
       />
    </div>
  </div>
)}



{showSoldoutFilter && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div className="h-full w-full">
      <SoldoutMobileFilter
      setshowSoldoutFilter={setshowSoldoutFilter}
      selectedCities={selectedCities}
      setSelectedCities={setSelectedCities}
      selectedBuilders={selectedBuilders}
      setSelectedBuilders={setSelectedBuilders}
      assetType={assetType}
      setAssetType={setAssetType}
      priceRange={priceRange}
      setPriceRange={setPriceRange}
      propertyPhases={propertyPhases}
      setPropertyPhases={setPropertyPhases}
      saleTypes={saleTypes}
      setSaleTypes={setSaleTypes}       
       />
    </div>
  </div>
)}






{/*Fifth col end */}

    </div>
    </>
  )
}

export default DashboardMobile
