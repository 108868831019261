import React from 'react'
import loader from "../../Assets/icons/Loader.gif"
import "./loader-style.css"

export default function LoaderRelative() {
    return (
      <div className='CommonloaderRelative'>
  <img src={loader} alt="Loader"  className='loaderCommon' />
  </div>
    )
  }