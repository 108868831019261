import React from 'react'
import {Line} from "react-chartjs-2";
import {Chart as Chart} from 'chart.js/auto'

function BarChart({chartData,chartOptions }) {
  return <Line data={chartData}  options={chartOptions}/>;
  
}

export default BarChart
