import React,{useState,useEffect} from 'react'
import PropImg from '../../../../Assets/bookedProp.svg'
import Location from '../../../../Assets/bookedPropLocation.svg'
import Download from '../../../../Assets/downloadBookedProp.svg'
import { RiArrowUpFill } from "react-icons/ri";
import Line from '../../../../Assets/verticalLineBookedProp.svg'
import { IoMdInformationCircleOutline } from "react-icons/io";
import ResaleCard from '../../../ResalePopups/ResaleCardPopUp/ResaleCard'
import WebCancelResalePopup from '../../../ResalePopups/WebCancelResalePopup/WebCancelResalePopup';
import SuccessAlert from '../../../Alert/SuccessAlert'

function ResaleDashoardWebCard(props) {
    const [showConfirmPopup, setshowConfirmPopup] = useState(false);
    const [successListing, setsuccessListing] = useState(false);

    const toggleResale=()=>{
        setshowConfirmPopup(true);
    }
    


  return (
    <div>
      <div className='flex flex-row justify-between border border-lightgray items-center px-[20px] py-[10px] bookedPropShadow rounded-xl'>
        <div className=' flex flex-row gap-[30px]'>
            <div className='flex flex-col justify-center'>
                <div className='  w-[100px] rounded-sm'><img src={PropImg}/></div>
            </div>
            <div className=' flex flex-col justify-center nameBookedProp'>
                <div className=' font-bold text-[14px] text-start' style={{lineHeight:"22px"}}>{props.name}</div>
                <div className=' flex flex-row gap-[10px]'>
                    <div className=''><img src={Location} className='h-[13px] w-[12px]'/></div>
                    <div className=' font-normal text-[10px] text-[#4A4A4A] text-start' style={{lineHeight:"13px"}}>{props.city}, {props.location}</div>
                </div>

            </div>
            <div className='ml-2 dotLineBookedCard'><img src={Line}/></div>
        </div>
        <div className='ml-2 dotLineBookedCardBigger'><img src={Line}/></div>
       
        <div className=' flex flex-row justify-between  gapDetailsBooked gap-[30px]'>
            <div className=' flex flex-col gap-[8px] justify-center'>
                <div className=' font-bold text-[14px]'>{parseFloat(props.listableShares).toFixed(0)}</div>
                <div className=' text-[12px] font-medium text-[#5D5C5C] tokenWidth'>No. of Tokens</div>

            </div>
            <div className=' flex flex-col gap-[8px] justify-center'>
            <div className='font-bold text-[14px]'>₹{parseFloat(props.currentPrice).toFixed(0)}</div>
                <div className=' text-[12px] font-medium text-[#5D5C5C] currentWidth'>Listing Price</div>
            </div>
            <div className=' flex flex-col gap-[8px] justify-center'>
            <div className='flex flex-row gap-1'>
                <div className='font-bold text-[14px]'>
                    {props.capitalGain}%
                </div>
                <div className='flex flex-col justify-center'><RiArrowUpFill style={{height:"24px",width:"18px",color:"#219653"}}/></div>
            </div>
                <div className=' text-[12px] font-medium text-[#5D5C5C]'>Potential Gain</div>
            </div>
        </div>


        <div className=' flex flex-row gap-[15px] downloadStatusSection'>
            <div className='flex justify-center gap-3'>
                <div className='flex justify-center items-center'>
                <div className="tooltip">
                    <IoMdInformationCircleOutline style={{fontSize:"20px",color:" #D328A7"}} />
                    <span className="tooltiptextLeft">
                    <p className='text-blue text-sm'>Download</p>
                    <p className='text-gray text-xs'>Download the investment agreement/ certificate via the download button.
                    </p>
                    </span>
                </div> 
                </div>
            </div>
            <div className=' flex flex-col justify-center  '><img src={Download} className='h-[22px] w-[22px]'/></div> 
        </div>


            <div className='flex flex-col justify-center'>
                <div className='flex flex-col justify-center  px-7 w-[126px] h-[39px] rounded-md font-bold text-[14px] bg-[black] text-white cursor-pointer' style={{lineHeight:"12px"}} onClick={toggleResale}>Cancel</div>
            </div>

         
           <div className={ `rounded-xl popup-containerBookedConfirm  ${showConfirmPopup ? 'active' : 'none'}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' }}>
                <WebCancelResalePopup 
                saleOfferId={props.saleOfferId}
                userId={props.userID}
                setshowConfirmPopup={setshowConfirmPopup}
                setsuccessListing={setsuccessListing}
                setapiUpdateFlag={props.setapiUpdateFlag}
                apiUpdateFlag={props.apiUpdateFlag}
             
                 />
            </div>



           
            {
                successListing && (
                    <SuccessAlert message="Listing Cancelled Successful"/>
                )
            }

      </div>
    </div>
  )
}

export default ResaleDashoardWebCard
