import React from 'react'
import './Index.css'
import PropImg from '../../../Assets/bookedProp.svg'
import Location from '../../../Assets/bookedPropLocation.svg'
import Download from '../../../Assets/downloadBookedProp.svg'
import { RiArrowUpFill } from "react-icons/ri";
import Line from '../../../Assets/verticalLineBookedProp.svg'
import { IoMdInformationCircleOutline } from "react-icons/io";

function BookedPropertiesCard(props) {
  return (
    <div>
      <div className='flex flex-row justify-between border border-lightgray items-center px-[20px] py-[10px] bookedPropShadow rounded-xl'>
        <div className=' flex flex-row gap-[30px]'>
            <div className='flex flex-col justify-center'>
                <div className='  w-[100px] rounded-sm'><img src={PropImg}/></div>
            </div>
            <div className=' flex flex-col justify-center nameBookedProp'>
                <div className=' font-bold text-[14px] text-start' style={{lineHeight:"22px"}}>{props.name}</div>
                <div className=' flex flex-row gap-[10px]'>
                    <div className=''><img src={Location} className='h-[13px] w-[12px]'/></div>
                    <div className=' font-normal text-[10px] text-[#4A4A4A] text-start' style={{lineHeight:"13px"}}>{props.city}, {props.location}</div>
                </div>

            </div>
            <div className='ml-2 dotLineBookedCard'><img src={Line}/></div>
        </div>
        <div className='ml-2 dotLineBookedCardBigger'><img src={Line}/></div>
       
        <div className=' flex flex-row justify-between  gapDetailsBooked gap-[30px]'>
            <div className=' flex flex-col gap-[8px] justify-center'>
                <div className=' font-bold text-[14px]'>{props.myShare}</div>
                <div className=' text-[12px] font-medium text-[#5D5C5C] tokenWidth'>No. of Tokens</div>

            </div>
            <div className=' flex flex-col gap-[8px] justify-center'>
            <div className='font-bold text-[14px]'>₹{parseFloat(props.currentPrice).toFixed(0)}</div>
                <div className=' text-[12px] font-medium text-[#5D5C5C] currentWidth'>Current Value</div>
            </div>
            <div className=' flex flex-col gap-[8px] justify-center'>
            <div className='flex flex-row gap-1'>
                <div className='font-bold text-[14px]'>
                    {  (((parseFloat(props.myShare)*parseFloat(props.currentPrice) - parseFloat(props.purchasedPrice)) / parseFloat(props.purchasedPrice)) * 100).toFixed(2)  }%
                </div>
                <div className='flex flex-col justify-center'><RiArrowUpFill style={{height:"24px",width:"18px",color:"#219653"}}/></div>
            </div>
                <div className=' text-[12px] font-medium text-[#5D5C5C]'>Potential Gain</div>
            </div>
        </div>

        <div className=' flex flex-row gap-[45px] downloadStatusSection'>


{/* test */}
<div className='flex justify-center gap-3'>

<div className='flex justify-center items-center'>




{/* 
<div className="tooltip">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"20px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>Download</p>
                                <p className='text-gray text-xs'>Please download the investor agreement from here.
                                 After a successful transaction,
                                 you will receive it via mail as well within 24 hours.
                            </p>
                              </span>
                          </div> */}

</div>


     

            {/* <div className=' flex flex-col justify-center  '><img src={Download} className='h-[22px] w-[22px]'/></div> */}
          </div>

            <div className='flex flex-col justify-center'>
                <div className='flex flex-col justify-center  px-7 h-[33px] rounded-sm font-bold text-[12px] bg-[#D4D7DB]' style={{lineHeight:"12px"}}>Booked</div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default BookedPropertiesCard
