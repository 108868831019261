import React,{useState,useEffect} from 'react'
import "./responsive.css"
import ResalePartB from '../ResalePartB'
import { useNavigate,useLocation } from 'react-router-dom'
import { ProceedToPurchase } from '../../../Utils/tokenCalculationResale'
import { getUserDetails } from '../../../Api/ProfileApi';

export default function ResaleStickyPriceComponent(props) {

  return (

    <div ref={props.priceSectionRef} class={`sticky-price-section sticky-price-sectionResale ${props.showStickyPriceSection ? 'show' : ''}`}>
      
      {/* close-section */}
      <div className=" flex flex-row justify-center" onClick={props.handleClosePushSection}>
        <div className="h-1  w-60 mb-1 rounded-lg" style={{'backgroundColor':"#D9D9D9"}}>
            </div>

        </div>
        {/* close-section */}

      <div>
      <ResalePartB 
      propertyCity={props.propertyCity}
      propertyName={props.propertyName}
      tokenLimitMob={props.tokenLimitMob}
      id={props.id} 
      buyPriceMob={props.buyPriceMob}
      /></div>






    </div>
  )
}
