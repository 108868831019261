import React, { useState,useEffect } from 'react'
import "./index.css"
import Navbar from '../Navbar/Index';
import OverviewSection from './OverviewSection';
import DocumentTab from './DocumentTab';
import PurchaseHistory from './PurchaseHistory';
import WhyToInvest from './WhyToInvest';
import FAQComponenet from './FAQComponenet';

import GrowthTabMobile from './ResponsiveComponents/GrowthTabMobile';
import growthIcon2 from "../../Assets/icons/icons8_increase 1.svg"
import Growthicon from '../../Assets/icons/Growthicon';
import WhytoInvesticon from '../../Assets/icons/WhytoInvesticon';
import PaymentSchIcon  from "../../Assets/icons/paymentScheduleIcon.svg"


import { ApiBaseUrl } from '../../Utils/urls';
import { useNavigate, useParams } from 'react-router-dom';
import PaymentSchedule from './PaymentSchedule';
import PaymentScheduleMobile from './PaymentScheduleMobile';



export default function TabsComponent() {

    const [activeTab, setActiveTab] = useState(1);
    const user_id = localStorage.getItem('user_id');
    const [isLoading,setLoading]=useState(false)
   
    const [isLoggedin, setisLoggedin] = useState(false); 
    const [userId, setUserId] = useState(null);
    const [details,setPropertyDetails]=useState({})
    const [isPresale,setPresale]=useState("") //resale status
    const [iscomingSoon,setComingSoon]=useState("")
    const {id}=useParams()
   
    useEffect(() => {
      // Check if there's a user ID and session LS in localStorage
      const userIdFromLocalStorage = localStorage.getItem('user_id');
      const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
      
      if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
        // If both exist, set isLoggedin to true and store the user ID in the state
        setisLoggedin(true);
        setUserId(userIdFromLocalStorage);
      }
    }, [isLoggedin]); // Run only once when the component mounts
   



    let  propertyDetailsURl=""
    if(userId)
    {
      propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}&user_id=${userId}`
    
    }
    else{
      propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}`
     
    }


    useEffect(() => {
      const fetchPropertyDetails = async () => {
        try {
          setLoading(true);
          const response = await fetch(propertyDetailsURl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
           
          });
          if (!response.ok) {
            throw new Error('Failed to fetch property details');
          }
          const res = await response.json();
          //console.log(data);
          setLoading(false)
          setPropertyDetails(res.data);
          setPresale(res.data.property_info.property_phase)
          // res.data.property_info.sale_status
          setComingSoon(res.data.property_info.sale_status)
         
        } catch (error) {
          console.error('Error fetching property details:', error);
          setLoading(false);
        }
      };
    
      fetchPropertyDetails();
    }, [id,userId]);
    


 
    
  return (
    <div className="w-full sm:pt-4">
    <Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
      {/* Tab buttons */}
      <div className="flex   border-b border-lightgray hidden sm:block">

        <button
          className={` font-nunito rounded-none px-4 py-2  ${activeTab === 1 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black  text-base '}`}
          onClick={() => setActiveTab(1)}
        >
          Overview
        </button>
        

        {
          isPresale && isPresale==="Presale" && (
            <button
            className={` font-nunito rounded-none px-4 py-2  ${activeTab === 6 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black  text-base '}`}
            onClick={() => setActiveTab(6)}
          >
            Payments Schedule
          </button>
          )
        }
       



        <button
          className={` font-nunito rounded-none px-4 py-2  ${activeTab === 2 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black  text-base '}`}
          onClick={() => setActiveTab(2)}
        >
          Documents
        </button>
        <button
          className={`font-nunito rounded-none px-4 py-2  ${activeTab === 3? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black text-base '}`}
          onClick={() => setActiveTab(3)}
        >
          Purchase History
        </button>
        <button
          className={`font-nunito rounded-none px-4 py-2  ${activeTab === 4 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black text-base'}`}
          onClick={() => setActiveTab(4)}
        >
         Why to Invest
        </button>
        <button
          className={` font-nunito rounded-none px-4 py-2  ${activeTab === 5 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black text-base '}`}
          onClick={() => setActiveTab(5)}
        >
          FAQs
        </button>
        
      </div>

{/******************  mobile-tab ******************/}
    <div className="flex tab-shadow overflow-auto justify-between   sm:hidden">
      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 1 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(1)}
      >
      <Growthicon/>
        <p className={`whitespace-nowrap ${activeTab === 1 ?'text-blue':'text-gray'}`}>Growth</p>
        
      </button>



      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 2 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(2)}
      >


        <span class="material-symbols-outlined">
          overview
      </span>
        <p className={`whitespace-nowrap ${activeTab === 2 ?'text-blue':'text-gray'}`}> Overview </p>
        
      </button>
{
   isPresale && isPresale==="Presale"  && iscomingSoon!=='CREATED' && (
    <button
    className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 6 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
    onClick={() => setActiveTab(6)}
  >

    <img src={PaymentSchIcon} alt="payment_schedule" />

   
    <p className={`whitespace-nowrap ${activeTab === 6 ?'text-blue':'text-gray'}`}> Payments Schedule </p>
    
  </button>
   )
}

   


      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 3 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(3)}
      >
        <span class="material-symbols-outlined">
contract
</span>
        <p className={`whitespace-nowrap ${activeTab === 3 ?'text-blue':'text-gray'}`}> Documents</p>
        
      </button>

    
      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 4 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(4)}
      >
      
        <WhytoInvesticon/>
        <p className={`whitespace-nowrap ${activeTab === 4 ?'text-blue':'text-gray'}`}>  Why to Invest</p>
        
      </button>

    
      {/* <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 4 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(4)}
      >
        <img src={} alt="" />
        <p className={`whitespace-nowrap ${activeTab === 4 ?'text-blue':'text-gray'}`}>  FAQ</p>
        
      </button> */}
      
     

</div>


{/********  mobile-tab-content ****************/}

<div className='block sm:hidden'>
      <div className="mt-4">
      {activeTab === 1 && (
          <div className="py-4">
          
          <GrowthTabMobile details={details}/>
           
          </div>
        )}


        {activeTab === 2 && (
          <div className="">
            <OverviewSection propDetails={details}/>

          </div>
        )}

        {activeTab === 3 && (
          <div className="">
            <DocumentTab propDetails={details}/>
          </div>
        )}

       

        {activeTab === 4 && (
          <div className="">

            <WhyToInvest/>
          </div>
        )}

        {activeTab === 5 && (
          <div className="p-4">
            <FAQComponenet />
          </div>
        )}

{activeTab === 6 && (
          <div className="">
          <PaymentScheduleMobile propDetails={details}/>
          </div>
        )}


      </div>
      </div>



{/********  mobile-tab-content-end ****************/}

      {/* Tab content */}

      <div className='hidden sm:block'>
      <div className="mt-4">
        {activeTab === 1 && (
          <div className="">
            <OverviewSection propDetails={details}/>

          </div>
        )}


{activeTab === 6 && (
          <div className="">
        <PaymentSchedule propDetails={details}/>
          </div>
        )}


        {activeTab === 2 && (
          <div className="">
            <DocumentTab propDetails={details}/>
          </div>
        )}

        {activeTab === 3 && (
          <div className="">
            <PurchaseHistory details={details}/>
          </div>
        )}

        {activeTab === 4 && (
          <div className="">

            <WhyToInvest/>
          </div>
        )}

        {activeTab === 5 && (
          <div className="p-4">
            <FAQComponenet/>
          </div>
        )}


      </div>
     
        
      </div>





    </div>
  )
}


