import { handlelogout } from "../Utils/logout";
import { ApiBaseUrl } from "../Utils/urls";

export const AddBankDetails=(
    e,
    bankDetails,
    userId,
    accesstoken,
    setbankNotAdded,
    setalertmsg,
    setbankAdded,
    navigate
    )=>{

    const bankBody={
        ...bankDetails,
        user_id:userId
    }
    e.preventDefault()
    fetch(`${ApiBaseUrl}/fr-user/add-bank-account`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(bankBody)
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          console.log("not ok");
        }
       
        return response.json();
      })
      .then(resdata => {
       
        //console.log('Response from server:', resdata);
        
  
        if(!resdata.success)
        {
         
            setbankNotAdded(true)
            setalertmsg(resdata.data.msg)
            setTimeout(() => {
                setbankNotAdded(false)
                setalertmsg("")
               
            }, 3000);
        
        }
        
        if(resdata.success)
        { 
            setbankAdded(true)
            setalertmsg(resdata.data.msg)
            setTimeout(() => {
                setbankAdded(false)
                setalertmsg("")
                navigate("/user/bank-details")
            }, 3000);
        }
      })
      .catch(error => {
       
        console.error('error:', error);
       
      });
  }


  export const getBankDetails=(userId,accesstoken,setBankDetails,setBankLoading,navigate)=>{
    setBankLoading(true)
    fetch(`${ApiBaseUrl}/fr-user/get-bank-accounts?user_id=${userId}`, 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      }
    
    }
    
    )
      .then(response => {
      
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
       
        return response.json();
      })
      .then(resdata => {
        setBankLoading(false)
        //console.log('Response from server:', resdata);
        if (!resdata.success && resdata.data === 'INVALID TOKEN OR USER ID MISMATCH') {
           
          handlelogout(navigate); // Log out the user
        }
          if(resdata.success)
          {
            setBankDetails(resdata.data.bank_accounts)
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}


export const getBankDetailsforEdit=(userId,accesstoken,setBankDetails)=>{
  fetch(`${ApiBaseUrl}/fr-user/get-bank-accounts?user_id=${userId}`, 
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken 
    }
  
  }
  
  )
    .then(response => {
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
     
      return response.json();
    })
    .then(resdata => {
     
      //console.log('Response from server:', resdata);
     
        if(resdata.success)
        {
          setBankDetails(resdata.data.bank_accounts[0])
        }

    })
    .catch(error => {
     
      console.error('error details:', error);
    });
}


export const EditBankDetailsApi=(
  e,
  bankDetails,
  userId,
  accesstoken,
  setbankNotAdded,
  setalertmsg,
  setbankAdded,
  navigate,
  setLoading
  )=>{

  const bankBody={
    ...bankDetails,
    user_id:userId,
    
}

setLoading(true)

  e.preventDefault()
    fetch(`${ApiBaseUrl}/fr-user/change-bank-account`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(bankBody)
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          console.log("not ok");
        }
       
        return response.json();
      })
      .then(resdata => {
       
        // console.log('Response from server:', resdata);
        
        setLoading(false)
        if(!resdata.success)
        {
         
            setbankNotAdded(true)
            setalertmsg(resdata.data.msg)
            setTimeout(() => {
                setbankNotAdded(false)
                setalertmsg("")
               
            }, 3000);
        
        }
        
        if(resdata.success)
        { 
            setbankAdded(true)
            setalertmsg(resdata.data.msg)
            setTimeout(() => {
                setbankAdded(false)
                setalertmsg("")
                navigate("/user/bank-details")
            }, 3000);
        }
      })
      .catch(error => {
       
        console.error('error:', error);
       
      });
}
