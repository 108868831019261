

export const getPropertyDetails = async (propertyDetailsURl,setPropertyDetails,setPropComingSoon) => {
    try {
      //setLoading(true);
      const response = await fetch(propertyDetailsURl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
       
      });
      if (!response.ok) {
        throw new Error('Failed to fetch property details');
      }
      const res = await response.json();
      //console.log(data);
     // setLoading(false)
      setPropertyDetails(res.data);
      setPropComingSoon(res.data.property_info.sale_status)
      
    } catch (error) {
      console.error('Error fetching property details:', error);
      //setLoading(false);
    }
  };