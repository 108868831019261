import React from 'react'
import infoIcon from "../../../Assets/icons/info.png"
import { IoMdInformationCircleOutline } from 'react-icons/io'

export default function CustomInput({value,onChange,label,symbol,istooltip,maxLength,disabled=false}) {
  return (
    <div className='w-full '>
    <div className='flex gap-4 items-center justify-between'>
{/* 
      <div className='flex items-center gap-1'>
     


{ istooltip && (<div className="tooltip">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">Information</span>
                          </div>)
}

             </div> */}
        
      
                      <div className='flex rounded-xl relative text-center' style={{width:"100%"}}>
        <div className='bg-secondary p-2.5 text-white input-label-pink'>{symbol}</div>
        <input 
          type="text" 
          value={value} 
          onChange={onChange} 
          className='bg-lightgray text-black p-2.5 text-base why-to-input' 
          style={{width:"100%"}}
          maxLength={maxLength}
          disabled={disabled}
        />
      </div>
      </div>
    
      
      
    </div>
  )
}

