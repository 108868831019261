import React from 'react'
import nopurchase from "../../Assets/icons/no-transaction.png"
import { purchaseformatDate } from '../../Utils/purchaseHistDateFormat'
import './purchaseHistoryTable.css'

export default function PurchaseHistory({details}) {
 
  const {purchase_history}=details

  return (
    <div className='tab-shadow p-4 rounded-2xl '>









<div className='flex flex-col purchaseSec'>
<div className='flex w-full py-2 border font-semibold text-blue border-b-lightgray border-t-0 border-r-0 border-l-0'>

  
    <div className='phSlNo  '>SNo.</div>
    <div className='phOwnerId '>Owner ID</div>
    <div className='phTime '>Purchase Time</div>
    <div className='phToken '>No. Of Tokens</div>
    <div className='phPrice'><p>Price</p></div>

</div>



{
  purchase_history && purchase_history.length==0
  ?
  

 

  (
    
  
     <div className='flex flex-col justify-center items-center text-gray py-5'>
       <img src={nopurchase} alt="no purchase history" className='w-28' />
       <p >No purchase history available </p>
       <p>at the moment.</p>
     </div>
   


 
 )
:
(
  purchase_history && purchase_history.map((el,index)=>(
    

<div className='flex w-full py-2 border text-gray border-b-lightgray border-t-0 border-r-0 border-l-0'>

  
<div className='phSlNo'>{index+1}</div>
<div className='phOwnerId'>{el && el.user_id}</div>
<div className='phTime'>{purchaseformatDate(el && el.transaction_date)}</div>
<div className='phToken'>{el && el.number_of_shares}</div>
<div className='phPrice'><p>₹ {el && el.price.toLocaleString()}</p></div>

</div>



  ))





)


}



</div>









    </div>
  )
}
