export function CheckValidIFSCCode(ifsc_Code) {
    
    let regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
   
    // If IFSC Code is empty, return false
    if (ifsc_Code == null) {
      return false;
    }
   
    return regex.test(ifsc_Code);
  }