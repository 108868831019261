// phone_number_store

export const STORE_PHONE_NUMBER = 'STORE_PHONE_NUMBER';
export const STORE_COUNTRY_CODE = 'STORE_COUNTRY_CODE';

// signup

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

// otp verification

export const OTP_VERIFICATION_REQUEST = 'OTP_VERIFICATION_REQUEST';
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS';
export const OTP_VERIFICATION_FAILURE = 'OTP_VERIFICATION_FAILURE';

//update profile
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';


//email verification first time
export const EMAIL_VERIFICATION_REQUEST = 'EMAIL_VERIFICATION_REQUEST';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_FAILURE = 'EMAIL_VERIFICATION_FAILURE';
