import React, { useState, useEffect,useMemo,useRef } from 'react';
import './Index.css';
import DashboardSideNav from '../DashboardSideNav/Index';
import Navbar from '../Navbar/Index'
import { AiOutlineSearch } from 'react-icons/ai';
import PropertyCard from '../Cards/PropertyCard/Index';
import { BeatLoader } from 'react-spinners'
import { ApiBaseUrl } from '../../Utils/urls';
import { handlelogout } from '../../Utils/logout'
import SuccessAlert from '../Alert/SuccessAlert';
import { IconContext } from 'react-icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
function Wishlist() {
const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);
const [currentOption, setcurrentOption] = useState("Wishlist");
const [isMobile, setIsMobile] = useState(false);
const [selectedOpt, setSelectedOpt] = useState(2);
const [isLoading,setLoading]=useState(false)
const [showAlert, setshowAlert] = useState(false)
const [selected, setSelected] = useState(0);
const [isnotUserIdPresent, setisnotUserIdPresent] = useState(false);
const [currentInterestPropId, setcurrentInterestPropId] = useState(0);
const [responseData, setResponseData] = useState([]);
const [refreshPage, setrefreshPage] = useState(false);
const [fetchAPIHelper, setfetchAPIHelper] = useState(false);
const [isFirstTime, setIsFirstTime] = useState(true);
const [landingPageRefreshHelp, setlandingPageRefreshHelp] = useState(false);
const [isnotEmailVerified, setisnotEmailVerified] = useState(false);
const handleDivClick = (index) => {
  setSelected(index);
};


const [searchInput, setSearchInput] = useState('');


const [isOneSecondOver, setisOneSecondOver] = useState(false);
useEffect(() => {
  const timer = setTimeout(() => {
    setisOneSecondOver(true);
  }, 1500);

  // Clear the timer when the component unmounts or when the state changes
  return () => clearTimeout(timer);
}, []); // Empty dependency array means this effect runs only once after initial render

useEffect(() => {
  if (isOneSecondOver && refreshPage) {
    setisOneSecondOver(false);
  }
}, [refreshPage]); // This effect runs whenever refreshPage changes


const [isLoggedin, setisLoggedin] = useState(false); 
const [userId, setUserId] = useState(null);
const [accessToken, setaccessToken] = useState(null)
const username = localStorage.getItem('user_name');
useEffect(() => {
  // Check if there's a user ID and session LS in localStorage
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
  const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
  
  if (userIdFromLocalStorage) {
    // If both exist, set isLoggedin to true and store the user ID in the state
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
    setaccessToken(AccessTokenFromLocalStorage);
  }
}, [isLoggedin]); // Run only once when the component mounts

const [wishlishtedPropertyDetails, setwishlishtedPropertyDetails] = useState([]);
const navigate=useNavigate()

useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true);
      if (userId && accessToken && (fetchAPIHelper || isFirstTime)  ) { // Ensure both userId and accessToken are available
        const response = await fetch(`${ApiBaseUrl}/fr-user/get-wishlisted-properties?user_id=${userId}`, {
          headers: {
            Authorization: accessToken, 
            'Content-Type': 'application/json', 
          },
        });
       
        const data = await response.json();

        if (!data.success && data.data === 'INVALID TOKEN OR USER ID MISMATCH') {
           
          handlelogout(navigate); // Log out the user
        }


        setwishlishtedPropertyDetails(data.data);
        setResponseData(data.data)
        setLoading(false);
        if (isFirstTime) {
          setIsFirstTime(false);
        }
        if(fetchAPIHelper){
          setfetchAPIHelper(false);
          setIsFirstTime(true);
        }
       
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching investment details:', error);
    }
  };

  fetchData();
}, [userId, accessToken,refreshPage,fetchAPIHelper]);


useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

const sideNavRef = useRef(null);
useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1020px)');
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addListener(handleResize);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, []);

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
  };
  useEffect(() => {
    const debouncedSearch = debounce((input) => {
      const filteredProperties = input.trim() === '' ? responseData : responseData.filter(property =>
        `${property.property_name} ${property.property_city} ${property.property_location} ${property.builder_name} ${property.price_per_share}`.toLowerCase().includes(input.toLowerCase())
      );
      setwishlishtedPropertyDetails(filteredProperties);
    }, 300);
  
    if (searchInput !== '') {
      debouncedSearch(searchInput);
    } else {
      setwishlishtedPropertyDetails(responseData);
    }
  
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, responseData]);

  useEffect(() => {
    if (wishlishtedPropertyDetails.length === 0) {
      setisOneSecondOver(true);
    }
  }, [wishlishtedPropertyDetails]); // This effect runs whenever wishlistedPropertyDetails changes


// useEffect(()=>{

//   const stateData = { key: 'value', someOtherKey: 'someValue' }; // Your state object
//   const pageTitle = 'Page Title'; // Optional - Title of the new history entry
//   const newUrl = '/new-url'; // URL of the new history entry

//   // Add state to history stack
//   window.history.pushState(stateData, pageTitle, newUrl);
// },[])

  return (
    <div className='parent font-nunito mt-16'>
      <div className=''><Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/></div>
      <div className=' flex flex-row justify-center firstHelper'>
        <div className='bg-white text-black flex flex-row  helperWishlist pr-12'>


        {/*Left part start */}
          <div 
          ref={sideNavRef}
          className=' leftPaneCollectionDash  text-black flex flex-col ml-2 -mt-8 px-4 py-8 pb-12 '
          style={{ 
            width:"240px",
            borderRadius:"0px",
            backgroundColor: "rgba(244, 243, 245, 1)",
            position:"fixed",
            height: leftPaneHeight, 
            overflowY: 'auto' 
          }}>
            <DashboardSideNav currentOption={currentOption} selectedOpt={selectedOpt} setSelectedOpt={setSelectedOpt}/>
          </div>
        {/*Left part end*/}



          {/*Right part start */}

                  
   

          <div className=' marginWishlist sm:mt-[30px]'>

          <div className=' topParentWishlist'>
          <div className=''>
            <div className=' text-[24px] font-bold text-start'>Hi, {username==="null"?"User":username}</div>
            <div className=' text-[16px] font-normal text-start'>Here is your list of wishlist properties</div>
          </div>
          <div className=''>
          <div className='bg-white searchSpace gap-1.5 p-1.5'>
          <div className='bg-white flex flex-col justify-center'>
            <div className=' searchCollect flex flex-col justify-center '>
              <IconContext.Provider value={{ color: 'black' }}>
                <div>
                  <AiOutlineSearch />
                </div>
              </IconContext.Provider>
            </div>
          </div>
          <div className='text-base flex flex-col justify-center'>
            <input
            className='inputWidhtCont flex flex-column justify-center'
              type='text'
              placeholder="Search by Properties, Builder's name, Location"
              value={searchInput}
              onChange={handleSearchInputChange}
              style={{ fontSize: '12px',fontWeight:'400' }} 
            />
          </div>
        </div>
</div>

          </div>
{/* 
          {wishlishtedPropertyDetails.length === 0 && isOneSecondOver  && (
           <div className='noWishContainer'>
     
      
           <div className='noWishactionImg'></div>
           <p className='noWishactionText text-gray'>No property has been booked yet.</p>
           
           </div>
          )} */}


{isLoading  && !isOneSecondOver? (
  <div className="alertBar" style={{height:"100vh"}}>
    <BeatLoader color={'#123abc'} loading={isLoading}  />
  </div>
) : (
  <div className={`propertyContainer bg-white flex-wrap gap-5 mt-[40px] wishlistPropertyCard ${wishlishtedPropertyDetails.length > 3 ? 'wishlistPropertyCardSpecial' : ''}`}>
    {wishlishtedPropertyDetails.length === 0 && isOneSecondOver ? (
      searchInput === '' ? (
        <div className='noWishContainer'>
          <div className='noWishactionImg'></div>
          <p className='noWishactionText text-gray'>No property has been wishlisted yet.</p>
        </div>
      ) : (
        <div className='noWishContainer'>
           <div className='noWishactionImg'></div>
          <p className='noWishactionText text-gray'>No property found.</p>
        </div>
      )
    ) : (
      wishlishtedPropertyDetails.map((property, index) => (
        <PropertyCard
          key={index}
          isLoggedin={isLoggedin}  
          setshowAlert={setshowAlert}
          setcurrentInterestPropId={setcurrentInterestPropId}
          setisnotUserIdPresent={setisnotUserIdPresent}
          name={property.property_name}
          place={property.property_city}
          tokensSold={property.sold_shares}
          totalTokens={property.total_shares}
          type={property.property_type}
          targetIRR={property.target_IRR}
          grossYield={property.gross_yield}
          capitalGain={property.capital_gain}
          price={property.property_price}
          minInvestment={property.price_per_share}
          exactLocation={property.property_location}
          id={property.property_id}
          sale_type={property.sale_type}
          sale_status={property.sale_status}
          has_enquired={property.has_enquired}
          is_wishlisted={!property.is_wishlisted}
          refreshPage={refreshPage}
          setrefreshPage={setrefreshPage}
          fetchAPIHelper={fetchAPIHelper}
          setfetchAPIHelper={setfetchAPIHelper}
          setlandingPageRefreshHelp={setlandingPageRefreshHelp}
          isnotEmailVerified={isnotEmailVerified}
          setisnotEmailVerified={setisnotEmailVerified}
          image={property.property_images[0]?.Key}
          propertyPhase={property.property_phase}
        />
      ))
    )}
  </div>
)}




            {showAlert && (
              <div className='successIndex'> <SuccessAlert  message="Interest Saved!" /></div>
             
   
           )}    
          </div>
        
          {/*Right part end */}





        </div>
      </div>
    </div>
  );
}

export default Wishlist;
