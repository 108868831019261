import React, { createContext, useContext, useState } from 'react';
const BookedPropertyFiltersContext = createContext();
export const useBookedPropertyFilters = () => useContext(BookedPropertyFiltersContext);
export const initialPropertyFiltersBooked = {
  
  filtersByString: {
    "property_type": null,
    "resale_status": null,
    "sale_type": null
  },
  purchased_date: {
      "from": "2023-03-12 00:00:00",
      "to": "2024-03-19 23:59:59"
  },
  filtersByPlaces: [],
  filtersByBuilders: [],
};
export const BookedPropertyFiltersProvider = ({ children }) => {
  const [BookedpropertyFilters, setBookedPropertyFilters] = useState(initialPropertyFiltersBooked);

  return (
    <BookedPropertyFiltersContext.Provider value={{ BookedpropertyFilters, setBookedPropertyFilters }}>
      {children}
    </BookedPropertyFiltersContext.Provider>
  );
};



