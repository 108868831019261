import React, { useEffect, useState } from 'react'
import BankContainer from './BankContainer'
import BankModal from './BankModal'
import { Link, useNavigate } from 'react-router-dom'
import { ApiBaseUrl } from '../../Utils/urls'
import bankImg from "../../Assets/icons/Bank_Detail.png"
import { getBankDetails } from '../../Api/BankDetailsapi'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import SignupLoader from '../Signup/SignupLoader'
import BankLoader from './BankLoader'
import "./bank-style.css"


export default function BankDetailsPage() {
    const [showAddModal,setShowAddModal]=useState(false)
    const [bankDetails,setBankDetails]=useState([])
    const [showEditModal,setShowEditModal]=useState(false)
    const [isBankLoading,  setBankLoading]=useState(false)
    const [closeStickyEditModal,setCloseStickyModal]=useState(false)
   

  const userId=localStorage.getItem("user_id")
  const accesstoken=localStorage.getItem("accestoken")

    const navigate=useNavigate()

    const handleCloseModal=()=>{
        setShowAddModal(false)
    }
    const handleOpenModal=()=>{
        setShowAddModal(true)
    }
    const handleOk=()=>{
        navigate("/user/bank-details/add")
        }


  //update confirm modal
const handleOpenUpdateModal=()=>{
 
  setShowEditModal(true)
}
const handleCloseUpdateModal=()=>{
  setShowEditModal(false)

}
const handleProceed=()=>{
  navigate("/user/bank-details/edit")
}

const fetchBankDetails=()=>{
  getBankDetails(userId,accesstoken,setBankDetails,setBankLoading,navigate)
}




useEffect(()=>{
        fetchBankDetails()
},[])


const handleCloseStickyModal=()=>{
  setShowEditModal(false)
}



  return (
    <>


{
    showAddModal && (
<BankModal closeModal={handleCloseModal}>
<div className='flex flex-col  gap-7 font-nunito'>
            <p className='text-gray text-xl font-bold text-center'> More About Bank Details</p>

           <p className='text-gray text-sm font-normal text-center'>
           We take verified bank details for a smooth transaction, which includes profit and 
           dividend income distribution. 
           However, in the event of any discrepancy, PropFTX will not be liable for any loss.

           </p>
            
            <button 
            className='bg-secondary rounded-md text-white nomineeOkbtn'
            onClick={handleOk}
            >
                OK
            </button>

 </div>

</BankModal>
    )
}

{
  showEditModal && (
    <BankModal closeModal={handleCloseUpdateModal}>
<div className='flex flex-col gap-2 sm: font-nunito'>
            <p className='text-gray text-lg sm:text-xl font-bold text-center'> Are you sure you wish to edit the bank account 
you currently added?</p>

           <p className='text-gray text-sm font-normal text-center'>
           Make sure you are editing the right information about the bank account

           </p>
            <img src={bankImg} alt="bank" className='bankImg mx-auto' />
            <button 
            className='bg-secondary rounded-md text-white nomineeOkbtn'
            onClick={handleProceed}
            >
               Yes, Proceed
            </button>

 </div>

</BankModal>
  )
}


{/* 
{
  showEditModal && (
<div class="mobileModalBank block sm:hidden">

<div class=" flex flex-row justify-center cursor-pointer" onClick={handleCloseStickyModal}>
  <div class="h-1  w-60 mb-1 rounded-lg" style={{backgroundColor:'#dddddd'}}>
    </div>
    </div>


<div className='flex flex-col gap-5 font-nunito'>
            
            <img src={bankImg} alt="bank" className='bankImg mx-auto' />

            <p className='text-gray text-lg sm:text-xl font-bold text-left'> Are you sure you wish to edit the bank account 
you currently added?</p>

           <p className='text-gray text-sm font-normal text-left'>
           Make sure you are editing the right information about the bank account

           </p>
            <button 
            className='bg-secondary rounded-md text-white nomineeOkbtn'
            onClick={handleProceed}
            >
               Yes, Proceed
            </button>

 </div>

    </div>
  )
} */}







    <BankContainer>

    <div>
      <div className='font-nunito'>



<div className='text-center w-full   px-1 sm:px-2.5  pb-4'>
  <p className='text-2xl font-medium sm:text-base text-center sm:text-left'>Bank Details</p>
  </div>



 <div className='profileSection  font-nunito'>





    {
      isBankLoading
      ? (
        <BankLoader/>
      )
      :
      ( bankDetails &&  bankDetails.length === 0 
       ?
       <div className='flex flex-col px-1 sm:px-0'>
      
       <div className='flex justify-between'>
                  <p className='text-sm sm:text-2xl text-gray sm:text-blue font-bold mb-3'>Add Your Bank Details</p> 
                   <div className='flex gap-4 items-center'>
                     
                   </div>
   </div>
 
    <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-2 text-left bankInnerDiv'>
               <p className='text-gray text-sm sm:text-sm'>Add your bank account which is  linked with your Aadhar card and Pan Card. 
               Please make sure that all the information is correct and verified.</p>
                     
           </div>
 
        
            </div>
 
     {/* background-image */}
         
 
            <div className='bankContainer'>
     
      
     <div className='bankBgImg'></div>
     
     </div>
 
 
 <hr className='kychrClass' />
 
 <div className='flex items-center justify-between mt-6'>
 <p className='text-base text-secondary underline'></p>
 
 <button className='bg-secondary text-white addAccountBtn'   onClick={handleOpenModal} >
 + Add Bank Account
     </button>
 </div>
            </div>
       :


      <div>
      <div className='bank-details'>
          <div className='flex flex-col gap-4'>
              
              <div className='flex justify-between items-center'>
                  <p className='text-gray text-sm'>Account no:</p>
                  <p className='text-blue text-sm font-medium'>
                      {bankDetails && bankDetails[0].account_number}
                      </p>
              </div>
              <div className='flex justify-between items-center'>
                  <p className='text-gray text-sm'>IFSC Code:
                  
                 



                  </p>
                  <p className='text-blue text-sm font-medium'>
                    {bankDetails && bankDetails[0].ifsc_code}
                      </p>
              </div>
      <hr />
              <div className='flex justify-between items-center'>
                  <p className='text-gray text-sm font-bold'>Primary account:</p>
                  <p className='text-blue text-sm font-medium'>
                      Status:{
            bankDetails && bankDetails[0]?.is_active === 0 && !bankDetails[0]?.note ? (
                <span className='text-warning'>In Review</span>
            ) : bankDetails[0]?.is_active === 0 ? (
                <span className='text-red'>Inactive</span>
            ) : (
                <span className='text-green'>Active</span>
            )
        }
                      
                      </p>
              </div>


{
   
    bankDetails && bankDetails[0]?.note && (
      <div className='flex text-left justify-between text-gray items-center text-sm sm:text-basic'>
      <p >  Reason for Inactive Status:<span className='text-red text-sm sm:text-basic'> {bankDetails && bankDetails[0]?.note}</span> </p>
      </div>
    )
}

        

          </div>
      </div>

      <div className='bank-details mt-7 flex  justify-between'>


      <button onClick={handleOpenUpdateModal} disabled={bankDetails[0]?.note === ""} 
      className={`bankBtn ${bankDetails[0]?.note === ""?'bg-lightgray text-black':'bg-secondary text-white'}   text-base font-semibold`}>
              Edit  Account
             </button>

           
        
      </div>

      </div>
      )

       
    }
    
           





          </div>    









</div>
    </div>
    </BankContainer>
    
    </>
  )
}
