

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RestrictPurchaseRoute = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/purchase') {
      sessionStorage.removeItem('isFromPropertyDetails');
    }
  }, [location.pathname]);

  return null; 
};

export default RestrictPurchaseRoute


