import React from 'react'

function AvailableBuyOfferCard(props) {
  // const formatDate = (datetimeString) => {
  //   const dateObj = new Date(datetimeString);
  //   return dateObj.toLocaleDateString('en-GB', { timeZone: 'UTC' });
  // };

  // const formattedDate = formatDate(props.listingDate);

  return (
    <div className='rounded-md mt-[17px] hoverBuySellResale'>
    <div className='flex flex-row justify-between mt-[10px] border-b border-[#E6E6E6] pb-[8px] pl-2'>
    <div className=' text-start optResaleCards text-[14px] text-[#787878]' style={{width:"35%"}}> {props.orders}</div>
    <div className=' text-start optResaleCards text-[14px] text-[#787878] ' style={{width:"25%"}}>{parseInt(props.tokenQuantity)}</div>
    <div className=' text-start optResaleCards text-[14px] text-[#787878]' style={{width:"40%"}}>₹{props.buyPrice}</div>
  </div>
    </div>
  )
}

export default AvailableBuyOfferCard
