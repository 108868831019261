
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CookieLogout = () => {

    const navigate=useNavigate()
    useEffect(() => {
    
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        };

        
        const checkAndClearLocalStorage = () => {
            const clearLocalStorageCookie = getCookie('clearLocalStorage');
            if (clearLocalStorageCookie === 'true') {
                localStorage.clear();
               
                document.cookie = "clearLocalStorage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.propftx.com";
                navigate("/")
            }
        };

       
        const intervalId = setInterval(checkAndClearLocalStorage, 5000); 

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, []);

    return null; 
};

export default CookieLogout;
