import React, { createContext, useContext, useState } from 'react';
const PropertyFiltersContext = createContext();
export const usePropertyFilters = () => useContext(PropertyFiltersContext);
const currentDate = new Date();
const oneYearAgo = new Date(currentDate); 
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);


oneYearAgo.setHours(oneYearAgo.getHours() + 5);
oneYearAgo.setMinutes(oneYearAgo.getMinutes() + 30);


const fromDateString = oneYearAgo.toISOString().slice(0, 19).replace('T', ' ');
currentDate.setHours(currentDate.getHours() + 5);
currentDate.setMinutes(currentDate.getMinutes() + 30);
const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');

export const initialPropertyFilters = {
  filtersByString: {
    "NFT_status": null,
    "SPV_status": null,
    "property_city": null,
    "property_type": null,
    "resale_status": null,
    "sale_status": null,
    "sale_type":null,
    "property_phase":null
    
  },
  filtersByDate: {
    "listing_date": {
      "from": fromDateString,
      "to": toDateString
 }
  },
  filtersByPrice: {
    "price_per_share": null
  },
  filtersBySearch: {
    "property_location": null
  },
  filtersByPlaces: [],
  filtersByBuilders: [],
  filterBySort: {
    "listing_date": null,
    "price_per_share": null
  },
  userType: ""
};
export const PropertyFiltersProvider = ({ children }) => {
  const [propertyFilters, setPropertyFilters] = useState(initialPropertyFilters);

  return (
    <PropertyFiltersContext.Provider value={{ propertyFilters, setPropertyFilters }}>
      {children}
    </PropertyFiltersContext.Provider>
  );
};



