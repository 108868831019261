import React, { useState } from 'react'

import { AiOutlineLink } from 'react-icons/ai'
import { MdOutlineWhatsapp } from 'react-icons/md'
import { RiFacebookFill, RiTwitterXFill } from "react-icons/ri";
import { TiSocialLinkedin } from "react-icons/ti";

export default function SharePopup() {

    //get the current window URL
    const shareUrl = window.location.href;
    const [linkCopied, setLinkCopied] = useState(false);
    const copyToClipboard = () => {
        // Copy URL to clipboard
        navigator.clipboard.writeText(shareUrl)
        .then(() => {
            setLinkCopied(true); 
            setTimeout(() => {
                setLinkCopied(false); // Reset linkCopied state after 2 seconds
            }, 3000);
        })
            .catch((error) => {
                console.error('Failed to copy: ', error);
            });
    };


    const shareOnFacebook = () => {
      // Open Facebook sharing dialog
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=Hi! Have a look at this awesome fractional property investment opportunity with PropFTX`, '_blank');
      
    
    };


    const shareOnWhatsApp = () => {
        //message with the URL 
        const shareUrlWA = encodeURIComponent(shareUrl);
        const message = 'Hi! Have a look at this awesome fractional property investment opportunity with PropFTX: ' + shareUrlWA;

      //for whatsapp web
        const whatsappWebUrl = `https://api.whatsapp.com/send?text=${message}`;

        // Open WhatsApp Web in a new tab
        window.open(whatsappWebUrl, '_blank');
    };

    const shareOnLinkedIn = () => {
       // Construct the LinkedIn share URL
        const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`;

        // Open LinkedIn share dialog in a new tab
        window.open(linkedInShareUrl, '_blank');
    };
    const shareOnTwitter = () => {
         const shareUrlLinked = encodeURIComponent(window.location.href);
         const twitterShareUrl = `https://twitter.com/intent/tweet?url=${shareUrlLinked}`;
         window.open(twitterShareUrl, '_blank');
    };


  return (

    <div className='absolute font-nunito  text-blue bg-white p-4 rounded-lg  flex flex-col  gap-4 share-popup' aria-labelledby="modal-title" role="dialog" aria-modal="true">
        
        <div className='flex gap-3 cursor-pointer items-center' onClick={copyToClipboard}>
        <AiOutlineLink  className='socialMediaIcon linkicon'/>
        <p>{linkCopied ? "Link Copied" : "Copy Link"}</p>
        </div>
        <div className='flex gap-3 cursor-pointer items-center' onClick={shareOnFacebook}>
           
            <RiFacebookFill className='socialMediaIcon fbicon'  />
            <p>Share on Facebook</p>
        </div>
        <div className='flex gap-3 cursor-pointer items-center' onClick={shareOnWhatsApp}>
        <MdOutlineWhatsapp className='socialMediaIcon whatsappicon' />
            <p>Share on Whatsapp</p>
        </div>
        <div className='flex gap-3 cursor-pointer items-center' onClick={shareOnTwitter}>
        <RiTwitterXFill className='socialMediaIcon twittericon' />
      
            <p>Share on X</p>
        </div>
        <div className='flex gap-3 cursor-pointer items-center' onClick={shareOnLinkedIn}>
        <TiSocialLinkedin className='socialMediaIcon linkedinicon' />
            <p>Share on LinkedIn</p>
        </div>
        
        </div>
    


  )
}
