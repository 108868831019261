import React,{useState,useEffect} from 'react'
import './ResaleCard.css'
import { ImCross } from "react-icons/im";
import ResaleImage from '../../../Assets/resalePropImg.svg'
import { MdOutlineLocationOn } from "react-icons/md";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { BiUpArrowAlt } from 'react-icons/bi'
import axios from 'axios';
import { ApiBaseUrl } from '../../../Utils/urls';



function ResaleCard(props) {
  const [noOfTokens, setnoOfTokens] = useState(0);
  const [isLoggedin, setisLoggedin] = useState(false); 
const [userId, setUserId] = useState(null);
const [accessToken, setaccessToken] = useState(null);
const [propertyDetails, setPropertyDetails] = useState([]);
const [tokenLimitExceeded, settokenLimitExceeded] = useState(false);
const [tokenLimit, settokenLimit] = useState(0);
const [myPriceBool, setmyPriceBool] = useState(false);
const [myPrice, setmyPrice] = useState(0);
const [myPriceLowerLimit, setmyPriceLowerLimit] = useState(0);
const [myPriceUpperLimit, setmyPriceUpperLimit] = useState(0);
    const closeResale=()=>{
        props.setResalePopupOpen(false);
    }
    function padWithLeadingZero(value) {
      return value.toString().padStart(1, '0');
  }


  const showConfirmPopup =()=>{

    if(!myPriceBool){
      if(noOfTokens>0){
        props.setPropertyIdForListingApi(propertyDetails.user_property_id);
        props.setuserIdForListingApi(userId);
        props.setaccessTokenForListingApi(accessToken);
        props.setnoOfTokensForListingApi(noOfTokens);
        props.setcurrentPriceForListingApi(propertyDetails.current_price);
        
            props.setResalePopupOpen(false);
            props.setshowConfirmPopup(true)
      }
    }

    else if(myPriceBool){
      if(noOfTokens>0){
        props.setPropertyIdForListingApi(propertyDetails.user_property_id);
        props.setuserIdForListingApi(userId);
        props.setaccessTokenForListingApi(accessToken);
        props.setnoOfTokensForListingApi(noOfTokens);
        props.setcurrentPriceForListingApi(myPrice);
        
            props.setResalePopupOpen(false);
            props.setshowConfirmPopup(true)
      }

    }




  }

  useEffect(()=>{
    settokenLimit(props.listableShares);
  },[props.listableShares])


  const handleTokens=(op)=>{
    if(op=="plus"){
      if(noOfTokens+1<=tokenLimit){
              setnoOfTokens(noOfTokens+1);
              settokenLimitExceeded(false);
            }
            else{
              settokenLimitExceeded(true);
            }
    }
    else{
      if(noOfTokens>0){
              setnoOfTokens(noOfTokens-1);
              settokenLimitExceeded(false);
          }
     
    }
  }

useEffect(() => {
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
  if (userIdFromLocalStorage ) {
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
    setaccessToken(AccessTokenFromLocalStorage);
  }
}, [isLoggedin]); 


useEffect(() => {
  if (userId && props.propertyId && accessToken) {
    const fetchPropertyDetails = async () => {
      try {
        const response = await axios.post(`${ApiBaseUrl}/fr-resale/available-property-details`, {
          user_id: userId,
          user_property_id: props.propertyId
        }, {
          headers: {
            Authorization: accessToken
          }
        });
        setPropertyDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    fetchPropertyDetails();
  }
}, [userId, props.propertyId, accessToken]);

const handleInputChangeMyPrice = (event) => {
  const value = event.target.value;
  if (!isNaN(value)) {
    setmyPrice(value);
  }
};


useEffect(() => {
  setmyPriceLowerLimit(0.90*propertyDetails.current_price);
  setmyPriceUpperLimit(1.10*propertyDetails.current_price);
 }, [propertyDetails.current_price]);
 




  return (
    <div className='py-[40px] px-[30px] font-nunito parentResale  '>
      <div className='flex flex-row justify-between'>
        <div className='text-[24px] font-bold'>Sell Tokens</div>
        <div className='flex flex-col justify-center' onClick={closeResale}><ImCross /></div>
      </div>

      <div className='  flex flex-col gap-[40px] mt-[20px] marginBelowSell'>
        <div className=' flex flex-row gap-[20px]'>
          <div className=' flex flex-row justify-center pl-8 pr-8' style={{width:"60%"}}><img  src={ResaleImage}/></div>
          <div className=' shadowRightResalePopup flex flex-col p-[30px] justify-between' style={{width:"40%"}}>
           
            <div className=' partAResale'>
              <div className=' text-[24px] font-bold text-start'>List your token for Resale</div>
              <div className=' text-[16px] font-semibold mt-[15px] text-start detailRealeMargin'>{propertyDetails.property_name}</div>
              <div className=' mt-[10px] text-start flex flex-row gap-[5px]'>
                <div className=' cursor-pointer'><MdOutlineLocationOn /></div>
                <div className=' text-[12px] font-normal'>{propertyDetails.property_location}, {propertyDetails.property_city}</div>
              </div>
        
            </div>  

            <div className='partBResale mt-[30px] '>
              <div className=' flex flex-col'>
                  <div className=' text-[16px] font-normal'>Select the number of tokens:</div>
                  <div className='flex flex-row justify-center h-[38px] mt-[15px]'>
                    <div className='px-[22px] flex flex-col justify-center bg-[#091526] text-white cursor-pointer' onClick={()=>handleTokens("minus")}  style={{borderBottomLeftRadius:"9px",borderTopLeftRadius:"9px"}}><FaMinus /></div>
                    <div className='border flex flex-col justify-center px-[20px] text-[13.5px] font-semibold'> {padWithLeadingZero(noOfTokens)}</div>
                    <div className={`px-[22px] flex flex-col justify-center  text-white cursor-pointer ${tokenLimitExceeded? "bg-lightgray":"bg-[#091526]"}`} onClick={()=>handleTokens("plus")} style={{borderBottomRightRadius:"9px",borderTopRightRadius:"9px"}}> <FaPlus style={{ color: tokenLimitExceeded ? "black" : "white" }} /></div>
                  </div>
                  <div className='h-2'>
                    {tokenLimitExceeded && (
                      <div className='text-[red] text-[12px]'>
                        You have exceeded the available token limit.
                      </div>
                    )}
                  </div>
              </div>
            </div>


            <div className=' partCResale mt-[25px]'>
              <div className='flex flex-row justify-between '>
                <div className='flex flex-row gap-[10px]' onClick={()=>setmyPriceBool(false)}>
                  <div className={`rounded-md border border-[#E6E6E6] w-[120px] py-[10px] text-[#787878 text-[16px] font-normal ${!myPriceBool?"bg-black text-white":""}`}>Market Price</div>
                  <div className="tooltip flex flex-col justify-center">
                      <IoMdInformationCircleOutline style={{marginTop:"15px",color:" #D328A7"}} />
                      <span className="tooltiptextLeft mt-[14px]">
                      <p className='text-blue text-sm'>What is Market Price?</p>
                      <p className='text-gray text-xs'>
                      It is the current market price of the token that is determined by AI.
                      </p>
                      </span>
                  </div> 
                </div>
                <div className='flex flex-row gap-[10px]' onClick={()=>setmyPriceBool(true)}>
                  <div className={`rounded-md border border-[#E6E6E6] w-[120px]  py-[10px] text-[#787878 text-[16px] font-normal ${myPriceBool?"bg-black text-white":""}`}>My Price</div>
                  <div className="tooltip flex flex-col justify-center">
                      <IoMdInformationCircleOutline style={{marginTop:"15px",color:" #D328A7"}} />
                      <span className="tooltiptextLeft mt-[14px]">
                      <p className='text-blue text-sm'>What is My Price?</p>
                      <p className='text-gray text-xs'>
                      Token holders can list the token at a price between 10% higher and 10% lower than the current market price.
                      </p>
                      </span>
                  </div> 
                </div>
              </div>
              <div className=' text-[16px] font-bold text-start mt-[15px]'>Token Price</div>
              <div className=''>
                  <div className=' flex flex-row justify-between h-[38px] mt-[15px]'>
                    <div className='px-[15px] flex flex-col justify-center bg-[#091526] text-white cursor-pointer' onClick={()=>handleTokens("minus")}  style={{borderBottomLeftRadius:"9px",borderTopLeftRadius:"9px"}}><FaIndianRupeeSign /></div>
                    <div className=' flex flex-col justify-center px-[20px] text-[13.5px] font-semibold text-start bg-[#F2F2F2] text-[#808080]' style={{width:"100%"}}>
                    
                     {!myPriceBool && propertyDetails.current_price && parseFloat(propertyDetails.current_price).toFixed(2)}

                    {myPriceBool && (
                      <input
                        type="number"
                        style={{height:"100%",backgroundColor:"#F2F2F2",fontSize:"13px"}}
                        onChange={handleInputChangeMyPrice}
                        placeholder="Enter price"
                      />
                    )}
                    
                     </div>
                  </div>
              </div>

              {(myPriceBool&&
                <div className='myPrice flex flex-row justify-start font-normal text-[10px] h-[20px] pt-[10px] pr-[5px]'>Price Range: ₹{parseFloat(0.90*propertyDetails.current_price).toFixed(2)} - ₹{parseFloat(1.10*propertyDetails.current_price).toFixed(2)}</div>
              )}


            </div>

          </div>
        </div>
        <div className=' flex flex-row justify-between'>

        <div className='pl-16' style={{width:"45%"}}>
          <div className="investmentSection flex justify-between rounded-lg py-2.5 px-4">
          <div className="flex flex-col items-center">
            <p className="font-semibold font-nunito text-gray text-lg">Yield</p>
            <div className="flex items-center gap-1">
              <div className="font-semibold text-blue text-xl">{propertyDetails.gross_yield}%</div>
              <div className="tooltip">
                <IoMdInformationCircleOutline style={{ fontSize: "14px", color: "#D328A7" }} />
                <span className="tooltippolitical">
                  <p className="text-blue text-sm">What is Yield?</p>
                  <p className="text-gray text-sm">
                  The earnings generated and realized on the investment over a particular period of time.
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className="font-semibold text-gray font-nunito text-lg">Target IRR</p>
            <div className="flex items-center gap-1">
              <div className="font-semibold text-blue text-xl">{propertyDetails.target_IRR}%</div>
              <div className="tooltip">
                <IoMdInformationCircleOutline style={{ fontSize: "14px", color: "#D328A7" }} />
                <span className="tooltiptextLeft">
                  <p className="text-blue text-sm">What is Target IRR?</p>
                  <p className="text-gray text-sm">
                  Expected rate of return that an investment aims to achieve over a period of time.
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className="font-semibold text-gray font-nunito text-lg">
              Potential Gain
              {/* {details && details.property_info?.sale_type==="Presale"?"Potential Gain":" Capital Gain"} */}
            </p>
            <div className="flex items-center gap-1">
              <BiUpArrowAlt style={{ fontSize: "23px", color: "#2FDF99" }} />
              <div className="font-semibold text-blue text-xl">{propertyDetails.capital_gain}</div>
              <div className="tooltip">
                <IoMdInformationCircleOutline style={{ fontSize: "14px", color: "#D328A7" }} />
                <span className="tooltiptextLeft">
                  <p className="text-blue text-sm">
                    What is Potential Gain?
                    {/* {property_info?.sale_type==="Presale"?"Potential Gain":"Capital Gain"}? */}
                  </p>
                  <p className="text-gray text-sm">
                  Potential profit an investor would get over a certain period.
                    {/* {details && details.property_info?.sale_type==="Presale"?
                    "It refers to the estimated gain generated upon sale of the property."
                    :
                    " It refers to the estimated profit generated upon sale of the property."
                    } */}
                  </p>
                </span>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className=' flex flex-col justify-between' style={{width:"45%"}}>
          <div className='flex flex-row justify-between'>
            <div className={` h-[46.5px] flex flex-col justify-center rounded-md text-[16px] font-bold text-white  cursor-pointer ${myPriceBool &&  myPrice<myPriceLowerLimit|| myPrice>myPriceUpperLimit || noOfTokens===0?"bg-[#E993D3]":"bg-[#D328A7]"}`} style={{width:"40%"}} onClick={showConfirmPopup}>List Now</div>
            <div className='border h-[46.5px] flex flex-col justify-center rounded-md text-[16px] font-bold text-[#787878] cursor-pointer' style={{width:"40%"}} onClick={closeResale}>Cancel Resale</div>
          </div>
          <div className='text-[16px] font-bold text-[#787878]' style={{display:"none"}}>Estimated time for Resale is <span className='text-[#081956]'>3 days</span></div>
        </div>
        

      



        </div>
      </div>
    </div>
  )
}

export default ResaleCard
