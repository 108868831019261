export const IncrementToken = (tokenCount, setTokenCount, tokenLimit, setReachedTokenLimit) => {
    if (tokenCount < tokenLimit) {
        setTokenCount(tokenCount + 1);
    } else {
        setReachedTokenLimit(true);
    }
};

export const DecrementToken = (tokenCount, setTokenCount, setReachedTokenLimit) => {
    if (tokenCount > 1) {
        setTokenCount(tokenCount - 1);
        setReachedTokenLimit(false); 
    }
};




export const calculateTotalPrice = (PerTokenPrice, tokenCount, phm_percentage, gst_percentage) => {
    // if (typeof PerTokenPrice === 'undefined' || typeof phm_percentage === 'undefined' || typeof gst_percentage === 'undefined') {
    //     // Data is not available yet, return early or set default values
    //     return {
    //         totalPrice: 0,
    //         calculatedPlatformFee: 0,
    //         gst_fee: 0,
    //         total_fee: 0
    //     };
    // }

    PerTokenPrice = PerTokenPrice || 0;
    phm_percentage = phm_percentage || 0;
    gst_percentage = gst_percentage || 0;

    // Calculate the total price
    const totalPrice = PerTokenPrice * tokenCount;

    // Calculate the platform fee
    const calculatedPlatformFee = (totalPrice * phm_percentage) / 100;

    // Calculate GST Fee
    const gst_fee = (calculatedPlatformFee * gst_percentage) / 100;

    // Calculate Total Amount
    const total_fee = parseFloat(totalPrice) + parseFloat(calculatedPlatformFee) + parseFloat(gst_fee);

    return {
        totalPrice,
        calculatedPlatformFee,
        gst_fee,
        total_fee
    };
};



export const ClickOutside = (event,priceSectionRef,setshowStickyPriceSection) => {
    if (priceSectionRef.current && !priceSectionRef.current.contains(event.target)) {
        setshowStickyPriceSection(false);
    }
};




export const ProceedToPurchase = (
  isLoggedin,
  kycStatus,
  isUserEmail,
  navigate,
  setEmailupdated,
  location,
  tokenCountResaleMob,
  totalPriceResaleMob,
  formattedPriceResaleMob,
  gstFeeResaleMob,
  platformFeeResaleMob,
  totalFeeResaleMob,
  id,
  propertyCity,
  propertyName,
  isSuccessfulPayment,
  ) => {
  
    if (isLoggedin && kycStatus === "APPROVED" && isUserEmail) {
      // Navigate to the purchase page with state
      navigate('/purchase/resale', {
        state: {
            tokenCountResaleMob,
            totalPriceResaleMob,
            formattedPriceResaleMob,
            gstFeeResaleMob,
            platformFeeResaleMob,
            totalFeeResaleMob,
            id,
            propertyCity,
            propertyName,
            isSuccessfulPayment
        }
      });
    } else if (!isLoggedin) {
      navigate("/login", { state: { from: location } });
    } else if (!isUserEmail) {
      setEmailupdated(false);
      setTimeout(() => {
        setEmailupdated(true);
        navigate("/user/profile");
      }, 3000);
    } else if (isLoggedin && isUserEmail && kycStatus !== "APPROVED") {
      navigate("/user/kyc", { state: { from: location } });
    }
  }
  


 