// Reducer.js

import { OTP_VERIFICATION_FAILURE, OTP_VERIFICATION_REQUEST, OTP_VERIFICATION_SUCCESS } from "../actionTypes";


const initialState = {
  loading: false,
  response: null,
  accessToken: null,
  userDetails: null,
  error: null,
};

const otpVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case OTP_VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        // accessToken: action.payload.AuthenticationResult.AccessToken,
        userDetails: action.payload.UserDetails,
      };
    case OTP_VERIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default otpVerificationReducer;
