import React from 'react'
import { useEffect,useMemo } from 'react';
import { useState } from 'react';
import { useSoldoutPropertyFilters } from '../../Utils/soldoutPropFilter';
import { FaCaretDown } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { ApiBaseUrl } from '../../Utils/urls';
import { initialPropertyFiltersSoldout } from '../../Utils/soldoutPropFilter';



function CityButton({ city, onClick, selectedCities }) {
    const isClicked = selectedCities.includes(city.city_name);
  
    const handleClick = () => {
      onClick(city.city_name);
    };
  
    return (
      <button
        className={`cityButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
        onClick={handleClick}
      >
        {city.city_name}
      </button>
    );
  }
  
    
  function BuilderButton({ builder, onClick, selectedBuilders }) {
    if (!builder) {
      return null;
    }
    const isClicked = selectedBuilders.includes(builder.builder_name);
    const handleClick = () => {
      onClick(builder.builder_name);
    };
  
    return (
      <button
        className={`BuilderButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
        onClick={handleClick}
      >
        {builder.builder_name}
      </button>
    );
  }
  
  function AssetButton({ name, onClick, assetType }) {
    const isClicked = assetType.includes(name);
  
    const handleClick = () => {
      onClick(name);
    };
      return (
        <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
          <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
          <button
            className=" text-black text-sm font-normal"
          >
            {name}
          </button>
        </div>
      );
    }
    
    function  PriceRangeButton({ name, onClick, priceRange }) {
        const isClicked = priceRange.includes(name);
      
        const handleClick = () => {
          onClick(name);
        };
      
        return (
          <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
            <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
            <button
              className=" text-black text-sm font-normal"
            >
              {name}
            </button>
          </div>
        );
      }
function SoldoutMobileFilter(props) {
  const [propertyPhases, setPropertyPhases] = useState([]);
  const [saleTypes, setSaleTypes] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isClearButtonActive, setIsClearButtonActive] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');  
  const [placeFilters, setPlaceFilters] = useState([]);
  const [builderFilters, setBuilderFilters] = useState([]);
  const [currencyFilters, setCurrencyFilters] = useState([]);
  const [isOpenOptionProp, setIsOpenOptionsProp] = useState(false);
  const [isOpenOptionsSales, setIsOpenOptionsSales] = useState(false);
  const [isOpenOptionsCity, setIsOpenOptionsCity] = useState(false);
  const [isOpenOptionsBuilder, setIsOpenOptionsBuilder] = useState(false);
  const [isOpenOptionsPrice, setIsOpenOptionsPrice] = useState(false);
  const [isOpenOptionsAsset, setIsOpenOptionsAsset] = useState(false);
 const [isPreSale, setisPreSale] = useState(false);

  const toggleOptionsProp = () => {
    setIsOpenOptionsProp(prevState => !prevState);
  };
  const toggleOptionsSales = () => {
    setIsOpenOptionsSales(prevState => !prevState);
  };
  const toggleOptionsCity = () => {
    setIsOpenOptionsCity(prevState => !prevState);
  };
  const toggleOptionsBuilder = () => {
    setIsOpenOptionsBuilder(prevState => !prevState);
  };
  const toggleOptionsPrice = () => {
    setIsOpenOptionsPrice(prevState => !prevState);
  };

  const toggleOptionsAsset = () => {
    setIsOpenOptionsAsset(prevState => !prevState);
  };



  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
    
    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
    }
  }, []);
  
  const [cityClicked, setcityClicked] = useState(false);
  const [BuilderClicked, setBuilderClicked] = useState(false)
  
    const { SoldoutpropertyFilters, setSoldoutPropertyFilters } = useSoldoutPropertyFilters();
  
  
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${ApiBaseUrl}/fr-resale/get-filters-sold-properties?user_id=${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const data1 = await response.json();
          
          if (data1 && data1.data && data1.data.place_filters) {
            const places = data1.data.place_filters.map(city => ({
              city_id: city.city_id,
              city_name: city.city_name
            }));
            setPlaceFilters(places);
          }
      
          if (data1 && data1.data && data1.data.builder_filters) {
            const builders = data1.data.builder_filters.map(builder => ({
              builder_id: builder.builder_id,
              builder_name: builder.builder_name
            }));
            setBuilderFilters(builders);
          }
      
          if (data1 && data1.data && data1.data.currency_filters) {
            const currencies = data1.data.currency_filters.map(currency => ({
              currency_id: currency.currency_id,
              fiat_currency: currency.fiat_currency
            }));
            setCurrencyFilters(currencies);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      
  
      fetchData();
    }, [cityClicked,BuilderClicked]);
  
  
    const handleStartDateChange = (event) => {
      setStartDate(event.target.value);
    };
  
    const handleEndDateChange = (event) => {
      setEndDate(event.target.value);
    };
  
  
    const handlePropertyPhaseClick = (propertyPhaseType) => {
      setIsFilterActive(1);
      if (props.propertyPhases.includes(propertyPhaseType)) {
        props.setPropertyPhases(props.propertyPhases.filter((phase) => phase !== propertyPhaseType));
      } else {
        props.setPropertyPhases([...props.propertyPhases, propertyPhaseType]);
      }
      setIsFilterActive(true);
    };
  
    const handleAssetTypeClick = (assetTypeName) => {
      let updatedAssetTypes;
      if (props.assetType.includes(assetTypeName)) {
          updatedAssetTypes = props.assetType.filter((type) => type !== assetTypeName);
      } else {
          updatedAssetTypes = [...props.assetType, assetTypeName];
      }
  
      props.setAssetType(updatedAssetTypes);
      let updatedPropertyType;
      if (updatedAssetTypes.length === 0) {
          updatedPropertyType = null;
      } else if (updatedAssetTypes.includes("All")) {
          updatedPropertyType = ["Commercial", "Residence", "Plots"];
      } else {
          updatedPropertyType = updatedAssetTypes.map(typeName => {
              if (typeName === "Residential") {
                  return "Residence";
              }
              return typeName;
          });
      }
      setSoldoutPropertyFilters(prevFilters => ({
          ...prevFilters,
          filtersByString: {
              ...prevFilters.filtersByString,
              property_type: updatedPropertyType
          }
      }));
  };
  
  const handleSaleTypeClick = (saleType) => {
    let updatedSaleTypes;
    if (props.saleTypes.includes(saleType)) {
      updatedSaleTypes = props.saleTypes.filter((type) => type !== saleType);
    } else {
      updatedSaleTypes = [...props.saleTypes, saleType];
    }
  
    props.setSaleTypes(updatedSaleTypes);
    setIsFilterActive(true);
  
    let updatedPropertySaleTypes;
    if (updatedSaleTypes.length === 0) {
      updatedPropertySaleTypes = null;
    } else if (updatedSaleTypes.includes('all')) {
      updatedPropertySaleTypes = ['Public Sale', 'Resale','Presale'];
    } else {
      updatedPropertySaleTypes = updatedSaleTypes;
    }
  
    setSoldoutPropertyFilters((prevFilters) => ({
      ...prevFilters,
      filtersByString: {
        ...prevFilters.filtersByString,
        sale_type: updatedPropertySaleTypes,
      },
    }));
  };
  
    const handleCityClick = (cityName) => {
      let updatedCities;
      if (props.selectedCities.includes(cityName)) {
          updatedCities = props.selectedCities.filter((city) => city !== cityName);
      } else {
          updatedCities = [...props.selectedCities, cityName];
      }
    
      props.setSelectedCities(updatedCities);
      const updatedFiltersByPlaces = updatedCities.map((cityName) => {
          const selectedCity = placeFilters.find(city => city.city_name === cityName);
          return { city_id: selectedCity.city_id, locations: []  };
      });
    
  
      const updatedBookedPropertyFilters = { ...SoldoutpropertyFilters, filtersByPlaces: updatedFiltersByPlaces };
      setSoldoutPropertyFilters(updatedBookedPropertyFilters);
    
      setIsFilterActive(true);
  };
  
  
  const handlePriceRangeClick = (price) => {
    let updatedPriceRange;
    if (props.priceRange.includes(price)) {
        updatedPriceRange = props.priceRange.filter((range) => range !== price);
    } else {
        updatedPriceRange = [...props.priceRange, price];
    }
  
    props.setPriceRange(updatedPriceRange);
    setIsFilterActive(true);
  
    // Update property_filters with the new price range
    let filtersByPrice = {};
  
    if (updatedPriceRange.includes("Upto 50000")) {
        filtersByPrice = { from: "0", to: "50000" };
    } else if (updatedPriceRange.includes("50,000-1,00,000")) {
        filtersByPrice = { from: "50000", to: "100000" };
    } else if (updatedPriceRange.includes("Above 1,00,000")) {
        filtersByPrice = { from: "100000", to: "10000000" };
    } else {
        // If no price range is selected, set to null
        filtersByPrice = null;
    }
  
    setSoldoutPropertyFilters(prevFilters => ({
        ...prevFilters,
        filtersByPrice: { price_per_share: filtersByPrice }
    }));
  };
  
  
    const handleBuilderClick = (builderName) => {
      let updatedBuilders;
  
      if (props.selectedBuilders.includes(builderName)) {
          updatedBuilders = props.selectedBuilders.filter((builder) => builder !== builderName);
      } else {
          updatedBuilders = [...props.selectedBuilders, builderName];
      }
  
      props.setSelectedBuilders(updatedBuilders);
  
      const updatedFiltersByBuilders = updatedBuilders.map((builderName) => {
          const selectedBuilder = builderFilters.find(builder => builder.builder_name === builderName);
          return selectedBuilder ? selectedBuilder.builder_id : null;
      }).filter(id => id !== null);
      const updatedBookedPropertyFilters = { ...SoldoutpropertyFilters, filtersByBuilders: updatedFiltersByBuilders };
      setSoldoutPropertyFilters(updatedBookedPropertyFilters);
  
      setIsFilterActive(true);
  };
  
  
    const handlePhaseClearClick = () => {
      props.setPropertyPhases([]);
    }
  
    const handleSaleTypeClearClick = () => {
      props.setSaleTypes([]);
    }
  
    const handleCityClearClick = () => {
  
      props.setSelectedCities([]);
      const updatedFiltersByPlaces = [];
      const updatedBookedPropertyFilters = { ...SoldoutpropertyFilters, filtersByPlaces: updatedFiltersByPlaces };
      setSoldoutPropertyFilters(updatedBookedPropertyFilters);
    }
    const handleBuilderClearClick = () => {
  
      props.setSelectedBuilders([]);
       const updatedFiltersByBuilders = [];
       const updatedBookedPropertyFilters = { ...SoldoutpropertyFilters, filtersByBuilders: updatedFiltersByBuilders };
       setSoldoutPropertyFilters(updatedBookedPropertyFilters);
    }
    const handleAssetTypeClearClick = () => {
      props.setAssetType([]);
      const updatedFilters = { ...SoldoutpropertyFilters };
      updatedFilters.filtersByString.property_type = null; // Reset asset type filter
      setSoldoutPropertyFilters(updatedFilters);
    }
    const handlePriceRangeClearClick = () => {
      props.setPriceRange([]);
      const updatedFilters = { ...SoldoutpropertyFilters };
      updatedFilters.filtersByPrice.price_per_share = null; // Reset price range filter
      setSoldoutPropertyFilters(updatedFilters);
    }

    const [clearButtonBeingClicked, setclearButtonBeingClicked] = useState(false)
const handleClearClick =()=>{
  setclearButtonBeingClicked(true)
  setTimeout(() => {
    setclearButtonBeingClicked(false);
}, 200);
  props.setPropertyPhases([]);
  props.setSaleTypes([]);
  props.setSelectedCities([]);
  props.setSelectedBuilders([]);
  props.setAssetType([]);
  props.setPriceRange([]);
  setStartDate('');
  setEndDate('');
  setIsFilterActive(false);
  setIsClearButtonActive(false);
  setSoldoutPropertyFilters(initialPropertyFiltersSoldout);
}



  return (
    <>
    <div className='hideMobFilter flex flex-col  bg-white' style={{height:"100%"}}>

    <div>
  <div className=' text-black pt-4 flex flex-col py-2 px-4' >

  <div className='flex flex-row justify-between py-2 mt-10'>
    <div className='mt-2  text-[16px] font-bold' onClick={()=>props.setshowMobFilter(false)}>Filter</div>
    <div className={`mt-2  text-[14px] font-normal cursor-pointer px-2 py-1 rounded-md ${clearButtonBeingClicked ? 'clickedClearDashFilter' : ''}`} onClick={handleClearClick}>Clear All</div>
  </div>


  <div className='flex flex-row justify-between' onClick={toggleOptionsAsset}>
  <div className='text-base font-medium text-start'>Property Type</div>
  
  <div className='flex flex-col justify-center'>
   {isOpenOptionsAsset ? <FaCaretRight /> : <FaCaretDown />}
  </div>
</div>





{(isOpenOptionsAsset && 
    <div className=' text-lg font-bold text-start flex flex-col p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <AssetButton name="All" assetType={props.assetType} onClick={handleAssetTypeClick} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <AssetButton name="Commercial" onClick={handleAssetTypeClick} assetType={props.assetType} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <AssetButton name="Residential" onClick={handleAssetTypeClick} assetType={props.assetType} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        <AssetButton name="Plots" onClick={handleAssetTypeClick} assetType={props.assetType} />
      </div>
    </div>

)}

  </div>



  {/* <div className='flex flex-row justify-center mt-1'>
    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
  </div> */}




    {/* <div className='flex flex-col justify-between px-4 py-2'>
    
    <div className='flex flex-row justify-between' onClick={toggleOptionsProp}>
        <div className='text-base font-medium text-start'>Property Phases</div>
        
        <div className='flex flex-col justify-center'>
         {isOpenOptionProp ? <FaCaretRight /> : <FaCaretDown />}
        </div>
    </div>

    <div>
    {isOpenOptionProp && (
      <div className='openOptions'>
        <div className='flex flex-row flex-wrap gap-2 p-2 -ml-2'>
          <button
            className={`propertyPhasesButton text-black text-sm w-32 h-8 ${
              props.propertyPhases.includes('launching') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            }`}
            onClick={() => handlePropertyPhaseClick('launching')}
          >
            Launching Soon
          </button> 
          <button
            className={`propertyPhasesButton text-black text-sm w-16 h-8 ${
              props.propertyPhases.includes('open') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            }`}
            onClick={() => handlePropertyPhaseClick('open')}
          >
            Open
          </button>
          <button
            className={`propertyPhasesButton text-black text-sm w-16 h-8 ${
              props.propertyPhases.includes('resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            }`}
            onClick={() => handlePropertyPhaseClick('resale')}
          >
            Resale
          </button>
          <button
          className={`propertyPhasesButton text-black text-sm w-20 h-8 ${
            props.propertyPhases.includes('soldout') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
          }`}
          onClick={() => handlePropertyPhaseClick('soldout')}
        >
          Sold Out
        </button>
        </div>
      </div>
    )}
    </div>
  </div> */}




  <div className='flex flex-row justify-center'>
  <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
</div>


  <div className=' text-black flex flex-col px-4 py-2'>
  <div className='flex flex-row justify-between' onClick={toggleOptionsSales}>
        <div className='text-base font-medium text-start'>Sales Type</div>
        
        <div className='flex flex-col justify-center'>
         {isOpenOptionsSales ? <FaCaretRight /> : <FaCaretDown />}
        </div>
    </div>


    {isOpenOptionsSales && (
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
      <button
        className={`propertyPhasesButton  text-black text-sm w-14 h-8 ${
          props.saleTypes.includes('all') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`
    }
    onClick={() => {handleSaleTypeClick('all');}}
      >
        All
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-8  whitespace-nowrap ${
          props.saleTypes.includes('Presale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Presale')}
      >
        Presale
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-8 whitespace-nowrap ${
          props.saleTypes.includes('Public Sale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Public Sale')}
      >
        Public Sale
      </button>
      {/* <button
      className={`propertyPhasesButton  text-black text-sm w-24 h-8 whitespace-nowrap ${
        props.saleTypes.includes('Resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
      }`}
      onClick={() => handleSaleTypeClick('Resale')}
    >
      Resale
    </button> */}
    </div>
    )}
  </div>


  <div className='flex flex-row justify-center'>
    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
  </div>

  <div className=' text-black flex flex-col py-2 px-4 ' onClick={()=>setcityClicked(true)}>
  <div className='flex flex-row justify-between' onClick={toggleOptionsCity}>
  <div className='text-base font-medium text-start'>City</div>
  
  <div className='flex flex-col justify-center'>
   {isOpenOptionsCity ? <FaCaretRight /> : <FaCaretDown />}
  </div>
</div>

{(isOpenOptionsCity &&
  <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
    {placeFilters.map(city => (
        <CityButton
        key={city.city_id}
        city={city}
        onClick={handleCityClick}
        selectedCities={props.selectedCities}
      />
    ))}
  </div>
  )}
</div>
<div className='flex flex-row justify-center'>
<hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
</div>




<div className=' text-black flex flex-col py-2 px-4' onClick={()=>setBuilderClicked(true)}>
<div className='flex flex-row justify-between' onClick={toggleOptionsBuilder}>
<div className='text-base font-medium text-start'>Builder's Name</div>

<div className='flex flex-col justify-center' >
 {isOpenOptionsBuilder ? <FaCaretRight /> : <FaCaretDown />}
</div>
</div>

{(isOpenOptionsBuilder && 
<div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
  {builderFilters.map(builder => (
    <BuilderButton
      key={builder.builder_id}
      builder={builder}
      onClick={handleBuilderClick}
      selectedBuilders={props.selectedBuilders}
    />
  ))}
</div>
)}
</div>


<div className='flex flex-row justify-center'>
<hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
</div>


  <div className='flex flex-row justify-center' >
  <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
</div>


  <div className=' text-black pt-4 px-4 flex flex-col'>
    <div className='flex flex-row justify-between' onClick={toggleOptionsPrice}>
    <div className='text-base font-medium text-start'>Price Range</div>

    <div className='flex flex-col justify-center'>
    {isOpenOptionsPrice ? <FaCaretRight /> : <FaCaretDown />}
    </div>
    </div>
 

    {(isOpenOptionsPrice && 
    <div className='text-lg font-bold text-start flex flex-col p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <PriceRangeButton name="Upto 50000" priceRange={props.priceRange} onClick={handlePriceRangeClick} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <PriceRangeButton name="50,000-1,00,000" onClick={handlePriceRangeClick} priceRange={props.priceRange} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <PriceRangeButton name="Above 1,00,000" onClick={handlePriceRangeClick} priceRange={props.priceRange} />
      </div>
     
    </div>
    )}
  </div>
  </div>
  <div className='flex flex-row w-full justify-between absolute bottom-0 py-2 px-4 mt-10'>
  <div className='flex flex-col justify-center mt-2  text-[14px] font-normal belowBorders h-[44px] w-[150px] rounded-lg cursor-pointer' onClick={()=>props.setshowSoldoutFilter(false)}>Close</div>
  <div className='flex flex-col justify-center mt-2  text-[14px] text-white font-normal belowBorders h-[44px] w-[150px] rounded-lg bg-secondary cursor-pointer' onClick={()=>props.setshowSoldoutFilter(false)}>Apply</div>
</div>
  </div>

  </>
  );
}



export default SoldoutMobileFilter
