// Actions.js

import axios from 'axios';
import { SignUp } from '../../Api/SignupApi';
import { SIGNUP_FAILURE, SIGNUP_REQUEST, SIGNUP_SUCCESS } from '../actionTypes';
import { ApiBaseUrl } from '../../Utils/urls';

export const signupRequest = () => ({
  type: SIGNUP_REQUEST,
});

export const signupSuccess = (username, session,new_user, phone_number,country_code) => ({
  type: SIGNUP_SUCCESS,
  payload: { username, session ,new_user,country_code, phone_number},
});

export const signupFailure = (error) => ({
  type: SIGNUP_FAILURE,
  payload: error,
});

export const signup = (signUpData) => (dispatch) => {
  dispatch(signupRequest());

  return axios.post(`${ApiBaseUrl}/fr-cognito/sign-in`,signUpData)
 
};
