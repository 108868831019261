// reducer.js

import { STORE_COUNTRY_CODE, STORE_PHONE_NUMBER } from "../actionTypes";


const initialState = {
  phoneNumber: '',
  countryCode:""
};

const phoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload
      };
      case STORE_COUNTRY_CODE:
        return {
          ...state,
          countryCode: action.payload
        };
    default:
      return state;
  }
};

export default phoneReducer;
