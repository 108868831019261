import React,{useState,useEffect} from 'react'
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { ProceedToPurchase } from '../../Utils/tokenCalculationResale'
import { getUserDetails } from '../../Api/ProfileApi';
import './resaleIndex.css'
import { useNavigate,useLocation } from 'react-router-dom'
import { ApiBaseUrl } from '../../Utils/urls';
import InfoPopup from '../InfoPopup/InfoPopup'

function ResalePartB(props) {
  const [noOfTokens, setnoOfTokens] = useState(0);
  const [selectedOption, setSelectedOption] = useState('marketprice');
  const [offerPrice, setofferPrice] = useState(0);
  const [userId, setUserId] = useState(null);
  const [isLoggedin, setisLoggedin] = useState(false);
  const [accessToken, setaccessToken] = useState(null)
  const navigate=useNavigate();
  const location=useLocation()
  const [isUserEmail,setUserEmail]=useState("")
  const [isEmailUpdated,setEmailupdated]=useState(true)
  const [isLoggedIn,setLoggedin]=useState(false)
  const [kycStatus,setKycStatus]=useState("")
  const [tokenCountResaleMob, settokenCountResaleMob] = useState(0)
  const [totalPriceResaleMob, settotalPriceResaleMob] = useState(0)
  const [formattedPriceResaleMob, setformattedPriceResaleMob] = useState(0)
  const [gstFeeResaleMob, setgstFeeResaleMob] = useState(0)
  const [platformFeeResaleMob, setPlatformFeeResaleMob] = useState(0)
  const [totalFeeResaleMob, settotalFeeResaleMob] = useState(0)
  const [isSuccessfulPayment, setIsSuccessfulPayment] = useState(false);
  const [tokenLimit, settokenLimit] = useState(0);
  const [tokenLimitExceeded, settokenLimitExceeded] = useState(false);
  const [myPriceBool, setmyPriceBool] = useState(false);
  const [myPrice, setmyPrice] = useState(0);
  const [myPriceLowerLimit, setmyPriceLowerLimit] = useState(0);
  const [myPriceUpperLimit, setmyPriceUpperLimit] = useState(0);
  const accestoken=localStorage.getItem("accestoken")

  const handleTokens=(operation)=>{
    if(operation==="add"){
      if(noOfTokens+1<=tokenLimit){
        setnoOfTokens(noOfTokens+1);
        settokenLimitExceeded(false);
      }
      else{
        settokenLimitExceeded(true);
      }
      
    }
    else if(operation==='subtract'){
      if(noOfTokens>0){
        setnoOfTokens(noOfTokens-1);
        settokenLimitExceeded(false);
      }
    }
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleOptionChangeSec=(opt)=>{
  
    if(opt===1){
      if(props.buyPrice!=undefined){
        setofferPrice(props.buyPrice)
      }
      else if(props.buyPriceMob!=undefined){
        setofferPrice(props.buyPriceMob)
      }
      setmyPriceBool(false);
      setSelectedOption("marketprice");

    }
    else if(opt===2){
      setmyPriceBool(true);
      setSelectedOption("myprice");

    }

  }

  useEffect(() => {
    if(props.buyPrice!=undefined){
      setofferPrice(props.buyPrice)
    }
    else if(props.buyPriceMob!=undefined){
      setofferPrice(props.buyPriceMob)
    }
  }, [props.buyPrice,props.buyPriceMob]);
  
  useEffect(() => {
    if(props.tokenLimit!=undefined){
      settokenLimit(props.tokenLimit)
    }
    else if(props.tokenLimitMob!=undefined){
      settokenLimit(props.tokenLimitMob)
    }
  }, [props.tokenLimit,props.tokenLimitMob]);
  

  useEffect(() => {
   setmyPriceLowerLimit(0.90*offerPrice);
   setmyPriceUpperLimit(1.10*offerPrice);
  }, [offerPrice]);
  



  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
    if (userIdFromLocalStorage && AccessTokenFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
      setaccessToken(AccessTokenFromLocalStorage);
    }
  }, [isLoggedin]); 
  
  

  const postOffer = async () => {
    const url = `${ApiBaseUrl}/fr-resale/add-buy-offer`;
    const body = {
      user_id: userId,
      property_id: props.id,
      number_of_tokens: noOfTokens,
      offer_price: offerPrice,
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data);
      setIsSuccessfulPayment(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleBuyNow = () => {
    postOffer();
  };
  
  useEffect(() => {
    if(isSuccessfulPayment){
      handleBuyNow();
    }
  }, [isSuccessfulPayment]);



  const fetchUserDetails=()=>{
    getUserDetails(userId,accestoken,setLoggedin,setKycStatus,setUserEmail,navigate)
}
    useEffect(()=>{
        if(userId)
            {
                fetchUserDetails()
            }
    },[isLoggedin])
    
const handleProceed = () => {
if(!myPriceBool){

  if(noOfTokens>0){
    ProceedToPurchase(
      isLoggedin,
      kycStatus,
      isUserEmail,
      navigate,
      setEmailupdated,
      location,
      tokenCountResaleMob,
      totalPriceResaleMob,
      formattedPriceResaleMob,
      gstFeeResaleMob,
      platformFeeResaleMob,
      totalFeeResaleMob,
      props.id,
      props.propertyCity,
      props.propertyName,
      isSuccessfulPayment
  )
  }
}
  

else if(myPriceBool && noOfTokens>0 && myPrice>=myPriceLowerLimit && myPrice<=myPriceUpperLimit ){
  ProceedToPurchase(
    isLoggedin,
    kycStatus,
    isUserEmail,
    navigate,
    setEmailupdated,
    location,
    tokenCountResaleMob,
    totalPriceResaleMob,
    formattedPriceResaleMob,
    gstFeeResaleMob,
    platformFeeResaleMob,
    totalFeeResaleMob,
    props.id,
    props.propertyCity,
    props.propertyName,
    isSuccessfulPayment
)
}



      }

      useEffect(()=>{
        if(!myPriceBool){
          settokenCountResaleMob(noOfTokens)
          settotalPriceResaleMob(noOfTokens*offerPrice)
          setformattedPriceResaleMob(offerPrice)
          setgstFeeResaleMob(17)
          setPlatformFeeResaleMob(95)
          settotalFeeResaleMob(totalPriceResaleMob+gstFeeResaleMob+platformFeeResaleMob)
        }
        else if(myPriceBool){
          settokenCountResaleMob(noOfTokens)
          settotalPriceResaleMob(noOfTokens*myPrice)
          setformattedPriceResaleMob(myPrice)
          setgstFeeResaleMob(17)
          setPlatformFeeResaleMob(95)
          settotalFeeResaleMob(totalPriceResaleMob+gstFeeResaleMob+platformFeeResaleMob)
        }
       
    },[isLoggedIn,offerPrice,noOfTokens,totalPriceResaleMob,totalFeeResaleMob,myPrice])

    const handleInputChangeMyPrice = (event) => {
      const value = event.target.value;
      if (!isNaN(value)) {
        setmyPrice(value);
      }
    };
    
    const [showIRRInfoPopup, setshowIRRInfoPopup] = useState(false);
    const handleIRRInfoPopup = () => {
      setshowIRRInfoPopup(!showIRRInfoPopup);
    };
    const [showIRRInfoPopupMyPrice, setshowIRRInfoPopupMyPrice] = useState(false);
    const handleIRRInfoPopupMyPrice = () => {
      setshowIRRInfoPopupMyPrice(!showIRRInfoPopupMyPrice);
    };
    const [showIRRInfoPopupOfferPrice, setshowIRRInfoPopupOfferPrice] = useState(false);
    const handleIRRInfoPopupOfferPrice = () => {
      setshowIRRInfoPopupOfferPrice(!showIRRInfoPopupOfferPrice);
    };


  return (
    <div className='flex flex-col p-[20px] font-nunito'>

      <div className='text-start text-[18px] font-extrabold buyTokensText'>Buy Tokens</div>
      <div className=' flex flex-col justify-between mt-[20px]'>
        <div className=' salePriceFlex'>
        <div className=' text-[#5D5C5C] flex flex-col justify-center selectPriceTypeText'>Select Price Type :</div>
        <div className=' flex flex-row gap-[15px]'>
        <div className='partC partCWidth'>
        <div className='flex flex-row justify-between gap-[40px] marketMyPriceGapInResale'>
          <div className='flex flex-row gap-3 '
          onClick={()=>handleOptionChangeSec(1)}
          >
            <div className=' flex flex-col justify-center'>
              <label>
              <input
              type="radio" 
              value="marketprice" 
              checked={selectedOption === 'marketprice'} 
              onChange={handleOptionChange}
              style={{ accentColor: '#D328A7' }} 
            />
            </label>
            </div>
            <div className='rounded-md widthMarketPrice py-[10px] text-[#787878 text-[18px] font-normal'>Market Price</div>
            {window.innerWidth>640 &&
              <div className="tooltip flex flex-col justify-center">
                <IoMdInformationCircleOutline style={{marginTop:"15px",color:" #D328A7"}} />
                <span className="tooltiptextLeft mt-[14px]">
                <p className='text-blue text-sm'>What is Market Price?</p>
                <p className='text-gray text-xs'>
                It is the current market price of the token that is determined by AI.
                </p>
                </span>
            </div> 
            }
            {window.innerWidth<=640 &&
               <div className="tooltip mt-[16.5px]" onClick={handleIRRInfoPopup}>
              <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
              {

                showIRRInfoPopup && (
                    <>
                  
                                    <InfoPopup>
                                        <div className='p-2'>
                                        <p className='text-blue text-left text-sm'>What is Market Price?</p>
                                            <p className='text-gray text-left text-sm'>
                                            It is the current market price of the token that is determined by AI.
                                        </p>
                                       
            
            
                                        </div>
                       
                                        <button onClick={handleIRRInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                            Ok</button>
                                        
                                        </InfoPopup>
                    </>
                )
              }
          </div> 
            }
            


          </div>
          
          <div className='flex flex-row gap-3' onClick={()=>handleOptionChangeSec(2)}>

            <div className=' flex flex-col justify-center'>
              <label>
              <input 
              type="radio" 
              value="myprice" 
              checked={selectedOption === 'myprice'} 
              onChange={handleOptionChange}
              style={{ accentColor: '#D328A7' }} 
              />
              </label>
            
            </div>
            <div className='rounded-md widthMyPrice py-[10px] text-[#787878 text-[18px] font-normal'>My Price</div>
            {window.innerWidth>640 &&
              <div className="tooltip flex flex-col justify-center">
                <IoMdInformationCircleOutline style={{marginTop:"15px",color:" #D328A7"}} />
                <span className="tooltiptextLeft mt-[14px]">
                <p className='text-blue text-sm'>What is My Price?</p>
                <p className='text-gray text-xs'>
                Token holders can list the token at a price between 10% higher and 10% lower than the current market price.
                </p>
                </span>
            </div> 
            }
            {window.innerWidth<=640 &&
               <div className="tooltip mt-[16.5px]" onClick={handleIRRInfoPopupMyPrice}>
              <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
              {

                showIRRInfoPopupMyPrice && (
                    <>
                  
                                    <InfoPopup>
                                        <div className='p-2'>
                                        <p className='text-blue text-left text-sm'>What is My Price?</p>
                                            <p className='text-gray text-left text-sm'>
                                            Token holders can list the token at a price between 10% higher and 10% lower than the current market price.
                                        </p>
                                       
            
            
                                        </div>
                       
                                        <button onClick={handleIRRInfoPopupMyPrice} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                            Ok</button>
                                        
                                        </InfoPopup>
                    </>
                )
              }
          </div> 
            }
          </div>
        </div>     
      </div>
        </div>
      </div>
      
 
       

        <div className=' flex flex-row justify-between mt-[25px] marginHelpOfferSect'>
          <div className=' flex flex-row gap-[6px] '>
            <div className=' selectPriceTypeText text-[#5D5C5C] flex flex-col justify-center'>Offer Price :</div>
             {window.innerWidth>640 &&
              <div className="tooltip flex flex-col justify-center -mt-[1.5px]">
                <IoMdInformationCircleOutline style={{marginTop:"15px",color:" #D328A7"}} />
                <span className="tooltippolitical mt-[12px]">
                <p className='text-blue text-sm'>What is Offer Price?</p>
                <p className='text-gray text-xs'>
                The price of the token can be offered to buy the token from resale.
                </p>
                </span>
            </div> 
            }
            {window.innerWidth<=640 &&
               <div className="tooltip mt-[14px]" onClick={handleIRRInfoPopupOfferPrice}>
              <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
              {

                showIRRInfoPopupOfferPrice && (
                    <>
                  
                                    <InfoPopup>
                                        <div className='p-2'>
                                        <p className='text-blue text-left text-sm'>What is Offer Price?</p>
                                            <p className='text-gray text-left text-sm'>
                                            The price of the token can be offered to buy the token from resale.
                                        </p>
                                       
            
            
                                        </div>
                       
                                        <button onClick={handleIRRInfoPopupOfferPrice} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                            Ok</button>
                                        
                                        </InfoPopup>
                    </>
                )
              }
          </div> 
            }
          </div>
          <div className=' flex flex-row rounded-md bg-[#F2F2F2]  overflow-hidden w-[140px] '>
            <div className='font-semibold text-[16px] bg-[#D328A7] text-white flex flex-col justify-center h-[40px] px-[10px]'><FaRupeeSign /></div>

            <div className='font-semibold text-[16px] flex flex-col justify-center pl-[8px] pr-[50px] offerPriceValue'>
            {!myPriceBool && parseFloat(offerPrice).toFixed(2)}

            {myPriceBool && (
              <input
                type="number"
                style={{height:"100%",backgroundColor:"#F2F2F2",fontSize:"13px"}}
                onChange={handleInputChangeMyPrice}
                placeholder="Enter price"
              />
            )}
            </div>


          </div>
        </div>
        {(myPriceBool&&
          <div className='myPrice flex flex-row justify-end font-normal text-[10px] h-[20px] pt-[10px] pr-[5px]'>Price Range: ₹{parseFloat(0.90*offerPrice).toFixed(2)} - ₹{parseFloat(1.10*offerPrice).toFixed(2)}</div>
        )}
        <div className={`flex flex-row justify-between mt-[30px] marginHelpTokens ${myPriceBool?"mt-[34px]":"mt-[30px]"}`}>
          <div className=' flex flex-row gap-[6px]'>
            <div className=' selectPriceTypeText text-[#5D5C5C] flex flex-col justify-center'>Select Tokens :</div>
          </div>
          <div className=' flex flex-row justify-between rounded-md  overflow-hidden w-[140px] bg-[#F2F2F2]'>
            <div className='font-semibold text-[16px] bg-[#D328A7] text-white flex flex-col justify-center h-[40px] px-[10px] cursor-pointer' onClick={()=>handleTokens("subtract")}><FaMinus /></div>
            <div className='font-semibold text-[16px] flex flex-col justify-center offerPriceValue'>
            <div className='flex flex-row justify-center '>
              {noOfTokens}
            </div>
            </div>
            <div className={`font-semibold text-[16px]  text-white flex flex-col justify-center h-[40px] px-[10px] cursor-pointer ${tokenLimitExceeded? "bg-[#E993D3]":"bg-[#D328A7]"}`} onClick={()=>handleTokens("add")}><FaPlus style={{ color: tokenLimitExceeded ? "#F0F0F0" : "white" }} /></div>
          </div>
        </div>
        {tokenLimitExceeded&&
          <div className='font-normal text-[16px] text-[red] h-1'>Token Limit Exceeded</div>

        }
        <div className='mt-[40px] horizontalLineMarginHelp'><hr style={{color:"#D9D9D9"}}></hr></div>

       
          <div className='mt-[35px] lastSectionBuyTokens'>
          <div className=' flex flex-row gap-[6px] spacerBuyNow'>
            <div className='  text-[#5D5C5C] flex flex-col justify-center tokenPriceFontHelper'>Total Tokens :</div>
            <div className=' flex flex-col justify-center text-[#5D5C5C] font-bold ml-[10px]'>{noOfTokens}</div>
          </div>

          <div className='lastSecondBuyNow gap-[30px] '>

          <div className=' flex flex-row gap-[6px] totalPriceMargin spacerBuyNow'>
            <div className='  text-[#5D5C5C] flex flex-col justify-center'>Total Price :</div>
            <div className=' flex flex-col justify-center text-[#5D5C5C] font-bold ml-[10px]'>
            {
              !myPriceBool?
              "₹"+offerPrice*noOfTokens:
              "₹"+myPrice*noOfTokens
            
            }</div>
          </div>


            <div className={`rounded-md  w-[140px] font-bold text-[16px]  hideInMob  ${ myPriceBool && myPrice<myPriceLowerLimit|| myPrice>myPriceUpperLimit || noOfTokens===0 ? "bg-[#E993D3]":"bg-[#D328A7]"}`}>
              <div className=' text-white h-[40px] flex flex-col justify-center cursor-pointer' onClick={handleProceed}>Proceed</div>
            </div>
          </div>



          

        </div>

        <div className=''>
            <button onClick={handleProceed}  className={`text-white py-2.5 px-4 mt-3 rounded-md font-semibold text-base buyNowHiddenButton cursor-pointer ${ myPriceBool &&  myPrice<myPriceLowerLimit|| myPrice>myPriceUpperLimit || noOfTokens===0? "bg-[#E993D3]":"bg-[#D328A7]"}`} style={{width:"100%"}}>
                  Proceed
            </button>
          </div>

      </div>
    </div>
  )
}

export default ResalePartB
