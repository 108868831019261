import React, { useEffect, useState } from 'react'
import "./kyc-style.css"
import { ApiBaseUrl } from '../../Utils/urls'
import { initiateKYC } from '../../Api/KycApi'
import { Link, useNavigate } from 'react-router-dom'
import { fetchUserDetailes } from '../../Api/ProfileApi'
import KYCModal from './KYCModal'
import SuccessAlert from '../Alert/SuccessAlert'
import WarningAlert from '../Alert/WarningAlert'
import { handlelogout } from '../../Utils/logout'
export default function KycComponent({state}) {

  const accessToken=localStorage.getItem("accestoken")
  const userId=localStorage.getItem("user_id")
  const [kycToken,setKycToken]=useState("")
  const [kycStatus, setKycStatus] = useState("");
    const [kycStausData,setKycStatusdata]=useState(null) //from userfetchprofile api
  const [showModal,setShowModal]=useState(false)
  const [alertmsg,setAlertMsg]=useState("")
  const [isChecked, setIsChecked] = useState(false);
const [isEmailExist,setEmailExist]=useState("") //to check email is verified ot not
const [isMobileExist,setisMobileExist]=useState(false) //to check mobile is verified ot not

const [isEmailUpdated,setEmailupdated]=useState(true)
const [isMobileUpdated,setMobileUpdated]=useState(true) //for mobile no not updated elert


 const WORKFLOW_ID = 'propftx_new_onboarding';
const navigate=useNavigate()



  const getKYCtoken=()=>{
let keyBody={
  user_id:userId
}

    fetch(`${ApiBaseUrl}/fr-user/generate-kyc-token`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken 
      },
      body: JSON.stringify(keyBody)
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(res => {
       
   
        // Assuming response is your JSON object
      let tokenWithBearer = res.data.token;
      let token = tokenWithBearer.split(' ')[1];


        setKycToken(res.data.token)
      
      })
      .catch(error => {
       
        console.error('There was a problem :', error);
      });
  }
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  

  useEffect(()=>{
    getKYCtoken()
  },[])

  const handleProceedClick = async () => {

if(!isEmailExist)
{

  setEmailupdated(false)

  setTimeout(() => {
    setEmailupdated(true); 
    navigate("/user/profile")
  }, 3000);

}
else if(!isMobileExist){
  setMobileUpdated(false)

  setTimeout(() => {
    setMobileUpdated(true); 
    navigate("/user/profile")
  }, 3000);
}
else{
  try {
   
   initiateKYC(kycToken, WORKFLOW_ID, userId, handler);

  } catch (error) {
    console.error('API Error:', error);
  
  
  }
}
   
  };

  

  const handler=(HyperKycResult,userId,accessToken)=>{
    switch (HyperKycResult.status) {
      case "user_cancelled":
       setShowModal(true)
      setAlertMsg("You have opted to interrupt the KYC process while it was underway.")
        break;
      case "error":
        setShowModal(true)
        setAlertMsg("An error has occurred during the KYC process. Please retry again.")
        
        break;
      case "auto_approved":
        // alert("auto_approved")
        handleUserVerifyKYC(userId,accessToken)
        fetchUserDetailes(userId,accessToken)

        navigate("/user/kyc/verified",{ state })
      //  if(kycStatus==='APPROVED')
      //  {
      //   navigate("/user/kyc/verified")
      //  }
        break;
      case "auto_declined":
        // setShowModal(true)
        // setAlertMsg("An error has occurred during the KYC process. Please retry again.")
        
        navigate('/user/kyc/rejected')
      break;
      case "needs_review":
        handleUserVerifyKYC(userId,accessToken)
        alert("needs_review");
        break;
        default:
          alert("default");
    }
  }


useEffect(()=>{
  fetchUserDetailes(userId,accessToken)
  .then(response => {
            
 return response.json();
  })
  .then((res)=>{
    if (!res.success && res.data === 'INVALID TOKEN OR USER ID MISMATCH') {
           
      handlelogout(navigate); // Log out the user
    }
    if(res.success && res.data.kyc!==null)
    
    {
      
          setKycStatusdata(res.data.kyc)
          setKycStatus(res.data.kyc.kyc_status)
          setEmailExist(res.data.profile?.email)
          setisMobileExist(res.data.profile?.mobile)
    }
   
    if(res.success && res.data.kyc===null)
    {
      
         setKycStatus('NOTINITIATED')
         setEmailExist(res.data.profile?.email)
         setisMobileExist(res.data.profile?.mobile)
    }
  })
  .catch((err)=>{
    console.log(err);
  })
},[])


let reqBody={
  user_id:userId
}

const handleUserVerifyKYC=(accessToken)=>{


  
      fetch(`${ApiBaseUrl}/fr-user/user-verify-kyc`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken 
        },
        body:JSON.stringify(reqBody) 
       
          
        }
      
      )
        .then(response => {
        
        
         
          return response.json();
        })
        .then(res => {
          console.log('Response from server:', res);
         })
        .catch(error => {
         
          console.error('There was a problem :', error);
        });
}

const handleCloseModal=()=>{
  
  setShowModal(false)
}



  return (

    <>
{
  showModal && (
    <KYCModal closeModal={handleCloseModal}>

<div className='flex flex-col gap-7 font-nunito'>
            <p className='text-secondary text-xl font-bold text-center'>KYC Status</p>
            <p className='text-lg font-normal text-center'>{alertmsg} </p>
           
     
           

     <button className='bg-secondary rounded-md text-white nomineeOkbtn'
            onClick={handleCloseModal}
            >
                OK
            </button>
          
          
            

         </div>

    </KYCModal>
  )
}

{
  !isEmailUpdated && (
    <WarningAlert  message="Please Update Your Email"/>
  )
}
{
  !isMobileUpdated && (
    <WarningAlert  message="Please Update Your Mobile number"/>
  )
}


    <div className='font-nunito px-2 sm:px-0'>
 <div className='text-center w-full    pb-4'>
  <p className='text-2xl sm:text-base font-medium text-center sm:text-left'>KYC  Verification</p>
  </div>


{/* verification-part */}




{
kycStatus === "APPROVED" ? 
(

<div className='profileSection  font-nunito'>
<div className='flex flex-col'>

<div className='flex justify-between'>
           <p className='text-xl sm:text-2xl text-blue font-bold'>Your KYC  is Verified!</p> 
            {/* <div className='flex gap-4 items-center'>
                <div>
                    <span class="material-symbols-outlined tip-icon">lightbulb</span>
                </div>
                    <p className='text-gray text-xl font-normal'>Tips</p>
            </div> */}
</div>

<div className='flex flex-col gap-4'>
     <div className='flex flex-col gap-2 text-left kycInnerDiv'>
        <p className='text-gray text-sm'>Time to kickstart your investment journey!</p>
              
    </div>

 
     </div>

{/* background-image */}


<div className='approvedContainer'>
          
            
          <div className='approvedKycImg'></div>
         
          
          </div>
  

<hr className='kychrClass' />
<Link to='/'>
<div className='flex  items-center justify-between mt-5'>
<p className='text-base text-gray'></p>
<button className='bg-secondary  text-white exploreKycBtn'>Explore Assests</button>
</div>
</Link>
     </div>
     
    </div>

    
  )
//   :kycStatus === "REJECTED"?(
// <div className='profileSection  font-nunito'>
// <div className='flex flex-col'>

// <div className='flex justify-between'>
//            <p className='text-xl sm:text-2xl text-blue font-bold'>Your KYC is Rejected!</p> 
          
// </div>

// <div className='flex flex-col gap-4'>
//      <div className='flex flex-col gap-2 text-left kycInnerDiv'>
//         <p className='text-gray text-sm'>Uh-oh! Your KYC is rejected. Let's get it back in action again.</p>
              
//     </div>

 
//      </div>




// <div className='approvedContainer'>
          
            
//           <div className='rejectedImg'></div>
         
          
//           </div>
  

// <hr className='kychrClass' />

// <div className='flex  items-center justify-between mt-5'>
// <p className='text-base text-gray'></p>
// <button onClick={handleProceedClick} className='bg-secondary  text-white proceedBtn'>Verify Again</button>
// </div>

//      </div>
     
//     </div>
//   )

 :
kycStatus === "REQUESTED"?
(
  <div className='profileSection  font-nunito'>
  <div className='flex flex-col'>
  
  <div className='flex justify-between'>
             <p className='text-xl sm:text-2xl text-blue font-bold'>Your KYC is Under Process!</p> 
              
  </div>
  
  <div className='flex flex-col gap-4'>
       <div className='flex flex-col gap-2 text-left kycInnerDiv'>
          <p className='text-gray text-sm'>Your KYC is under our verification squad. Stay tuned for the big reveal.</p>
                
      </div>
  
   
       </div>
  
  {/* background-image */}
  
  
  <div className='approvedContainer'>
            
              
            <div className='processKycImg'></div>
           
            
            </div>
    
  
  <hr className='kychrClass' />
  <Link to='/'>
  <div className='flex  items-center justify-between mt-5'>
  <p className='text-base text-gray'></p>
  <button className='bg-secondary  text-white kycBtn proceedBtn'>Explore Assests</button>
  </div>
  </Link>
       </div>
       
      </div>
)
:
  (   
     <div className='profileSection  font-nunito'>
            <div className='flex flex-col'>
            
            <div className='flex justify-between'>
                       <p className=' text-lg sm:text-2xl mb-3 text-blue font-bold'>Start Your KYC Verification Here!</p> 
                       
                        {/* <div className='flex gap-4 items-center'>
                            <div>
                                <span class="material-symbols-outlined tip-icon">lightbulb</span>
                            </div>
                                <p className='text-gray text-xs sm:text-xl font-normal'>Tips</p>
                        </div> */}

        </div>

         <div className='flex flex-col gap-4'>
                 <div className='flex flex-col gap-2 text-left kycInnerDiv'>
                    <p className='text-blue text-sm  sm:text-lg font-bold'>Unlock your investment potential! </p>
                    <p className='text-gray text-sm font-normal'>Before you dive in, please ensure that your KYC is in order. Get your PAN card and address proof ready,
                         then start your KYC process in minutes.</p>      
                </div>

                 <div className='flex flex-col  text-gray text-sm font-normal text-left'>
                        <p>Note:</p>

                       
                  <p>1. We never share or sell your personal information.</p>
                        <p>2. Your information is secure and encrypted.</p>
                </div>
                 </div>




                 <div className='flex mt-3  items-start sm:hidden gap-4 text-left  text-gray text-sm kyc-check' >
<input type="checkbox" name="consentCheckbox" id="consentCheckbox" checked={isChecked} onChange={handleCheckboxChange} />
    <p>I consent to the use of my PAN card/Aadhar/Other POA documents for the purpose of authentication and verification by PropFTX. 
      I further consent to the use of facial recognition for the same.</p>

</div>

          {/* background-image */}
                 <div className='kycContainer'> </div>


<div className='sm:flex gap-4 hidden items-center text-left text-gray text-sm kyc-check' >
<input type="checkbox" name="consentCheckbox" id="consentCheckbox" checked={isChecked} onChange={handleCheckboxChange} />
    <p>I consent to the use of my PAN card/Aadhar/Other POA documents for the purpose of authentication and verification by PropFTX. 
      I further consent to the use of facial recognition for the same.</p>

</div>

<hr className='kychrClass' />

<div className='flex flex-col   items-center sm:flex-row-reverse justify-between mt-5'>
      {/* <p className='text-base text-gray'>Skip for now</p> */}
      <button className={`hidden sm:block  ${isChecked?'bg-secondary text-white':'bg-lightgray text-black'} kycBtn`}  disabled={!isChecked} 
     onClick={handleProceedClick}>Proceed</button>


<button className={`block sm:hidden  ${isChecked?'bg-secondary text-white':'bg-lightgray text-black'} kycBtn`}  disabled={!isChecked} 
      onClick={handleProceedClick}>Start KYC Process</button>
</div>
                 </div>
                 
                </div>)
     


}




      </div>
      </>
  )
}
