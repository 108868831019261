import React from 'react'
import "./transaction-style.css"

export default function NoTransaction() {
  return (
    <div className='noTransContainer'>
    <hr className='hrTransac'/>
    
    <div className='noTransactionImg'></div>
    <p className='noTransactionText text-gray'>No transaction has been recorded yet</p>
    
    </div>
  )
}
