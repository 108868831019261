import React, { useEffect, useState } from 'react'
import "./index.css"
import "../PropertyDetails/ResponsiveComponents/responsive.css"
import DetailsComponent from './DetailsComponent'
import TabsComponent from './TabsComponent'
import { useParams } from 'react-router'

import { useLocation } from "react-router-dom"

import { ApiBaseUrl, PropertImageBaseUrl } from '../../Utils/urls'
import MobileImageSlider from './ResponsiveComponents/MobileImageSlider'
import  locationIcon from "../../Assets/icons/pin_drop.png"
import { BeatLoader } from 'react-spinners'
import loader from "../../Assets/icons/Loader.gif"
import Footer from '../MainFooter/Footer'
import MobileFooter from '../MainFooter/MobileFooter'
import { MdOutlineLocationOn } from 'react-icons/md'




export default function PropertyDetailsPage() {
const {id}=useParams()
const location = useLocation();

const [propertDetails,setPropertyDetails]=useState({})
const {property_info,invoice_info}=propertDetails
const [isLoading,setLoading]=useState(false)
//extract the property image as array from details
const AllPropertyImages = propertDetails?.property_info?.property_images || [];
const user_id=localStorage.getItem("user_id")

const [bigImage, setBigImage] = useState( "");

const handleThumbnailClick = (image) => {
  setBigImage(image.Key);
};









let  propertyDetailsURl=""
if(user_id)
{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}&user_id=${user_id}`

}
else{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}`
 
}


useEffect(() => {
  const fetchPropertyDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(propertyDetailsURl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
       
      });
      if (!response.ok) {
        throw new Error('Failed to fetch property details');
      }
      const res = await response.json();
      //console.log(data);
      setLoading(false)
      setPropertyDetails(res.data);
      if (res.data.property_info && res.data.property_info.property_images) {
        setBigImage(res.data.property_info.property_images[0]?.Key || '');
      }
    } catch (error) {
      console.error('Error fetching property details:', error);
      setLoading(false);
    }
  };

  fetchPropertyDetails();
}, [id]);


useEffect(() => {
  // Scroll to the top 
 
      window.scrollTo(0, 0);
  
}, [location]);


  return (

    <>
 
    <div className="mt-14 sm:mt-16">
   



{/***********  image-slider-for-mobile ************/}
   <div className="block sm:hidden">
   
<MobileImageSlider AllPropertyImages={AllPropertyImages} isLoading={isLoading}/>

<div className='flex flex-col text-blue  p-5'>
  <p className='text-xl  font-bold font-nunito text-left' >{property_info?.property_name}</p>
  <div className='flex items-center gap-1'>
         
          <MdOutlineLocationOn/>
          <p>{property_info?.property_location}, {property_info?.property_city}</p>
  </div>
</div>

  </div>

{/********* image-slider-for-mobile-end *********/}

   < div class="container mx-auto px-2 pt-4">



<div className="grid grid-cols-1 md:grid-cols-3 gap-3 ">

  {/*****  image-section *********/}

  {/* web-view */}
      <div className=" md:col-span-2  px-1">

    <div className='hidden sm:block'>



        <div className="grid  md:grid-cols-6 gap-4  ">
           
         
           <div className="thumbnail-container">
    {
      AllPropertyImages && AllPropertyImages.map((el,id)=>(
      

        <img onClick={()=>handleThumbnailClick(el)} key={id} className="active:opacity-70 thumbnail-img active:border-Orange active:border-2 w-32 rounded cursor-pointer border-Orange lg:w-32 xl:w-32 "
         src={`${PropertImageBaseUrl}/${el.Key}`} alt="Property"  />

        

      ))
    }
    </div>

            <div className=" md:col-span-5">
              
          
              {
                isLoading
                ?
                <div className='lg:h-80 md:w-80 mx-auto'>
                {/* <BeatLoader color={'#123abc'} loading={isLoading}  /> */}
               
                <div className='loaderDiv'>
              <img src={loader} altloaderDiv=""  className='LoaderClass' alt='loader'/>
              </div>

                </div>
                :
                <img className="rounded-md bigImage  lg:h-80 md:w-80 cursor-pointer " src={`${PropertImageBaseUrl}/${bigImage}`} alt="property" ></img>
           
              }
            </div>
        </div>

    </div>

    <div> 
      {/* web-view-end */}




  <TabsComponent details={propertDetails}/>
</div>
       
      </div>

     
 {/*****  image-section-end *********/}
 {/* max-h-[calc(100vh-100px)] overflow-y-auto */}
{/* details-part */}

      <div className="hidden sm:block ">
      <div className=" sticky top-0  ">
        <DetailsComponent />
        </div>
      </div>

      {/* <div className="py-4 hidden sm:block details-section">
            <div className="details-scrollable">
              <DetailsComponent details={propertDetails} />
            </div>
          </div> */}
   
  {/* details-part-end */}   

    </div> 


    
</div>
</div>

<Footer/>
<MobileFooter/>

</>
  )
}
