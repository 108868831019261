
export const purchaseformatDate = (transactionDate) => {
    const currentDate = new Date();
    const transactionDateTime = new Date(transactionDate);
    const timeDifference = currentDate - transactionDateTime;
  
    // Convert milliseconds to minutes
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  
    if (minutesDifference < 10) {
      return "Just now";
    } else if (minutesDifference < 24 * 60) {
      const hoursAgo = Math.floor(minutesDifference / 60);
      return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else if (currentDate.getDate() - transactionDateTime.getDate() === 1) {
      return "1 day ago";
    } else {
      const transactionDay = transactionDateTime.getDate();
      const transactionMonth = transactionDateTime.getMonth() + 1;
      const transactionYear = transactionDateTime.getFullYear();
      return `${transactionDay}/${transactionMonth}/${transactionYear}`;
    }
  };
  