import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BiUpArrowAlt } from 'react-icons/bi'
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { ApiBaseUrl } from '../../../Utils/urls';
import { FaIndianRupeeSign } from "react-icons/fa6";
import InfoPopup from '../../InfoPopup/InfoPopup'

function ResaleCardMob(props) {
    const [noOfTokens, setnoOfTokens] = useState(0);
    const [isLoggedin, setisLoggedin] = useState(false); 
    const [userId, setUserId] = useState(null);
    const [accessToken, setaccessToken] = useState(null);
    const [propertyDetails, setPropertyDetails] = useState([]);
    const [tokenLimitExceeded, settokenLimitExceeded] = useState(false);
    const [tokenLimit, settokenLimit] = useState(0);
    const [myPriceBool, setmyPriceBool] = useState(false);
const [myPrice, setmyPrice] = useState(0);
const [myPriceLowerLimit, setmyPriceLowerLimit] = useState(0);
const [myPriceUpperLimit, setmyPriceUpperLimit] = useState(0);

function padWithLeadingZero(value) {
  return value.toString().padStart(1, '0');
}

    useEffect(() => {
        const userIdFromLocalStorage = localStorage.getItem('user_id');
        const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
        if (userIdFromLocalStorage ) {
          setisLoggedin(true);
          setUserId(userIdFromLocalStorage);
          setaccessToken(AccessTokenFromLocalStorage);
        }
      }, [isLoggedin]); 
      
    useEffect(() => {
        if (userId && props.propertyId && accessToken) {
          const fetchPropertyDetails = async () => {
            try {
              const response = await axios.post(`${ApiBaseUrl}/fr-resale/available-property-details`, {
                user_id: userId,
                user_property_id: props.propertyId
              }, {
                headers: {
                  Authorization: accessToken
                }
              });
              setPropertyDetails(response.data.data);
            } catch (error) {
              console.error('Error fetching property details:', error);
            }
          };
      
          fetchPropertyDetails();
        }
      }, [userId, props.propertyId, accessToken]);
      
      
      useEffect(()=>{
        settokenLimit(props.listableShares);
      },[props.listableShares])
    
      const handleTokens=(op)=>{
        if(op=="plus"){
          if(noOfTokens+1<=tokenLimit){
                  setnoOfTokens(noOfTokens+1);
                  settokenLimitExceeded(false);
                }
                else{
                  settokenLimitExceeded(true);
                }
        }
        else{
          if(noOfTokens>0){
                  setnoOfTokens(noOfTokens-1);
                  settokenLimitExceeded(false);
              }
         
        }
      }

      const closeResale=()=>{
        props.setIsOpenMob(false);
        props.setResalePopupOpen(false);
      }


      const showConfirmPopup =()=>{

        if(!myPriceBool){
          if(noOfTokens>0){
            props.setPropertyIdForListingApi(propertyDetails.user_property_id);
            props.setuserIdForListingApi(userId);
            props.setaccessTokenForListingApi(accessToken);
            props.setnoOfTokensForListingApi(noOfTokens);
            props.setcurrentPriceForListingApi(propertyDetails.current_price);
            
                props.setResalePopupOpen(false);
                props.setshowConfirmPopup(true)
          }
        }
    
        else if(myPriceBool){
          if(noOfTokens>0){
            props.setPropertyIdForListingApi(propertyDetails.user_property_id);
            props.setuserIdForListingApi(userId);
            props.setaccessTokenForListingApi(accessToken);
            props.setnoOfTokensForListingApi(noOfTokens);
            props.setcurrentPriceForListingApi(myPrice);
            
                props.setResalePopupOpen(false);
                props.setshowConfirmPopup(true)
          }
    
        }
      }


      const handleInputChangeMyPrice = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
          setmyPrice(value);
        }
      };
      
      
      useEffect(() => {
        setmyPriceLowerLimit(0.90*propertyDetails.current_price);
        setmyPriceUpperLimit(1.10*propertyDetails.current_price);
       }, [propertyDetails.current_price]);
       
      
      //  const [showIRRInfoPopup, setshowIRRInfoPopup] = useState(false);
      //  const handleIRRInfoPopup = () => {
      //    setshowIRRInfoPopup(!showIRRInfoPopup);
      //    const [showIRRInfoPopup, setshowIRRInfoPopup] = useState(false);
      //    const handleIRRInfoPopup = () => {
      //      setshowIRRInfoPopup(!showIRRInfoPopup);
      const [showYeildInfoPopup, setshowYeildInfoPopup] = useState(false);
      const handleYeildInfoPopup = () => {
        setshowYeildInfoPopup(!showYeildInfoPopup);
      };
      const [showPotentialGainInfoPopup, setshowPotentialGainInfoPopup] = useState(false);
      const handlePotentialGainInfoPopup = () => {
        setshowPotentialGainInfoPopup(!showPotentialGainInfoPopup);
      };
          
    const [showIRRInfoPopup, setshowIRRInfoPopup] = useState(false);
    const handleIRRInfoPopup = () => {
      setshowIRRInfoPopup(!showIRRInfoPopup);
    };
    const [showIRRInfoPopupMyPrice, setshowIRRInfoPopupMyPrice] = useState(false);
    const handleIRRInfoPopupMyPrice = () => {
      setshowIRRInfoPopupMyPrice(!showIRRInfoPopupMyPrice);
    };
    const [showIRRInfoPopupOfferPrice, setshowIRRInfoPopupOfferPrice] = useState(false);
    const handleIRRInfoPopupOfferPrice = () => {
      setshowIRRInfoPopupOfferPrice(!showIRRInfoPopupOfferPrice);
    };
        

          

  return (
    <div className='bg-white'>
    <div className='px-[30px] mt-[18px]' style={{width:"100%"}}>
    <div className="investmentSection flex justify-between rounded-lg py-2.5 px-4">
    <div className="flex flex-col items-center">
      <p className="font-semibold font-nunito text-gray text-lg">Yield</p>
      <div className="flex items-center gap-1">
        <div className="font-semibold text-blue text-xl">{propertyDetails.gross_yield}%</div>
        <div className="tooltip " onClick={handleYeildInfoPopup}>
        <IoMdInformationCircleOutline style={{fontSize:"15px",color:" #D328A7"}} />
        {

          showYeildInfoPopup && (
              <>
            
                              <InfoPopup>
                                  <div className='p-2'>
                                  <p className='text-blue text-left text-sm'>What is Yield?</p>
                                      <p className='text-gray text-left text-sm'>
                                      The earnings generated and realized on the investment over a particular period of time.
                                  </p>
                                 
      
      
                                  </div>
                 
                                  <button onClick={handleYeildInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                      Ok</button>
                                  
                                  </InfoPopup>
              </>
          )
        }
    </div> 
      </div>
    </div>
    <div className="flex flex-col items-center">
      <p className="font-semibold text-gray font-nunito text-lg">Target IRR</p>
      <div className="flex items-center gap-1">
        <div className="font-semibold text-blue text-xl">{propertyDetails.target_IRR}%</div>
        <div className="tooltip " onClick={handleIRRInfoPopupOfferPrice}>
        <IoMdInformationCircleOutline style={{fontSize:"15px",color:" #D328A7"}} />
        {

          showIRRInfoPopupOfferPrice && (
              <>
            
                              <InfoPopup>
                                  <div className='p-2'>
                                  <p className='text-blue text-left text-sm'>What is Target IRR?</p>
                                      <p className='text-gray text-left text-sm'>
                                      Expected rate of return that an investment aims to achieve over a period of time.
                                  </p>
                                 
      
      
                                  </div>
                 
                                  <button onClick={handleIRRInfoPopupOfferPrice} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                      Ok</button>
                                  
                                  </InfoPopup>
              </>
          )
        }
    </div> 
      </div>
    </div>
    <div className="flex flex-col items-center">
      <p className="font-semibold text-gray font-nunito text-lg">
        Potential Gain
        {/* {details && details.property_info?.sale_type==="Presale"?"Potential Gain":" Capital Gain"} */}
      </p>
      <div className="flex items-center gap-1">
        <BiUpArrowAlt style={{ fontSize: "23px", color: "#2FDF99" }} />
        <div className="font-semibold text-blue text-xl">{propertyDetails.capital_gain}</div>
        <div className="tooltip " onClick={handlePotentialGainInfoPopup}>
        <IoMdInformationCircleOutline style={{fontSize:"15px",color:" #D328A7"}} />
        {

          showPotentialGainInfoPopup && (
              <>
            
                              <InfoPopup>
                                  <div className='p-2'>
                                  <p className='text-blue text-left text-sm'>What is Potential Gain?</p>
                                      <p className='text-gray text-left text-sm'>
                                      Potential profit an investor would get over a certain period.
                                  </p>
                                 
      
      
                                  </div>
                 
                                  <button onClick={handlePotentialGainInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                      Ok</button>
                                  
                                  </InfoPopup>
              </>
          )
        }
    </div> 
      </div>
    </div>
    </div>
    </div>

    <div className='text-[#081956] text-[18px] font-semibold mt-[25px]'>List your token for Resale</div>
    <div className=' text-[16px] font-normal mt-[17px]'>Choose the number of tokens:</div>
    <div className=' flex flex-row justify-center h-[38px] mt-[12px]'>
        <div className='px-[22px] flex flex-col justify-center bg-[#091526] text-white cursor-pointer' onClick={()=>handleTokens("minus")}  style={{borderBottomLeftRadius:"9px",borderTopLeftRadius:"9px"}}><FaMinus /></div>
        <div className='border flex flex-col justify-center px-[20px] text-[13.5px] font-semibold'> {padWithLeadingZero(noOfTokens)}</div>
        <div className={`px-[22px] flex flex-col justify-center  text-white cursor-pointer ${tokenLimitExceeded? "bg-lightgray":"bg-[#091526]"}`} onClick={()=>handleTokens("plus")} style={{borderBottomRightRadius:"9px",borderTopRightRadius:"9px"}}> <FaPlus style={{ color: tokenLimitExceeded ? "black" : "white" }} /></div>
    </div>
    <div className='h-2'>
                    {tokenLimitExceeded && (
                      <div className='text-[red] text-[12px]'>
                        You have exceeded the available token limit.
                      </div>
                    )}
                  </div>


    <div className=' partC mt-[25px] px-[30px]'>
        <div className='flex flex-row justify-between '>
        <div className='flex flex-row gap-[10px]' onClick={()=>setmyPriceBool(false)}>
            <div className={`rounded-md border border-[#E6E6E6] w-[120px] py-[10px] text-[#787878] text-[16px] font-normal  ${!myPriceBool?"bg-black text-white":""}`}>Market Price</div>
            <div className="tooltip mt-[18px]" onClick={handleIRRInfoPopup}>
            <IoMdInformationCircleOutline style={{fontSize:"15px",color:" #D328A7"}} />
            {

              showIRRInfoPopup && (
                  <>
                
                                  <InfoPopup>
                                      <div className='p-2'>
                                      <p className='text-blue text-left text-sm'>What is Market Price?</p>
                                          <p className='text-gray text-left text-sm'>
                                          It is the current market price of the token that is determined by AI.
                                      </p>
                                     
          
          
                                      </div>
                     
                                      <button onClick={handleIRRInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                          Ok</button>
                                      
                                      </InfoPopup>
                  </>
              )
            }
        </div> 
        </div>
        <div className='flex flex-row gap-[10px]' onClick={()=>setmyPriceBool(true)}>
            <div className={`rounded-md border border-[#E6E6E6] w-[120px]  py-[10px] text-[#787878] text-[16px] font-normal ${myPriceBool?"bg-black text-white":""}`}>My Price</div>
            <div className="tooltip mt-[18px]" onClick={handleIRRInfoPopupMyPrice}>
            <IoMdInformationCircleOutline style={{fontSize:"15px",color:" #D328A7"}} />
            {

              showIRRInfoPopupMyPrice && (
                  <>
                
                                  <InfoPopup>
                                      <div className='p-2'>
                                      <p className='text-blue text-left text-sm'>What is My Price?</p>
                                          <p className='text-gray text-left text-sm'>
                                          Token holders can list the token at a price between 10% higher and 10% lower than the current market price.
                                      </p>
                                     
          
          
                                      </div>
                     
                                      <button onClick={handleIRRInfoPopupMyPrice} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                          Ok</button>
                                      
                                      </InfoPopup>
                  </>
              )
            }
        </div> 
        </div>
        </div>
    <div className=' text-[16px] font-bold text-start mt-[30px]'>Price</div>
        <div className=''>
            <div className=' flex flex-row justify-between h-[38px] mt-[10px]'>
            <div className='px-[15px] flex flex-col justify-center bg-[#091526] text-white cursor-pointer' onClick={()=>handleTokens("minus")}  style={{borderBottomLeftRadius:"9px",borderTopLeftRadius:"9px"}}><FaIndianRupeeSign /></div>
            <div className=' flex flex-col justify-center px-[20px] text-[13.5px] font-semibold text-start bg-[#F2F2F2] text-[#808080]' style={{width:"100%"}}>
            {!myPriceBool && propertyDetails.current_price && parseFloat(propertyDetails.current_price).toFixed(2)}

            {myPriceBool && (
              <input
                type="number"
                style={{height:"100%",backgroundColor:"#F2F2F2",fontSize:"13px"}}
                onChange={handleInputChangeMyPrice}
                placeholder="Enter price"
              />
            )}
             
             </div>
            </div>
        </div>

        {(myPriceBool&&
          <div className='myPrice flex flex-row justify-start font-normal text-[10px] h-[20px] pt-[10px] pr-[5px]'>Price Range: ₹{parseFloat(0.90*propertyDetails.current_price).toFixed(2)} - ₹{parseFloat(1.10*propertyDetails.current_price).toFixed(2)}</div>
        )}

        
    </div>

   

  <div className='text-[12px] font-normal text-[#787878] mt-[25px]' style={{display:"none"}}>Estimated time for Resale is <span className='text-[#081956]'>3 days</span></div>
  <div className=' flex flex-col justify-between px-[30px] mt-[27px]' style={{width:"100%"}}>
  <div className='flex flex-col justify-between gap-[18px]'>
    <div className={`h-[46.5px] flex flex-col justify-center rounded-md text-[16px] font-bold text-white bg-[#D328A7] cursor-pointer ${myPriceBool &&  myPrice<myPriceLowerLimit|| myPrice>myPriceUpperLimit || noOfTokens===0?"bg-[#E993D3]":"bg-[#D328A7]"}`} style={{width:"100%"}} onClick={showConfirmPopup}>List Now</div>
    <div className='border h-[46.5px] flex flex-col justify-center rounded-md text-[16px] font-bold text-[#787878] cursor-pointer' style={{width:"100%"}} onClick={closeResale}>Cancel Resale</div>
  </div>
 
</div>



    </div>

    

    
  )
}

export default ResaleCardMob
