import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';

const ProfileModal = ({ children, closeModal }) => {
  return (
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
 
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full bg-modalbg items-end justify-center p-4 text-center sm:items-center sm:p-0">
      
      <div class="relative p-4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className='modal-close-btn cursor-pointer' onClick={closeModal}>
      <IoCloseOutline style={{color:"#808080", fontSize:"24px"}} />
      </div>
             {children}
            </div>
    </div>
  </div>
</div>

  );
};

export default ProfileModal;
