import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import './Index.css'
import { ApiBaseUrl, DefaultFilter } from '../../Utils/urls';
import { usePropertyFilters } from '../../Utils/filter';




function CityButton({ city, onClick, selectedCities }) {
  const isClicked = selectedCities.includes(city.city_name);

  const handleClick = () => {
    onClick(city.city_name);
  };

  return (
    <button
      className={`cityButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {city.city_name}
    </button>
  );
}

  
function BuilderButton({ builder, onClick, selectedBuilders }) {
  if (!builder) {
    return null;
  }
  const isClicked = selectedBuilders.includes(builder.builder_name);
  const handleClick = () => {
    onClick(builder.builder_name);
  };

  return (
    <button
      className={`BuilderButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {builder.builder_name}
    </button>
  );
}

function AssetButton({ name, onClick, assetType }) {
  const isClicked = assetType.includes(name);

  const handleClick = () => {
    onClick(name);
  };
    return (
      <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
        <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
        <button
          className=" text-black text-sm font-normal"
        >
          {name}
        </button>
      </div>
    );
  }
  
  function  PriceRangeButton({ name, onClick, priceRange }) {
      const isClicked = priceRange.includes(name);
    
      const handleClick = () => {
        onClick(name);
      };
    
      return (
        <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
          <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
          <button
            className=" text-black text-sm font-normal"
          >
            {name}
          </button>
        </div>
      );
    }
function SideNav() {
  const [propertyPhases, setPropertyPhases] = useState([]);
  const [saleTypes, setSaleTypes] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isClearButtonActive, setIsClearButtonActive] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [anyFilterSelected, setanyFilterSelected] = useState(0);
  
  const [placeFilters, setPlaceFilters] = useState([]);
  const [builderFilters, setBuilderFilters] = useState([]);
  const [currencyFilters, setCurrencyFilters] = useState([]);


  const { propertyFilters, setPropertyFilters } = usePropertyFilters();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${ApiBaseUrl}/fr-property/fetch-properties-default-filters`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data1 = await response.json();
        
        // Extracting data for place filters
        const places = data1.data.place_filters.map(city => ({
          city_id: city.city_id,
          city_name: city.city_name
        }));
        setPlaceFilters(places);

        // Extracting data for builder filters
        const builders = data1.data.builder_filters.map(builder => ({
          builder_id: builder.builder_id,
          builder_name: builder.builder_name
        }));
        setBuilderFilters(builders);

        // Extracting data for currency filte
        const currencies = data1.data.currency_filters.map(currency => ({
          currency_id: currency.currency_id,
          fiat_currency: currency.fiat_currency
        }));
        setCurrencyFilters(currencies);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };


  const handlePropertyPhaseClick = (propertyPhaseType) => {
    setIsFilterActive(1);
    let propPhase;
    if (propertyPhases.includes(propertyPhaseType)) {
      propPhase=propertyPhases.filter((phase) => phase !== propertyPhaseType)
      //setPropertyPhases(propertyPhases.filter((phase) => phase !== propertyPhaseType));
    } else {
      propPhase=[...propertyPhases, propertyPhaseType]
      //setPropertyPhases([...propertyPhases, propertyPhaseType]);
    }
    setPropertyPhases(propPhase)
    setIsFilterActive(true);
    

    let updatedPropertyPhase;
    if (propPhase.length === 0) {
      updatedPropertyPhase = null;
    } else if (propPhase.includes('all')) {
      updatedPropertyPhase = ['Public Sale', 'Presale'];
    } else {
      updatedPropertyPhase = propPhase;
    }
  
    setPropertyFilters((prevFilters) => ({
      ...prevFilters,
      filtersByString: {
        ...prevFilters.filtersByString,
        property_phase: updatedPropertyPhase,
      },
    }));


  };



  

  const handleAssetTypeClick = (assetTypeName) => {
    let updatedAssetTypes;
    if (assetType.includes(assetTypeName)) {
        updatedAssetTypes = assetType.filter((type) => type !== assetTypeName);
    } else {
        updatedAssetTypes = [...assetType, assetTypeName];
    }

    setAssetType(updatedAssetTypes);
    let updatedPropertyType;
    if (updatedAssetTypes.length === 0) {
        updatedPropertyType = null;
    } else if (updatedAssetTypes.includes("All")) {
        updatedPropertyType = ["Commercial", "Residence", "Plots"];
    } else {
        updatedPropertyType = updatedAssetTypes.map(typeName => {
            if (typeName === "Residential") {
                return "Residence";
            }
            return typeName;
        });
    }
    setPropertyFilters(prevFilters => ({
        ...prevFilters,
        filtersByString: {
            ...prevFilters.filtersByString,
            property_type: updatedPropertyType
        }
    }));
};

const handleSaleTypeClick = (saleType) => {
  let updatedSaleTypes;
  if (saleTypes.includes(saleType)) {
    updatedSaleTypes = saleTypes.filter((type) => type !== saleType);
  } else {
    updatedSaleTypes = [...saleTypes, saleType];
  }

  setSaleTypes(updatedSaleTypes);
  setIsFilterActive(true);

  // Update property_filters with the new sale types
  let updatedPropertySaleTypes;
  if (updatedSaleTypes.length === 0) {
    updatedPropertySaleTypes = null;
  } else if (updatedSaleTypes.includes('all')) {
    updatedPropertySaleTypes = ["Coming Soon","Open","Resale","Sold out"];
  } else {
    updatedPropertySaleTypes = updatedSaleTypes;
  }

  setPropertyFilters((prevFilters) => ({
    ...prevFilters,
    filtersByString: {
      ...prevFilters.filtersByString,
      sale_type: updatedPropertySaleTypes,
    },
  }));
};

  const handleCityClick = (cityName) => {
    let updatedCities;
    if (selectedCities.includes(cityName)) {
        updatedCities = selectedCities.filter((city) => city !== cityName);
    } else {
        updatedCities = [...selectedCities, cityName];
    }
  
    setSelectedCities(updatedCities);
    const updatedFiltersByPlaces = updatedCities.map((cityName) => {
        const selectedCity = placeFilters.find(city => city.city_name === cityName);
        return { city_id: selectedCity.city_id, locations: []  };
    });
  

    const updatedPropertyFilters = { ...propertyFilters, filtersByPlaces: updatedFiltersByPlaces };
    setPropertyFilters(updatedPropertyFilters);
  
    setIsFilterActive(true);
};

const handlePriceRangeClick = (price) => {
  let updatedPriceRange;
  if (priceRange.includes(price)) {
    updatedPriceRange = priceRange.filter((range) => range !== price);
  } else {
    updatedPriceRange = [price];
  }
  setPriceRange(updatedPriceRange);
  setIsFilterActive(true);

  let filtersByPrice = {};

  if (updatedPriceRange.includes("Upto 50000")) {
    filtersByPrice = { from: "0", to: "50000" };
  } else if (updatedPriceRange.includes("50,000-1,00,000")) {
    filtersByPrice = { from: "50000", to: "100000" };
  } else if (updatedPriceRange.includes("Above 1,00,000")) {
    filtersByPrice = { from: "100000", to: "10000000" };
  } else {
    filtersByPrice = null;
  }

  setPropertyFilters((prevFilters) => ({
    ...prevFilters,
    filtersByPrice: { price_per_share: filtersByPrice },
  }));
};


  const handleBuilderClick = (builderName) => {
    let updatedBuilders;

    if (selectedBuilders.includes(builderName)) {
        updatedBuilders = selectedBuilders.filter((builder) => builder !== builderName);
    } else {
        updatedBuilders = [...selectedBuilders, builderName];
    }

    setSelectedBuilders(updatedBuilders);

    const updatedFiltersByBuilders = updatedBuilders.map((builderName) => {
        const selectedBuilder = builderFilters.find(builder => builder.builder_name === builderName);
        return selectedBuilder ? selectedBuilder.builder_id : null;
    }).filter(id => id !== null);
    const updatedPropertyFilters = { ...propertyFilters, filtersByBuilders: updatedFiltersByBuilders };
    setPropertyFilters(updatedPropertyFilters);

    setIsFilterActive(true);
};


  const handlePhaseClearClick = () => {
    setPropertyPhases([]);

    setPropertyFilters((prevFilters) => ({
      ...prevFilters,
      filtersByString: {
        ...prevFilters.filtersByString,
        property_phase: null,
      },
    }));
  }

  const handleSaleTypeClearClick = () => {
    setSaleTypes([]);
    setPropertyFilters((prevFilters) => ({
      ...prevFilters,
      filtersByString: {
        ...prevFilters.filtersByString,
        sale_type: null,
      },
      
    }));

   
  }

  const handleCityClearClick = () => {

    setSelectedCities([]);
    const updatedFiltersByPlaces = [];
    const updatedPropertyFilters = { ...propertyFilters, filtersByPlaces: updatedFiltersByPlaces };
    setPropertyFilters(updatedPropertyFilters);
  }
  const handleBuilderClearClick = () => {

    setSelectedBuilders([]);
     const updatedFiltersByBuilders = [];
     const updatedPropertyFilters = { ...propertyFilters, filtersByBuilders: updatedFiltersByBuilders };
     setPropertyFilters(updatedPropertyFilters);
  }
  const handleAssetTypeClearClick = () => {
    setAssetType([]);
    const updatedFilters = { ...propertyFilters };
    updatedFilters.filtersByString.property_type = null; // Reset asset type filter
    setPropertyFilters(updatedFilters);
  }
  const handlePriceRangeClearClick = () => {
    setPriceRange([]);
    const updatedFilters = { ...propertyFilters };
    updatedFilters.filtersByPrice.price_per_share = null; // Reset price range filter
    setPropertyFilters(updatedFilters);
  }
 

  const handleClearClick = () => {
    setPropertyPhases([]);
    setSaleTypes([]);
    setSelectedCities([]);
    setSelectedBuilders([]);
    setAssetType([]);
    setPriceRange([]);
    setStartDate('');
    setEndDate('');
    setIsFilterActive(false);
    setIsClearButtonActive(false);

    setPropertyFilters(prevFilters => ({
      ...prevFilters,
      filtersByPlaces: [],
      filtersByBuilders: [],
      filtersByString: {},
      filtersByDate: {},
      filtersByPrice: {},
      filtersBySearch: {},
      filterBySort: {},
      userType: ""
    }));

  };




  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>

    
    <div>
    <div className='text-black flex flex-row justify-between mb-2 -mt-5  '>
    <div className='text-black text-2xl font-bold'>Filter </div>

    <div className='flex flex-row gap-2 pr-2' >
    {/* 
    <button
    onClick={handleFilterClick}
    className={`text-sm w-16 font-medium ${
      isFilterActive ? 'ButtonCollect' : 'ButtonBeforeCollect'
    }`}
    disabled={!isFilterActive}
  >
    Apply
  </button>
  */}
  <button
  onClick={handleClearClick}
  className={`clearButton text-xs text-start font-normal -mr-3 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer ${
    // Check if all filters are cleared
    !propertyPhases.length && !saleTypes.length && !selectedCities.length && !selectedBuilders.length && !assetType.length && !priceRange.length ? 'ButtonBeforeCollect' : 'ButtonCollect'
  }`}
      >
        Clear
      </button>

</div> 


  </div>



  <div className=' text-black pt-4 flex flex-col'>
  <div className='flex flex-row justify-between'>
  <div className='text-sm font-bold text-start'>Property Type</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleAssetTypeClearClick}>Clear</div>
  </div>

  </div>
    <div className=' text-lg font-bold text-start flex flex-col p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <AssetButton name="All" onClick={handleAssetTypeClick} assetType={assetType}  />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <AssetButton name="Commercial" onClick={handleAssetTypeClick} assetType={assetType} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <AssetButton name="Residential" onClick={handleAssetTypeClick} assetType={assetType} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        <AssetButton name="Plots" onClick={handleAssetTypeClick} assetType={assetType} />
      </div>
    </div>
  </div>

  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-sm font-bold text-start'>Sales Type</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleSaleTypeClearClick}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
      <button
        className={`propertyPhasesButton  text-black text-sm w-14 h-7 ${
          saleTypes.includes('all') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('all')}
      >
        All
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7  whitespace-nowrap ${
          saleTypes.includes('Coming Soon') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Coming Soon')}
      >
        Coming Soon
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7 whitespace-nowrap ${
          saleTypes.includes('Open') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Open')}
      >
        Open
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7 ${
          saleTypes.includes('Resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Resale')}
      >
        Resale
      </button>


      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7 ${
          saleTypes.includes('Sold out') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Sold out')}
      >
        Sold out
      </button>
    </div>
  </div>









  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className=' text-base font-bold text-start'>Property Phases</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer' 
    onClick={handlePhaseClearClick} 
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
          propertyPhases.includes('Public Sale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhaseClick('Public Sale')}
      >
        Public Sale
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-16 h-7 ${
          propertyPhases.includes('Presale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhaseClick('Presale')}
      >
        Presale
      </button>
   
    </div>
  </div>


  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
    <div className='text-sm font-bold text-start'>City</div>
    <div className='flex flex-col justify-center'>
      <div
        className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
        onClick={handleCityClearClick}
      >
        Clear
      </div>
    </div>
  </div>
  <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
    {placeFilters.map(city => (
      <CityButton
        key={city.city_id}
        city={city}
        onClick={handleCityClick}
        selectedCities={selectedCities}
      />
    ))}
  </div>
</div>

<div className=' text-black flex flex-col pt-4'>
<div className='flex flex-row justify-between'>
  <div className='text-sm font-bold text-start'>Builder's Name</div>
  <div className='flex flex-col justify-center'>
    <div
      className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
      onClick={handleBuilderClearClick}
    >
      Clear
    </div>
  </div>
</div>
<div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
  {builderFilters.map(builder => (
    <BuilderButton
      key={builder.builder_id}
      builder={builder}
      onClick={handleBuilderClick}
      selectedBuilders={selectedBuilders}
    />
  ))}
</div>
</div>


  <div className=' text-black pt-4 flex flex-col mb-12'>
  <div className='flex flex-row justify-between'>
  <div className='text-sm font-bold text-start'>Price Range</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg px-2 h-5 cursor-pointer'
    onClick={handlePriceRangeClearClick}>Clear</div>
  </div>

  </div>
    <div className='text-lg font-bold text-start flex flex-col p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <PriceRangeButton name="Upto 50000" priceRange={priceRange} onClick={handlePriceRangeClick} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <PriceRangeButton name="50,000-1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <PriceRangeButton name="Above 1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      
     
    </div>
  </div>

  
  </div>

  </>
  );
}



export default SideNav
