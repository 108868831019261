import React,{useState,useEffect} from 'react'
import CancelResale from '../../../Assets/cancelResale.svg'
import { ApiBaseUrl } from '../../../Utils/urls';


function CancelResalePopup(props) {
    const [isLoggedin, setisLoggedin] = useState(false); 
    const [accessToken, setaccessToken] = useState(null);
  
    useEffect(() => {
      const userIdFromLocalStorage = localStorage.getItem('user_id');
      const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
  
      if (userIdFromLocalStorage ) {
        setisLoggedin(true);
        setaccessToken(AccessTokenFromLocalStorage);
      }
    }, [isLoggedin]);

  


   const handleSuccessListing = async () => {
        const url = `${ApiBaseUrl}/fr-resale/cancel-resale-offer`;
        const body = {
            user_id: props.userId,
            sale_offer_id: props.saleOfferId
        };
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            });
            const data = await response.json();
            props.setsuccessListing(true);
            props.setIsOpenMob(false);
            props.setapiUpdateFlag(!props.apiUpdateFlag);
            props.setshowConfirmPopup(false);

            setTimeout(() => {
                props.setsuccessListing(false);
            }, 3000);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleClick =()=>{
     props.setIsOpenMob(false);
      props.setshowConfirmPopup(false);
    }

  
  return (
    <div className='px-[24px] py-[30px]  flex flex-col justify-between font-nunito'>

    <div className=' flex flex-row justify-center'><img className=' w-[110px] h-[80px]' src={CancelResale} alt="clock_PropFTX"/></div>
    
    <div className=' mt-[33px] font-normal text-[16px]'>
    Are you sure you want to cancel the listing in the resale marketplace?
    </div>


    <div className=' flex flex-col justify-between mt-[34px]'>
        <div className=' h-[47px] px-[65px] py-[12px] rounded-md text-[16px] font-bold text-white bg-[#D328A7] cursor-pointer' onClick={handleClick}>Don&apos;t Cancel</div>
        <div className='mt-[18px] border h-[47px] px-[65px] py-[12px] rounded-md text-[16px] font-bold text-[#787878] border-[#787878] cursor-pointer' onClick={handleSuccessListing} >Yes, Cancel</div>
    </div>
   
    
    </div>
   
  )
}

export default CancelResalePopup
