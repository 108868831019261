
import React, { useEffect, useRef, useState } from 'react';
import AccordionItem from './AccordionItem';
import carBattery from "../../Assets/icons/Car Battery.png"
import AroundPropertyTab from './AroundPropertyTab';
import ImageCarousel from './ImageCarousel';
import expandIcon from '../../Assets/icons/arrow_downward_alt.png'
import { ApiBaseUrl, PropertImageBaseUrl } from '../../Utils/urls';
import { Id, No } from 'react-flags-select';
import { IoArrowDown } from 'react-icons/io5';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserDetails } from '../../Api/ProfileApi';
import SuccessAlert from '../Alert/SuccessAlert';
import { ClickOutside, DecrementToken, IncrementToken, ProceedToPurchase, calculateTotalPrice } from '../../Utils/tokenCalculation';
import ResaleStickyPriceComponent from './ResponsiveComponents/ResaleStickyPriceComp';
import WarningAlert from '../Alert/WarningAlert';
import notenant from "../../Assets/icons/no-tenant.svg"


export default function OverviewSection({propDetails}) {
  const [showStickyPriceSection,setshowStickyPriceSection]=useState(false) //note
  const priceSectionRef = useRef(null);//note


  const [isLoggedIn,setLoggedin]=useState(false)
  const [kycStatus,setKycStatus]=useState("")

  const userId=localStorage.getItem("user_id")
  const accestoken=localStorage.getItem("accestoken")

  const fetchUserDetails=()=>{
    getUserDetails(userId,accestoken,setLoggedin,setKycStatus,setUserEmail,navigate)
}

      
      useEffect(()=>{
        if(userId)
          {
              fetchUserDetails()
          }
      },[])

//note--------------
const location=useLocation()
const navigate=useNavigate()
const {id}=useParams()
const {property_info,invoice_info}=propDetails
const [tokenCount, setTokenCount] = useState(1);
const [formattedPrice, setFormattedPrice] = useState(0);
const [platformFee,setPlatformFee]=useState(1)
const [gstFee,setGstFee]=useState(1)
const initialTotalFee=parseFloat(property_info?.price_per_share) + platformFee + gstFee
const [totalFee,setTotalFee]=useState(initialTotalFee)
const [reachedTokenLimit, setReachedTokenLimit] = useState(false);
const [isUserEmail,setUserEmail]=useState("")
const [isEmailUpdated,setEmailupdated]=useState(true)
const [tokenLimitMob, settokenLimitMob] = useState(0);




 let isPropComingSoon=property_info?.sale_status

  const tokenLimit=property_info?.limit_per_share
const amenities=property_info?.amenities
const tenant=property_info?.tenants
const price = property_info?.price_per_share ;

const [accordionState, setAccordionState] = useState({
    'tab-one': false,
    'tab-two': false,
    'tab-three': false,
    'tab-four': false,
    'tab-five': false,
    'tab-six': false,
  });

  const [isExpandAll,setExpandAll]=useState(true)

  const toggleAccordion = (id, newState) => {
    setAccordionState({ ...accordionState, [id]: newState });
  };

  const expandAll = () => {
    const isAllExpanded = Object.values(accordionState).every(state => state === true);

    setExpandAll(isAllExpanded)

    if (isAllExpanded) {
      const collapsedState = {};
      for (const key in accordionState) {
        collapsedState[key] = false;
      }
      setAccordionState(collapsedState);
    } else {
      const expandedState = {};
      for (const key in accordionState) {
        expandedState[key] = true;
      }
      setAccordionState(expandedState);
    }
  };

  const projectSpecification=property_info?.project_specs


      const tabData = [{ label: "Schools" }, { label: "Hospitals" }, { label: "Gym" }];
      
      const [buyPriceMob, setbuyPriceMob] = useState(0);


    
      const handleToggleStickyPriceSection=()=>{
          setbuyPriceMob(propDetails.last_secondary_order_price);
          setshowStickyPriceSection(!showStickyPriceSection)
      }
      
    const handleClickOutside = (event) => {
      ClickOutside(event,priceSectionRef,setshowStickyPriceSection)
    };


  const handleClosePushSection=()=>{
      setshowStickyPriceSection(false)
  }

//ectract the element with property invoice_details
let invoiceDetails=invoice_info?.find(el=>el?.hasOwnProperty("invoice_details"))

//get the array
invoiceDetails=invoiceDetails?.invoice_details

//get the element with platformm handling fee name as an object
let phmArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="Platform Handling fee".toLowerCase())
const phm_percentage=phmArray?.invoice_price


let gstArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="gst".toLowerCase())
const gst_percentage=gstArray?.invoice_price




  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

const handleIncrement = () => {
  IncrementToken(tokenCount, setTokenCount, tokenLimit, setReachedTokenLimit);
};


const handleDecrement = () => {
  DecrementToken(tokenCount, setTokenCount, setReachedTokenLimit);
};

const PerTokenPrice = property_info?.price_per_share ;

useEffect(() => {
  const { totalPrice, calculatedPlatformFee, gst_fee, total_fee } = calculateTotalPrice(PerTokenPrice, tokenCount, phm_percentage, gst_percentage);

  setFormattedPrice(totalPrice.toLocaleString());
  setPlatformFee(calculatedPlatformFee);
  setGstFee(gst_fee);
  setTotalFee(total_fee);
}, [tokenCount, PerTokenPrice, phm_percentage, gst_percentage]);

// Calculate the total price
const totalPrice = price * tokenCount;



const handleProceed = () => {

  ProceedToPurchase(
    isLoggedIn,
    kycStatus,
    isUserEmail,
    navigate,
    tokenCount,
    totalPrice,
    formattedPrice,
    property_info,
    gstFee,
    platformFee,
    totalFee,
    id,
    PerTokenPrice,
    setEmailupdated,
    location
  
  )

  }
  const [propertyCity, setpropertyCity] = useState("")
const [propertyName, setpropertyName] = useState("")
useEffect(() => {
  if (property_info !== undefined) {
    setpropertyCity(property_info?.property_city)
    setpropertyName(property_info?.property_name.toLowerCase())
    settokenLimitMob(property_info?.limit_per_share)
  }
}, [property_info]);


  return (
<>

{
    !isEmailUpdated && (
        <WarningAlert message="Please Update Your Email"/>
    )
}
  



<div class="sticky-button sm:hidden">
        <button  onClick={handleToggleStickyPriceSection} className='w-full bg-secondary text-white py-2.5 px-4 rounded-md font-semibold text-base'>
            Buy Now
        </button>
    </div>



    {
    showStickyPriceSection && 
    (
      <ResaleStickyPriceComponent
      id={id}
      propertyCity={propertyCity}
      propertyName={propertyName}
      buyPriceMob={buyPriceMob}
      tokenLimitMob={tokenLimitMob}
      handleClosePushSection={handleClosePushSection}
      handleProceed={handleProceed}
      priceSectionRef={priceSectionRef}
      showStickyPriceSection={showStickyPriceSection}
      />
    )
}













    <div className="hs-accordion-group flex flex-col gap-4">

<div className="flex flex-row-reverse">
    <div onClick={expandAll} className='flex gap-2 cursor-pointer  items-center font-nunito font-medium border text-black border-lightgray rounded-md py-1 px-2.5' style={{fontSize:"12.73px"}}>
        <p>{isExpandAll?" Expand All":"Collapse All"}</p>
        <div>
        <IoArrowDown style={{fontSize:"18px",color:"#595757"}} />
        
        </div>
    </div>
</div>


{/****************** about-property *****************/}
     
      <AccordionItem id="tab-one" title=" About Property" isActive={accordionState['tab-one']} onToggle={toggleAccordion}>

      <p className=" text-justify px-2 text-xs sm:text-sm">
         {property_info?.property_description}
          </p>
  
      </AccordionItem>

{/*************  Project Specification ******************/}

<AccordionItem id="tab-two" title="Project Specification" isActive={accordionState['tab-two']} onToggle={toggleAccordion } >



<ul className='project-ul text-xs sm:text-sm'>
  {
    projectSpecification ?
    projectSpecification.map((el,id)=>(
      <li key={id}>• {el}</li>
    ))
    :
    "Project Specification is not available."
  }
   
  </ul>

      </AccordionItem>



{/***************** Amenities *************************/}

<AccordionItem id="tab-three" title="Amenities" isActive={accordionState['tab-three']} onToggle={toggleAccordion }>
<div className='grid grid-cols-4 sm:grid-cols-7 gap-2.5 sm:text-sm p-2'>

{
amenities && amenities.map((el,id)=>(
<div key={id}>

<div className='flex flex-col gap-2.5 items-center  sm:text-sm'>
<img src={`${PropertImageBaseUrl}/${el.icon.Key}`} alt="" style={{width:"31px"}} />
<p className='text-center'>{el.title}</p>
</div>




</div>

))
}



</div> 
       
       
       
      
      </AccordionItem>

      {/********  Tenant Overview ****************/}

    <AccordionItem id="tab-four" title="Tenant Overview" isActive={accordionState['tab-four']} onToggle={toggleAccordion }>
<div className='grid grid-cols-1  sm:grid-cols-3 gap-2 '>


{
  tenant && tenant.map((el,id)=>(
<div key={id} className='w-full border border-lightgray flex flex-col gap-4 px-2 py-5 w-1/3' style={{borderRadius:"10px"}}>

<div>
    <div className='flex justify-between'>
        <p>Tenant Name</p>
        <p className='font-semibold'>{el.tenant_name}</p>
    </div>

</div>


<div>
    <div className='flex justify-between'>
                    <p>Lock-in Period (Yrs)</p>
                    <p className='font-semibold'>{el.lock_in_period}</p>
        </div>

</div>

<div>
<div className='flex justify-between'>
        <p>Lease Period (Yrs)</p>
        <p className='font-semibold'>{el.lease_period}</p>
    </div>

</div>

<div>
<div className='flex justify-between'>
        <p>Price per sqft (INR)</p>
        <p className='font-semibold'>{el.price_per_sqft.toLocaleString()}</p>
    </div>

</div>


</div>
  ))
  
}




 
</div>

{
  tenant && tenant.length==0 &&
(
  <div className='w-full flex justify-center items-center'>

<div className='flex flex-col justify-center items-center'>

<img src={notenant} alt="no tenant" className='w-11' />
<p>No tenant is available</p>
<p>at the moment.</p>
</div>
</div>
)
}
       
        
      </AccordionItem>


      {/*********  Around this Property */}
      
      {/* <AccordionItem id="tab-five" title="Around this Property" isActive={accordionState['tab-five']} onToggle={toggleAccordion }>

      <AroundPropertyTab tabs={tabData} />

      </AccordionItem> */}




<AccordionItem id="tab-six" title="Floor Plans" isActive={accordionState['tab-six']} onToggle={toggleAccordion }>


<ImageCarousel propDetails={propDetails} />


</AccordionItem>
    


    </div>

    </>
  );
}


