import React, { createContext, useContext, useState } from 'react';
const ResalePropertyFiltersContext = createContext();
export const useResalePropertyFilters = () => useContext(ResalePropertyFiltersContext);
export const initialPropertyFiltersResale = {
  
  filtersByString: {
    "property_type": null,
    "resale_status": null,
    "sale_type": null
  },
  filterByDate: {
      "from": "2023-03-12 00:00:00",
      "to": "2024-03-19 23:59:59"
  },
  filtersByPlaces: [],
  filtersByBuilders: [],
};
export const ResalePropertyFiltersProvider = ({ children }) => {
  const [ResalepropertyFilters, setResalePropertyFilters] = useState(initialPropertyFiltersResale);

  return (
    <ResalePropertyFiltersContext.Provider value={{ ResalepropertyFilters, setResalePropertyFilters }}>
      {children}
    </ResalePropertyFiltersContext.Provider>
  );
};



