import axios from "axios";
import { ApiBaseUrl } from "../Utils/urls";
import { changeDateFormat } from "../Utils/DateFormat";
import { indianStatesCode } from "../Utils/isoStateCode";
import { handlelogout } from "../Utils/logout";

//fetch user details
export const fetchUserDetailes=(
  userId,
  accessToken,
  setProfileLoading)=>{
  
    
  setProfileLoading  && setProfileLoading(true)
   return fetch(`${ApiBaseUrl}/fr-user/fetch-profile-and-kyc?user_id=${userId}`, 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken 
      }
    
    }
    
    )
      
  


  }
 
  function getStateLabel(code) {
    return indianStatesCode.find(state => state.code === code).label;
  }

//profile details update handler
  export const SaveChangeProfile = async (userProfileDetails,userId,accessToken,setIupdated,selectedProfilePic,setLoadingProfile) => {

    const formattedUserProfile = {
      ...userProfileDetails,
      dob: changeDateFormat(userProfileDetails.dob) ,
       profile_pic:selectedProfilePic,
      state: getStateLabel(userProfileDetails.state_short_code)
        
    };
  
  
    setLoadingProfile(true)
    axios.post(`${ApiBaseUrl}/fr-user/update-profile-kyc`,
   {
    user_id: userId,
    profile: formattedUserProfile
  },
  {
    headers: {
      Authorization: accessToken
    }
  }
   )
   .then((res)=>{
    setLoadingProfile(false)
    setIupdated(true)
    if(res.data.success){
      fetchUserDetailes(userId,accessToken,undefined)
      .then(response => {
            
   
        return response.json();
      })
      .then((res)=>{
      
      console.log(res);
      if(res.success)
      {
       localStorage.setItem("user_name",res.data?.profile?.first_name)
       localStorage.setItem("user_mobile_no",res.data?.profile?.mobile)
      }
        
      })
      .catch((err)=>{
        console.log(err);
      })
    
    }
    setTimeout(() => {
      setIupdated(false);
     }, 2000);
    
    console.log(res.data);
   })
   .catch((err)=>{
    console.log(err);
   })
   
  };


  export const fetchUserAmlDetailes=(userId,accessToken)=>{
    return  axios.get(`${ApiBaseUrl}/fr-user/get-aml-details?user_id=${userId}`,{
        headers: {
          Authorization: accessToken, 
          'Content-Type': 'application/json', 
        },
      })
      
    }


//send otp to new email


export const EmailUpdateOnly = (
  updatedEmail,
  userProfileDetails,
  userId,
  accessToken,
  setOtpSentEmail,
  openModal,
  setEmailEditable,
  setAlreadyEmailExist,
  setUpdatedEmail
) => {
  if (updatedEmail !== userProfileDetails.email) {
    fetch(`${ApiBaseUrl}/fr-user/initiate-update-email-only`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken
      },
      body: JSON.stringify({
        email: updatedEmail,
        first_name: userProfileDetails.first_name,
        user_id: userId
      })
    })
      .then(response => {
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        return response.json();
      })
      .then(data => {
       
        localStorage.setItem("session_only_email", data.data.sessionForOTP);

        if (data.success) {
          //console.log(data)
          setOtpSentEmail(true);
          openModal();
          setEmailEditable(false);
          setTimeout(() => {
            setOtpSentEmail(false);
          }, 2000);
        } 
        if(!data.success) {
          setAlreadyEmailExist(true)
          //setUpdatedEmail("")
          setTimeout(() => {
            setAlreadyEmailExist(false);
            
          }, 3000);
        }
      })
      .catch(error => {
       
        console.error('There was a problem with your fetch operation:', error);
        
      });
  } else {
    setEmailEditable(false);
  }
};


//otp verification
export const OTPVerifyForEmailUpdate=
(
  userId,
  otp,
  userProfileDetails,
  accessToken,
  setEmailUpdated,
  closeModal,
  setOtpVerifyError,
  setEmailEditable

  )=>{
  axios.post(`${ApiBaseUrl}/fr-user/verify-update-email`,
  {
    user_id: userId,
      sessionForOTP: localStorage.getItem("session_only_email"),
      answer: otp, //OTP provided by the user
      first_name: userProfileDetails.first_name
  },
  {
    headers: {
      Authorization:accessToken
    }
  }
  )
  .then((res)=>{
    console.log(res.data);
  
    if(res.data.success)
    {
      localStorage.setItem("user_email",res.data.data.userData.email)
      localStorage.setItem("user_name",res.data.data.userData.user_name)
        setEmailUpdated(true)
        setTimeout(() => {
              setEmailUpdated(false);
              
            }, 2000);
            setEmailEditable(false);
            closeModal()
    }
  
  })
  .catch((err)=>{
    setOtpVerifyError(true)
  
    setTimeout(() => {
      setOtpVerifyError(false);
      
    }, 2000);
    console.log(err);
  })
  
  }


//for enable property details proceed button
 export  const getUserDetails=(userId,accestoken,setLoggedin,setKycStatus,setUserEmail,navigate)=>{
    fetch(`${ApiBaseUrl}/fr-user/fetch-profile-and-kyc?user_id=${userId}`, 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accestoken 
      }
    
    }
    
    )
      .then(response => {
      
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
       
        return response.json();
      })
      .then(resdata => {
      

        if (!resdata.success && resdata.data === 'INVALID TOKEN OR USER ID MISMATCH') {
           
          handlelogout(navigate); 
        }
       //console.log('Response from server:', resdata);
       
          if(resdata.success )
          {
          
           
                setLoggedin(true) 
              
                setUserEmail(resdata.data.profile.email) 
                if(resdata.data.kyc!=null)
                {
                  setKycStatus(resdata.data.kyc?.kyc_status) 
                }
                
                
          }

        
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}