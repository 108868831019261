import React, { useEffect, useState } from 'react'
import FormContainer from './FormContainer'
import LoginCustomInput from './LoginCustomInput'

import { FaRegCheckCircle } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoginNavbar from '../LoginNavbar/LoginNavbar';



export default function VerificationDone(props) {
  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  const location = useLocation();
  const { first_name, email,from } = location.state || {};
 const navigate=useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
     
      window.location.replace(from || "/");
    }, 3000);

   
    return () => clearTimeout(timeout);
  }, []);


  const handleProceed=()=>{
    navigate(from || "/")
  }

  return (
    <>
      <LoginNavbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
    <FormContainer 

    isEmailTitle={true}
    emailTitle="Account Verification Done - You're Good To Go!"
    isfooterExist={false}
    issubTitle={false}
    
    >

  
    
    <div>
        <div  className='loginform flex flex-col gap-4'>
          <div className='flex flex-col gap-4'>

            <form className='flex flex-col gap-4'>
            <LoginCustomInput 
                      placeholder="Full Name"
                      value={first_name} 
                      
                     />
                      
    
   


    <div class="pt-2 relative mx-auto w-full">
        <input class="border w-full grow border-gray text-sm text-gray rounded-md  sm:rounded-lg   login-input"
            placeholder="Email Address" 
            value={email} 
           
            />
        



                    <FaRegCheckCircle className=' absolute right-0 top-0 mt-5 mr-4' style={{color:"#07BC0C" ,fontSize:"20px"}} />
              
      </div>
            </form>
                  
            </div>

       
               <button 
               onClick={handleProceed} 
                className={`loginbtn w-full text-sm  sm:text-base  font-semibold bg-secondary text-white`}>
                  Proceed
                  </button>
       
            
  </div>
    
              
    
            <hr className='hrClass' />
              </div>
    
    </FormContainer>
    </>
  )
}
