import React from 'react'
import nopurchase from "../../Assets/icons/no-transaction.png"
import { purchaseformatDate } from '../../Utils/purchaseHistDateFormat'
export default function PurchaseHistory({details}) {
 
  const {purchase_history}=details

  return (
    <div className='tab-shadow p-4 rounded-2xl '>

{/* <table className="purchase-table w-full" >
    <thead>
    <tr className=''>
        <th>SNo.</th>
        <th>Owner ID</th>
        <th>Purchase Time</th>
        <th>No. Of Tokens</th>
        <th>Price</th>
    </tr>
    </thead>
  

        <tbody className='purchase-scroll'>
            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>
            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>

            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>
            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>
            


        </tbody>


</table> */}


<table className="purchase-table w-full" >
    <thead>
    <tr className={` ${purchase_history && purchase_history.length==0?'text-lightgray':''} `}>
        <th>SNo.</th>
        <th>Owner ID</th>
        <th>Purchase Time</th>
        <th>No. Of Tokens</th>
        <th>Price</th>
    </tr>
    </thead>
  



        
       <tbody className='purchase'>
        



{
  purchase_history && purchase_history.length==0
  ?
  

 

  (
     <tr>
   <td colSpan="5" className="text-center py-5"> {/* Use colSpan="5" to span the entire row */}
     <div className='flex flex-col justify-center items-center text-gray'>
       <img src={nopurchase} alt="no purchase history" className='w-28' />
       <p >No purchase history available </p>
       <p>at the moment.</p>
     </div>
   </td>
 </tr>

 
 )
:
(
  purchase_history && purchase_history.map((el,index)=>(
    <tr>
    <td>{index+1}</td>
    <td>{el && el.user_id}</td>
    <td>{purchaseformatDate(el && el.transaction_date)}</td>
    <td>{el && el.number_of_shares}</td>
    <td>₹ {el && el.price.toLocaleString()}</td>
  </tr>
  ))

)


}

 
        
        </tbody>    
         
 
       


      


</table>





    </div>
  )
}
