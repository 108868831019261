import React, { useState, useEffect,useMemo,useRef } from 'react';
import './Index.css';
import SideNav from '../SideNav/Index';
import SortMenu from '../SortMenu/Index';
import { AiOutlineSearch } from 'react-icons/ai';
import { AiFillCaretDown } from 'react-icons/ai';
import PropertyCard from '../Cards/PropertyCard/Index';
import { IconContext } from 'react-icons';
import { useLocation, withRouter } from 'react-router-dom';
import { BsFilterLeft } from "react-icons/bs";
import { IoMdArrowBack } from "react-icons/io";
import { TfiFilter } from "react-icons/tfi";
import SuccessAlert from '../Alert/SuccessAlert';
import Navbar from '../Navbar/Index'
import Footer from '../../Components/MainFooter/Footer'
import MobileFooter from '../MainFooter/MobileFooter';
import { FetchPropertyApi } from '../../Utils/urls'
import { usePropertyFilters } from '../../Utils/filter';
import { debounce } from 'lodash';
import MobileFilter from '../MobileFilter/Index';
import { IoCheckmarkDone } from "react-icons/io5";
import InterestForm from '../InterestForm/Index';
import { BeatLoader } from 'react-spinners'
import  BankLoader  from "../BankDetails/BankLoader"
import { ApiBaseUrl } from '../../Utils/urls';




function PropertyPage(props) {
  const [properties, setProperties] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [sortOption, setSortOption] = useState('default');
  const [responseData, setResponseData] = useState([]);
  const debounceTimeout = useRef(null);
  const mobSectionRef = useRef(null);
  const FilterSectionRef=useRef(null);
  const [showMobHeight, setShowMobHeight] = useState(0);
  const [showFilterHeight, setshowFilterHeight] = useState(0);
const [showAlert, setshowAlert] = useState(false)
  const [isInterestFormOpen, setIsInterestFormOpen] = useState(false);
  const [currentInterestPropId, setcurrentInterestPropId] = useState(0);
  const [isLoading,setLoading]=useState(false)
  const [isnotUserIdPresent, setisnotUserIdPresent] = useState(false);
  const [isnotEmailVerified, setisnotEmailVerified] = useState(false);
  const [refreshPage, setrefreshPage] = useState(false);
  const [landingPageRefreshHelp, setlandingPageRefreshHelp] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

 

//for changing the position of sidenav after a certain height (start)
  const rightPanelRef = useRef(null);
  const [isFixed, setIsFixed] = useState(true);
  const [thresholdHeight, setThresholdHeight] = useState(0);
  const footerHelperRef = useRef(null);
  const [footerHelp, setfooterHelp] = useState(false);

  const currentDate = new Date();
  const oneYearAgo = new Date(currentDate); 
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  

  oneYearAgo.setHours(oneYearAgo.getHours() + 5);
  oneYearAgo.setMinutes(oneYearAgo.getMinutes() + 30);
  

  const fromDateString = oneYearAgo.toISOString().slice(0, 19).replace('T', ' ');
  currentDate.setHours(currentDate.getHours() + 5);
  currentDate.setMinutes(currentDate.getMinutes() + 30);
  const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setfooterHelp(true);
        } else {
          setfooterHelp(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1 // Change this threshold value as needed
      }
    );

    if (footerHelperRef.current) {
      observer.observe(footerHelperRef.current);
    }

    return () => {
      if (footerHelperRef.current) {
        observer.unobserve(footerHelperRef.current);
      }
    };
  }, []);

 

  const initialPropertyFilters = useMemo(() => {
    return {
      filtersByString: {
        "NFT_status": null,
        "SPV_status": null,
        "property_city": null,
        "property_type": null,
        "resale_status": null,
        "sale_status": null,
        "sale_type":null,
        "property_phase":null
      },
      filtersByDate: {
        "listing_date": {
          "from": fromDateString,
          "to": toDateString
        }
      },
      filtersByPrice: {
        "price_per_share": null
      },
      filtersBySearch: {
        "property_location": null
      },
      filtersByPlaces: [],
      filtersByBuilders: [],
      filterBySort: {
        "listing_date": null,
        "price_per_share": null
      },
      userType: ""
    };
  }, []);

  //localPropertyFilters get refereshed everytime we change any filter parameters.
  const { propertyFilters: localPropertyFilters } = usePropertyFilters();
  useEffect(() => {
    setPropertyFilters(localPropertyFilters);
  }, [localPropertyFilters]);

  const [propertyFilters, setPropertyFilters] = useState(initialPropertyFilters);
  

  const [isLoggedin, setisLoggedin] = useState(false); 
const [userId, setUserId] = useState(null);
useEffect(() => {
  // Check if there's a user ID and session LS in localStorage
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
  
  if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
    // If both exist, set isLoggedin to true and store the user ID in the state
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
  }
}, [isLoggedin]); // Run only once when the component mounts



useEffect(() => {
  const fetchProperties = async () => {
    try {
      setLoading(true);
      if (landingPageRefreshHelp || isFirstTime) {
        let apiEndpoint = `${ApiBaseUrl}/fr-property/fetch-property`;
        const userIdFromLocalStorage = localStorage.getItem('user_id');
        const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
        

        if (userIdFromLocalStorage) {
          apiEndpoint = `${ApiBaseUrl}/fr-property/fetch-property?user_id=${userIdFromLocalStorage}`;
        }

        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(propertyFilters)
        });

        if (!response.ok) {
          throw new Error('Failed to fetch properties');
        }

        const responseData = await response.json();
        setProperties(responseData.data);
        setResponseData(responseData.data);
        setLoading(false);
        if (isFirstTime) {
          setlandingPageRefreshHelp(false);
        }
        if(landingPageRefreshHelp){
          setlandingPageRefreshHelp(false);
          setIsFirstTime(true);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching properties:', error);
    }
  };

  // Call the fetchProperties function
  fetchProperties();
}, [propertyFilters,isLoggedin,landingPageRefreshHelp, isFirstTime]);

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
  };
  useEffect(() => {
    const debouncedSearch = debounce((input) => {
      const filteredProperties = input.trim() === '' ? responseData : responseData.filter(property =>
        `${property.property_name} ${property.property_city} ${property.property_location} ${property.builder_name} ${property.price_per_share}`.toLowerCase().includes(input.toLowerCase())
      );
      setProperties(filteredProperties);
    }, 300);

    if (searchInput !== '') {
      debouncedSearch(searchInput);
    } else {
      setProperties(responseData);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, responseData]);



  // useEffect(() => {
    
  //   if (sortOption === 'date-newest') {
  //     const sortedProperties = [...responseData].sort((a, b) => {
  //       return new Date(b.listing_date).getTime() - new Date(a.listing_date).getTime();
  //     });
      
  //     // setProperties(sortedProperties);
  //     setPropertyFilters(prevState => ({
  //       ...prevState,
  //       filterBySort: {
  //           ...prevState.filterBySort,
  //           listing_date: "DESC"
  //       }
  //   }));
  //   }
   
    
  //   if (sortOption === 'date-oldest') {
  //     const sortedProperties = [...responseData].sort((a, b) => {
  //       return new Date(a.listing_date).getTime() - new Date(b.listing_date).getTime();
  //     });
      
  //     setProperties(sortedProperties);
  //   }
  //   else if (sortOption === 'price-asc') {
  //     const sortedProperties = [...responseData].sort((a, b) => parseFloat(a.property_price) - parseFloat(b.property_price));
  //     setProperties(sortedProperties);
  //   } else if (sortOption === 'price-desc') {
  //     const sortedProperties = [...responseData].sort((a, b) => parseFloat(b.property_price) - parseFloat(a.property_price));
  //     setProperties(sortedProperties);
  //   } else {
  //     setProperties(responseData);
  //   }
  // }, [sortOption, responseData]);


  const [isOpen, setIsOpen] = useState(false);
  const [sortOption1, setSortOption1] = useState('default');
 const [isOpenMob, setIsOpenMob] = useState(false)

  const handleSortChange = (option) => {
    setSortOption(option);
if(option === 'default'){
  setPropertyFilters(initialPropertyFilters)
}
 else if (option === 'date-newest') {
      
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date: "DESC",
            price_per_share: null
        }
    }));
}

 else if (option === 'date-oldest') {
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date: "ASC",
            price_per_share: null
        }
    }));
    }
    else if (option === 'price-asc') {
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date:null,
            price_per_share: "ASC"
        }
    }));
    } else if (option === 'price-desc') {
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date:null,
            price_per_share: "DESC"
        }
    }));
    } 


    
   
    setIsOpen(false); // Close the dropdown after selecting an option
  };
  const handleSortChangeMob = (option) => {
    setSortOption(option);
    setIsOpenMob(false); // Close the dropdown after selecting an option



    if (option === 'date-newest') {
      
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date: "DESC",
            price_per_share: null
        }
    }));
}

 else if (option === 'date-oldest') {
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date: "ASC",
            price_per_share: null
        }
    }));
    }
    else if (option === 'price-asc') {
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date:null,
            price_per_share: "ASC"
        }
    }));
    } else if (option === 'price-desc') {
      setPropertyFilters(prevState => ({
        ...prevState,
        filterBySort: {
            ...prevState.filterBySort,
            listing_date:null,
            price_per_share: "DESC"
        }
    }));
    } 


  };
  const selectedOptionText = {
    'default': 'Select',
    'date-newest':'Date of Listing (Newest First)',
    'date-oldest': 'Date of Listing (Oldest First)',
    'price-asc': 'Price Range (Low to High)',
    'price-desc': 'Price Range (High to Low)'
  }[sortOption];

  const optionText = {
    'default': ' Select Options',
    'date-newest':'Date of Listing (Newest First)',
    'date-oldest': 'Date of Listing (Oldest First)',
    'price-asc': 'Price Range (Low to High)',
    'price-desc': 'Price Range (High to Low)'
  };

  const [isOpenFilter, setisOpenFilter] = useState(false)
  useEffect(() => {
    const updateShowMobHeight = () => {
      if (mobSectionRef.current) {
        setShowMobHeight(mobSectionRef.current.getBoundingClientRect().height);
      }
    };
    

    window.addEventListener('resize', updateShowMobHeight);
    updateShowMobHeight();

    return () => window.removeEventListener('resize', updateShowMobHeight);
  }, [isOpenMob]);

  useEffect(() => {
    setShowMobHeight(isOpenMob ? 295 : 0); // Adjust height based on isOpenMob value
  }, [isOpenMob]);


  useEffect(() => {
    const updateShowFilterHeight = () => {
      if (FilterSectionRef.current) {
        setshowFilterHeight(FilterSectionRef.current.getBoundingClientRect().height);
      }
    };
    

    window.addEventListener('resize', updateShowFilterHeight);
    updateShowFilterHeight();

    return () => window.removeEventListener('resize', updateShowFilterHeight);
  }, [isOpenFilter]);

  useEffect(() => {
    setShowMobHeight(isOpenMob ? 275 : 0); // Adjust height based on isOpenMob value
  }, [isOpenMob]);

  const filterIconRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobSectionRef.current &&
        !mobSectionRef.current.contains(event.target) &&
        filterIconRef.current &&
        !filterIconRef.current.contains(event.target)
      ) {
        setIsOpenMob(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobSectionRef]);
  const [mobFilterHighlight, setmobFilterHighlight] = useState("");

  useEffect(() => {
    if (isOpenMob) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpenMob,isOpenFilter]);

const handleMobClearClick=()=>{
  if (turnOFF !== 0) {
    setturnOFF(0);
  } else {
    setturnOFF(1);
  }
setPropertyFilters(initialPropertyFilters);
}

const [turnOFF, setturnOFF] = useState(0);

const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);
const sideNavRef = useRef(null);

useEffect(() => {
  const handleResize = () => {
    setLeftPaneHeight(window.innerHeight);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const [isOneSecondOver, setisOneSecondOver] = useState(false);
useEffect(() => {
  const timer = setTimeout(() => {
    setisOneSecondOver(true);
  }, 1500);

  // Clear the timer when the component unmounts or when the state changes
  return () => clearTimeout(timer);
}, []); // Empty dependency array means this effect runs only once after initial render



if (isnotUserIdPresent) {
  window.location.href = '/login';
  return null; // Prevent rendering anything else
}

if(isnotEmailVerified){
  window.location.href='/user/profile';
  return null;
}


function getCookie(name) {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.startsWith(nameEQ)) {
      return cookie.substring(nameEQ.length);
    }
  }
  return null;
}





  return (
    <div className='parent font-nunito mt-16 '>


  {(isInterestFormOpen &&
    <div className="custom-popup-overlay paddingEnquiry" >
          <div className="custom-popup-content ">
            <div><InterestForm setIsInterestFormOpen={setIsInterestFormOpen} currentInterestPropId={currentInterestPropId} /></div>
          </div>
        </div>
    )}

    <div className='flex flex-col justify-between ' ref={FilterSectionRef}>
   
    <div>
    <div className={`flex flex-row justify-between px-4 py-3 ${isOpenFilter ? '' : 'openFilterTopMarg' }`}>
      <div className='flex flex-row gap-3'>
        <div className={`flex flex-col justify-center  ${isOpenFilter ? '' : 'openFilterHeight' }`}  >
        <IoMdArrowBack onClick={() => setisOpenFilter(!isOpenFilter)} />
        </div>
        <div className='text-base font-bold'>Filter</div>
      </div>
      <div className='text-sm font-normal' onClick={handleMobClearClick}>Clear All</div>
    </div>
    <div>

      <div className='flex flex-row justify-center'>
      <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
      </div>

      <div className={`${isOpenFilter ? '' : 'openFilterTopMargCase' }`}><MobileFilter turnOFF={turnOFF} setturnOFF={setturnOFF}/></div>
    </div>
    </div>

  

    <div className={`${isOpenFilter ? '' : 'hideFilterControl' }`} style={{ position: 'fixed', bottom: 15, width: '100%',zIndex:"999" }}>
      <div className={`flex flex-row justify-between px-4 ${isOpenFilter ? '' : 'openFilterBottomMarg' }`}>
        <div className='flex flex-row justify-center h-[44px] w-[150px]'>
          <div className='flex flex-col justify-center text-[14px] font-normal rounded-lg bg-white w-full borderCloseFilter cursor-pointer' onClick={() => setisOpenFilter(!isOpenFilter)} >
          Close
          </div>
        </div>
        <div className='flex flex-row justify-center h-[44px] w-[150px] text-white'>
          <div className='flex flex-col justify-center text-[14px] font-normal rounded-lg bg-secondary w-full cursor-pointer' onClick={() => setisOpenFilter(!isOpenFilter)}>
            Apply
          </div>
        </div>
      </div>
    </div>
    </div>
   
    <div className='w-100'><Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/> </div>
      <div className=' flex flex-row justify-center '>
        <div className='bg-white helperContainer helperContainerNew text-black flex flex-row'>
<div>

  {/* <div 
       ref={sideNavRef}
        className={`leftPaneCollection text-black flex flex-col ml-2 -mt-2 px-4 py-8 pb-12 ${isFixed ? 'fixed' : 'static'}`}
        style={{
          height: leftPaneHeight, 
          overflowY: 'auto'
        }}
 >
 <SideNav/>
 </div> */}


 <div 
  ref={sideNavRef}
 className={`leftPaneCollection text-black flex flex-col ml-2 -mt-2 px-4 py-8 pb-12 ${footerHelp?"":"fixed"}  `}
  style={{ 
    height: footerHelp ? "1010px" : leftPaneHeight,
    maxHeight: "100%",
    overflowY: 'auto' 
  }}
  
  >

  <SideNav/>
  </div>






          </div>
          
          <div className={`paddingHelperPhone  ${isOpenFilter ? 'jaja' : ''} ${footerHelp ? 'helpUnleft' : 'helpLeft'}`}>


          {/* ref={rightPanelRef} */}

          <div  ref={rightPanelRef}   className={`bg-white rightPaneCollection text-black flex flex-col sm:pl-2 sm:pr-2 mt-1 ${ (footerHelp && window.innerWidth < 1400 )|| (footerHelp && window.innerWidth < 1300) ? "stabilityHelpOther":""}`}>

          <div className={`h-12 zIndHelper ${properties.length < 3 && window.innerWidth > 1100 ? "ml-12" : ""}`}>


            <div className='flex flex-row justify-between '>
              <div className={`bg-white text-2xl font-bold text-start propMargin ${properties.length<3 && window.innerWidth > 1100?"-ml-6":
                properties.length==3 && window.innerWidth > 1100?"ml-6":
                ""}`}>Properties</div>
              <div className='mobSortFitler flex flex-row justify-between mt-0.5 gap-2 w-16'>
                <div className='bg-white text-2xl font-bold text-start ' ref={filterIconRef}>
                  <BsFilterLeft  onClick={() => setIsOpenMob(!isOpenMob)} />    
                </div>
                <div className='bg-white text-2xl font-bold text-start'>
                  <TfiFilter className='h-4 mt-1 mr-1' onClick={() => setisOpenFilter(!isOpenFilter)}/>
                </div>
              </div>
            </div>
           

            {
              isOpenMob && <div className="overlay showOverlay" onClick={() => setIsOpenMob(false)}></div>
            }

              <div className={`mob-section rounded-t-lg ${isOpenMob ? 'showMob ' : 'hideMob'}`} ref={mobSectionRef} style={{ height: `${showMobHeight}px` }}>
   
                  <div className='bg-white pb-5 hideMob' >
                  <div className=' flex flex-row justify-center'>
                    <div className='h-1.5 text-[8px] w-60 mt-1.5 rounded-lg' style={{backgroundColor:"rgba(217, 217, 217, 1)"}}></div>
                  </div>
                  <div className=' text-start pl-4 pt-8 pb-3 text-base font-bold bg-white' style={{color:"rgba(93, 92, 92, 1)"}}>Sort by</div>
                  <div className='flex flex-row justify-center'>
                    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"90%" }}></hr>
                  </div>

                  {/* <div className='flex flex-row justify-between bg-white' onClick={() => {handleSortChangeMob('default');}} >
                  <div  className='py-3 px-4 text-start' style={{fontSize:'16px',fontWeight:'500',color:"rgba(93, 92, 92, 1)"}}>
                   Select Options
                  </div>
                 
                  </div> */}


                  <div className='flex flex-row justify-between bg-white' onClick={() => {handleSortChangeMob('date-newest');setmobFilterHighlight(1);}} >
                  <div  className='py-3 px-4 text-start' style={{fontSize:'16px',fontWeight:'500',color:"rgba(93, 92, 92, 1)"}}>
                    Date of Listing (Newest First)
                  </div>
                  {(mobFilterHighlight ==1 && <div className='mt-2 mr-6'><IoCheckmarkDone style={{ height: '30px', width: '30px', color: 'rgba(120, 120, 120, 1)' }} /></div> )}
                  
                  </div>

                  <div className='flex flex-row justify-center bg-white'>
                    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"90%" }}></hr>
                  </div>

                  <div className='flex flex-row justify-between bg-white' onClick={() => {handleSortChangeMob('date-oldest');setmobFilterHighlight(2)}} >
                    <div className='py-3 px-4 text-start' style={{fontSize:'16px',fontWeight:'500',color:"rgba(93, 92, 92, 1)"}}>
                      Date of Listing (Oldest First)
                    </div>
                    {(mobFilterHighlight ==2 && <div className='mt-2 mr-6'><IoCheckmarkDone style={{ height: '30px', width: '30px', color: 'rgba(120, 120, 120, 1)' }} /></div> )}
                  
                  </div>


                  <div className='flex flex-row justify-center bg-white'>
                    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"90%" }}></hr>
                  </div>

                  <div className='flex flex-row justify-between bg-white' onClick={() => {handleSortChangeMob('price-asc');setmobFilterHighlight(3);}}  >
                    <div className='py-3 px-4 text-start' style={{fontSize:'16px',fontWeight:'500',color:"rgba(93, 92, 92, 1)"}}>
                      Price Range (Low to High)
                    </div>
                    {(mobFilterHighlight ==3 && <div className='mt-2 mr-6'><IoCheckmarkDone style={{ height: '30px', width: '30px', color: 'rgba(120, 120, 120, 1)' }} /></div> )}
                  </div>

                  <div className='flex flex-row justify-center bg-white'>
                    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"90%" }}></hr>
                  </div>
                  <div className='flex flex-row justify-between bg-white' onClick={() => {handleSortChangeMob('price-desc');setmobFilterHighlight(4);}}  >
                    <div className='py-3 px-4 text-start' style={{fontSize:'16px',fontWeight:'500',color:"rgba(93, 92, 92, 1)"}}>
                      Price Range (High to Low)
                    </div>
                    {(mobFilterHighlight ==4 && <div className='mt-2 mr-6 '><IoCheckmarkDone style={{ height: '30px', width: '30px', color: 'rgba(120, 120, 120, 1)' }} /></div> )}
                  </div>
                  </div> 
            </div>

            
            </div>
        



            <div className={`bg-white searchCollection pl-2 pt-2 pb-2 h-12 z-10 -mt-9 -ml-2 ${properties.length<3 && window.innerWidth > 1100?"ml-4":
              properties.length==3 && window.innerWidth > 1100?"ml-4":""}`}>
              <div className='bg-white searchSpace gap-1.5 p-1.5'>
                <div className='bg-white flex flex-col justify-center'>
                  <div className=' searchCollect flex flex-col justify-center '>
                    <IconContext.Provider value={{ color: 'black' }}>
                      <div>
                        <AiOutlineSearch />
                      </div>
                    </IconContext.Provider>
                  </div>
                </div>
                <div className='text-base flex flex-col justify-center'>
                  <input
                  className='inputWidhtCont flex flex-column justify-center'
                    type='text'
                    placeholder="Search by Properties, Builder's name, Location"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    style={{ fontSize: '12px',fontWeight:'400' }} 
                  />
                </div>
              </div>





              <div className='flex flex-col'>
              <div className='sortSelection bg-white flex flex-row text-lg font-normal px-2 mr-1' style={{fontSize:'12px'}} onClick={() => setIsOpen(!isOpen)}>


      <div className='sortDropdown '>Sort by:</div>
      <div
        id='sortDropdown'
        style={{fontSize:'12px',fontWeight:'700'}}
        className={`sort-selectLocally  font-bold text-base w-42 -mt-1 ${isOpen ? 'open' : ''}`}
      >
      {optionText[sortOption]} 

      <div className='bg-white -ml-12' >
      <div onClick={() => handleSortChange('default')} className=' text-start hoverSortOptActive pl-2' style={{fontSize:'12px',fontWeight:'400'}}>
          Select Options
        </div>


        <div onClick={() => handleSortChange('date-newest')} className=' text-start hoverSortOptActive pl-2' style={{fontSize:'12px',fontWeight:'400'}}>
          Date of Listing (Newest First)
        </div>
        <div onClick={() => handleSortChange('date-oldest')} className=' text-start hoverSortOptActive pl-2' style={{fontSize:'12px',fontWeight:'400'}}>
          Date of Listing (Oldest First)
        </div>
        <div onClick={() => handleSortChange('price-asc')} className=' text-start hoverSortOptActive pl-2' style={{fontSize:'12px',fontWeight:'400'}}>
          Price Range (Low to High)
        </div>
        <div onClick={() => handleSortChange('price-desc')} className=' text-start hoverSortOptActive pl-2' style={{fontSize:'12px',fontWeight:'400'}}>
          Price Range (High to Low)
        </div>
        </div>

      </div>

      <div className='mt-2' onClick={() => setIsOpen(!isOpen)}>
        <IconContext.Provider value={{ color: 'black'}}>
          <div>
            <AiFillCaretDown />
          </div>
        </IconContext.Provider>
      </div>


      </div>
    </div>



            </div>

            
          


            {/* {properties.length === 0 && isOneSecondOver  && 
            
            (
              searchInput === '' ? (
                <div className='noPropContainer'>
                  <div className='noPropImg'></div>
                  <p className='noPropText text-gray'>No property is available</p>
                </div>
              ) : (
                <div className='noPropContainer'>
                   <div className='noPropImg'></div>
                  <p className='noPropText text-gray'>No property found.</p>
                </div>
              )
            )
            
            
            } */}
            

            {/* { isLoading ? (
              <div className="alertBar" style={{height:"100vh"}}> 
            
              <BankLoader/>
              </div>
            ) : (
              <div className='propertyContainer bg-white flex-wrap gap-5 mt-2'>
                {properties.map((property, index) => (
                  <PropertyCard
                  setshowAlert={setshowAlert}
                  isLoggedin={isLoggedin}
                  setisnotUserIdPresent={setisnotUserIdPresent}
                    setIsInterestFormOpen={setIsInterestFormOpen}
                    setcurrentInterestPropId={setcurrentInterestPropId}
                    key={index}
                    name={property.property_name}
                    place={property.property_city}
                    tokensSold={property.sold_shares}
                    totalTokens={property.total_shares}
                    type={property.property_type}
                    targetIRR={property.target_IRR}
                    grossYield={property.gross_yield}
                    capitalGain={property.capital_gain}
                    price={property.property_price}
                    minInvestment={property.price_per_share}
                    exactLocation={property.property_location}
                    id={property.property_id}
                    sale_type={property.sale_type}
                    sale_status={property.sale_status}
                    has_enquired={property.has_enquired}
                    is_wishlisted={property.is_wishlisted}
                    refreshPage={refreshPage}
                    setrefreshPage={setrefreshPage}
                    landingPageRefreshHelp={landingPageRefreshHelp}
                    setlandingPageRefreshHelp={setlandingPageRefreshHelp}
                    isnotEmailVerified={isnotEmailVerified}
                    setisnotEmailVerified={setisnotEmailVerified}
                    image={property.property_images[0]?.Key}
                  />
                ))}
              </div>
            )} */}


{/* check */}

{
  isLoading ? (
    <div className="alertBar" style={{height:"100vh"}}> 
            
    <BankLoader/>
    </div>
      ) : properties.length === 0 && isOneSecondOver  ? (
        searchInput === '' ? (
          <div className='noPropContainer'>
            <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property is available</p>
          </div>
        ) : (
          <div className='noPropContainer'>
             <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property found.</p>
          </div>
        )
      ) : (
        <div className={`propertyContainer bg-white flex-wrap gap-5 mt-2 ${footerHelp && window.innerWidth >1000 ? "ml-6" : ""}` }>
        {
        properties.map((property, index) => (
          <PropertyCard
          footerHelp={footerHelp}
          setshowAlert={setshowAlert}
          isLoggedin={isLoggedin}
          setisnotUserIdPresent={setisnotUserIdPresent}
            setIsInterestFormOpen={setIsInterestFormOpen}
            setcurrentInterestPropId={setcurrentInterestPropId}
            key={index}
            name={property.property_name}
            place={property.property_city}
            tokensSold={property.sold_shares}
            totalTokens={property.total_shares}
            type={property.property_type}
            targetIRR={property.target_IRR}
            grossYield={property.gross_yield}
            capitalGain={property.capital_gain}
            price={property.property_price}
            minInvestment={property.price_per_share}
            exactLocation={property.property_location}
            id={property.property_id}
            sale_type={property.sale_type}
            sale_status={property.sale_status}
            has_enquired={property.has_enquired}
            is_wishlisted={property.is_wishlisted}
            refreshPage={refreshPage}
            setrefreshPage={setrefreshPage}
            landingPageRefreshHelp={landingPageRefreshHelp}
            setlandingPageRefreshHelp={setlandingPageRefreshHelp}
            isnotEmailVerified={isnotEmailVerified}
            setisnotEmailVerified={setisnotEmailVerified}
            image={property.property_images[0]?.Key}
            propertyPhase={property.property_phase}
            propertSaleType={property.property_sale_type}
          />
        ))
        
        }
        </div>
      )}



{/* check end*/}



            {showAlert && (
              <div className='successIndex'> <SuccessAlert  message="Interest Saved!" /></div>
             
   
           )}    
  
            
          </div>
          <div ref={footerHelperRef} className="footerHelper"></div>
          </div>
        </div>
      </div>
      <div className={`${isOpenFilter ? 'jaja' : '' }`}>
        {/*Footer was here */}
        <Footer/>
        <MobileFooter/>
      </div>

    </div>
  );
}

export default PropertyPage;
