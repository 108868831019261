export const UserData =[
    {
        id:1,
        year:"Q1 19-20",
        PriceHsr:8000,
        PriceDel:8400,
    },
    {
        id:2,
        year:"Q2 19-20",
        PriceHsr:8200,
        PriceDel:8800,
    },
    {
        id:3,
        year:"Q3 19-20",
        PriceHsr:4300,
        PriceDel:4900,
    },
    {
        id:4,
        year:"Q4 19-20",
        PriceHsr:6000,
        PriceDel:6800,
    },
    {
        id:5,
        year:"Q1 19-20",
        PriceHsr:7600,
        PriceDel:7900,

    },
    {
        id:6,
        year:"Q2 19-20",
        PriceHsr:8200,
        PriceDel:7400,

    },
    {
        id:7,
        year:"Q3 19-20",
        PriceHsr:4300,
        PriceDel:4900,

    },
    {
        id:8,
        year:"Q4 19-20",
        PriceDel:8200,
        PriceHsr:8800,

    },


]