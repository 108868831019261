import React, { createContext, useContext, useState } from 'react';
const AvailablePropertyFiltersContext = createContext();
export const useAvailablePropertyFilters = () => useContext(AvailablePropertyFiltersContext);
export const initialPropertyFiltersAvailable = {
  
  filtersByString: {
    "property_type": null,
    "resale_status": null,
    "sale_type": null
  },
  filterByDate: {
      "from": "2023-03-12 00:00:00",
      "to": "2024-03-19 23:59:59"
  },
  filtersByPlaces: [],
  filtersByBuilders: [],
};
export const AvailablePropertyFiltersProvider = ({ children }) => {
  const [AvailablepropertyFilters, setAvailablePropertyFilters] = useState(initialPropertyFiltersAvailable);

  return (
    <AvailablePropertyFiltersContext.Provider value={{ AvailablepropertyFilters, setAvailablePropertyFilters }}>
      {children}
    </AvailablePropertyFiltersContext.Provider>
  );
};



