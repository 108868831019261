import React, { useEffect,useState } from 'react'
import "./paymentSchedule-style.css"
import { getPaymentScheduleData } from '../../Utils/paymentScheduleValue'


export default function PaymentSchedule({propDetails}) {

const [totalAmount, settotalAmount] = useState(0);
useEffect(() => {
  settotalAmount(propDetails?.payment_schedules?.[0]?.amount+propDetails?.payment_schedules?.[1]?.amount+propDetails?.payment_schedules?.[2]?.amount+propDetails?.payment_schedules?.[3]?.amount)
}, [propDetails])


const formatDate = (date) => {

   const dateRegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}\.\d{3}Z)?$/;

   if (typeof date === 'string' && dateRegex.test(date)) {
     const dateObj = new Date(date);
     const formattedDate = dateObj.toLocaleString('en-US', { month: 'short', year: '2-digit' });
     return formattedDate.replace(' ', "'"); 
   }
 
   return date;
 };
 


  return (
    <div className="flex font-nunito flex-col sm:flex-row gap-3.5 tab-shadow py-5  sm:py-5 sm:px-4 rounded-2xl">
      
      <div className='flex flex-col'>

       <p className='text-lg text-left mb-3'>
        <b>{propDetails?.property_info?.property_name
         ?.split(',')
         .pop()
         .trim()} {propDetails?.property_info?.property_city}
   </b>  <br />
        <span className='text-sm'>{propDetails?.property_info?.configuration}, {propDetails?.property_info?.property_name.split(' ')[0]}-{propDetails?.property_info?.block_no}, {"UNIT #"+propDetails?.property_info?.unit_no}</span> 
         </p>





<div className='flex flex-col'>

<div className='paymentTableHead'>
                <div>SNo.</div>
                <div className='descCol'>Description</div>
                {/* <div className='qtyCol'>Qty (BHK)</div>
                <div className='unitCol'>Unit (sqf)</div> */}
                <div className='priceCol'>Amount (IN ₹) </div>
                <div className='collectiondateCol'>Collection Dates</div>
            </div>
           
            {/* <div className='payDeatilsSec'>
               
               <div className='slNosec'></div>
               <div  className='descSec'>

                    BASIC PRICE INCLUDING PLC & FLOOR RISE CHARGES, CAR PARKING
                    CHARGES, INFRA DEVELOPMENT CHARGES, OTHER BUILDING
                    CHARGES & OTHERS - 65%
               </div>
               <div className='qtyCol'>  </div>
               <div className='unitCol'>

               <div className='flex flex-col text-left'>
                       <p className='text-sm text-gray'>2041 Sq ft.</p>  
                     <p className='text-base font-extrabold text-gray'> 3BHK</p>   
               </div>
               </div>

               <div className='priceCol'>
                  
               <div className='flex flex-col text-left'>
                       <p className='text-sm text-gray'>   ₹35,500</p>  
                     <p className='text-base font-extrabold text-gray'> 65%</p>   
               </div>
               
                  
                  </div>

               <div className='collectiondateCol'>
               Immediatly on Purchase
               </div>

            </div> */}

<div className='breakDownDiv'>



{/* sec-1 */}
            <div className='breakDownSec pt-2'>
               
               <div className='slNosec'>1.</div>
               <div  className='descSec'>
               <p>{propDetails?.payment_schedules?.[0]?.schedule_order}st payment schedule <br></br>
               {propDetails?.payment_schedules?.[0]?.description}</p>
               
               </div>
             

               <div className='priceCol'>₹ {propDetails?.payment_schedules?.[0]?.amount?.toLocaleString('en-IN')}</div>

               <div className='collectiondateCol'>

               <div className='flex flex-col'>
                <p>{formatDate(propDetails?.payment_schedules?.[0]?.due_date)}</p>
               
               </div>

               </div>

            </div>

<hr className='paymenthrClass' />
{/* sec-2 */}

<div className='breakDownSec'>
               
               <div className='slNosec'>2.</div>
               <div  className='descSec'>

               <p>{propDetails?.payment_schedules?.[1]?.schedule_order}nd payment schedule <br></br>
               {propDetails?.payment_schedules?.[1]?.description}</p>

               </div>

               <div className='priceCol'>₹ {propDetails?.payment_schedules?.[1]?.amount?.toLocaleString('en-IN')}</div>

               <div className='collectiondateCol'>

               <div className='flex flex-col'>
                <p>By  {formatDate(propDetails?.payment_schedules?.[3]?.due_date)}</p>
               
               </div>

               </div>

            </div>

            <hr  className='paymenthrClass'/>
{/* sec-3 */}

<div className='breakDownSec'>
               
               <div className='slNosec'>3.</div>
               <div  className='descSec'>

               <p>{propDetails?.payment_schedules?.[2]?.schedule_order}rd payment schedule <br></br>
               {propDetails?.payment_schedules?.[2]?.description}</p>
               </div>
              

               <div className='priceCol'>₹ {propDetails?.payment_schedules?.[2]?.amount?.toLocaleString('en-IN')}</div>

               <div className='collectiondateCol'>

               <div className='flex flex-col'>
                <p>By  {formatDate(propDetails?.payment_schedules?.[2]?.due_date)}</p>
              
               </div>

               </div>

            </div>
            
            <hr className='paymenthrClass' />
 {/* sec-4 */}



  {/* sec-5 */}


 {/* sec-6 */}

<div className='breakDownSec'>
               
               <div className='slNosec'>4.</div>
               <div  className='descSec'>
               <p>{propDetails?.payment_schedules?.[3]?.schedule_order}th payment schedule <br></br>
               {propDetails?.payment_schedules?.[3]?.description}</p>
               
               </div>
              

               <div className='priceCol'>₹ {propDetails?.payment_schedules?.[3]?.amount?.toLocaleString('en-IN')}</div>

               <div className='collectiondateCol'>

               <div className='flex flex-col'>
               <p>By {formatDate(propDetails?.payment_schedules?.[3]?.due_date)}</p>
               </div>

               </div>

            </div>

  {/* sec-7 */}



         



            </div>

<hr className='paymetSchHr' style={{marginTop:"20px"}}/>

{/* final token section*/}
<div  className='finalTokenSection'>

<div className="finalTitle">
Final Unit Token Price (Approximate)
</div>
<div className="finalAmount">
₹ {totalAmount?.toLocaleString('en-IN')}
</div>

</div>



<div className='paymentNoteSec'>
NOTE: {propDetails?.property_info?.payment_schedule_note}
</div>

<div className='generalCondition'>
<p className='text-gray text-base font-semibold text-left'>Please Note General Conditions :</p>

<div className='conditionsSec'>
   <p>1 {propDetails?.property_info?.payment_schedule_conditions?.[0]}</p>
   <p>2 {propDetails?.property_info?.payment_schedule_conditions?.[1]}</p>
   <p>3 {propDetails?.property_info?.payment_schedule_conditions?.[2]}</p>
   <p>4 {propDetails?.property_info?.payment_schedule_conditions?.[3]}</p>
   <p>5 {propDetails?.property_info?.payment_schedule_conditions?.[4]}</p>
</div>

</div>

</div>

       
            
            
        


           
       

        </div>
        </div>
  )
}
