import React from 'react';
import { MdCheckCircleOutline } from 'react-icons/md';

const SuccessAlert = ({ message }) => {
  return (
    
     
        <div className='alert-section'>
          <div className='custom-alert' role='alert'>
            <div className='flex gap-2 items-center'>
              <MdCheckCircleOutline style={{ color: '#07BC0C', fontSize: '24px' }} />
              <p className='text-xs'>{message}</p>
            </div>
          </div>
        </div>
      
    
  );
};

export default SuccessAlert;
