import { useState, useEffect } from 'react';
import { ApiBaseUrl } from '../../Utils/urls';

// Custom hook to fetch profile and KYC details
const useGetProfileAndKycDetails = () => {
    const userId=localStorage.getItem('user_id')
    const accessToken=localStorage.getItem('accestoken')


  const [profileData, setData] = useState(null);
  const [profileloading, setLoading] = useState(true);
  const [profileError, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      fetch(`${ApiBaseUrl}/fr-user/fetch-profile-and-kyc?user_id=${userId}`, {
        method: 'GET',
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
            //console.log(response.status)
            

           
            
        //   if (!response.ok) {
        //     throw new Error('Failed to fetch profile and KYC details');
        //   }
          return response.json();
        })
        .then((result) => {
          setData(result.data); 
          setLoading(false); 
        })
        .catch((err) => {
          setError(err);
          setLoading(false); 
        });
    };

    fetchData(); 
  }, [userId, accessToken]);

  return { profileData, profileloading, profileError }; // Return raw data
};

export default useGetProfileAndKycDetails;
