import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import emailSentIcon from "../../Assets/icons/email_sent.png"
import { IoCloseOutline } from 'react-icons/io5';
import "./popup.css"

export default function PopUp({showEmailsentOpoup,onClose,children,width}) {


    const handleClose = () => {
        onClose();
      };

  return (
    <div className="fixed  inset-0 z-50 flex items-center justify-center bg-modalbg" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className={`relative commonPopup bg-white rounded-2xl shadow-xl max-w-${width} w-full p-5`}>
        <div className='absolute top-2 right-2 cursor-pointer'>
          <IoCloseOutline style={{ fontSize: "24px", color: "#808080" }} onClick={handleClose} />
        </div>
        {children}
      </div>
    </div>
  )
}
