import React,{useState} from 'react'
import { ApiBaseUrl } from '../../../Utils/urls'
import ConfirmWeb from '../../../Assets/ConfirmWeb.svg'


function MobResaleConfirmPopUp(props) {

    const handleClick =()=>{
      props.setPropertyIdForListingApi(0)
      props.setuserIdForListingApi("")
      props.setaccessTokenForListingApi("")
      props.setnoOfTokensForListingApi(0)
      props.setcurrentPriceForListingApi(0)
      props.setResalePopupOpen(true);
      props.setshowConfirmPopup(false);
    }



   const handleSuccessListing = async () => {
        const url = `${ApiBaseUrl}/fr-resale/add-sales-offer`;
        const body = {
            user_id: props.userIdForListingApi,
            user_property_id: props.PropertyIdForListingApi,
            number_of_tokens_for_resale: props.noOfTokensForListingApi,
            sale_price: props.currentPriceForListingApi
        };
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': props.accessTokenForListingApi
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            });
            const data = await response.json();
            props.setsuccessListing(true);
            props.setapiUpdateFlag(!props.apiUpdateFlag)
            props.setIsOpenMob(false);
            props.setResalePopupOpen(false);
            props.setshowConfirmPopup(false);

            setTimeout(() => {
                props.setsuccessListing(false);
            }, 3000);
        } catch (error) {
            console.error('Error:', error);
        }
    };




  
  return (
    <div className='px-[24px] py-[30px]  flex flex-col justify-between font-nunito'>

    <div className=' flex flex-row justify-center'><img className=' w-[110px] h-[80px]' src={ConfirmWeb} alt="clock_PropFTX"/></div>
    
    <div className=' mt-[33px] font-normal text-[16px]'>
    Please confirm the token listing on the resale marketplace. You can cancel the resale at any time.
    </div>


    <div className=' flex flex-col justify-between mt-[34px]'>
        <div className=' h-[47px] px-[65px] py-[12px] rounded-md text-[16px] font-bold text-white bg-[#D328A7] cursor-pointer' onClick={handleSuccessListing}>Yes, Confirm</div>
        <div className='mt-[18px] border h-[47px] px-[65px] py-[12px] rounded-md text-[16px] font-bold text-[#787878] border-[#787878] cursor-pointer' onClick={handleClick}>Cancel</div>
    </div>
   
    
    </div>
   
  )
}

export default MobResaleConfirmPopUp
