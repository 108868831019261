
// export const FetchPropertyApi = 'https://v0n58o03a2.execute-api.ap-south-1.amazonaws.com/Dev/fr-property/fetch-property';
// export const DefaultFilter= 'https://v0n58o03a2.execute-api.ap-south-1.amazonaws.com/Dev/fr-property/fetch-properties-default-filters'
export const ApiBaseUrl=process.env.REACT_APP_API_BASE_URL
export const PropertImageBaseUrl=process.env.REACT_APP_IMAGE_BASE_URL

export const googleClientId=process.env.REACT_APP_GOOGLE_CLIENT_ID



