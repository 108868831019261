import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoginNavbar from '../LoginNavbar/LoginNavbar';
import { FaChevronLeft } from 'react-icons/fa6';
import { OtpTab } from './OtpTab';
import FormContainer from './FormContainer';
import { ApiBaseUrl } from '../../Utils/urls';
import SignupLoader from './SignupLoader';
import ErrorAlert from '../Alert/ErrorAlert';
import SuccessAlert from '../Alert/SuccessAlert';

import { countries } from 'countries-list';
import { InitiateMobileUpdateWithoutEvent } from '../../Api/MobileNoUpdate';

export default function VerifyMobileOtp() {
  const location = useLocation(); 



  const { state } = location;

  const queryParams = new URLSearchParams(location.search); 
  function ensurePlus(queryParam) {
    return queryParam.replace(' ', '+'); 
  }

  const session = queryParams.get('session');
  const phoneNo = queryParams.get('phone');
  let CountryCode=queryParams.get('countrycode')

  
CountryCode=ensurePlus(CountryCode)

const [sessionForOtp,setSessionForOtp]=useState("")

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(true); 

  const [errorAlert, setShowErrorAlert] = useState(false);

  const navigate=useNavigate()

  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  const [accessToken, setaccessToken] = useState("")
  const UserId=localStorage.getItem('user_id')
  const accesstoken= localStorage.getItem('accestoken');
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [isLoading,setLoading]=useState(false) //otp is verified or not
  const [alertmsg,setAlertmsg]=useState("")
  const [isError,setError]=useState(false)
  const [isOtpVerified,setOtpVerified]=useState(false)
  const [isOtpSent,setOtpSent]=useState(false)
  

  const customLabels = {};


  for (const countryCode in countries) {
    const countryData = countries[countryCode];
    const primary = countryData.name;
    const secondary = '+' + countryData.phone[0]; 
    
    customLabels[countryCode] = { primary, secondary };
  }

useEffect(()=>{
  setSessionForOtp(session)
},[session])
  
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
    const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
      setaccessToken(AccessTokenFromLocalStorage);
    }
  }, [isLoggedin]); 

  useEffect(() => {
    if (otp.length === 4) { 
      setAllInputsFilled(true);
    } else {
      setAllInputsFilled(false);
    }
  }, [otp]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setTimerActive(false)
          
        }
        return prevTimer - 1;
      });
    }, 1000);
  
    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(interval);
  }, [timerActive]);

const MobileOtpReqBody={
  user_id:UserId,
  sessionForOTP:sessionForOtp,
  answer:otp
}


  const handleVerifyMobileUpdateOtp=(e)=>{
    e.preventDefault()
    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-user/verify-update-mobile`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(MobileOtpReqBody)
    }
    
    )
      .then(response => {
      
        
       
        return response.json();
      })
      .then(res => {
     
       // console.log('Response from server:', res);
       
        if(res.success)
        {
          setLoading(false)
          localStorage.setItem("user_mobile_no",res.data.data.mobile)
          localStorage.setItem("user_country_code",res.data.data.country_code)
          setOtpVerified(true)
          setAlertmsg(res.data.msg)
          
           setTimeout(()=>{
            setOtpVerified(false)
             setAlertmsg("")
             let redirectPath
             if(state){
              redirectPath=state.from.pathname
            }
            else if(!state){
              redirectPath= "/"
            }
             navigate(redirectPath)
            //  let redirectPath=state.from.pathname || "/"
            //  navigate(redirectPath)
           },3000)
        }
  
        if(!res.success)
        {
         
          setLoading(false)
         setAlertmsg(res.data.msg)
          setError(true)
          setTimeout(()=>{
            setAlertmsg("")
            setError(false)
          
          },3000)
         
        }
      })
      .catch(error => {
       
        console.error('error:', error);
       
      });
  }

  const mobileUpdateData = {
    phone_number: phoneNo,
    country_code:CountryCode,
    user_id: UserId
  };

const handleResend=()=>{
InitiateMobileUpdateWithoutEvent(
  setLoading,
  accesstoken,
  mobileUpdateData,
  setAlertmsg,
  setOtpSent,
  navigate,
  phoneNo,
  setError,
  CountryCode,setSessionForOtp,location
)

setTimer(60); 
setTimerActive(true)
}



  return (
    <>

{
  isLoading &&(
<SignupLoader/>
  )
}

{
  isError && (
    <ErrorAlert message={alertmsg}/>
  )
}

{
  isOtpVerified && (
    <SuccessAlert message={alertmsg}/>
  )
}


{
  isOtpSent && (
    <SuccessAlert message="OTP Sent Successfully"/>
  )
}

     <LoginNavbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
     <FormContainer 
title="Enter"
subtitle={<>Enter 4 digit OTP sent to ${phoneNo}
<Link  to= "/verify-mobile" >
  <span className='underline'>Edit</span>
</Link>

</>}
isfooterExist={false}
spantitle="OTP"
issubTitle={false}
isSmallSubTitle={true}
smalltitle={<span className='text-sm sm:text-lg font-normal'>Enter 4 digit OTP sent to <span className='text-secondary'>{phoneNo}</span>. <Link to="/verify-mobile"><span className='underline'>Edit</span></Link></span>}
>





  {/* {errorAlert && (<ErrorAlert message={alertMsg} />)}

  {showOtppAlert && (<SuccessAlert message="OTP Sent Successfully"/>)} */}

<div>
      <form onSubmit={handleVerifyMobileUpdateOtp} className='loginform flex flex-col gap-4'>
           

                  <OtpTab length={4} maxChar={1} setOtp={setOtp} otp={otp} setAllInputsFilled={setAllInputsFilled}/>
              
                  
                  <button   disabled={!allInputsFilled ||isLoading} type="submit" 
                className={`loginbtn text-base text-black font-semibold ${allInputsFilled ? 'bg-secondary text-white' : 'disable-btn-bg disabled'}`}>
                  {isLoading ? 'Verifying......' : 'Verify'}
                  </button>
              
            </form>

            <div className='flex flex-col gap-4 loginform pt-4 font-nunito'>
           

              {
                timerActive?
                <p className='text-sm'>00:{timer < 10 ? `0${timer}` : timer}</p> 
                  :
                  ""
              }
            
              {!timerActive && ( // Show resend section only when timer is active
            <div className='flex gap-5  items-center justify-center '>
              <p className='text-xs sm:text-lg font-normal text-gray'>Didn’t receive any code?
                <span className='text-xs sm:text-sm font-normal text-secondary cursor-pointer' onClick={handleResend}> RESEND</span> </p>
            </div>
          )}
            
        
              
          </div>
          
     <hr className='hrClass' />
          </div>

<Link to="/login">
<div className='flex items-center loginform justify-center text-blue'>
                <FaChevronLeft />
                <p className='text-xs sm:text-lg'>Back to login</p>
          </div>

</Link>
          

</FormContainer>
    </>
  )
}
