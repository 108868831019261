import React, { useState, useEffect } from 'react';
import { IoMdSearch } from "react-icons/io";
import { TfiFilter } from "react-icons/tfi";
import { SoldoutPropertyFiltersProvider, useSoldoutPropertyFilters } from '../../../Utils/soldoutPropFilter';
import SoldoutDashboardFilter from '../../SoldoutFilters/SoldoutFilterWeb';
import ResaleDashoardWebCard from '../../Cards/ResaleDashboardCards/ResaleDashboardWebCard/ResaleDashboardWebCard';
import { BeatLoader } from 'react-spinners'
import { initialPropertyFiltersSoldout } from '../../../Utils/soldoutPropFilter';
import { ApiBaseUrl } from '../../../Utils/urls';
import { debounce } from 'lodash';
import loader from "../../../Assets/icons/Loader.gif"

function SoldoutPropWeb() {
  const [searchInput, setSearchInput] = useState('');
  const [responseData, setResponseData] = useState([]);



  const [isOpenMob, setIsOpenMob] = useState(false);
  const togglePopup = () => {
    setIsOpenMob(!isOpenMob);
  };

  const { SoldoutpropertyFilters, setSoldoutPropertyFilters } = useSoldoutPropertyFilters();
  const [isLoading,setLoading]=useState(false)
  const [isPropertiesSame, setIsPropertiesSame] = useState(true);

  useEffect(() => {
    const compareProperties = () => {
      const keys = Object.keys(initialPropertyFiltersSoldout);
      for (const key of keys) {
        if (JSON.stringify(initialPropertyFiltersSoldout[key]) !== JSON.stringify(SoldoutpropertyFilters[key])) {
          setIsPropertiesSame(false);
          return;
        }
      }
      setIsPropertiesSame(true);
    };

    compareProperties();
  }, [SoldoutpropertyFilters]);



  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  const [accessToken, setaccessToken] = useState(null);
  const [propertiesData, setPropertiesData] = useState([]);

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
    const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');

    if (userIdFromLocalStorage ) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
      setaccessToken(AccessTokenFromLocalStorage);
    }
  }, [isLoggedin]);
  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate);
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 260);
  sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
  sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
  const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');
  
  currentDate.setHours(currentDate.getHours() + 5); 
  currentDate.setMinutes(currentDate.getMinutes() + 30); 
  const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');
  
  
const [startPrice, setstartPrice] = useState(0);
const [endPrice, setendPrice] = useState(50000);


  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedin && userId && accessToken) {
        setLoading(true);
        const url = `${ApiBaseUrl}/fr-resale/user-sold-properties`;
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken,
          },
          body: JSON.stringify({
            "user_id": userId,
            "filtersByString": SoldoutpropertyFilters.filtersByString,
            "filterByDate": SoldoutpropertyFilters.filterByDate,
            "filtersByPlaces": SoldoutpropertyFilters.filtersByPlaces,
            "filtersByBuilders": SoldoutpropertyFilters.filtersByBuilders,
            "filterByDate": {
              "from": fromDateString,
              "to": toDateString
            },
            "filtersByPrice": {
              "sale_price": {
                  "from": startPrice.toString(),
                  "to": endPrice.toString()
              }
           }
          }),
        };        
        try {
          const response = await fetch(url, requestOptions);
          if (response.ok) {
            const data = await response.json();
            setPropertiesData(data.data);
            setResponseData(data.data); 
            setLoading(false);
          } else {
            setLoading(false);
            console.error('Failed to fetch data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [isLoggedin, userId, accessToken, SoldoutpropertyFilters]);

  const handleInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
  };
  useEffect(() => {
    const debouncedSearch = debounce((input) => {
      const filteredProperties = input.trim() === '' ? responseData : responseData.filter(property =>
        `${property.property_name} ${property.property_city} ${property.current_price} ${property.purchased_price} ${property.number_of_owned_shares} ${property.property_location} ${property.builder_name} ${property.price_per_share}`.toLowerCase().includes(input.toLowerCase())
      );
      setPropertiesData(filteredProperties);
    }, 300);

    if (searchInput !== '') {
      debouncedSearch(searchInput);
    } else {
      setPropertiesData(responseData);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, responseData]);



  
  const [isOneSecondOver, setisOneSecondOver] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setisOneSecondOver(true);
    }, 1500);
  
    return () => clearTimeout(timer);
  }, []);
  

const [clearFilterSelection, setclearFilterSelection] = useState(false);

  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleClearClick=()=>{
    setclearFilterSelection(!clearFilterSelection);
    setSoldoutPropertyFilters(initialPropertyFiltersSoldout)
  }

  return (
    <div>
      <div className='flex flex-row justify-between mt-3 mb-3 belowThisDiv'>
        <div className='flex flex-row gap-2 px-1 py-2 searchBar justify-between'>

        <div className='flex flex-row gap-2'>
          <div><IoMdSearch style={{ height: "25px", width: "20px" }} /></div>
          <input
            type="text"
            className='text-[12px] w-64'
            placeholder="Search by Properties, Builder's Name, Location"
            value={searchInput}
            onChange={handleInputChange}
          />
        </div>
        </div>

      <div className='flex flex-row gap-4'>

      {!isPropertiesSame&&(
        <div className='flex flex-col justify-center cursor-pointer'>
          <div className=' flex flex-col justify-center h-7 px-5 py-1 text-white bg-secondary rounded-md cursor-pointer'   onClick={handleClearClick}>Clear</div>
        </div>
      )}
       

      {propertiesData.length > 0 && (
        <div className='flex flex-col justify-center'>
          <div className='mr-[21px] cursor-pointer' onClick={togglePopup}><TfiFilter style={{ height: "35px" }} /></div>
        </div>
      )}
      </div>

      </div>

      <div className={ `rounded-xl popup-containerBookedFilter  ${isOpenMob ? 'active' : ''}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' }}>
       <SoldoutDashboardFilter clearFilterSelection={clearFilterSelection}  isOpenMob={isOpenMob} setIsOpenMob={setIsOpenMob}/>
      </div>

      {/* {propertiesData.length === 0 && isOneSecondOver  && 
      
      (
        searchInput === '' ? (
          <div className='noPropContainer'>
            <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property has been booked yet.</p>
          </div>
        ) : (
          <div className='noPropContainer'>
             <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property found.</p>
          </div>
        )
      )
      
       
      } */}



{isLoading && !isOneSecondOver  ? (
         <div className='BookedloaderRelative'>
          
         <img src={loader} alt="Loader"  className='loaderBooked' />
         </div>
      ) : propertiesData && propertiesData.length=== 0 ? (
        searchInput === '' ? (
          <div className='noPropContainer'>
            <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No properties are sold out yet.</p>
          </div>
        ) : (
          <div className='noPropContainer'>
             <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property found.</p>
          </div>
        )
      ) : (
        <div className='bookedPropScroll pr-2'>  
        <div className=' mt-[20px]  flex flex-row justify-center gap-[20px]'>
  <div className='flex flex-col justify-center gap-[20px]' style={{width:"100%"}}>
        {Array.isArray(propertiesData) && propertiesData.map(property => (
          <ResaleDashoardWebCard key={property.user_property_id} 
          name={property.property_name} 
          location={property.property_location}
          city={property.property_city} 
          listableShares={property.number_of_available_shares}
          currentPrice={property.current_price}
          purchasedPrice={property.purchased_price}
          propertyId={property.user_property_id}
          userID={userId}
          capitalGain={property.capital_gain}

          />
        ))}
      </div>
      </div>
      </div>
      )}


    </div>
  );
}

export default SoldoutPropWeb;
