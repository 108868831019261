import React, { createContext, useContext, useState } from 'react';
const SoldoutPropertyFiltersContext = createContext();
export const useSoldoutPropertyFilters = () => useContext(SoldoutPropertyFiltersContext);
export const initialPropertyFiltersSoldout = {
  
  filtersByString: {
    "property_type": null,
    "resale_status": null,
    "sale_type": null
  },
  filterByDate: {
      "from": "2023-03-12 00:00:00",
      "to": "2024-03-19 23:59:59"
  },
  filtersByPlaces: [],
  filtersByBuilders: [],
};
export const SoldoutPropertyFiltersProvider = ({ children }) => {
  const [SoldoutpropertyFilters, setSoldoutPropertyFilters] = useState(initialPropertyFiltersSoldout);

  return (
    <SoldoutPropertyFiltersContext.Provider value={{ SoldoutpropertyFilters, setSoldoutPropertyFilters }}>
      {children}
    </SoldoutPropertyFiltersContext.Provider>
  );
};



