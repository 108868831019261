export function getPaymentScheduleData(property_id) {
    switch (property_id) {
      case 5:
        return {
          block:"BLOCK # 7",
          unit: "UNIT # 1005",
          first_amount: "54,000",
          second_amount: "17,340",
          third_amount: "13,000",
          others_amount: "5,560",
          total_amount: "89,900",
          bhk:"3BHK"
        };
  
      case 4:
        return {
          block:"BLOCK # 6",
          unit: "UNIT # 1403",
          first_amount: "35,500",
          second_amount: "11,400",
          third_amount: "8,550",
          others_amount: "4,200 ",
          total_amount: "59,650",
          bhk:"2BHK"
        };
  
      default:
       return {
          block:"BLOCK # 7",
          unit: "UNIT # 805",
          first_amount: "37,500",
          second_amount: "11,400",
          third_amount: "8,550",
          others_amount: "4,200 ",
          total_amount: "59,650",
          bhk:"2BHK"
        };
    }
  }