import { ApiBaseUrl } from "../Utils/urls";

export const InitiateMobileUpdate=
(e,
setLoading,
accesstoken,
mobileUpdateData,
setAlertmsg,
setOtpSent,
navigate,
phoneNo,
setError,

customLabels,
selected,
state
)=>
{

    e.preventDefault()
    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-user/initiate-update-mobile-only`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(mobileUpdateData)
    }
    
    )
      .then(response => {
      
        // if (!response.ok) {
        //   console.log("not ok");
        // }
       
        return response.json();
      })
      .then(res => {
     
        //console.log('Response from server:', res);
        setLoading(true)
        if(res.success)
        {
          setLoading(false)
          setAlertmsg(res.data.msg)
           setOtpSent(true)
           setTimeout(()=>{
             setAlertmsg("")
             setOtpSent(false)
             const sessionForOTP = res.data.sessionForOTP; 
            navigate(`/verify-mobile-otp?session=${sessionForOTP}&phone=${phoneNo}&countrycode=${customLabels[selected].secondary}`,{state});
           },2000)
        }
  
        if(!res.success)
        {
         
          setLoading(false)
         setAlertmsg(res.data.msg)
          setError(true)
          setTimeout(()=>{
            setAlertmsg("")
            setError(false)
          },3000)
         
        }
      })
      .catch(error => {
       
        console.error('error:', error);
       
      });
  
  }
  


//   initiate mobile no without event(e)

export const InitiateMobileUpdateWithoutEvent=
(
setLoading,
accesstoken,
mobileUpdateData,
setAlertmsg,
setOtpSent,
navigate,
phoneNo,
setError,
countryCode,
setSessionForOtp,
location

)=>
{

   
    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-user/initiate-update-mobile-only`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(mobileUpdateData)
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          console.log("not ok");
        }
       
        return response.json();
      })
      .then(res => {
     
        //console.log('Response from server:', res);
        setLoading(true)
        if(res.success)
        {
          setLoading(false)
          setAlertmsg(res.data.msg)
           setOtpSent(true)
           setTimeout(()=>{
             setAlertmsg("")
             setOtpSent(false)
             const sessionForOTP = res.data.sessionForOTP; 
             setSessionForOtp( res.data.sessionForOTP)

             const queryParams = new URLSearchParams(location.search);
           queryParams.set('session', sessionForOTP);

           window.history.replaceState(
            null, 
            '', 
            `${location.pathname}?${queryParams.toString()}` 
          );
            //navigate(`/verify-mobile-otp?session=${sessionForOTP}&phone=${phoneNo}&countrycode=${countryCode}`);
           },3000)
        }
  
        if(!res.success)
        {
         
          setLoading(false)
         setAlertmsg(res.data.msg)
          setError(true)
          setTimeout(()=>{
            setAlertmsg("")
            setError(false)
          },3000)
         
        }
      })
      .catch(error => {
       
        console.error('error:', error);
       
      });
  
  }


  export const VerifyMobileUpdateOtp=(e,
     setLoading,
     accesstoken,
     MobileOtpReqBody,
     setOtpVerified,
     setAlertmsg,
     navigate,
     setError
    )=>{
    e.preventDefault()
    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-user/verify-update-mobile`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(MobileOtpReqBody)
    }
    
    )
      .then(response => {
      
        return response.json();
      })
      .then(res => {
     
        //console.log('Response from server:', res);
       
        if(res.success)
        {
          setLoading(false)
          localStorage.setItem("user_mobile_no",res.data.data.mobile)
        localStorage.setItem("user_country_code",res.data.data.country_code)
          setOtpVerified(true)
          setAlertmsg(res.data.msg)
          
           setTimeout(()=>{
            setOtpVerified(false)
             setAlertmsg("")
             navigate("/")
           },3000)
        }
  
        if(!res.success)
        {
         
          setLoading(false)
         setAlertmsg(res.data.msg)
          setError(true)
          setTimeout(()=>{
            setAlertmsg("")
            setError(false)
          
          },3000)
         
        }
      })
      .catch(error => {
       
        console.error('error:', error);
       
      });
  }
  