import React, { useState } from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { PropertImageBaseUrl } from '../../Utils/urls';
import { MdOutlineContentCopy,MdOutlineFileDownload } from 'react-icons/md';
import { IoCheckmarkSharp } from 'react-icons/io5';



export default function TransactionRow(props) {
   
const {
    id,
    transaction_id,
    transaction_date,
    total_price,
    no_of_tokens,
    property_name,
    property_city,
    property_location,
    property_images,
    transaction_status,
    index,
    price_per_share
   
}=props


const [isCopied, setIsCopied] = useState(false);
const parsedDate = new Date(transaction_date);

const formattedDate = `${parsedDate.getDate()}/${parsedDate.getMonth() + 1}/${parsedDate.getFullYear()}`;


const getStatusButtonClass = () => {
    switch (transaction_status) {
        case 'CANCEL':
            return 'cancelBtn';
        case 'COMPLETED':
            return 'CompletedBtn';
        case 'PENDING':
            return 'PendingBtn';
        default:
            return '';
    }
}
const getTTransacrionStatus = () => {
    switch (transaction_status) {
        case 'CANCEL':
            return 'Cancelled';
        case 'COMPLETED':
            return 'Completed';
        case 'PENDING':
            return 'Pending';
        default:
            return '';
    }
}
const copyToClipboard = () => {
    navigator.clipboard.writeText(transaction_id);
    setIsCopied(true);
    setTimeout(() => {
        setIsCopied(false); 
    }, 3000);
};


  return (
    <div className='w-full flex flex-col lg:flex-row items-center  gap-4 sm:gap-0 rowShadow rounded-lg border border-lightgray p-3 md:p-2 lg:p-4'>
                       
                        <div className='pr-2 borderSytle titleDiv'>
                        <div className='flex items-center gap-2.5'> 
                        <div className='serialNobox'>
                            <p>{index+1}.</p>
                        </div>
                           
                            <img className='transactionImg'  src={`${PropertImageBaseUrl}/${property_images[0].Key}`} alt="" />
                            <div className='flex flex-col gap-1.5 propTitleSec'>
                            {/* {property_name.substring(0, 10)}...  */}
                                <p className='text-xs text-blue font-semibold text-left'> {property_name}</p>
                                <div className='flex gap-1 items-center'>
                                <FaLocationDot style={{fontSize:"10px"}} />
                                <p className='propLocation text-gray'>{ property_location},  {property_city}</p>
                                </div>
    
                            </div>
                        </div>
    
                        </div>
                       
    
                        <div className='detailsdiv'>
                            <div className='flex lg:flex-row flex-col gap-4 '> 
    
                                    <div className='flex  tokenPriceSec transactionMobile'>
    
                                        <div className='flex flex-col gap-1.5 text-left lg:text-center noOfTokenSec'>
                                                <p className='totalPCap text-gray font-medium'>No. of Tokens</p>
                                                <p className='totalP font-semibold'>{no_of_tokens}</p>
                                        </div>
                                        <div className='flex flex-col gap-1.5 text-left lg:text-center tokenpricebox'>
                                                <p className='totalPCap text-gray font-medium'>Token Price</p>
                                                <p className='totalP font-semibold'>
                                                    {/* ₹{total_price.toLocaleString()} */}
                                                    
                                                    ₹ {price_per_share &&  (Number(price_per_share)* no_of_tokens).toLocaleString()}
                                                    </p>
                                        </div>
    
                                    </div>
    
    
                    <div className='flex gap-2 transIdSec transactionMobile'>
    
                            <div className='flex flex-col gap-1.5 text-left lg:text-center transactionIdBox'>
                                    <p className='totalPCap text-gray font-medium'>Transaction ID</p>
                                    <div className='flex gap-2 items-center relative lg:mx-auto'>

                                        {isCopied && (
                                        <div className="transactiontooltip">
                                            Transaction ID is copied!
                                        </div>
                                    )}
                

                                        <p className='totalP font-semibold'>{transaction_id.substring(0, 8)}..</p>
                                        <button className="copyButton" onClick={() => copyToClipboard(transaction_id)}>
                                            {
                                               isCopied? (<IoCheckmarkSharp />):  <MdOutlineContentCopy />
                                            }
                                      
                                        
                                        </button>

                         
                                    </div>
                             </div>
                            <div className='flex flex-col gap-1.5 text-left lg:text-center transactionDateBox'>
                                    <p className='totalPCap text-gray font-medium'>Date</p>
                                    <p className='totalP font-semibold'>{formattedDate}</p>
                            </div>
    
                            </div>

                     </div>
                     </div>
    
    
                     <div className='flex gap-4 btnSec btndiv  items-center'>
                            <div className='flex justify-between w-full btndiv transactionMobile items-center gap-2'>
                                <button className={`viewInvoicebtn font-medium  ${getStatusButtonClass()}`}>
                            {getTTransacrionStatus()}
                                    </button>


                                   
                            {/* <a href={`${PropertImageBaseUrl}/${props.invoice.Key}`}>
                            <button className={`downloadInvoicebtn  ${transaction_status!=='COMPLETED'?'hidden':''}`}>
                                    Download Invoice
                                    </button>
                            </a> */}

                            {
                                transaction_status==='COMPLETED' && (
                                    <a href={`${PropertImageBaseUrl}/${props.invoice?.Key}`} target="_blank">
                               
                               <p className='text-xs text-gray'>Invoice</p>
                                 
                                  <div className='downloadInvoicebtn'>
                                    
                                  <MdOutlineFileDownload />
                                  </div>
                                  
                                    {/* <button className={`downloadInvoicebtn`}>
                                            Download Invoice
                                            </button> */}
                                    </a>  
                                )
                            }
                               

                            </div>
                        </div>
    
            </div>
    
  )
}
