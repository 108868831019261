import React, { useEffect, useState } from 'react'


import "./bank-style.css"

import { useNavigate,Link } from 'react-router-dom';
import CustomeBankInput from './CustomeBankInput';
import BankContainer from './BankContainer';
import { isValidBankAccNumber } from '../../Utils/bankAcoountFormat';
import { CheckValidIFSCCode } from '../../Utils/IFSCCodeCheck';
import { ApiBaseUrl } from '../../Utils/urls';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';
import { AddBankDetails } from '../../Api/BankDetailsapi';
import { IoMdArrowBack } from "react-icons/io";


export default function BankAddForm() {
  const [bankDetails,setBankDetails]=useState({})
  const [isValidAccount, setIsValidAccount] = useState(true); 
  const [isConfirmAccntMathced,setConfirmAccntMathced]=useState(true)
  const [isValidIfsc,setValidIfsc]=useState(true)

  const userId=localStorage.getItem("user_id")
  const accesstoken=localStorage.getItem("accestoken")

  const [isSubmitEnable,setSubmitEnable]=useState(false) //to enable/disable submit btn
const [alertMsg,setalertmsg]=useState("")
const [isbankAdded,setbankAdded]=useState(false)
const [isbankNotAdded,setbankNotAdded]=useState(false)

const navigate=useNavigate()

const handleInputChange=(e)=>{
    const { name, value } = e.target;
    
    setBankDetails({ ...bankDetails, [name]: value });
    if (name === "account_number") {
      
   
        setIsValidAccount(isValidBankAccNumber(value));
      }
      if(name==="ifsc_code")
      {
            setValidIfsc(CheckValidIFSCCode(value))
      }
      if(name==="confirm_number"){
                setConfirmAccntMathced(value===bankDetails.account_number)
      }
   
     
  }
  console.log(isSubmitEnable)
  console.log(bankDetails.ifsc_code!== '');
  
useEffect(()=>{
    setSubmitEnable(false)
},[])
  
  useEffect(() => {
    setSubmitEnable(
      bankDetails.ifsc_code  &&
      bankDetails.account_number &&
      bankDetails.confirm_number  &&
      isValidIfsc &&
      isValidAccount &&
      isConfirmAccntMathced
    );
  }, [bankDetails, isValidIfsc, isValidAccount, isConfirmAccntMathced]);


  const handleAddBankDetails=(e)=>{

        AddBankDetails(e,
            bankDetails,
            userId,
            accesstoken,
            setbankNotAdded,
            setalertmsg,
            setbankAdded,
            navigate)

}








  return (


    <>

{
    isbankAdded && (
        <SuccessAlert message={alertMsg}/>
    )
}

{
    isbankNotAdded && (
        <ErrorAlert message={alertMsg}/>
    )
}

    <BankContainer>
       <div>
      <div className='font-nunito'>





 <div className='flex sm:px-2.5 pb-4 items-center gap-2'>
  <Link to='/user/bank-details'>
  <IoMdArrowBack />
  </Link>

<div className='w-full text-center sm:text-left'>
<p className=' text-base text-center sm:font-medium sm:text-left font-bold'>Account Details </p>
 
</div>
  </div>

        <div className='profileSection sm:pl-7 font-nunito'>
<div className='flex flex-col gap-1 text-left py-3'>
        <p className='text-blue text-sm sm:text-lg font-bold'>Enter the Bank Account Details and IFSC code</p>
    <p className='text-gray text-xs sm:text-sm'>We will deposit ₹1 to verify your Bank Account Details.</p>
</div>


<form onSubmit={handleAddBankDetails}>
    <div className='flex flex-col gap-2.5'>
        {/* form */}
        <div className='flex flex-col gap-4'>

                <div className='flex  labeldiv-bank'>

                    <div className='flex w-full   md:w-full lg:w-1/2 flex-col gap-2.5 sm:pr-10 '>
                            <p className='text-left text-sm text-gray '>IFSC Code <span style={{color:"#c51f1f"}}>*</span></p>
                          <div>
                          <CustomeBankInput 
                            name="ifsc_code" 
                            value={bankDetails.ifsc_code}
                            onChange={handleInputChange}
                            placeholder="Enter IFSC Code"
                             />
                           
                           {!isValidIfsc && <p className='text-red text-sm'>Please enter a valid IFSC Code</p>}
                          </div>
                           
                    </div>

                   
                </div>


                <div className='flex  labeldiv-bank'>

                <div className='flex w-full  md:w-full lg:w-1/2 flex-col gap-2.5 sm:pr-10 '>
        <p className='text-left text-sm text-gray '>Enter your Account Number  <span style={{color:"#c51f1f"}}>*</span></p>
      <div>
      <CustomeBankInput 
        name="account_number" 
        value={bankDetails.account_number}
        onChange={handleInputChange}
        placeholder="Enter Account Number"
        numericInput={true}
        preventPaste={true}
        
         />
       
       {!isValidAccount && <p className='text-red text-sm'>Please enter a valid account number</p>}
      </div>
       
</div>


</div>


<div className='flex  labeldiv-bank'>

    <div className='flex w-full  md:w-full lg:w-1/2 flex-col gap-2.5 sm:pr-10 '>
       <p className='text-left text-sm text-gray '>Confirm Account Number  <span style={{color:"#c51f1f"}}>*</span></p>
    <div>
        <CustomeBankInput 
        name="confirm_number" 
        value={bankDetails.confirm_number}
        onChange={handleInputChange}
        placeholder="Enter Account Number"
        numericInput={true}
        preventPaste={true}
        />

{!isConfirmAccntMathced && <p className='text-red text-sm'>Account number does not match</p>}
    </div>

    </div>


</div>
      
        </div>

{/* web-btn */}
        <div className='hidden sm:flex  pt-10'>
            <button 
            type='submit' 
             className={`bankSubmitBtn ${isSubmitEnable? 'activebankBtn':'disablebankBtn'}`}
            disabled={!isSubmitEnable}
            >
              Add Details
            </button>
        </div>

{/* mobile-btn */}
        <div className='flex sm:hidden pt-10'>
            <button 
            type='submit' 
             className={`bankSubmitBtn ${isSubmitEnable? 'activebankBtn':'disablebankBtn'}`}
            disabled={!isSubmitEnable}
            >
              Add Details
            </button>
        </div>

     </div>
</form>
   
           
          </div>

</div>
    </div>
    </BankContainer>

    </>
  )
}
