import React, { useEffect, useState } from 'react'
import FormContainer from './FormContainer'
import LoginCustomInput from './LoginCustomInput'
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileAction, updateprofileFailure, updateprofileSuccess } from '../../Redux/updateProfileRedux/updateProfileAction';
import { isValidEmail } from '../../Utils/EmailValid';
import SuccessAlert from '../Alert/SuccessAlert';
import { OtpTab } from './OtpTab';
import { emailUpdateVerifyAction, emailVerificationFailure, emailVerificationSuccess } from '../../Redux/emailVerificationRedux/emailVerificationAction';

import {  useLocation, useNavigate } from 'react-router-dom';
import ErrorAlert from '../Alert/ErrorAlert';
import LoginNavbar from '../LoginNavbar/LoginNavbar';
import SignupLoader from './SignupLoader';


 function EmailVerifyOtp() {
  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  const [first_name,setFirstName]=useState("") 
  const [email, setEmail] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOtpSent,setOtpSent]=useState(false)
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false); 
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [isVerificationDone,setVerificationdone]=useState(false)
  const [isVerifyOtpSent,setVerifyOtpSent]=useState(false)
  const [isNameFilled,setNameFilled]=useState(true)
const [showErrorAlert,setShowErrorAlert]=useState(false)
  const navigate=useNavigate()
  const user_id=useSelector((store)=>store.otpVerificationReducer.userDetails?.user_id)
    // const accessToken=useSelector((store)=>store.otpVerificationReducer?.accessToken)
    const isEmailVerifying=useSelector((store)=>store.updateProfileReducer?.loading)
    const isVerifying=useSelector((store)=>store.emailVerificationReducer?.loading) 
const sessionForOTP=useSelector((store)=>store.updateProfileReducer?.sessionForOTP)
const isSucceed=localStorage.getItem("isSucceed")
const accessToken=localStorage.getItem("accestoken")

    const dispatch=useDispatch()
    const location = useLocation();

    const { state } = location;
    const { from } = state || {};






    


useEffect(() => {
  // Check if both first_name and email are filled and email is valid
  // setIsFormValid(first_name.trim() !== "" && isValidEmail(email));
  setIsFormValid( isValidEmail(email));
}, [first_name, email]);



useEffect(() => {
  const interval = setInterval(() => {
    setTimer((prevTimer) => {
      if (prevTimer === 1) {
        clearInterval(interval);
        setTimerActive(false)
        
      }
      return prevTimer - 1;
    });
  }, 1000);

  
  return () => clearInterval(interval);
}, [timerActive]);



  const handleUpdateprofile = (e) => {
    e.preventDefault();

    if (!first_name.trim()) {
      setNameFilled(false)
      return;
    }
    
    dispatch(updateProfileAction({
       user_id ,
      
          first_name,
          email,
      
  },accessToken))
    .then((res) => {

     dispatch(updateprofileSuccess(res.data));
      setOtpSent(true)
     
      setVerifyOtpSent(true)
      setTimerActive(true)
      setShowOtpSection(true);
      setTimeout(() => {
        setOtpSent(false);
      }, 2000);
     
    })
    .catch((err) => {
  
     
      dispatch(updateprofileFailure(err.message));
  
      
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);

    });
  };


  const handleVerifyUpdateEmail = () => {
   
    dispatch(emailUpdateVerifyAction({
      answer: otp,
      user_id,
      sessionForOTP,
      first_name
    },accessToken))
    .then((res) => {
     
      if(res.data.success)
      {
        localStorage.setItem("user_email",res.data.data.userData.email)
        localStorage.setItem("user_name",res.data.data.userData.user_name)
      }
     dispatch(emailVerificationSuccess(res.data));
      setVerificationdone(true)
      

    })
    .catch((err) => {
       setShowErrorAlert(true);
     dispatch(emailVerificationFailure(err.message));
     setTimeout(() => {
      setShowErrorAlert(false);
    }, 2000);
   });
  };





 // Handle resend click
 const handleResendClick = () => {
 
  handleUpdateprofile()
  setTimer(60); 
  setTimerActive(true)
};


const handleSkip=()=>{
  
navigate(from ||"/")

}


if (isVerificationDone) {
 
  navigate('/verification-done', { state: { first_name, email,from } });
}



  return (
    <>

    {


isEmailVerifying && (
  <SignupLoader/>
)
    }




{


isVerifying && (
  <SignupLoader/>
)
    }

    <LoginNavbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
    <FormContainer 

    isEmailTitle={true}
    emailTitle="Assist us by verifying your email, please."
    isfooterExist={false}
    issubTitle={false}
    
    >

      {
        isOtpSent && (<SuccessAlert message="OTP Sent Successfully"/>  )
      }
    

    {
      showErrorAlert && (<ErrorAlert message="Invalid OTP. Please Try Again!"/>  )
    }
    <div>
        <div  className='loginform flex flex-col gap-4'>
          <div className='flex flex-col gap-4'>
           
            <form className='flex flex-col gap-4' onSubmit={handleUpdateprofile}>
            <LoginCustomInput 
                      placeholder="Full Name"
                      value={first_name} 
                      onChange={(e)=>setFirstName(e.target.value)}
                     />
                      
    
   


    <div class="pt-2 relative mx-auto w-full">
        <input class="border w-full grow border-gray text-sm text-gray rounded-md  sm:rounded-lg   login-input"
            placeholder="Email Address" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            />





{/* {(!isValidEmail(email) && email.trim() !== '') && (
        <p className='text-sm' style={{color:"#e23838",marginTop:"10px"}}>Please enter a valid email address.</p>
    )} */}
   
    {!isNameFilled && first_name.trim() === '' && (
        <p className='text-sm' style={{color:"#e23838",marginTop:"10px"}}>Please enter your name.</p>
    )}


      
        {isFormValid && ( 
                <button type="submit"  disabled={isOtpSent} class={`verifyBtn ${isEmailVerifying || isVerifyOtpSent?`verifyActivebtn`:`bg-secondary text-white`} absolute right-0 top-0 mt-5 mr-4`}
               >
                  { isEmailVerifying? "Verifying..":"Verify"}
                </button>
              )}



      </div>
            </form>
                  
            </div>

          
{
   showOtpSection && 
<>

<div className='flex flex-col gap-4'>                  
       <p>Enter 4 digit OTP </p>


        <OtpTab length={4} maxChar={1} setOtp={setOtp} otp={otp} setAllInputsFilled={setAllInputsFilled}/>                  
   

            <div className='flex flex-col gap-2 loginform font-nunito'>
              {/* <p className='text-sm'>00:60</p> */}

              {
                timerActive?
                <p className='text-sm'>00:{timer < 10 ? `0${timer}` : timer}</p> 
                  :
                  ""
              }


            
              <div className='flex gap-5  items-center justify-center '>
                <p className='text-xs sm:text-lg font-normal text-gray'>Didn’t receive any code?  
                  <span className='text-xs sm:text-sm font-normal text-secondary cursor-pointer' onClick={handleResendClick}>
                   &nbsp; RESEND
                  </span>
                  </p>
                {/* <p className='text-xs sm:text-sm font-normal text-secondary'>RESEND </p> */}
              </div>
              
          </div>


          </div>

          {/* <button  onClick={handleVerifyUpdateEmail} disabled={!allInputsFilled ||isVerifying} type="submit" 
                className={`loginbtn text-sm  sm:text-base text-black font-semibold ${allInputsFilled ? 'bg-secondary text-white' : 'disable-btn-bg disabled'}`}>
                  {isVerifying ? 'Proceeding......' : 'Proceed'}
                  </button> */}

</>
  
}

<button  onClick={handleVerifyUpdateEmail} disabled={!allInputsFilled ||isVerifying} type="submit" 
                className={`loginbtn text-sm  sm:text-base text-black font-semibold ${allInputsFilled ? 'bg-secondary text-white' : 'disable-btn-bg disabled'}`}>
                  {isVerifying ? 'Proceeding......' : 'Proceed'}
                  </button>
     

<button onClick={handleSkip} className='skipbtn text-base text-blue font-normal'>Skip for Now</button>
       
             
                </div>
    
                <div className='flex flex-col gap-4 loginform pt-4'>
    
                  
    
    
                  
              </div>
    
            <hr className='hrClass' />
              </div>
    
    </FormContainer>
    </>
  )
}


const MemoizedEmailVerifyOtp = React.memo(EmailVerifyOtp);

export default MemoizedEmailVerifyOtp;