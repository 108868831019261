import React, { useState,useEffect } from 'react'
import { MdArrowBack } from 'react-icons/md'
import "./purchase-style.css"
import { IoMdArrowDropdown, IoMdInformationCircleOutline } from 'react-icons/io'

import { Link, useLocation,useNavigate } from 'react-router-dom'
import { RiArrowUpSFill } from 'react-icons/ri'
import CryptoJS from 'crypto-js';
import Navbar from '../Navbar/Index'

import { ApiBaseUrl } from '../../Utils/urls'
import loader from "../../Assets/icons/Loader.gif"
import Footer from '../MainFooter/Footer'
import MobileFooter from '../MainFooter/MobileFooter'
import WarningAlert from '../Alert/WarningAlert'
import { formatForUrl } from '../../Utils/formatSinglePropertyUrl'
import { FaRegQuestionCircle } from "react-icons/fa";
import successIcon from "../../Assets/icons/purchase_success.png"
import failedIcon from "../../Assets/icons/payment_icon/payment_failed_icon.svg"
import cancelIcon from "../../Assets/icons/payment_icon/paymemt_cancelled.svg"

import PurchaseModal from './PurchaseModal'
import PayNowOwnLaterPopup from './PayNowOwnLaterPopup'
import InfoPopup from '../InfoPopup/InfoPopup'

export default function PurchasePage() {

    const [showBreakdown,setShowBreakdown]=useState(false)
    const location = useLocation();
   
    
   const [grandTotal,setGrandTotal]=useState(0)
   

    const [isLoggedin, setisLoggedin] = useState(false); 
    const [userId, setUserId] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isePaymentLoading,setpaymentLoading]=useState(false)

    const [showExitTokenLimit,setShowExittokenLimit]=useState(false)
    const [ExitTokenLimitMsg,setExitTokenLimitMsg]=useState("")
    
    
    const [showPayLaterPopUp,setShowPayLaterPopup]=useState(false)
    const [showConsentPopUp,setConsentPopup]=useState(false)
    const [paymentStatus,setPaymentStatus]=useState("")
    const [showPayResultPopUp,setShowPayResultPopUp]=useState(false)
    const [isCancelLoading,setCancelLoading]=useState(false)
    const userID=localStorage.getItem("user_id")


    // INfo Popuo
    const [showTokenPricePopup, setShowTokenPricePopup] = useState(false);
    const [platformHandlingPopup,setShowPlatformPopup]=useState(false)
    const [gstPopup,setShowgstPopup]=useState(false)

    const handleclosetokenPriceInfoPopup=()=>{
      setShowTokenPricePopup(false)
    }

    const handleshowtokenPriceInfoPopup=()=>{
      setShowTokenPricePopup(true)
    }

    const handleClosePFMpopup=()=>{
      setShowPlatformPopup(false)
    }
    const handleShowPFMpopup=()=>{
      setShowPlatformPopup(true)
    }

    
    const handleCloseGstpopup=()=>{
      setShowgstPopup(false)
    }
    const handleShowGstpopup=()=>{
      setShowgstPopup(true)
    }

const handleShowPaylaterPop=()=>{
  setShowPayLaterPopup(true)
}

const handleClosePaylaterPop=()=>{
  setShowPayLaterPopup(false)
}

const handleShowConsentPop=()=>{
  setConsentPopup(true)
}

const handleCloseConsentPop=()=>{
  setConsentPopup(false)
}
  const navigate=useNavigate()

    const accesstoken=localStorage.getItem("accestoken")
    useEffect(() => {
      // Check if there's a user ID and session LS in localStorage
      const userIdFromLocalStorage = localStorage.getItem('user_id');
      const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
      
      if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
        // If both exist, set isLoggedin to true and store the user ID in the state
        setisLoggedin(true);
        setUserId(userIdFromLocalStorage);
      }
    }, [isLoggedin]); 



    useEffect(() => {
      const isFromPropertyDetails = sessionStorage.getItem('isFromPropertyDetails') === 'true';
  
      if (!isFromPropertyDetails) {
        navigate('/');
        return;
      }
  
      const handleCleanup = () => {
        // Only remove session storage if navigating away from checkout
        if (location.pathname !== '/purchase') {
          sessionStorage.removeItem('isFromPropertyDetails');
        }
       
      };
  
      // Attach event listeners for route changes
      const handlePopState = () => {
        handleCleanup();
      };
  
      window.addEventListener('popstate', handlePopState);
  
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, [location, navigate]);

    
    const { tokenCount,
         totalPrice,
        formattedPrice,
        lockinPeriod,
        gstFee,
        platformFee,
        totalFee,
        id,
        PerTokenPrice,
        propertyName,
        propertyCity,
       propertyLocation

     } 
        = location.state || {};



        const handleCheckboxChange = () => {
            setIsChecked(!isChecked);
          };

    const handleToggleShiwBeakDown=()=>{
        setShowBreakdown(!showBreakdown)
    }

  
      useEffect(()=>{
        setGrandTotal(totalFee)
      },[])


      let queryData={
        formattedPrice,
        lockinPeriod,
        gstFee,
        platformFee,
        totalFee,
        grandTotal,
        tokenCount,
      
        propertyId:Number(id)
      }



      const jsonQuery=JSON.stringify(queryData);

      const encryptedjsonQuery=CryptoJS.AES.encrypt(jsonQuery, 'PROPFTX_PAYMENT').toString();
      
      
     let currentUrl = window.location.href;

    

     //currentUrl=currentUrl.replace("/purchase", "/success")
          // Append the encrypted data to the existing query string
  //  currentUrl += `?payment=true&data=${encodeURIComponent(encryptedjsonQuery)}`;
  
       

      const redirectUrl=currentUrl

      


let paymentReqbody={
  "user_id": userId, 
  "property_id": Number(id), 
  "number_of_shares":tokenCount,
  "transaction_type": "BUY_PRIMARY_SHARES" 
}
const handleInitiatePayment=()=>{

  setpaymentLoading(true)
  fetch(`${ApiBaseUrl}/fr-payment/initiate-payment`, 
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken 
    },
    body: JSON.stringify(paymentReqbody)
  }
  
  )
    .then(response => {
    
     
      return response.json();
    })
    .then(res => {
      setpaymentLoading(false)
    if(res.success)

    {
      handlePayment(
        res.data.PAYU_MERCHANT_KEY,
        res.data.hash,
        res.data.txnid,
        res.data.productinfo,
        res.data.firstname,
        res.data.email,
        res.data.phone,
        res.data.amount,

      )

 
    }
    else if(!res.success)
    {
      
      setShowExittokenLimit(true)
            setExitTokenLimitMsg(res.data)
            setTimeout(() => {
              setShowExittokenLimit(false)
             
            }, 4000);
      
    }
   

     
    })
    .catch(error => {
     
      console.error('error:', error);
     
    });

}


      useEffect(() => {
        // Scroll to the top 
       
            window.scrollTo(0, 0);
        
      }, [location]);
         
   


const handleBack=()=>{
  navigate(`/${formatForUrl(propertyCity)}/${formatForUrl(propertyName)}/${id}`)
}

const getPayuTransStatus=(transId)=>{
  
  setpaymentLoading(true)
  fetch(`${ApiBaseUrl}/fr-payment/get-payu-payment-status`, 
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken 
    },
    body: JSON.stringify({
      "txnId": [transId]
  })
  }
  
  )
    .then(response => {
    
     
      return response.json();
    })
    .then(res => {
     // console.log(res)
      setpaymentLoading(false)
    if(res.success && res.data[transId] && res.data[transId].status === "success")
  {
    setShowPayResultPopUp(true)
    setPaymentStatus("SUCCESS")
   
  }
    else if(res.success && res.data[transId] && res.data[transId].status === "failure")
    {
      setShowPayResultPopUp(true)
      setPaymentStatus("FAILED")
    }
   

     
    })
    .catch(error => {
     
      console.error('error:', error);
     
    });

}



const updateUserCancelPayment=(transId)=>{
  
  setCancelLoading(true)
  fetch(`${ApiBaseUrl}/fr-payment/update-user-cancelled-payment`, 
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken 
    },
    body: JSON.stringify({
      "txnId": transId,
  "txnMessage":"",
  "txnStatus":"",
   "user_id":userID
  })
  }
  
  )
    .then(response => {
    
     
      return response.json();
    })
    .then(res => {
      //console.log(res)
      setCancelLoading(false)
      if(res.success){
        setShowPayResultPopUp(true)
       setPaymentStatus("CANCEL")
      }
     })
    .catch(error => {
      });

}




//PayU Function


const handlePayment = (
  key,
  hash,
  tnxID,
  productInfo,
  firstname,
  email,
  mobileNo,
  amount
) => {
  const data = {
    key: key,
    hash: hash, 
    txnid: tnxID,
    amount: amount,
    firstname: firstname,
    email: email,
    phone: mobileNo,
    productinfo: productInfo,
    surl: redirectUrl,
    furl: redirectUrl,
    display_lang: 'English',
    enforce_paymethod: "debitcard|netbanking|upi",
    // pg:"UPI|debitcard|netbanking",
    // drop_category:"creditcard"
}
   


  const handlers = {
    responseHandler: function (BOLT) {
       if (BOLT.response.txnStatus ==='SUCCESS') {
        getPayuTransStatus(BOLT.response.txnid)
      }
      if (BOLT.response.txnStatus === 'FAILED') {
        getPayuTransStatus(tnxID)
     }
      if (BOLT.response.txnStatus === 'CANCEL') {

        updateUserCancelPayment(tnxID)
        //console.log(BOLT.response);
      }
    },
    catchException: function (BOLT) {
    
     console.log('Payment failed. Please try again.');
     
    },
  };

  
  if (window.bolt) {
  window.bolt.launch(data, handlers);
  
  } else {
    console.log('BOLT script is not loaded yet.');
  }
};






const handleCloseModal = () => {
  if(paymentStatus==="SUCCESS")
  {
    navigate('/dashboard')
  }
  else if(paymentStatus==="FAILED"){
    setShowPayResultPopUp(false)
  }
  else if(paymentStatus==="CANCEL"){
    setShowPayResultPopUp(false)
  }

};

const handleRetryPayment=()=>{
  setShowPayResultPopUp(false)
  handleInitiatePayment()
}

const [countdown, setCountdown] = useState(5);

useEffect(() => {
  let timer;
  if (showPayResultPopUp && paymentStatus === "SUCCESS") {
    timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
  }
  return () => clearInterval(timer);
}, [showPayResultPopUp, paymentStatus]);

useEffect(() => {
  if (countdown === 0) {
    localStorage.setItem('previousRoute', window.location.pathname);
    navigate('/dashboard');
  }
}, [countdown]);

const successCloseBtn=()=>{
  localStorage.setItem('previousRoute', window.location.pathname);
  navigate("/dashboard")
}


  return (

    <>

{
    isCancelLoading && (
        <div className='loader-bankAbsolute'>
    <img src={loader} alt="Loader"  className='bankLoader' />
    </div>
    )
}





{
  showPayResultPopUp && (
    <PurchaseModal width={"max-w-md"} closeModal={handleCloseModal}>
    <div className='flex flex-col gap-5'>
        {
           paymentStatus && paymentStatus=="SUCCESS" && (
    <>
    <img src={successIcon} alt="success" className='successIconP' />
                <p className='text-secondary text-lg font-bold'>Congratulations!</p>
                <p className='text-gray  text-xs sm:text-lg'>
                You have purchased the tokens successfully. <br /> Redirecting to your dashboard within the next {countdown && countdown} seconds.</p>
               


<div className='flex justify-center'>
                <button onClick={successCloseBtn} className='bg-secondary text-white font-semibold redirectBtn rounded-md'>Go to dashboard</button>        

                </div>
                </>
            )


        }


{
    paymentStatus &&   paymentStatus=="FAILED" && (
      <>
      <img src={failedIcon} alt="success" className='successIconP' />
                  <p className='text-red text-lg font-bold'>Payment Failed</p>
                  <p className='text-gray text-xs sm:text-lg'>
               
                  We couldn't process your payment. Please retry. <br />
  If any amount is deducted from your bank account, 
  please reach out to us at <a href="mailto: support@propftx.com" style={{color:"#0470FB"}}>support@propftx.com</a> .
               </p>
                 
  
  
  <div className='flex justify-center'>
                  <button onClick={handleRetryPayment}  className='bg-secondary text-white font-semibold redirectBtn rounded-md'>Retry</button>        
  
                  </div>
                  </>


        )
}

{
    paymentStatus &&   paymentStatus=="CANCEL" && (

<>
    <img src={cancelIcon} alt="success" className='successIconP' />
                <p className='text-red text-lg font-bold'>Payment Cancelled</p>
                <p className='text-gray text-xs sm:text-base'>
                Your token purchase has been cancelled.
              </p>
               
      </>

        )
    }
     </div>
                
       
    </PurchaseModal>
  )

}


{
  showExitTokenLimit  && (
    <WarningAlert message={ExitTokenLimitMsg}/>
  )
}



{
    isePaymentLoading && (
        <div className='loader-bankAbsolute'>
    <img src={loader} alt="Loader"  className='bankLoader' />
    </div>
    )
}


    < div class="container mx-auto pt-20 font-nunito ">
    <Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
        <div className='flex flex-col gap-2.5 px-3 purchase-header'>

            <div className='flex gap-2.5 items-center cursor-pointer'>
{/* test */}
           

                <MdArrowBack style={{fontSize:"24px"}}  onClick={handleBack}/>
              
                
                
                <p className= 'text-lg font-normal sm:text-2xl sm:font-bold text-left text-blue'>Purchase Confirmation</p>

            </div>

            <hr  className='hrstyle'/>
        </div>

       
       <div className='purchaseSection flex pt-10 justify-between flex-col sm:flex-row'>
            <div className="leftPart ">

                    <div className="inerPart flex flex-col gap-2.5 sm:gap-10">

                        <div className='flex flex-row-reverse items-center justify-between sm:flex-col gap-2.5'>
                            <p className='text-sm font-semibold  sm:text-2xl sm:font-bold'>{tokenCount && tokenCount}</p>
                            <p className='text-sm  font-medium sm:text-base sm:font-normal'>Selected Tokens</p>
                        </div>

                        <div className='flex flex-row-reverse items-center justify-between sm:flex-col gap-2.5'>
                            <p className='text-sm font-semibold  sm:text-2xl sm:font-bold'>₹ {formattedPrice  && formattedPrice}</p>
                            <p className='text-sm  font-medium sm:text-base sm:font-normal'>Token Price </p>
                        </div>

                 <div className='flex flex-row-reverse items-center justify-between sm:flex-col gap-2.5'>
                     <p className='text-sm font-semibold  sm:text-2xl sm:font-bold'>{lockinPeriod && lockinPeriod} days</p>
                        <div className='flex gap-1 items-center text-base font-normal'>
                            <p> Lock-in Period</p>
                            

                    <div className="tooltip">
                           <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">

                                <p className='text-blue text-xs sm:text-sm'>What is Lock-in Period?</p>
                                <p className='text-gray text-xs sm:text-sm'>
                                The investor cannot sell their tokens during the lock-in period of 150 days.

                                     </p>
                              </span>
                          </div>



                            </div>
                        </div>

                    </div>

            </div>
            <div className="rightPart flex flex-col gap-4">

                <div className='calculationPart flex flex-col gap-4'>
                    <div className="grandTotal flex flex-col gap-2.5">
                            <div className='w-full items-center flex justify-between'>
                                <p className='text-secondary text-base sm:text-lg font-medium'>Grand Total</p>
                                <p className='text-blue text-sm sm:text-2xl font-bold'>₹ {grandTotal && grandTotal.toLocaleString()}</p>
                            </div>

                            <div className='w-full items-center flex justify-between'>
                                <p className='text-xs'>Total Token Value + Platform Fee + Taxes</p>
                                <div className='flex gap-1.5 text-secondary items-center cursor-pointer text-xs' onClick={handleToggleShiwBeakDown}>
                                    <p >{showBreakdown?"Hide":"Show"} Breakdown</p>
                                   {
                                    showBreakdown?
                        <IoMdArrowDropdown />
                                    :
                                    <RiArrowUpSFill />
                                   }
                                    
                                  
                                </div>
                            </div>

                    </div>

                <div className="feepart flex flex-col gap-4">

                    {showBreakdown && 
                    <>
                     <div className='flex w-full justify-between'>
                     <div className='flex gap-1 items-center'>
                         <p className='text-xs sm:text-sm'>Token Price</p>
                         <div className="tooltip">
                           <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">

                                <p className='text-blue text-xs sm:text-sm'>What is Token Price?</p>
                                <p className='text-gray text-xs sm:text-sm'>
                                      It is the total price of tokens held by the investor.
                                     </p>
                              </span>
                          </div>



<div className='block sm:hidden'>
<IoMdInformationCircleOutline style={{fontSize:"14px",color:"#d328a7"}} onClick={handleshowtokenPriceInfoPopup} />
    
</div>
                   
    {
    
        showTokenPricePopup && (
            <>
             
                            <InfoPopup>
                                <div className='p-2'>
                                <p className='text-blue text-left text-sm'>What is Token Price?</p>
                                    <p className='text-gray text-left text-sm'>
                                    It is the total price of tokens held by the investor.
                                </p>
                               
    
    
                                </div>
               
                                <button onClick={handleclosetokenPriceInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                    Ok</button>
                                
                                </InfoPopup>
            </>
        )
    }



                          </div>
                          <p className='text-xs sm:text-sm font-bold text-balance'>₹ {formattedPrice && formattedPrice}</p>
               </div>

                       

              <div className='flex w-full justify-between'>
                     <div className='flex gap-1 items-center'>
                     <p className='text-xs sm:text-sm'>Platform Handling Fee</p>
                     
                     <div className="tooltip">
                           <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">

                                <p className='text-blue text-xs sm:text-sm'>What is Platform Handling fee?</p>
                                <p className='text-gray text-xs sm:text-sm'>
                                PropFTX charges a platform handling fee of 2.5% on the token price.

                                     </p>
                              </span>
                          </div>

                          <div className='block sm:hidden'>
<IoMdInformationCircleOutline style={{fontSize:"14px",color:"#d328a7"}} onClick={handleShowPFMpopup} />
    
</div>

{
    
    platformHandlingPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Platform Handling fee?</p>
                                <p className='text-gray text-left text-sm'>
                                PropFTX charges a platform handling fee of 2.5% on the token price.
                            </p>
                           


                            </div>
           
                            <button onClick={handleClosePFMpopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}





                 </div>
                     
                 <p className='text-xs sm:text-sm font-bold text-balance'>₹ {platformFee && Number(platformFee.toFixed(2)).toLocaleString()}</p>
                 </div>


          <div className='flex w-full justify-between'>
                 <div className='flex gap-1 items-center'>
                     <p className='text-xs sm:text-sm'>GST on (Platform Handling Fee)</p>
                     

                     <div className="tooltip">
                           <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltiptext">

                                <p className='text-blue text-xs sm:text-sm'>What is GST on Platform Handling fee?</p>
                                <p className='text-gray text-xs sm:text-sm'>
                                GST of 18% is charged on platform handling fees


                                     </p>
                              </span>
                          </div>

                          <div className='block sm:hidden'>
<IoMdInformationCircleOutline style={{fontSize:"14px",color:"#d328a7"}} onClick={handleShowGstpopup} />
    
</div>

{
    
    gstPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is GST on Platform Handling fee?</p>
                                <p className='text-gray text-left text-sm'>
                                GST of 18% is charged on platform handling fees
                            </p>
                           


                            </div>
           
                            <button onClick={handleCloseGstpopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}





                 </div>
                     <p className='text-xs sm:text-sm font-bold text-balance'>₹ {gstFee && Number(gstFee.toFixed(2)).toLocaleString()}</p>
                     
             
             </div>
             </>  
                    }
                   



{/* total fee */}
                <div className='flex w-full justify-between'>
                   
                        <p className='text-sm sm:text-sm font-bold text-blue'>Total</p>
                        
                        <p className='text-sm sm:text-sm  font-bold text-secondary'>₹{totalFee && Number(totalFee.toFixed(2)).toLocaleString()}</p>
                </div>
{/* total fee */}

                    </div>




                    <div className="legalPart w-full flex gap-4">

                        <span class="material-symbols-outlined protect-icon" >
                        verified_user
                        </span>

                        <div className='flex flex-col gap-2 text-left'>
                            <p className='text-xs sm:text-sm font-extrabold text-gray'>Your investment is under legal protection.</p>
                            <p className='text-xs  text-gray'>Rest assured, your investment is fully secured by legal measures. We'll promptly prepare the mutual legal agreement /investment certificate and send 
                                it to your registered email address .</p>
                                <p className='text-xs  text-gray '>
                                  {/* <span className='font-bold'> Note :</span>
                                We use the address provided in the KYC verification documents to generate the digital invoice for the purchase. */}
                                </p>
                        </div>


                    </div>

                </div>
{
  showPayLaterPopUp && (
<PayNowOwnLaterPopup  handleClosePaylaterPop={handleClosePaylaterPop} />
  )
}

{
  showConsentPopUp && (
    <PurchaseModal width={'max-w-4xl'} closeModal={handleCloseConsentPop}>


<div className='overflow-auto' style={{"height":"500px"}}>
    <p className='text-blue font-bold'>Consent to Share KYC Information for Loan Approval</p>
    
    <div className='flex flex-col gap-4 text-left text-gray text-sm '>
    
    <div>
    At PropFTX, we strive to provide seamless services to our users, including facilitating access to loan facilities for purchasing tokens. To expedite the loan approval process, we collaborate with trusted loan providers 
    who may require access to your Know Your Customer (KYC) information. Your consent to share this information
     is crucial for us to proceed with your loan application.
    </div>
    
    
<div>
 <ul>
   <b>Details Shared:</b> 
      <li>Full Name</li>
      <li>Date of Birth</li>
      <li>Residential Address</li>
      <li>Identification Document (e.g., Passport, Driver's License)</li>
      <li>Any additional KYC information deemed necessary for loan approval</li>
 </ul>
</div>
    

<div>
 <ul>
   <b>Purpose:</b> 
   <li>Sharing your KYC information will enable the loan facility vendor to assess your eligibility for the loan quickly and efficiently, thereby expediting the process of purchasing tokens on PropFTX</li>
  </ul>
</div>
    
    

<div>
 <ul>
   <b>Data Protection:</b> 
   <li> We prioritize the security and confidentiality of your personal information. Your data will be transmitted securely and handled with the utmost care to prevent unauthorized access or misuse.
   </li>
  </ul>
</div>
   


<div>
 <ul>
   <b>Withdrawal of Consent:</b> 
   <li> You have the right to withdraw your consent at any time by contacting our customer support team. However, please note that withdrawing 
    consent may affect the processing of 
   </li>
  </ul>
</div>
    
    
 <div className='flex  flex-col gap-4 items-center justify-center md:flex-row' >
  <button className='purchaseBtn bg-secondary text-white'>Yes, I agree</button>
  <button className='purchaseBtn bg-secondary text-white'>Visit Efundzz</button>
  </div>  
    
    
    </div>
    </div>
    
    </PurchaseModal>
  )
}






                <div className='btnPart flex flex-col mx-auto w-full justify-center gap-2.5  '>

                        <div className='flex gap-3 items-center text-left sm:text-center justify-center'>
                                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                             <p className='text-xs text-blue'>I hereby agree to buy token(s) and accept 
                             all the     <a href="https://www.propftx.com/terms-of-use/" target='_blank'>
                              <span className='text-secondary'>Terms of Use</span>
                              </a>  of PropFTX 
                              </p>
                        </div>

                        
                        <div className='flex gap-4 items-center w-full justify-center flex-col md:flex-row'>
                        <button onClick={handleInitiatePayment} className={`  payNowBtn ${isChecked  ?'bg-secondary text-white':'bg-lightgray text-black'}`} disabled={!isChecked || isePaymentLoading} 
                        
                        >Pay Now</button>

                       



{/* Loan Popup */}

{/* <div className='flex gap-2 items-center justify-center ownNowSec' >
<button onClick={handleInitiatePayment} className={`ownNowBtn  ${isChecked  ?'bg-secondary text-white':'bg-lightgray text-black'}`} disabled={!isChecked || isePaymentLoading} 
                        
                        >Pay Now</button>

<FaRegQuestionCircle className=' text-white ' />
</div>


<div className='flex gap-2 items-center justify-center ownNowSec' >
<button onClick={handleShowConsentPop} className={`ownNowBtn ${isChecked  ?'bg-secondary text-white':'bg-lightgray text-black'}`} disabled={!isChecked || isePaymentLoading} 
                        
                        >Own Now, Pay Later</button>

<FaRegQuestionCircle className='text-lg text-secondary cursor-pointer' onClick={handleShowPaylaterPop} />
</div> */}

                        </div>
                        
                </div>


            </div>
       </div>
       
        </div>

        <Footer/>
<MobileFooter/>
        </>
  )
}