import React from 'react'
import { Link } from 'react-router-dom'

export default function KycRejected() {
  return (
    <div>
      <div className='font-nunito'>

{/* <div className='px-2.5 pb-4'>
  <p className='text-base font-medium text-left'> KYC  Verification</p>
 </div> */}

 <div className='text-center w-full    pb-4'>
  <p className='text-2xl sm:text-base font-medium text-center sm:text-left'>KYC  Verification</p>
  </div>
<div className='profileSection  font-nunito'>
      <div className='flex flex-col'>
      
      <div className='flex justify-between'>
                 <p className='text-2xl text-blue font-bold'>Your KYC is Rejected!</p> 
                  {/* <div className='flex gap-4 items-center'>
                      <div>
                          <span class="material-symbols-outlined tip-icon">lightbulb</span>
                      </div>
                          <p className='text-gray text-xl font-normal'>Tips</p>
                  </div> */}
  </div>

   <div className='flex flex-col gap-4'>
           <div className='flex flex-col gap-2 text-left kycInnerDiv'>
              <p className='text-gray text-sm'>Uh-oh! Your KYC is rejected. Let's get it back in action again.</p>
                    
          </div>

       
           </div>

    {/* background-image */}
    <div className='approvedContainer'>
          
            
                    <div className='rejectedImg'></div>
                   
                    
                   </div>




<hr className='kychrClass' />

<div className='flex justify-end mt-5'>
{/* <p className='text-base text-gray'></p> */}
<Link to="/user/kyc"  className='w-full sm:w-1/4'>
<button className='bg-secondary text-white  exploreBtn'>Verify Again</button>

</Link>
</div>
           </div>
           
          </div>

</div>
    </div>
  )
}
