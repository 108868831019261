import React, { useEffect, useRef, useState } from 'react'
import SharePopup from '../SharePopup';
import { ApiBaseUrl } from '../../../Utils/urls';
import "../../PropertyDetails/index.css"
import nopurchase from "../../../Assets/icons/no-transaction.png"
import CommonAccordianTab from '../CommonAccordianTab';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { BiUpArrowAlt } from 'react-icons/bi';
import { MdOutlineShare } from 'react-icons/md';
import { FaRegHeart } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa6';
import InfoPopup from '../../InfoPopup/InfoPopup';
import { getUserDetails } from '../../../Api/ProfileApi';
import SuccessAlert from '../../Alert/SuccessAlert';
import { ClickOutside, DecrementToken, IncrementToken, ProceedToPurchase, calculateTotalPrice } from '../../../Utils/tokenCalculationResale';
import ResaleStickyPriceComponent from './ResaleStickyPriceComp';
import { getPropertyDetails } from '../../../Api/PropertyApi';





export default function GrowthTabMobile(props) {
    const location=useLocation()
  const [details,setPropertyDetails]=useState({})
    const {property_info,invoice_info}=details
  
    const [tokenCount, setTokenCount] = useState(1); 
    const [showSharePopup,setSharePopup]=useState(false)
    const [showStickyPriceSection,setshowStickyPriceSection]=useState(false)
    const [formattedPrice, setFormattedPrice] = useState(0);
    const [platformFee,setPlatformFee]=useState(1)
    const [gstFee,setGstFee]=useState(1)
    const initialTotalFee=parseFloat(property_info?.price_per_share) + platformFee + gstFee
    const [totalFee,setTotalFee]=useState(initialTotalFee)
    const [reachedTokenLimit, setReachedTokenLimit] = useState(false);
    const tokenLimit=details.property_info?.limit_per_share

    const [isLoggedIn,setLoggedin]=useState(false)
    const [kycStatus,setKycStatus]=useState("")

    const userId=localStorage.getItem("user_id")
    const accestoken=localStorage.getItem("accestoken")

    const [isUserEmail,setUserEmail]=useState("")
    const [isEmailUpdated,setEmailupdated]=useState(true)
    const [isPropComingSoon,setPropComingSoon]=useState("")

    const fetchUserDetails=()=>{
        getUserDetails(userId,accestoken,setLoggedin,setKycStatus,setUserEmail,navigate)
 }
        
        useEffect(()=>{
            if(userId)
                {
                    fetchUserDetails()
                }
        },[])
        


   const navigate=useNavigate()
    const {id}=useParams()
    
    const [showYieldInfoPopup, setShowYieldInfoPopup] = useState(false);
    const [showIRRInfoPopup, setShowIRRInfoPopup] = useState(false);
    const [showCapitalInfoPopup, setShowCapitalInfoPopup] = useState(false);

  const handleshowYieldInfoPopup = () => {
    setShowYieldInfoPopup(true);
  };
  const handleshowIRRInfoPopup = () => {
    setShowIRRInfoPopup(true);
  };
  const handleshowCapitalInfoPopup = () => {
    setShowCapitalInfoPopup(true);
  };

  const handlecloseYieldInfoPopup = () => {
    setShowYieldInfoPopup(false);
  };
  const handlecloseIRRInfoPopup = () => {
    setShowIRRInfoPopup(false);
  };
  const handlecloseCapitalInfoPopup = () => {
    setShowCapitalInfoPopup(false);
  };

  
//make this section reusable

//ectract the element with property invoice_details
let invoiceDetails=invoice_info?.find(el=>el?.hasOwnProperty("invoice_details"))
const PerTokenPrice = property_info?.price_per_share ;
//get the array
invoiceDetails=invoiceDetails?.invoice_details

//get the element with platformm handling fee name as an object
let phmArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="Platform Handling fee".toLowerCase())
const phm_percentage=phmArray?.invoice_price


let gstArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="gst".toLowerCase())
const gst_percentage=gstArray?.invoice_price

//make this section reusable end

    const handleIncrement = () => {
        IncrementToken(tokenCount, setTokenCount, tokenLimit, setReachedTokenLimit);
    };

   
    const handleDecrement = () => {
        DecrementToken(tokenCount, setTokenCount, setReachedTokenLimit);
    };

    //toggle show share popup
    const handleToggleSharePopup=()=>{
        setSharePopup(!showSharePopup)
    }

    const price = property_info?.price_per_share ;

useEffect(() => {
    const { totalPrice, calculatedPlatformFee, gst_fee, total_fee } = calculateTotalPrice(PerTokenPrice, tokenCount, phm_percentage, gst_percentage);

    setFormattedPrice(totalPrice.toLocaleString());
    setPlatformFee(calculatedPlatformFee);
    setGstFee(gst_fee);
    setTotalFee(total_fee);
}, [tokenCount, PerTokenPrice, phm_percentage, gst_percentage]);




const totalPrice = price * tokenCount;

const handleProceed = () => {
ProceedToPurchase(
    isLoggedIn,
    kycStatus,
    isUserEmail,
    navigate,
    tokenCount,
    totalPrice,
    formattedPrice,
    property_info,
    gstFee,
    platformFee,
    totalFee,
    PerTokenPrice,
    id,
    setEmailupdated,
    location,


    
    
)

    }
    
const [buyPriceMob, setbuyPriceMob] = useState(0);
const [propertyCity, setpropertyCity] = useState("")
const [propertyName, setpropertyName] = useState("")
const [tokenLimitMob, settokenLimitMob] = useState(0)
useEffect(() => {
  if (details !== undefined) {
    setpropertyCity(details.property_info?.property_city)
    setpropertyName(details.property_info?.property_name.toLowerCase())
    settokenLimitMob(details.property_info?.limit_per_share)
  }
}, [details]);


    
const handleToggleStickyPriceSection=()=>{
    setbuyPriceMob(details.last_secondary_order_price);
    setshowStickyPriceSection(!showStickyPriceSection)
}


const priceSectionRef = useRef(null);

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // clicks outside the price section close it
    const handleClickOutside = (event) => {
      ClickOutside(event,priceSectionRef,setshowStickyPriceSection)
    };

    const handleClosePushSection=()=>{
        setshowStickyPriceSection(false)
    }




const user_id=localStorage.getItem("user_id")
let  propertyDetailsURl=""
if(user_id)
{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}&user_id=${user_id}`

}
else{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}`
 
}

const fetchPropertyDetails = async () => {
    getPropertyDetails(propertyDetailsURl,setPropertyDetails,setPropComingSoon)
 };

useEffect(() => {
  fetchPropertyDetails();
}, [id]);





  return (


    <>

{
    !isEmailUpdated && (
        <SuccessAlert message="Please Update Your Email"/>
    )
}

    
    <div class="sticky-button">
        <button onClick={handleToggleStickyPriceSection} className='w-full bg-secondary text-white py-2.5 px-4 rounded-md font-semibold text-base'>
            Buy Now 
        </button>
    </div>







{
    showStickyPriceSection && 
    (

<ResaleStickyPriceComponent
id={id}
buyPriceMob={buyPriceMob}
propertyName={propertyName}
propertyCity={propertyCity}
tokenLimitMob={tokenLimitMob}
handleClosePushSection={handleClosePushSection}
handleProceed={handleProceed}
priceSectionRef={priceSectionRef}
showStickyPriceSection={showStickyPriceSection}
/>


    )
}

   <div className='relative'>
    <div className="mt-5 ">

    <CommonAccordianTab title="Purchase History" >

{/* <table className="purchase-table w-full " >
    <thead>
    <tr className='text-center' style={{fontSize:"8px"}}>
        <th>SNo.</th>
        <th>Owner ID</th>
        <th>Purchase Time</th>
        <th>No. Of Tokens</th>
        <th>Price</th>
    </tr>
    </thead>
  

        <tbody className='purchase-scroll-mobile text-center' style={{fontSize:"10px"}}>
            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>
            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>

            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>
            <tr>
                <td>1</td>
                <td>#12345</td>
                <td>26/2/2024</td>
                <td>3</td>
                <td>₹ 565464</td>
            </tr>
            


        </tbody>


</table> */}

<table className=" w-full" >
    <thead>
    <tr className='text-lightgray'>
        <th>SNo.</th>
        <th>Owner ID</th>
        <th>Purchase Time</th>
        <th>No. Of Tokens</th>
        <th>Price</th>
    </tr>
    </thead>
  

        
       <tbody>
        
    {/* this section should be centered */}
    <tr>
            <td colSpan="5" className="text-center py-5"> {/* Use colSpan="5" to span the entire row */}
              <div className='flex flex-col justify-center items-center text-gray'>
                <img src={nopurchase} alt="no purchase history" className='w-28' />
                <p >No purchase history available </p>
                <p>at the moment.</p>
              </div>
            </td>
          </tr>
        {/* this section should be centered */}  
        
        </tbody>    
         
 
            


      


</table>




</CommonAccordianTab>
    </div>



    </div>
    </>
  )
}
