import React, { useState,useEffect } from 'react'
import "./index.css"
import Navbar from '../Navbar/Index';
import OverviewSection from './OverviewSection';
import DocumentTab from './DocumentTab';
import PurchaseHistory from './PurchaseHistory';
import WhyToInvest from './WhyToInvest';
import FAQComponenet from './FAQComponenet';
import { MdOutlineLocationOn, MdOutlineShare } from "react-icons/md";
import GrowthTabMobile from './ResponsiveComponents/GrowthTabMobile';
import growthIcon2 from "../../Assets/icons/icons8_increase 1.svg"
import Growthicon from '../../Assets/icons/Growthicon';
import WhytoInvesticon from '../../Assets/icons/WhytoInvesticon';
import PaymentSchIcon  from "../../Assets/icons/paymentScheduleIcon.svg"
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BiUpArrowAlt } from 'react-icons/bi'
import SharePopup from './SharePopup'
import { ApiBaseUrl } from '../../Utils/urls';
import { useNavigate, useParams } from 'react-router-dom';
import PaymentSchedule from './PaymentSchedule';
import PaymentScheduleMobile from './PaymentScheduleMobile';
import TagResale from '../../Assets/tagResale.svg'
import { PropertImageBaseUrl } from '../../Utils/urls'
import ResalePartA from './ResalePartA';
import InfoPopup from '../InfoPopup/InfoPopup'


export default function TabsComponent(props) {

    const [activeTab, setActiveTab] = useState(1);
    const user_id = localStorage.getItem('user_id');
    const [isLoading,setLoading]=useState(false)
   
    const [isLoggedin, setisLoggedin] = useState(false); 
    const [userId, setUserId] = useState(null);
    const [details,setPropertyDetails]=useState({})
    const [isPresale,setPresale]=useState("") //resale status
    const [iscomingSoon,setComingSoon]=useState("")
    const {id}=useParams()
   
    useEffect(() => {
      // Check if there's a user ID and session LS in localStorage
      const userIdFromLocalStorage = localStorage.getItem('user_id');
      const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
      
      if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
        // If both exist, set isLoggedin to true and store the user ID in the state
        setisLoggedin(true);
        setUserId(userIdFromLocalStorage);
      }
    }, [isLoggedin]); // Run only once when the component mounts
   




    let  propertyDetailsURl=""
    if(userId)
    {
      propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}&user_id=${userId}`
    
    }
    else{
      propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}`
     
    }


    useEffect(() => {
      const fetchPropertyDetails = async () => {
        try {
          setLoading(true);
          const response = await fetch(propertyDetailsURl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
           
          });
          if (!response.ok) {
            throw new Error('Failed to fetch property details');
          }
          const res = await response.json();
          //console.log(data);
          setLoading(false)
          setPropertyDetails(res.data);
          setPresale(res.data.property_info.property_phase)
          // res.data.property_info.sale_status
          setComingSoon(res.data.property_info.sale_status)
         
        } catch (error) {
          console.error('Error fetching property details:', error);
          setLoading(false);
        }
      };
    
      fetchPropertyDetails();
    }, [id,userId]);
    


    const {property_info,invoice_info}=details
    const [propertyInfo, setPropertyInfo] = useState(property_info);
    const [showSharePopup,setSharePopup]=useState(false)
    const [isPropComingSoon,setPropComingSoon]=useState("")
    const navigate=useNavigate();
 
    const addToWishlist = () => {
      const userId = localStorage.getItem("user_id");
      if (userId) {
          // Make API request to add property to wishlist
          fetch(`${ApiBaseUrl}/fr-user/wishlist-property`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${localStorage.getItem("accestoken")}`
              },
              body: JSON.stringify({
                  "user_id": userId,
                  "property_id": id
              })
          })
          .then(response => response.json())
          .then(data => {
              console.log(data);
              if (data.success) {
                  
                  
                 fetchPropertyDetails()
              }
  
              // Handle success or error message as required
          })
          .catch(error => console.error('Error:', error));
      } else {
          // Redirect to login page if user is not logged in
          navigate('/login');
      }
  };

  
    
    const handleToggleSharePopup=()=>{
      setSharePopup(!showSharePopup)
  }

  const fetchPropertyDetails = async () => {
    setLoading(true);
    try {
     
      const response = await fetch(propertyDetailsURl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
       
      });
      if (!response.ok) {
        throw new Error('Failed to fetch property details');
      }
      setLoading(false)
      const res = await response.json();
      //console.log(data);
     // setLoading(false)
      setPropertyDetails(res.data);
      setPropComingSoon(res.data.property_info.sale_status)
      
    } catch (error) {

        //for handling internet connection error
        // if (error instanceof TypeError) {
        //    console.error('Network error or no internet connection:', error.message);
        //    alert("check your internet connection")
        //   }


      //console.error('Error fetching property details:', error);
      //setLoading(false);
    }
  };

useEffect(() => {


  fetchPropertyDetails();
}, [id]);

const [showYieldInfoPopup, setshowYieldInfoPopup] = useState(false);
const handleYieldInfoPopup = () => {
  setshowYieldInfoPopup(!showYieldInfoPopup);
};
const [showIRRInfoPopup, setshowIRRInfoPopup] = useState(false);
const handleIRRInfoPopup = () => {
  setshowIRRInfoPopup(!showIRRInfoPopup);
};
const [showPotentialGainInfoPopup, setshowPotentialGainInfoPopup] = useState(false);
const handlePotentialGainInfoPopup = () => {
  setshowPotentialGainInfoPopup(!showPotentialGainInfoPopup);
};
const [showTokenPriceInfoPopup, setshowTokenPriceInfoPopup] = useState(false);
const handleTokenPriceInfoPopup = () => {
  setshowTokenPriceInfoPopup(!showTokenPriceInfoPopup);
};

  return (


    <div className="w-full sm:pt-4">
    <Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
      {/* Tab buttons */}
      <div className="flex border-b border-lightgray hidden sm:block">

    
        
        <button
          className={` font-nunito rounded-none px-4 py-2  ${activeTab === 1 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black  text-base '}`}
          onClick={() => setActiveTab(1)}
        >
          Overview
        </button>
        

        {
          isPresale && isPresale==="Presale"  && iscomingSoon!=='CREATED' && (
            <button
            className={` font-nunito rounded-none px-4 py-2  ${activeTab === 6 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black  text-base '}`}
            onClick={() => setActiveTab(6)}
          >
            Payments Schedule
          </button>
          )
        }
       



        <button
          className={` font-nunito rounded-none px-4 py-2  ${activeTab === 2 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black  text-base '}`}
          onClick={() => setActiveTab(2)}
        >
          Documents
        </button>
        <button
          className={`font-nunito rounded-none px-4 py-2  ${activeTab === 3? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black text-base '}`}
          onClick={() => setActiveTab(3)}
        >
          Purchase History
        </button>
        <button
          className={`font-nunito rounded-none px-4 py-2  ${activeTab === 4 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black text-base'}`}
          onClick={() => setActiveTab(4)}
        >
         Why to Invest
        </button>
        <button
          className={` font-nunito rounded-none px-4 py-2  ${activeTab === 5 ? ' text-black font-extrabold text-lg border-b-4  border-b-secondary' : 'text-black text-base '}`}
          onClick={() => setActiveTab(5)}
        >
          FAQs
        </button>
        
      </div>










{/*Top Section Resale Mob Starts */}
      <div className='hideInWebResale font-nunito'>

        <div className='relative'>

  <div className='absolute presale-badge2 text-[12px]'>Resale</div>
      <div className='detailsCard'>



  {/* Badge-section */}
  <div className='badgeSection flex justify-between -mt-[10px]'>

      <div className='flex justify-between gap-3'>

      </div>

  <div className='flex gap-5 items-center'>

    <MdOutlineShare style={{fontSize:'24px',color:"gray",cursor:"pointer"}} onClick={handleToggleSharePopup} />
      {/* <FaRegHeart onClick={addToWishlist} style={{fontSize:'24px',color:"gray"}} /> */}

  {
      property_info?.is_wishlisted==0?
      <FaRegHeart onClick={addToWishlist} className='cursor-pointer' style={{fontSize:'24px',color:"gray"}} />
      :
      <FaHeart onClick={addToWishlist} className='cursor-pointer' style={{fontSize:'24px',color:"red"}}/>
      
  }




  </div>

  {showSharePopup && <SharePopup/> }
  </div>

  <div className="investmentSection flex justify-between rounded-lg mt-[16px] py-2.5 px-4">

  <div className='flex flex-col items-center'>
      <p  className='font-semibold font-nunito text-gray  text-[14px]'>
      
      {details && details.property_info?.sale_type==="Presale"?"Est. Yield":"Yield"}
      </p>

      <div className="flex items-center gap-1">
      <div  className='font-semibold text-blue  text-[16px]'>
      {property_info?.gross_yield}%</div>
    

      <div className='flex justify-center gap-3'>
                <div className='flex justify-center items-center'>
                <div className="tooltip" onClick={handleYieldInfoPopup}>
                    <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                    {

                      showYieldInfoPopup && (
                          <>
                        
                                          <InfoPopup>
                                              <div className='p-2'>
                                              <p className='text-blue text-left text-sm'>What is Yield?</p>
                                                  <p className='text-gray text-left text-sm'>
                                                  The earnings generated and realized on the investment over a particular period of time.
                                              </p>
                                             
                  
                  
                                              </div>
                             
                                              <button onClick={handleYieldInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                                  Ok</button>
                                              
                                              </InfoPopup>
                          </>
                      )
                    }
                </div> 
                </div>
            </div>
      

      </div>
      
  </div>




  <div className='flex flex-col items-center'>
      <p  className='font-semibold text-gray font-nunito  text-[14px]'>
      
      {details && details.property_info?.sale_type==="Presale"?"Target ARR":"Target IRR"}
      </p>

      <div className="flex items-center gap-1">
          <div  className='font-semibold text-blue  text-[16px]'>
          {property_info?.target_IRR}%
          </div>
          <div className='flex justify-center gap-3'>
          <div className='flex justify-center items-center'>
          <div className="tooltip" onClick={handleIRRInfoPopup}>
              <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
              {

                showIRRInfoPopup && (
                    <>
                  
                                    <InfoPopup>
                                        <div className='p-2'>
                                        <p className='text-blue text-left text-sm'>What is Target IRR?</p>
                                            <p className='text-gray text-left text-sm'>
                                            Expected rate of return that an investment aims to achieve over a period of time.
                                        </p>
                                       
            
            
                                        </div>
                       
                                        <button onClick={handleIRRInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                            Ok</button>
                                        
                                        </InfoPopup>
                    </>
                )
              }
          </div> 
          </div>
      </div>
      </div>
      
  </div>




  <div className='flex flex-col items-center'>
      <p  className='font-semibold text-gray font-nunito text-[14px]'>
      Potential Gain
            {/* {details && details.property_info?.sale_type==="Presale"?"Potential Gain":" Capital Gain"} */}
      </p>

      <div className="flex items-center gap-1">
    
      <BiUpArrowAlt style={{fontSize:"23px",color: "#2FDF99"}} />
          <div  className='font-semibold text-blue  text-[16px]'>
            
          {property_info?.capital_gain}%
          </div>
           <div className='flex justify-center gap-3'>
                <div className='flex justify-center items-center'>
                <div className="tooltip" onClick={handlePotentialGainInfoPopup}>
                    <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                    {

                      showPotentialGainInfoPopup && (
                          <>
                        
                                          <InfoPopup>
                                              <div className='p-2'>
                                              <p className='text-blue text-left text-sm'>What is Potential Gain?</p>
                                                  <p className='text-gray text-left text-sm'>
                                                  Potential profit an investor would get over a certain period.
                                              </p>
                                             
                  
                  
                                              </div>
                             
                                              <button onClick={handlePotentialGainInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                                  Ok</button>
                                              
                                              </InfoPopup>
                          </>
                      )
                    }
                </div> 
                </div>
            </div>
      </div>
      
  </div>

  </div>




  <div className=" flex flex-row justify-between px-[20px] mt-[23px]">
      <div className=" flex flex-row gap-[10px]">
          <div className=" flex flex-col justify-center"><img src={TagResale}/></div>
          <div className=" text-[12px] font-normal">Token Price Range:</div>
      </div>
      <div className=" flex flex-row gap-[8px]">
      <div className="text-[12px] font-bold">
     {`₹${(0.90 * (parseFloat(property_info?.price_per_share) / 1000)).toFixed(2)}K - ₹${(1.10 * (parseFloat(property_info?.price_per_share) / 1000)).toFixed(2)}K`}

    </div>
    
          <div className="flex flex-col justify-center">   
          <div className='flex justify-center gap-3'>
          <div className='flex justify-center items-center'>
          <div className="tooltip" onClick={handleTokenPriceInfoPopup}>
              <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
              {

                showTokenPriceInfoPopup && (
                    <>
                  
                                    <InfoPopup>
                                        <div className='p-2'>
                                        <p className='text-blue text-left text-sm'>What is Token Price Range?</p>
                                            <p className='text-gray text-left text-sm'>
                                            Minimum and maximum token price ranges are available and listed by the user.
                                        </p>
                                       
            
            
                                        </div>
                       
                                        <button onClick={handleTokenPriceInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                            Ok</button>
                                        
                                        </InfoPopup>
                    </>
                )
              }
          </div> 
          </div>
      </div>
          </div>
      </div>
  </div>


  <div className="mt-[10px]"><hr style={{color:"#D9D9D9"}}></hr></div>


  <div className="border border-[#D328A7] mt-[29px] py-[11px] rounded-lg" style={{ boxShadow: '0px 3px 4.5px -0.75px rgba(0, 0, 0, 0.1)' }}>
      <div className=" text-[14px] text-[#5D5C5C] font-bold">Total Tokens available in Resale</div>
      <div className=" mt-[11px] text-[20px] font-medium">{parseFloat(details.total_tokens_available_in_resale)} Tokens</div>
  </div>


      </div>
        </div>

      </div>
{/*Top Section Resale Mob Ends */}




{/*Mid Section Resale Mob Starts */}
    <div className='hideInWebResale'>

  <div className='mt-[16px] rounded-xl' style={{ boxShadow: '0px 3px 30px 0px #0000001A' }}>
  <ResalePartA avaialbleSellOffers={props.avaialbleSellOffers} availableBuyOffers={props.availableBuyOffers}/>
  
</div>

      </div>
{/*Mid Section Resale Mob Ends */}











{/******************  mobile-tab ******************/}
    <div className="flex tab-shadow overflow-auto justify-between mt-[23px] sm:hidden">
      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 1 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(1)}
      >
      <Growthicon/>
        <p className={`whitespace-nowrap ${activeTab === 1 ?'text-blue':'text-gray'}`}>Growth</p>
        
      </button>



      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 2 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(2)}
      >


        <span class="material-symbols-outlined">
          overview
      </span>
        <p className={`whitespace-nowrap ${activeTab === 2 ?'text-blue':'text-gray'}`}> Overview </p>
        
      </button>
{
   isPresale && isPresale==="Presale"  && iscomingSoon!=='CREATED' && (
    <button
    className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 6 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
    onClick={() => setActiveTab(6)}
  >

    <img src={PaymentSchIcon} alt="payment_schedule" />

   
    <p className={`whitespace-nowrap ${activeTab === 6 ?'text-blue':'text-gray'}`}> Payments Schedule </p>
    
  </button>
   )
}

   


      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 3 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(3)}
      >
        <span class="material-symbols-outlined">
contract
</span>
        <p className={`whitespace-nowrap ${activeTab === 3 ?'text-blue':'text-gray'}`}> Documents</p>
        
      </button>

    
      <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 4 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(4)}
      >
      
        <WhytoInvesticon/>
        <p className={`whitespace-nowrap ${activeTab === 4 ?'text-blue':'text-gray'}`}>  Why to Invest</p>
        
      </button>

    
      {/* <button
        className={` flex flex-col items-center text-xs font-nunito  px-4 py-2 text-nunito ${activeTab === 4 ? 'bg-blue-500 text-black font-semibold  border  border-secondary rounded-lg' : 'text-gray'}`}
        onClick={() => setActiveTab(4)}
      >
        <img src={} alt="" />
        <p className={`whitespace-nowrap ${activeTab === 4 ?'text-blue':'text-gray'}`}>  FAQ</p>
        
      </button> */}
      
     

</div>


{/********  mobile-tab-content ****************/}

<div className='block sm:hidden'>
      <div className="mt-4">
      {activeTab === 1 && (
          <div className="py-4">
          
          <GrowthTabMobile details={details}/>
           
          </div>
        )}


        {activeTab === 2 && (
          <div className="">
            <OverviewSection propDetails={details}/>

          </div>
        )}

        {activeTab === 3 && (
          <div className="">
            <DocumentTab propDetails={details}/>
          </div>
        )}

       

        {activeTab === 4 && (
          <div className="">

            <WhyToInvest/>
          </div>
        )}

        {activeTab === 5 && (
          <div className="p-4">
            <FAQComponenet />
          </div>
        )}

{activeTab === 6 && (
          <div className="">
          <PaymentScheduleMobile propDetails={details}/>
          </div>
        )}


      </div>
      </div>



{/********  mobile-tab-content-end ****************/}

      {/* Tab content */}

      <div className='hidden sm:block'>
      <div className="mt-4">
        {activeTab === 1 && (
          <div className="">
            <OverviewSection propDetails={details}/>

          </div>
        )}


{activeTab === 6 && (
          <div className="">
        <PaymentSchedule propDetails={details}/>
          </div>
        )}


        {activeTab === 2 && (
          <div className="">
            <DocumentTab propDetails={details}/>
          </div>
        )}

        {activeTab === 3 && (
          <div className="">
            <PurchaseHistory details={details}/>
          </div>
        )}

        {activeTab === 4 && (
          <div className="">

            <WhyToInvest/>
          </div>
        )}

        {activeTab === 5 && (
          <div className="p-4">
            <FAQComponenet/>
          </div>
        )}


      </div>
     
        
      </div>





    </div>
  )
}


