import React from 'react'
import "./bank-style.css"



export default function CustomeBankInput({name,placeholder,disabled,value,onChange,maxLength,numericInput,preventPaste }) {
  
  const handleKeyPress = (e) => {
    if (numericInput) {
      // Check if the pressed key is a number or a special key
      const isValidInput = /^\d*$/.test(e.key);
      if (!isValidInput) {
        e.preventDefault(); // Prevent 
      }
    }
  };

  const handlePaste = (e) => {
    if (preventPaste) {
        e.preventDefault(); // Prevent pasting
    }
};
  
  return (
    <div className="bank-input-container">
    <div className="encrypt-icon-container">
        <span className="material-symbols-outlined encrypt-icon" >
          encrypted
        </span>

        
    </div>
    <input
      type="text"
      className={`border grow border-gray text-sm text-gray rounded-md sm:rounded-lg bank-input ${disabled ? 'disabledbg' : ''}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
      onKeyPress={handleKeyPress}
      name={name}
      onPaste={handlePaste}
      autoComplete="off"
    />
  </div>

  )
}
