import React, { useState } from 'react';
import infoIcon from "../../Assets/icons/info.png";
import InfoPopup from '../InfoPopup/InfoPopup';
import { IoMdInformationCircleOutline } from 'react-icons/io';

function WhyToInvestAccordian({ title, children, showInfoIcon = false}) {
  const [isAccordionActive, setAccordionActive] = useState(true);
  const [showCalPopup, setshowCalPopup] = useState(false);

  const handleToggleAccordion = () => {
    setAccordionActive(!isAccordionActive);
  };

  const handleShowCalPopup = () => {
    setshowCalPopup(true);
  };
  const handlecloseCalPopup = () => {
    setshowCalPopup(false);
  }


  return (
    <div className={`font-nunito hs-accordion ${isAccordionActive ? 'tab-shadow rounded-2xl py-2 px-1 sm:p-4' : ''}`}>
      <button
        className={`font-nunito text-gray hs-accordion-toggle  px-2.5 inline-flex items-center justify-between w-full text-lg text-start text-gray-800 hover:text-gray-500 rounded-lg focus:outline-none ${
          isAccordionActive ? 'hs-accordion-active:text-blue-600 border-0' : 'border border-lightgray py-5'
        }`}
        onClick={handleToggleAccordion}
      >
        <div className='flex gap-2 items-center'>
          {title}
          {showInfoIcon && (
            <div className="tooltip">
             <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}}/>
              <span className="tooltiptext">
             
                                <p className='text-gray text-sm'>
                                These figures are anticipated and may fluctuate depending
                                 on the market circumstances and conditions.


                            </p>
              </span>
            </div>
)}

<IoMdInformationCircleOutline className='block sm:hidden' style={{fontSize:"14px",color:" #D328A7"}} 
                          onClick={handleShowCalPopup} />

{

showCalPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                          
                                <p className='text-gray text-left text-sm'>
                                These figures are anticipated and may fluctuate depending
                                 on the market circumstances and conditions.
                            </p>
                            </div>
           
                            <button onClick={handlecloseCalPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}





        </div>
        <svg
          className={`w-4 h-4 transition-transform ml-2 ${isAccordionActive ? 'transform rotate-90' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
      <div className={`font-nunito text-sm text-justify text-secondarygray hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${isAccordionActive ? '' : 'h-0'}`}>
        <div className='flex flex-col gap-2.5 px-2.5 py-3.5'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default WhyToInvestAccordian;
