import React, { useState, useEffect } from 'react';
import './Index.css';
import { RxCross2 } from 'react-icons/rx';
import { BeatLoader } from 'react-spinners'
import Success from '../../Assets/icons/success.png'
import { ApiBaseUrl } from '../../Utils/urls';

function InterestForm(props) {
    const [localName, setLocalName] = useState('');
    const [localPhone, setLocalPhone] = useState('');
    const [localEmail, setLocalEmail] = useState('');
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading,setLoading]=useState(false)

    const validateEmail = (email) => {
        // Basic email validation
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const validatePhoneNumber = (phone) => {
        // Basic phone number validation
        const numericRegex = /^[0-9]*$/;
        return (phone.length === 10 || phone.length === 12) && numericRegex.test(phone);
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        // Check if the input value contains non-numeric characters or exceeds 12 digits
        if (!/^\d{0,12}$/.test(inputValue)) {
            setError('Please enter a valid 10-digit phone number.');
        } else {
            setError('');
            setLocalPhone(inputValue);
        }
    };

    const submitResponse = () => {
        if (!localName.trim()) {
            setError('Please enter your name.');
        } else if (!validatePhoneNumber(localPhone)) {
            setError('Please enter a valid 10-digit phone number.');
        } else if (!validateEmail(localEmail)) {
            setError('Please enter a valid email address.');
        } 
        
        else{
            setError('');
            setSubmitting(true);
        }
         
           
        
           
        
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${ApiBaseUrl}/fr-property-enquiry/add-enquiry-fr-property`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: localName,
                        email: localEmail,
                        mobile: localPhone,
                        property_id: props.currentInterestPropId, 
                    }),
                });
                const responseData = await response.json();
                setResponseStatus(response.status);
                if (response.status === 200) {
                    setLoading(false);
                    setFormSubmitted(true);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error:', error);
            } finally {
                setSubmitting(false);
            }
        };

        if (submitting) {
            fetchData();
        }
    }, [submitting, localName, localEmail, localPhone]);

    const closePopup = () => {
        props.setIsInterestFormOpen(false);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    return (
        <div>
       
            <div className='flex flex-col justify-between'>
{isLoading&&(
    <>
        <div className='flex flex-row justify-center'>
            <BeatLoader color={'#123abc'} loading={isLoading}  />
        </div>
    </>
)}
            {formSubmitted && (


                <>
                <div className='flex flex-row h-[0px] justify-end'>
                <div className='w-8 -mt-4 z-10 cursor-pointer' onClick={closePopup}><RxCross2 style={{ color: 'rgba(128, 128, 128, 0.25)', height: '35px' }} /></div>
            </div>
            <div className=''>
            <div className=' flex flex-row justify-center'><img src={Success}/></div>
                <div className='mt-4 px-12 paddingEnq' style={{color:"rgba(93, 92, 92, 1)", lineHeight:"16px"}}>Thank you for enquiring. <br className='confirmNextLine'></br>Our Team will contact you soon. </div>
                <div className='flex flex-row justify-center mt-[20px]'>
                <div className="bg-secondary h-[36px] text-white px-6 rounded-md  flex flex-col justify-center enquiryDescText font-bold enqnowHeight cursor-pointer mt-2" onClick={closePopup}>OK</div>
                </div>
                </div>
                </>
            )}


            {!formSubmitted && (
                <>
                <div className='flex flex-row h-[43px]'>
                <div className='text-2xl font-bold w-full ' style={{ color: 'rgba(211, 40, 167, 1)' }}>Enquiry Form</div>
                <div className='w-8 cursor-pointer' onClick={closePopup}><RxCross2 style={{ color: 'rgba(128, 128, 128, 0.25)', height: '35px' }} /></div>
            </div>

            <div className='flex flex-row justify-center enquiryParent'>
                <hr style={{ color: 'rgba(217, 217, 217, 1)', width: '100%' }}></hr>
            </div>

            <div className='text-sm flex flex-row px-6 py-1 font-normal h-[58px] enquiryDescText mt-2'>
            Interested to know more about this property? Fill the   form below 
            <br className="hide-br-sm"></br> 
           and our team will revert at the earliest.</div>

            <div className='flex flex-col justify-center text-xs font-normal enquiryFieldBackground h-[48px] rounded-xl' >
                <input type='text' placeholder='Full Name' value={localName} onChange={(e) => setLocalName(e.target.value)} className='bgInputFieldEnquiry h-ful enquiryFieldInput pl-[14.25px]' />
            </div>

            <div className='flex flex-col justify-center text-xs font-normal enquiryFieldBackground h-[48px] rounded-xl mt-[20px]' style={{ color: 'rgba(120, 120, 120, 1)' }}>
            <input type='text' inputMode='numeric' placeholder='Mobile Number' value={localPhone}  onChange={handlePhoneChange} className='bgInputFieldEnquiry h-ful enquiryFieldInput pl-[14.25px]' />

            </div>

            <div className='flex flex-col justify-center text-xs font-normal enquiryFieldBackground h-[48px] rounded-xl mt-[20px]' style={{ color: 'rgba(120, 120, 120, 1)' }}>
                <input type='text' placeholder='Email' value={localEmail} onChange={(e) => setLocalEmail(e.target.value)} className='bgInputFieldEnquiry h-ful enquiryFieldInput pl-[14.25px]' />
            </div>
            </>

            )}
               
            {!formSubmitted &&(
                <>
                <div className='h-6 -mb-3'>
                    {error && <div className='text-xs mt-2 ' style={{ color: 'red' }}>{error}</div>}
                    
                </div>
                
                    <div className='flex flex-row justify-between mt-[20px] px-4'>
                    <div className='flex flex-col justify-center'>
                        <input type='checkbox' id='enquiryCheckbox' className='hiddenbox' checked={isChecked} onChange={handleCheckboxChange}/>
                        <label htmlFor='enquiryCheckbox' className='checkbox-label rounded-sm' style={{ color: 'rgba(211, 40, 167, 1)' }}></label>
                    </div>
                    <div className='enquiryDescText w-full text-start pl-3' style={{ color: 'rgba(93, 92, 92, 1)' }}>You agree to Our
                    
                    <a href="https://www.propftx.com/terms-of-use/" target="_blank">  <span style={{ color: 'rgba(211, 40, 167, 1)' }}>Terms of Use </span></a>
                   
                     
                     
                     and data 
                     <a href="https://www.propftx.com/privacy-policy/" target="_blank">
                     <span style={{ color: 'rgba(211, 40, 167, 1)' }}> Privacy policy</span>
                     </a>
                    
                     
                     </div>
                </div>

                <div className='flex flex-row justify-center mt-[20px]'>
                <div className={`bg-secondary h-[36px] text-white px-6 rounded-md  flex flex-col justify-center enquiryDescText font-bold enqnowHeight cursor-pointer ${isChecked ? '' : 'opacity-50 pointer-events-none'}`} onClick={submitResponse}>Enquire Now</div>
                </div>
                </>
                    )}
               

            </div>
        </div>
    );
}

export default InterestForm;
