import {applyMiddleware, combineReducers, legacy_createStore} from "redux"

import { thunk } from "redux-thunk";
import phoneReducer from "./phoneRedux/phoneReducer";
import signupReducer from "./AuthRedux/authReducer";
import otpVerificationReducer from "./otpVerificationRedux/otpVerificationReducer";
import updateProfileReducer from "./updateProfileRedux/updateProfileReducer";
import emailVerificationReducer from "./emailVerificationRedux/emailVerificationReducer";

const rootReducer = combineReducers({
    phoneReducer,
    signupReducer,
    otpVerificationReducer,
  updateProfileReducer,
  emailVerificationReducer

    });
    export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
  