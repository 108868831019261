import { changeDateFormat } from "../Utils/DateFormat";
import { handlelogout } from "../Utils/logout";
import { ApiBaseUrl } from "../Utils/urls";

//send otp for adding nominee
export const AddNomineeFunc=(e,
    nomineeDetails,
    userId,
    setOtpVerifying,
    accesstoken,
    setOtpsent,
    openModal,
    setSameAadhar,
    setAlertMsg,
    setSendingOtp
    )=>{
    e.preventDefault()
  
    const nomineeBody = {
      ...nomineeDetails,
      dob: changeDateFormat(nomineeDetails.dob),
      user_id: userId
      };
    
  
      setOtpVerifying(true)
      setSendingOtp(true)
    fetch(`${ApiBaseUrl}/fr-user/initiate-add-nominee`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(nomineeBody)
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          console.log("not ok");
        }
       
        return response.json();
      })
      .then(data => {
       setOtpVerifying(false)
       setSendingOtp(false)
        //console.log('Response from server:', data);
        if(data.success)
        {
          localStorage.setItem("session_nominee_otp",data.data.sessionForOTP)
  
          setOtpsent(true)
          setTimeout(() => {
            setOtpsent(false)
            openModal()
          }, 3000);
        }
  
        if(!data.success)
        {
         
 
          setSameAadhar(true)
          setAlertMsg(data.data.msg)
          setTimeout(() => {
            setSameAadhar(false)
          
          }, 3000);
  
         
        }
      })
      .catch(error => {
       
        console.error('error:', error);
       
      });
  
  }

 //resend otp for adding nominee 
export const ResendAddNomineeFunc=(
  nomineeDetails,
  userId,
  setOtpVerifying,
  accesstoken,
  setOtpsent,
  openModal,
  setSameAadhar,
  setAlertMsg
  )=>{


  const nomineeBody = {
    ...nomineeDetails,
    dob: changeDateFormat(nomineeDetails.dob),
    user_id: userId
    };
  

    setOtpVerifying(true)
 
  fetch(`${ApiBaseUrl}/fr-user/initiate-add-nominee`, 
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken 
    },
    body: JSON.stringify(nomineeBody)
  }
  
  )
    .then(response => {
    
      if (!response.ok) {
        console.log("not ok");
      }
     
      return response.json();
    })
    .then(data => {
     setOtpVerifying(false)
      //console.log('Response from server:', data);
      if(data.success)
      {
        localStorage.setItem("session_nominee_otp",data.data.sessionForOTP)

        setOtpsent(true)
        setTimeout(() => {
          setOtpsent(false)
          openModal()
        }, 3000);
      }

      if(!data.success)
      {
       
console.log(data.data.msg);
        setSameAadhar(true)
        setAlertMsg(data.data.msg)
        setTimeout(() => {
          setSameAadhar(false)
        
        }, 3000);

       
      }
    })
    .catch(error => {
     
      console.error('error:', error);
     
    });

}

//verify nominee
export const NomineeVerifyFunc=(
    userId,
    otp,
    accesstoken,
    setOtpVerified,
    closeModal,
    navigate,
    setWrongOtp
    )=>{

    const otpBody={
      user_id:userId,
      sessionForOTP:localStorage.getItem("session_nominee_otp"),
      answer:otp
    }
  
    setOtpVerified(false)
    fetch(`${ApiBaseUrl}/fr-user/verify-nominee-otp`, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify(otpBody)
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(data => {
       
       // console.log('Response from server:', data);
        if(data.success)
        {
         setOtpVerified(true)
          setTimeout(() => {
            closeModal()
            setOtpVerified(false)
            navigate("/user/nominee")
           
          }, 3000)
        }
  
       
  
      })
      .catch(error => {
        setWrongOtp(true)
        setTimeout(() => {
         
          setWrongOtp(false)
         
        }, 3000)
        console.error('There was a problem with the fetch operation:', error);
      });
  
  
  }



//fetch all Nominee details
export const fetchAllNominee=(
  userId,
  accesstoken,
  setAllNominee,
  setLoading,
  navigate)=>{

  setLoading(true)
    fetch(`${ApiBaseUrl}/fr-user/get-nominees?user_id=${userId}`, 
         {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
             'Authorization': accesstoken 
           }
         
         }
         
         )
           .then(response => {
            
            //  if (!response.ok) {
            //    throw new Error('Network response was not ok');
            //  }
            
           
             return response.json();
           })
           .then(resdata => {
            
            //console.log('Response from server:', resdata);
             setLoading(false)
             if (!resdata.success && resdata.data === 'INVALID TOKEN OR USER ID MISMATCH') {
           
              handlelogout(navigate); 
            }
                 setAllNominee(resdata.data.nominees)
            
       
           })
           .catch(error => {
            
             console.error('error details:', error);
           });
       
 }


 //delete nominee
 
export const deleteNomineeFunc=(nomineeId,userId,accesstoken,setAllNominee,setLoading)=>{
    fetch(`${ApiBaseUrl}/fr-user/delete-nominee?user_id=${userId}&nominee_id=${nomineeId}`, 
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      }
    
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(resdata => {
       
        //console.log('Response from server:', resdata);
       
        fetchAllNominee(userId,accesstoken,setAllNominee,setLoading)
       
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}