import React, { useState } from 'react';
import infoIcon from "../../Assets/icons/info.png";

function CommonAccordianTab({ title, children, showInfoIcon = false}) {
  const [isAccordionActive, setAccordionActive] = useState(false);

  const handleToggleAccordion = () => {
    setAccordionActive(!isAccordionActive);
  };

  return (
    <div className={`font-nunito hs-accordion ${isAccordionActive ? 'tab-shadow rounded-2xl py-1 sm:p-4' : ''}`}>
      <button
        className={`font-nunito text-gray hs-accordion-toggle  px-2.5 inline-flex items-center justify-between w-full text-lg text-start text-gray-800 hover:text-gray-500 rounded-lg focus:outline-none ${
          isAccordionActive ? 'hs-accordion-active:text-blue-600 border-0' : 'border border-lightgray py-2 sm:py-5'
        }`}
        onClick={handleToggleAccordion}
      >
        <div className='flex gap-2 items-center'>
          {title}
          {showInfoIcon && (
            <div className="tooltip">
              <img src={infoIcon} alt="" />
              <span className="tooltiptext">Information</span>
            </div>
          )}
        </div>
        <svg
          className={`w-4 h-4 transition-transform ml-2 ${isAccordionActive ? 'transform rotate-90' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
      <div className={`font-nunito text-sm text-justify text-secondarygray hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${isAccordionActive ? '' : 'h-0'}`}>
        <div className='flex flex-col gap-2.5 p-2.5'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default CommonAccordianTab;
