import React, { useState } from 'react'
import "./mobile-footer.css"
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md'
import logo from "../../Assets/logo/loog_new_light.svg"
import { IoLogoLinkedin, IoLogoWhatsapp } from 'react-icons/io'
import { AiFillInstagram } from 'react-icons/ai'
import { RiTwitterXLine } from 'react-icons/ri'
import googlePlay from "../../Assets/Footer/google_play.svg"
import appleplay from "../../Assets/Footer/apply_play.png"
import startupIndia from "../../Assets/Footer/Startup_india.svg"
import stKarnataka from "../../Assets/Footer/startup_karnataka.svg"
import submitNewsletter from '../../Api/NewsLetterApi'
import emailSent2 from '../../Assets/icons/Mail_1.png'
import FooterModal from './FooterModal'


export default function MobileFooter() {


        const [email, setEmail] = useState('');
        const [errorMsg, setErrorMsg] = useState('');
        const [successMsg,setSuccessMsg]=useState('')
        const [isLoading,setLoading]=useState(false)
      const [isModalOpen,setModalopen]=useState(false)
      
      
      
        const handleNewsletterSubmit = () => {
          submitNewsletter(email, setEmail, setErrorMsg, setSuccessMsg, setModalopen, setLoading);
        };
      
        const closeContactPopNewLetter = () => {
          setEmail('');
          setErrorMsg('');
          setModalopen(false)
        };

  return (
<>



{
!isLoading && successMsg!="" && isModalOpen &&
(
  <FooterModal closeModal={closeContactPopNewLetter}>
<div class="flex flex-col gap-5 sm:gap-7">
             
             <div className='flex flex-col items-center gap-5 font-nunito'>
                 <img src={emailSent2} alt="" />
                 <p className='text-blue text-lg sm:text-2xl font-bold'>Thanks</p>
                  <p className='  text-xs sm:text-lg text-gray text-center'>{successMsg && successMsg}</p>
             </div>

             <button   
             style={{width:"43%"}} 
             onClick={closeContactPopNewLetter}
             className='bg-secondary mx-auto  property-popupbtn rounded-md text-white text-sm sm:text-lg font-medium '>
            Ok
               
               </button>
          
          
         </div>
  </FooterModal>
)
}


    <footer className='mobileFooter font-nunito bg-blue flex flex-col mt-6 gap-5'>

{/* section-1 */}
<div className='flex justify-between text-left w-full'>
        <div className='flex flex-col w-1/2  gap-2 text-xs font-semibold text-white'>
                <p className='text-secondary text-base'>Resources</p>
                {/* <p>Fractional Investment</p> */}
                <a href="https://www.propftx.com/how-it-works/" >
        <p>How it Works</p>
        </a>
        <a href="https://www.propftx.com/blog/" >
        <p>Blogs & News</p>
        </a>
        <a href="https://www.propftx.com/faq/" >
        <p>FAQ’s</p>
        </a>
        </div>

        <div className='flex flex-col w-1/2 gap-2 text-xs font-semibold text-white'>
                <p className='text-secondary text-base'>Company</p>
                <a href="https://www.propftx.com/about-us/">
        <p>About Company</p>
      </a>

      <a href="https://www.propftx.com/leadership/" >
        <p>Leadership</p>
      </a>
                
                
        </div>
</div>


{/* section-2 */}

<div className='flex justify-between text-left w-full'>
        <div className='flex flex-col w-1/2  gap-2 text-xs font-semibold text-white'>
                <p className='text-secondary text-base'>Legal</p>


                <a href="https://www.propftx.com/terms-of-use/">
        <p>Terms of Use</p>
</a>
 <a href="https://www.propftx.com/privacy-policy/" >
        <p>Privacy Policy</p>
</a>
 <a href="https://www.propftx.com/cookies/" >
        <p>Cookie Policy</p>
</a>

 <a href="https://www.propftx.com/kyc/">
       <p>KYC Policy</p>
</a>

 <a href="https://www.propftx.com/aml/">
       <p>AML Policy</p>
</a>

 <a href="https://www.propftx.com/risk-policy/">      
       <p>Risk Disclosure</p>
</a>
<a href="https://www.propftx.com/refund-policy/"  >      
       <p>Refund Policy</p>
</a>     
        </div>

        <div className='flex flex-col w-1/2 gap-2 text-xs font-semibold text-white'>
                <p className='text-secondary text-base'>Get in Touch</p>

                <a href="https://www.propftx.com/contact/">
          <p className='text-white text-xs'>Contact</p>
          </a>

               <div className='flex gap-1 items-center'>
               <MdOutlineEmail  style={{color:"#78F9FF",fontSize:"20px"}}/>
          <p className='text-white font-semibold text-xs'>support@propftx.com</p>
               </div>
               <div className='flex gap-1 items-center'>
               <MdOutlinePhone  style={{color:"#78F9FF",fontSize:"20px"}}/>
          <p className='text-white font-semibold text-xs'>+91 7619464634</p>
               </div>
                
        </div>
</div>

{/* section-3 */}


<div className="newsletterSubscM">
  <p>Subscribe to the Newsletter</p>
  <div className="subsInputsec">
    <input type="text" 
     className="subscripInput"
     value={email}
      onChange={(e) => setEmail(e.target.value)}
        placeholder="Your Email"
         />
    <button className="subscribeBtn"  onClick={handleNewsletterSubmit}>Subscribe</button>
  </div>
  
  <div className="errorMsgNewsLetter" style={{ display: errorMsg ? 'block' : 'none',color:"#b92424" }}>
              {errorMsg}
            </div>
  </div>




<div className='flex flex-col justify-between'>

<div className='flex w-full justify-between'>

<img src={logo} alt="" className='w-1/2 mobileLogo' />

<div className='flex w-1/2 justify-between text-white text-lg'>
<a href="https://www.linkedin.com/company/propftx" target="_blank">
  <div className='mSocialbox '>
  <IoLogoLinkedin className='' />
  </div>
  </a>

  <a href="https://wa.link/xagc5o" target="_blank">
    <div className='mSocialbox'>
    <IoLogoWhatsapp />
    </div>
    </a>

    <a href="https://www.instagram.com/propftx/" target="_blank">
    <div className='mSocialbox'>
    <AiFillInstagram />
    </div>
    </a>

    <a href="https://twitter.com/propftx"  target="_blank">
    <div className='mSocialbox'>
    <RiTwitterXLine />
    </div>
    </a>

</div>
</div>




<div className='flex flex-col text-white text-justify'>
 
        <p className='text-sm font-semibold pt-4 text-left'>
                Your gateway to fractional real estate
         investments where you earn rentals and capital appreciation.</p>

            <div className='flex flex-col gap-2 mt-2 mb-2 justify-between w-full mPlaystoreSec py-5'>

            <p className='text-sm text-white'>Coming Soon</p>
       
       <div className='flex justify-between'>
       <img src={googlePlay} alt="" />
           <img src={appleplay} alt="" />
       </div>
        </div>

           
            <p className='text-xs py-2'>© 2024 PropFTX | All rights reserved</p>

           

</div>










</div>

{/* section-4 */}
<div className=''>
<p className='text-white text-left text-xs italic'>
  <span  className='font-bold  not-italic'>DISCLAIMER: </span>
 Our platform offers fractional property investment opportunities in India, 
but it's crucial to recognize the associated risks. By using our services, you acknowledge potential market fluctuations, illiquidity,
 property management challenges, regulatory and legal uncertainties, currency exchange risks, market volatility, tenant issues, limited diversification, default and interest rate risks, technology and platform vulnerabilities, taxation complexities, and the lack of a guarantee of future performance. You may lose some or all of your investment, and it's essential to seek professional advice and evaluate your risk tolerance and financial situation before investing. This Risk Disclaimer is not exhaustive, and additional risks may apply.

</p>

</div>

    </footer>

    </>
  )
}
