import React from 'react'
import "./nominee-style.css"



export default function CustomeNomineeInput({name,placeholder,disabled,value,onChange,maxLength,numericInput}) {
  const handleKeyPress = (e) => {
    if (numericInput) {
      // Check if the pressed key is a number or a special key
      const isValidInput = /^\d*$/.test(e.key);
      if (!isValidInput) {
        e.preventDefault(); // Prevent the default behavior of the event
      }
    }
  };

 
  return (
    <input
      type="text"
      className={`border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg   nominee-input ${disabled ? 'disabledbg' : ''}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      
      maxLength={maxLength}
      style={{width:"100%"}}
      name={name}
      onKeyPress={handleKeyPress}
    />
  )
}
