import React from 'react'
import "./signup.css"
import { useLocation } from "react-router-dom"

export default function FormContainer({title,spantitle,subtitle,smalltitle,emailTitle,isEmailTitle=false,isfooterExist=true,children,issubTitle=false,isSmallSubTitle=false}) {
  const location=useLocation()
  return (
  <div className={`flex   justify-center ${location.pathname === '/verify-with-otp' ? '' : 'items-center'} h-screen font-nunito`}>
    <div className='signupSection flex flex-col gap-16'>

  

      <div className='flex flex-col gap-9'>

          <div className='flex flex-col gap-2.5  sm:gap-5'>
          <p className='text-secondary  text-2xl  sm:text-4xl font-nunito font-bold'>{title} <span className='text-blue'>{spantitle}</span> </p>
         
         {
          isEmailTitle && (
            <p className='text-blue text-base  sm:text-2xl font-nunito font-normal'>{emailTitle} </p>
         
          ) 
         }
        
        
          {
            issubTitle &&(
                <p className='text-blue  text-base sm:text-2xl'>{subtitle}</p>
            )
            }

            {
                isSmallSubTitle && (
                    <p className='text-blue  text-base font-normal sm:text-lg'>{smalltitle}</p>
                )
            }
          
        
          </div>
                {children}
                </div>

{
    isfooterExist && (
<div>
        <div className='login-bottom text-xs font-normal font-nunito text-gray'>

          <div>By proceeding, You agree to accept PropFTX's 
            <span className='text-secondary'>
                <a href="https://www.propftx.com/terms-of-use/" target='_blank'> Terms of Use</a>
                </span> ,
            </div>

            <div>   as well as its  
           <span className='text-secondary'>
           <a href="https://www.propftx.com/privacy-policy/" target='_blank'> Privacy Policy.</a>
            </span>
            </div>
      
        </div>
      </div>
    )
}
      

    </div>
  </div>
  )
}
