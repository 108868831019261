
export const changeDateFormat=(dateString)=>{

const date = new Date(dateString);


const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
const day = String(date.getDate()).padStart(2, '0');

// Format the date as "YYYY-MM-DD"
const formattedDate = `${year}-${month}-${day}`;
return formattedDate
}


export const formatDateWithMonthName=(isoDateString)=>{

    const date = new Date(isoDateString);
    
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // Extract the day, month, and year
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    
    // Format the date as "1-Jan-2024"
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate
    
    }
     
    