export const handlelogout = (navigate) => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('sessionLS');
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_mobile_no');
    localStorage.removeItem('user_country_code');
    localStorage.removeItem('signup_phonenumber')
    localStorage.removeItem('signup_countryCode')
    localStorage.removeItem('signup_username')
    navigate("/")
   
   
  }