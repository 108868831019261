import React, { useEffect, useState } from 'react';

const AccordionItem = ({ id, title, children ,isActive,onToggle}) => {
 const [localActive, setLocalActive] = useState(false);

  useEffect(() => {
    setLocalActive(isActive);
  }, [isActive]);

  const toggleAccordion = () => {
    const newState = !localActive;
    setLocalActive(newState);
    onToggle(id, newState);
  };

  return (
    <div className={`hs-accordion ${localActive ? 'tab-shadow rounded-2xl py-2  sm:p-4' : ''}`} id={id}>
      <button
        className={`font-nunito text-black hs-accordion-toggle  px-2.5 inline-flex items-center justify-between w-full text-base  sm:text-lg text-start text-gray-800 hover:text-gray-500 rounded-lg focus:outline-none ${
          isActive ? 'hs-accordion-active:text-blue-600 border-0' : 'border border-lightgray py-2.5'
        }`}
        onClick={toggleAccordion}
        aria-controls={id}
      >
        {title}
        <svg
          className={`w-4 h-4 transition-transform ml-2 ${isActive ? 'transform rotate-90' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>

      <div
        id={`hs-basic-with-arrow-collapse-${id}`}
        className={`font-nunito text-sm text-justify text-gray-medium hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${
          isActive ? '' : 'h-0'
        }`}
        aria-labelledby={id}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
