// newsletterUtils.js
import { ApiBaseUrl } from "../Utils/urls";
const isValidEmail = (email) => {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

const submitNewsletter = (email, setEmail, setErrorMsg, setSuccessMsg, setModalOpen, setLoading) => {
    if (email.trim() === "") {
      setErrorMsg("Please Enter Email");
      return;
    }
    if (!isValidEmail(email)) {
      setErrorMsg("Please Enter a Valid Email");
      return;
    }
  
    setLoading(true);
    fetch(`${ApiBaseUrl}/fr-landingpage/subscribe-to-newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
       // console.log(data);
        setLoading(false);
        if (data.success && data.data === "Email already subscribed to newsletter") {
          setErrorMsg('Email is already subscribed to newsletter!');
        } else {
          setSuccessMsg('Thanks for subscribing to our newsletter.');
          setModalOpen(true); 
        }
        setTimeout(() => {
          setErrorMsg('');
          setSuccessMsg("");
          setModalOpen(false);
        }, 6000);
  
        setEmail('');
      })
      .catch(error => {
        console.error('There was a problem with the request:', error);
        // Handle error
      });
  };
  
  export default submitNewsletter;
  