import React, { useEffect, useRef, useState } from 'react'
import NomineeContainer from './NomineeContainer'
import CustomeNomineeInput from './CustomeNomineeInput'
import "./nominee-style.css"
import NomineeModal from './NomineeModal';
import { OtpTab } from '../Signup/OtpTab';
import { changeDateFormat } from '../../Utils/DateFormat';
import { ApiBaseUrl } from '../../Utils/urls';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AddNomineeFunc, NomineeVerifyFunc } from '../../Api/NomineeApi';
import { IoMdArrowBack } from 'react-icons/io';
import {  fetchAllNominee } from '../../Api/NomineeApi';


export default function EditNominee() {

  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [isVerifying,setIsVerying]=useState(false)
  const sliderRef = useRef(null);
  
  const [nomineeDetails,setNomineeDetails]=useState({})
  const [percentage, setPercentage] = useState(0);
  const valueRef = useRef(null);
  const [isMobileTyping,setMobileTyping]=useState(false) //to check  user has started typing mobile no or not
  const [isAadharTyping,setAadharTyping]=useState(false) //to check  user has started typing aadhar no or not
 
  const [isOtpsent,setOtpsent]=useState(false)
  const [isSameAadhaar,setSameAadhar]=useState(false) //same aadhaar error
  const [alertMessage,setAlertMsg]=useState("")
  const [isotpVerifying,setOtpVerifying]=useState(false) //for otp verify button string
  const [isOtpVeried,setOtpVerified]=useState(false)//for successfull otp verification alert
  const [iswrongOtp,setWrongOtp]=useState(false)
  const userId=localStorage.getItem("user_id")
  const accesstoken=localStorage.getItem("accestoken")
  const userCountryCode=localStorage.getItem("user_country_code")
  const userMobileNo=localStorage.getItem("user_mobile_no")
  const {id}=useParams()
  const [singleNominee,setSingleNominee]=useState({})
  const [isAnyFieldChanged, setIsAnyFieldChanged] = useState(false);
const navigate=useNavigate()
const [allNominee,setAllNominee]=useState([])
const [isLoading,setLoading]=useState(false)
const [totalAllocated,setTotalAllocated]=useState(0)
const [remainingAllocation,setRemainingAllocation]=useState(0)
const [isNomUpdationLoading,setnomUpdationLoading]=useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setTimerActive(false)
          
        }
        return prevTimer - 1;
      });
    }, 1000);
  
    
    return () => clearInterval(interval);
  }, [timerActive]);

  useEffect(() => {
    if (otp.length === 4) {
      setAllInputsFilled(true);
    } else {
      setAllInputsFilled(false);
    }
  }, [otp]);


  const openModal = () => {
    setIsModalOpen(true);
    setTimer(60); 
    setTimerActive(true)
  };
  
  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  const handleResend=()=>{
   
    setTimer(60); 
    setTimerActive(true)
  }

const handleTypingMobile = () => {
    setMobileTyping(true);
};
const handleTypingAadhar = () => {
  setAadharTyping(true);
};

const handleNomineeInputChange=(e)=>{
  const { name, value } = e.target;
  if (name === 'percentage_of_investment') {
    // If the input is the percentage range input, update the percentage state
    setPercentage(value);
  }

  setNomineeDetails({ ...nomineeDetails, [name]: value });
  
  setSingleNominee(prevState => ({
    ...prevState,
    [name]: value
  }));
  // Set isAnyFieldChanged to true when any field changes its value
  setIsAnyFieldChanged(true);

}




const handlenomineeVerify=()=>{
  NomineeVerifyFunc(
    userId,
    otp,
    accesstoken,
    setOtpVerified,
    closeModal,
    navigate,
    setWrongOtp)
}

//fetch single nominee details
const fetchSingleNominiDetails=()=>{
    fetch(`${ApiBaseUrl}/fr-user/get-nominee-details?user_id=${userId}&nominee_id=${id}`, 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      }
    
    }
    
    )
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(resdata => {
       
        console.log('Response from server:', resdata);
       
            setSingleNominee(resdata.data.nominees[0])
            setTotalAllocated(resdata.data.total_percentage)
       
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}
useEffect(()=>{
    fetchSingleNominiDetails()
    
},[])

const handleUpdateNominee=(e)=>{
  e.preventDefault()
  
  const nomineeBody = {
    ...singleNominee,
    dob: changeDateFormat(singleNominee.dob),
    user_id: userId,
    nominee_id:id
    };
  

    setOtpVerifying(true)
 
  fetch(`${ApiBaseUrl}/fr-user/initiate-update-nominee`, 
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken 
    },
    body: JSON.stringify(nomineeBody)
  }
  
  )
    .then(response => {
    
     
     
      return response.json();
    })
    .then(data => {
     setOtpVerifying(false)
    //  console.log('Response from server:', data);
      if(data.success)
      {
        localStorage.setItem("session_nominee_otp",data.data.sessionForOTP)

        setOtpsent(true)
        setTimeout(() => {
          setOtpsent(false)
          openModal()
        }, 2000);
      }

      if(!data.success)
      {
       
//console.log(data.data.msg);
        setSameAadhar(true)
        setAlertMsg(data.data.msg)
        setTimeout(() => {
          setSameAadhar(false)
        
        }, 3000);

       
      }
    })
    .catch(error => {
     
      console.error('error:', error);
     
    });
}


// useEffect(()=>{
//   fetchAllNominee(userId,accesstoken,setAllNominee,setLoading)
// },[])


useEffect(()=>{
const remainingAllocationIntotal=100-Number(totalAllocated)

setRemainingAllocation(remainingAllocationIntotal+Number(singleNominee.percentage_of_investment))
console.log(remainingAllocation)
},[totalAllocated])





  return (


    <>


{
  isOtpsent && (
    <SuccessAlert message="OTP Sent Successfully" />
  )
}

{
  isOtpVeried && (
    <SuccessAlert message="Nominee Updated Successfully" />
  )
}
    

{
  iswrongOtp && (
    <ErrorAlert message="Invalid OTP. Please Try Again!" />
  )
}


{
  isSameAadhaar && (
    <ErrorAlert message={alertMessage}/>
  )
}


{
  isModalOpen &&(
<NomineeModal closeModal={closeModal}>


         <div className='flex flex-col gap-7 font-nunito'>

     
            <p className='text-secondary text-xl font-bold text-center'>OTP Verification</p>
            <p className='text-lg font-normal text-center'>Enter 4 digit OTP sent to registered mobile 
number <span className='text-secondary'>{userCountryCode}{userMobileNo}</span> </p>
           
              <div className='flex flex-col gap-9 '>
                <div className='flex flex-col gap-9 '>
                <OtpTab length={4} maxChar={1} setOtp={setOtp} otp={otp} setAllInputsFilled={setAllInputsFilled}/>
                  <button onClick={handlenomineeVerify} disabled={!allInputsFilled ||isVerifying} 
                  className={`bg-secondary text-white verifyBtn-profile ${allInputsFilled ? 'bg-secondary text-white' : 'disable-btn-bg disabled'} `}
                  
                  >
                    {isotpVerifying?"Verifying...":"Verify"}
                  </button>
         
                

                </div>
                 
                 
             
               
            <div>

            {
                timerActive?
                <p className='text-sm text-center'>00:{timer < 10 ? `0${timer}` : timer}</p> 
                  :
                  ""
              }
           {/* <p className='text-sm text-center'>00:60</p>  */}

           {
            !timerActive ?
<div className='flex gap-5  items-center justify-center '>
              <p className='text-xs sm:text-lg font-normal text-gray'>Didn’t receive any code? 
              <span className='text-xs sm:text-sm font-normal text-secondary' onClick={handleResend}>
                &nbsp; RESEND</span> 
                </p>
            </div>
            :
            ""
           }
             
          </div>
        
             
         
         
              </div>


         </div>
          
        </NomineeModal>
  )
}


    <NomineeContainer>
       <div>
      <div className='font-nunito'>

      <div className='flex gap-2 items-center px-2.5 pb-4'>
  <Link to='/user/nominee'>
  <IoMdArrowBack />
  </Link>
  <div className='text-center w-full'>
  <p className='text-base sm:font-medium text-center sm:text-left font-bold '>Nominee Details </p>
  </div>

 </div>

        <div className='profileSection  font-nunito'>

<form  onSubmit={handleUpdateNominee}>
    <div className='flex flex-col gap-2.5'>
        {/* form */}
        <div className='flex flex-col gap-4'>

                <div className='flex flex-col sm:flex-row labeldiv'>

                    <div className='flex sm:w-1/2 flex-col gap-2.5  px-2 sm:px-10 '>
                            <p className='text-left text-sm text-gray '>Nominee Name<span style={{color:"#c51f1f"}}>*</span></p>
                          <div>
                          <CustomeNomineeInput 
                            name="nominee_name" 
                            value={singleNominee.nominee_name} 
                            onChange={handleNomineeInputChange}
                            placeholder="Full Name (As per PAN Card)"
                            maxLength={70}
                             />
                           
                          <p className='text-sm'></p>
                          </div>
                           
                    </div>

                    <div className='flex sm:w-1/2 flex-col gap-2.5  px-2 sm:px-10'>
                            <p className='text-left text-sm text-gray'>Mobile Number<span style={{color:"#c51f1f"}}>*</span></p>
                           <div >
                           <CustomeNomineeInput 
                            name="mobile_no" 
                            maxLength="10"
                           onChange={(e)=>{
                              handleTypingMobile();
                              handleNomineeInputChange(e)
                          }}
                             placeholder="Enter mobile number"
                            value={singleNominee.mobile_no}  
                          
                            />
      
                            {
  isMobileTyping && nomineeDetails.mobile_no.length !== 10 &&
  <p className='text-sm' style={{color:"#e23838",textAlign:"left"}}>
  Please enter a valid 10-digit number.
  </p>
                            }
                           </div>
                            
                    </div>
                </div>


                <div className='flex flex-col sm:flex-row labeldiv'>

         <div className='flex w-full  flex-col gap-2.5  px-2 sm:px-10 '>
                  <p className='text-left text-sm text-gray '>Gender<span style={{color:"#c51f1f"}}>*</span></p>
              <select 
              name="gender"
              value={singleNominee.gender}  
              onChange={handleNomineeInputChange}
            className='border w-full border-gray text-sm text-gray rounded-md  sm:rounded-lg nominee-input'
            >

            <option value="" selected  disabled>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
         </select>

                    </div>

    <div className='flex  w-full flex-col gap-2.5  px-2 sm:px-10'>
        <p className='text-left text-sm text-gray'>Relation<span style={{color:"#c51f1f"}}>*</span></p>
          <select 
             name='relation'
              value={singleNominee.relation}  
              onChange={handleNomineeInputChange}
            className='border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg nominee-input'
            >

            <option   selected  disabled>Relation with Nominee</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Sister">Sister</option>
              <option value="Brother">Brother</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Spouse">Spouse</option>
         </select>
                    </div>
                </div>

      <div className='flex flex-col sm:flex-row labeldiv'>
          <div className='flex sm:w-1/2 flex-col gap-2.5  px-2 sm:px-10 '>
            <p className='text-left text-sm text-gray '>Date of Birth<span style={{color:"#c51f1f"}}>*</span></p>
              <input
              type="date"
              className={`border grow border-gray  text-sm text-gray rounded-md  sm:rounded-lg   profile-input`}
              placeholder=""
               name="dob"
               onChange={handleNomineeInputChange}
               value={singleNominee && singleNominee.dob? changeDateFormat(singleNominee.dob):""} 
               max={new Date().toISOString().split("T")[0]}
             />
            </div>

            <div className='flex sm:w-1/2 flex-col gap-2.5  px-2 sm: px-2 sm:px-10'>
            
              <p className='text-left text-sm text-gray'>Aadhar Number <span style={{color:"#c51f1f"}}>*</span></p>
                 <CustomeNomineeInput 
                 name="aadhar_number" 
                  placeholder="1234567890"
                  maxLength="12"
                  disabled
                  onChange={(e)=>{
                    handleTypingAadhar()
                    handleNomineeInputChange(e)
                  }

                  }
                  value={singleNominee.aadhar_number}  
                   />
                   {
                      isAadharTyping && singleNominee.aadhar_number.length !== 12 &&
                      <p className='text-sm' style={{color:"#e23838",textAlign:"left"}}>
                      Please enter a valid 12-digit aadhar number.
                      </p>
                      }
            
                
           </div>
     </div>



                  <div className='flex flex-col sm:flex-row labeldiv'>

                <div className='flex sm:w-1/2 flex-col gap-2.5  px-2 sm:px-10 '>
                  <p className='text-left text-sm text-gray '>What percentage of your investment do you wish to dedicate to this nominee?</p>
             

      {/* show range here */}
      <div className="slider-value" ref={valueRef}> {singleNominee?.percentage_of_investment}%</div>
       <input 
                        type="range"
                        min="1"
                        max="100"
                        name='percentage_of_investment'
                        value={singleNominee.percentage_of_investment} 
                        onChange={handleNomineeInputChange}
                        className='nomineeRangeSlider'
                        ref={sliderRef}
                      />


<div className='flex gap-2'>
<span class="material-symbols-outlined allocationIcon">
donut_large
</span>

<p>Remaining allocation: {remainingAllocation}%</p>
</div>


                    </div>

                </div>   

        </div>

        <div className='flex  pr-0 pt-10 sm:pl-8'>
            <button 
            type='submit' 
            className={`nomineesibmitRelative ${isAnyFieldChanged? 'activenomineeBtn':'disablenomineeBtn'}`}
            disabled={!isAnyFieldChanged}
            >
             Edit Nominee
            </button>
        </div>


     </div>
</form>
   
           
          </div>

</div>
    </div>
    </NomineeContainer>

    </>
  )
}
