import React from 'react'
import "./responsive-profile.css"



export default function CustomeProfileInput({name,placeholder,disabled,value,onChange,maxLength}) {
  return (
    <input
      type="text"
      className={`border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg   profile-input ${disabled ? 'disabledbg' : ''}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
      style={{width:"100%"}}
      name={name}
    />
  )
}
