import React, { useState,useRef,useEffect } from 'react'
import propImage from '../../../../Assets/propImageMob.svg'
import Location from '../../../../Assets/bookedPropLocation.svg'
import Vertical from '../../../../Assets/verticalLineBooked.svg'
import { RiArrowUpFill } from "react-icons/ri";
import { BsFilterLeft } from "react-icons/bs";
import Download from '../../../../Assets/downloadBookedProp.svg'
import { IoMdInformationCircleOutline } from "react-icons/io";
import InfoPopup from '../../../InfoPopup/InfoPopup'
import SuccessAlert from '../../../Alert/SuccessAlert';
import ResaleCardMob from '../../../ResalePopups/MobResaleCardPopUp/MobResaleCardPopUp';
import MobResaleConfirmPopUp from '../../../ResalePopups/MobResaleConfirmPopup/MobResaleConfirmPopup';
import CancelResalePopup from '../../../ResalePopups/CancelResalePopup/CancelResalePopup';
import { IconContext } from 'react-icons'
import { MdOutlineLocationOn } from 'react-icons/md'

function ResaleDashboardMobCard(props) {
    const [showdownloDInfoPopup, setShowdownloDInfoPopup] = useState(false);
    const [isOpenMob, setIsOpenMob] = useState(false);
    const [showMobHeight, setShowMobHeight] = useState(0);
    const [mobFilterHighlight, setmobFilterHighlight] = useState(1);
    const mobSectionRef = useRef(null);

    const handleshowdownloadInfoPopup = () => {
        setShowdownloDInfoPopup(true);
      };
      const handleclosedownloadInfoPopup=()=>{
        setShowdownloDInfoPopup(false);
      }
    //   console.log(props.propertyId);
    //   console.log(props.userID)

    const filterIconRef = useRef(null);
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          mobSectionRef.current &&
          !mobSectionRef.current.contains(event.target) &&
          filterIconRef.current &&
          !filterIconRef.current.contains(event.target)
        ) {
          setIsOpenMob(false);
          setshowConfirmPopup(false);
    
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [mobSectionRef]);

    useEffect(() => {
        const updateShowMobHeight = () => {
          if (mobSectionRef.current) {
            setShowMobHeight(mobSectionRef.current.getBoundingClientRect().height);
          }
        };
        
    
        window.addEventListener('resize', updateShowMobHeight);
        updateShowMobHeight();
    
        return () => window.removeEventListener('resize', updateShowMobHeight);
      }, [isOpenMob]);
    
   

      const [PropertyIdForListingApi, setPropertyIdForListingApi] = useState(0);
      const [userIdForListingApi, setuserIdForListingApi] = useState("");
      const [accessTokenForListingApi, setaccessTokenForListingApi] = useState("");
      const [noOfTokensForListingApi, setnoOfTokensForListingApi] = useState(0);
      const [currentPriceForListingApi, setcurrentPriceForListingApi] = useState(0)
      const [showConfirmPopup, setshowConfirmPopup] = useState(false);
      const [successListing, setsuccessListing] = useState(false);
      const [showIRRInfoPopup, setshowIRRInfoPopup] = useState(false);

    
      const ShowBanner=()=>{
        setIsOpenMob(!isOpenMob);
        setshowConfirmPopup(true);
      }


      useEffect(() => {
        if(showConfirmPopup){
          setShowMobHeight(isOpenMob ? 380 : 0);
        }
        else{
          setShowMobHeight(!isOpenMob?0:"");
        }
      }, [isOpenMob,showConfirmPopup]);



      useEffect(() => {
        if (showConfirmPopup) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [showConfirmPopup, isOpenMob]);



     



      const handleIRRInfoPopup = () => {
        setshowIRRInfoPopup(!showIRRInfoPopup);
      };
  

  return (
    <div>
      <div className=' flex flex-col p-2.5 rounded-lg parentBookedContainer'>
        <div className=' flex flex-row justify-center'><img src={propImage} className='w-full'/></div>
        
        <div className='-mt-8'>
            <div className=' text-start pl-[12px] '>
                <div className='bg-[#78F9FF] h-[22px] w-[76px] text-[12px] font-normal flex rounded-md flex-row justify-center'>
                    <div className='flex flex-col justify-center'>Residential</div>
                </div>
            </div>
            <div className=' mt-6 flex flex-col'>
                <div className=' text-[16px] font-semibold text-start'>{props.name}</div>
                <div className=' text-[16px] font-semibold text-start flex flex-row gap-[14px]'>
                <div className='locationImageCollectCard'>
                <IconContext.Provider value={{ color: "black", className:"w-4 h-4" }}>
                <div>
                <MdOutlineLocationOn/>
                </div>
                </IconContext.Provider>
            </div>
                    <div className=' text-[12px] font-normal text-[#5D5C5C] -ml-[8px]'>{props.city}, {props.location}</div>
                </div>
            </div>

            <div className='mt-[15px]' style={{color:"#DE28A7",width:"100%"}}><img className='w-full' src={Vertical}/></div>



            <div className='flex flex-row justify-between mt-[15px]'>
                <div className=' flex flex-col'>
                    <div className='  text-[16px] font-semibold'>{parseFloat(props.listableShares).toFixed(0)}</div>
                    <div className=' text-[12px] font-medium text-[#5D5C5C]'>No. of Tokens</div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <div className='  text-[16px] font-semibold'>₹{parseFloat(props.currentPrice).toFixed(0)}</div>
                        <div className='flex flex-col justify-center'><RiArrowUpFill style={{height:"24px",width:"18px",color:"#219653"}}/></div>
                    </div>
                    <div className=' text-[12px] font-medium text-[#5D5C5C]'>Listing Price</div>
                </div>
                <div className=' flex flex-col'>
                    <div className='  text-[16px] font-semibold'>{props.capitalGain}%</div>
                    <div className=' text-[12px] font-medium text-[#5D5C5C]'>Potential Gain</div>
                </div>
            </div>


            <div className=' flex flex-row gap-[15px] mt-[16px] downloadStatusSection pl-2'>
            <div className=' flex flex-col justify-center  '><img src={Download} className='h-[17px] w-[17px]'/></div> 
            <div className='flex justify-center gap-3'>
                <div className='flex justify-center items-center'>
                <div className="tooltip" onClick={handleIRRInfoPopup}>
                    <IoMdInformationCircleOutline style={{fontSize:"20px",color:" #D328A7"}} />
                    {

                      showIRRInfoPopup && (
                          <>
  
                                          <InfoPopup>
                                              <div className='p-2'>
                                              <p className='text-blue text-left text-sm'>Download</p>
                                                  <p className='text-gray text-left text-sm'>
                                                  Download the investment agreement/ certificate via the download button.
                                              </p>
                                             
                  
                  
                                              </div>
                             
                                              <button onClick={handleIRRInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                                  Ok</button>
                                              
                                              </InfoPopup>
                          </>
                      )
                    }
                </div> 
                </div>
            </div>
            
           </div>


            <div className=' bg-[black] rounded-lg h-[44px] mt-[20px] flex flex-col justify-center'>
                <div className='flex flex-col justify-center text-[16px] font-bold'>
                <div className='bg-white text-2xl font-bold text-start '
                 ref={filterIconRef}>
                    <div onClick={ShowBanner} className='bg-[black] text-[16px] font-bold text-center text-white cursor-pointer' >Cancel</div>    
                </div>
                    </div>
            </div>


            


              {
                isOpenMob && <div className="overlay showOverlay" onClick={() => setIsOpenMob(false)}></div>
              }

              <div className={`bg-white mob-section rounded-t-lg`} ref={mobSectionRef} style={{ height: `${showMobHeight}px`, position: 'fixed', bottom: 0, width: '100%', left: 0, right: 0 }}>
              <div className='bg-white pb-5 hideMob' >
              <div className=' flex flex-row justify-center'>
                <div className='h-1.5 text-[8px] w-60 mt-1.5 rounded-lg' style={{backgroundColor:"rgba(217, 217, 217, 1)"}}></div>
              </div>
             <div className='bg-white'>
              {showConfirmPopup&&(
                <CancelResalePopup
                setIsOpenMob={setIsOpenMob}
                userId={props.userID}
                saleOfferId={props.saleOfferId}
                setshowConfirmPopup={setshowConfirmPopup}
                setsuccessListing={setsuccessListing}
                setapiUpdateFlag={props.setapiUpdateFlag}
                apiUpdateFlag={props.apiUpdateFlag}
                
                />
              )}
            


             </div>

              </div> 
              </div>
              {
                successListing && (
                    <SuccessAlert message="Listing Cancelled Successful"/>
                )
            }


        </div>


      </div>
    </div>
  )
}

export default ResaleDashboardMobCard
