
import axios from 'axios';
import { ApiBaseUrl } from '../../Utils/urls';
import { EMAIL_VERIFICATION_REQUEST, EMAIL_VERIFICATION_SUCCESS } from '../actionTypes';

export const emailVerificationRequest = () => ({
  type: EMAIL_VERIFICATION_REQUEST,
});

export const emailVerificationSuccess = (response) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  payload: response,
});

export const emailVerificationFailure = (error) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  payload: error,
});

export const emailUpdateVerifyAction = (otpData,accessToken) => (dispatch) => {
  dispatch(emailVerificationRequest());
  return axios.post(`${ApiBaseUrl}/fr-user/verify-update-email`,otpData,
  
  {
    headers: {
      Authorization: accessToken, 
      'Content-Type': 'application/json', 
    },
  }
  )

 
    
};
