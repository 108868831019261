import React from 'react'
import { changeDateFormat } from '../../Utils/DateFormat'

export default function NomineeCard(props) {

    const {
        nominee_id,
        nominee_name,
        mobile_no,
        gender,
        relation,
        dob,
        aadhar_number,
        percentage_of_investment,
        handleOpenDeleteModal,
        handleOpenUpdateModal
       
    }=props

    // const onclickDeleteNominee=()=>{
    //     handleDeleteNominee(nominee_id)
    // }

  

  return (
    <div className='nominee-card'>
    <div className='flex gap-2.5 flex-row-reverse'>

    <span 
    class="material-symbols-outlined nomineeIcon cursor-pointer" 
    onClick={handleOpenDeleteModal}>
        delete
        </span>

    <span class="material-symbols-outlined nomineeIcon cursor-pointer" onClick={handleOpenUpdateModal}>border_color </span>

    </div>

        <div className='flex flex-col gap-4 mt-3'>
                <div className='flex justify-between'>
                    <p className='text-gray text-sm font-medium text-left w-1/2'>Name</p>
                    <p className='text-blue text-sm font-semibold text-left w-1/2'>{nominee_name && nominee_name}</p>
                </div>

                <div className='flex justify-between'>
                    <p className='text-gray text-sm font-medium text-left w-1/2'>Mobile Number</p>
                    <p className='text-blue text-sm font-semibold text-left w-1/2'>{mobile_no && mobile_no}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray text-sm font-medium text-left w-1/2'>Gender</p>
                    <p className='text-blue text-sm font-semibold text-left w-1/2'>{gender && gender}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray text-sm font-medium text-left w-1/2'>Relationship</p>
                    <p className='text-blue text-sm font-semibold text-left w-1/2'>{relation && relation}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray text-sm font-medium text-left w-1/2'>Date of Birth</p>
                    <p className='text-blue text-sm font-semibold text-left w-1/2'>{dob && changeDateFormat(dob)}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray text-sm font-medium text-left w-1/2'>Aadhar Number</p>
                    <p className='text-blue text-sm font-semibold text-left w-1/2'>{aadhar_number && aadhar_number}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray text-sm font-medium text-left w-1/2'>Dedicated percentage</p>
                    <p className='text-blue text-sm font-semibold text-left w-1/2'>{percentage_of_investment && percentage_of_investment}%</p>
                </div>

        </div>

  </div> 
  )
}
