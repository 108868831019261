import React, { useEffect, useState } from 'react';
import AvailableSellOfferCard from '../Cards/AvailableSellOfferCard/AvailableSellOfferCard';
import ResaleBankLoader from '../BankDetails/ResaleBankLoader';


function AvailableSell(props) {
  const [SellData, setSellData] = useState([]);


  useEffect(() => {
    setSellData(props.avaialbleSellOffers);
  }, [props.avaialbleSellOffers]);


  return (
    <div className='p-[10px] flex flex-col'>
      <div className='flex flex-row justify-between pl-2'>
        <div className='optResaleCards text-[#16A34A] text-start' style={{ width: "30%" }}>Orders</div>
        <div className='optResaleCards text-[#16A34A] text-start' style={{ width: "30%" }}>Tokens Qty.</div>
        <div className='optResaleCards text-[#16A34A] text-start' style={{ width: "40%" }}>Per Token Price</div>
      </div>


      <div className=''>
      {(
        <div className='flex flex-col scrollableResale'>
        {SellData && SellData.length > 0 ? (
          SellData.map((offer, index) => (
            <AvailableSellOfferCard 
            key={index} 
            offer={offer}
            salePrice={offer.sale_price}
            saleId={offer.sale_offer_id}
            tokenQuantity={offer.total_shares}
            orders={offer.orders}
            />
          ))
        ) : (
          SellData ? (
            <div className="alertBar" style={{height:"100vh"}}> 
              No  offers available at the moment.
            </div>
          ) : ( 
            <div> <ResaleBankLoader /></div>
          )
        )}
      </div>
      
      )}
    </div>
    </div>
  );
}

export default AvailableSell;
